<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Class of Bussiness"></app-drawer_menu>
	  	<v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
	    ></v-progress-linear>
		<v-tabs
	      background-color="white"
	      color="fourth"
		  v-if = "this.access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_classbussiness">
				<v-row class="mx-2 ma-1">
					<v-col cols="12" sm="12" md="12">
						<v-btn
						color		="tertiary"
						class		="white--text"
						:disabled 	= "access.add === 0 ? true:false" 
						rounded
						@click 		= "Add" 
						small
						>
						<v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
						</v-btn> &nbsp;
						<v-btn
						color		="primary"
						class		="white--text"
						:disabled 	= "access.add === 1 || access.edit === 1 ? false:true"
						rounded
						@click 		= "Confirm('write','','')" 
						small
						>
						<v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
						</v-btn>
					</v-col>
					<v-col cols="12" sm="12" md="12" class="mb-n5">
						<v-row class="mx-2">
							<v-col cols="12" sm="4" md="4">
								<v-text-field 
								v-model 	  	= "form.class_id"
								required 
								:rules 	  		="form.class_id_rules"
								:readonly    	= "form.class_id_readonly"
								:counter		="maxinput.id"
								:maxlength		="maxinput.id"
								label       	= "Code *"
								placeholder 	= "Code"
								id 				= "class_id"
								@keyup.enter 	= "Confirm('write','','')"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="4" md="4">
								<v-text-field 
								v-model 	  	= "form.class_desc"
								required 
								:rules 	  		="form.class_desc_rules"
								label       	= "Class Description"
								placeholder 	= "Class Description"
								id 				= "class_desc"
								@keyup.enter 	= "Confirm('write','','')"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" sm="2" md="2" class="mt-n5">
								<label>Active Status</label>
								<v-switch
									v-model		="form.class_actived"
									color  		= "primary"
									id 			= "form.class_actived"
									:label 		="form.class_actived !== true ? '' : 'Active'" 
								></v-switch>
							</v-col>
						</v-row>
						<v-spacer></v-spacer>
						<v-row class="mx-2">
							<v-col cols="12" sm="8" md="8" class="mt-n5">
								<v-btn-toggle dense v-model="form.class_fieldtype" shaped v-if="this.url_type === ''">
									<v-btn>
									Und. Survey Info
									</v-btn>
									<v-btn color="tertiary">
									Claim Survey Info
									</v-btn>
									<v-btn color="error">
									Popular TC Date
									</v-btn>
								</v-btn-toggle>
							</v-col>
							<v-col cols="12" sm="4" md="4" class="mt-n5">
								<!-- <v-btn-toggle dense v-model="form.class_fieldfunc" shaped>
									<v-btn>
									Add
									</v-btn>
									<v-btn>
									Delete
									</v-btn>
								</v-btn-toggle> -->
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" sm="12" md="12">
						<!-- <v-btn-toggle dense v-model="form.class_mapdata" shaped width="100%">
							<v-btn>
							General Condition
							</v-btn>
							<v-btn>
							General Exclusion
							</v-btn>
							<v-btn>
							General Clauses
							</v-btn>
							<v-btn>
							Premium Scalling Table
							</v-btn>
							<v-btn>
							First Loss Scaling
							</v-btn>
							<v-btn>
							Risk & Lose
							</v-btn>
						</v-btn-toggle> -->
					</v-col>
				</v-row>
				<v-row class="mx-2">
					<v-col cols="12" sm="12" md="12">
					</v-col>
				</v-row>
				<v-row class="mx-2">
					<v-col cols="12" sm="5" md="5">
					<div v-show="url_type !== 'add' ? true:false ">
						<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
						<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.class_cdate}}
Created User 	 : {{form.class_cuser}}
Last Update Date : {{form.class_ldate}}
Last Update User : {{form.class_luser}}
</pre>
						</v-banner>
					</div>
					</v-col>
                <v-col cols="12" sm="12" md="12" class="mt-n8" v-show="url_type !== 'add' ? true:false ">
                	<template>
					  <v-card-title>
							<v-btn-toggle dense v-model="form.classtc_category" shaped>
								<v-btn @click="ListClassTC('',form.class_id,'CO')">
								Condition
								</v-btn>
								<v-btn @click="ListClassTC('',form.class_id,'CL')">
								Clause
								</v-btn>
								<v-btn @click="ListClassTC('',form.class_id,'EX')">
								Exclusion
								</v-btn>
							</v-btn-toggle>
		                <v-spacer></v-spacer>
		                <v-btn
		                	color  = "primary"
		                	title  = "Search"
		                	v-if   = "btn_search === false"
		                	@click = "btn_search = true"
							small>
		                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
		                </v-btn>
		                <v-text-field
				        v-model="search_classtc"
				        append-icon="search"
				        @click:append="btn_search = false"
				        label="Search"
				        single-line
				        hide-details
				        v-if ="btn_search"
				      	></v-text-field>
					  </v-card-title>
					  <v-data-table
					    v-model="selected_classtc"
					    :headers="header_classtc"
					    :items="items_classtc"
					    :single-select="singleselect_classtc"
					    :search="search_classtc"
					    item-key="classtc_id"
					    show-select
					    class="elevation-1"
					    :footer-props="{
					      showFirstLastPage: true,
					      firstIcon: 'first_page',
					      lastIcon: 'last_page',
					      prevIcon: 'keyboard_arrow_left',
					      nextIcon: 'keyboard_arrow_right'
					    }"
						dense
						:loading 	= "loading.list"
						loading-text="Loading... Please wait"
					>
					  >
					    <template v-slot:top>
					      <v-btn 
		                	class = "mt-3 ml-2"
							color = "fourth"
		                	title = "Add"
							@click="Classtc('addclasstc')"
		                	outlined
							:disabled = "access.add === 0 ? true:false">
		                    <v-icon>{{$vars.V('icon_add')}}</v-icon>
		                	</v-btn>
						  <v-btn 
						  	class = "mt-3 ml-2"
		                	color = "tertiary"
							v-if  = "selected_classtc.length > 0"
		                	title = "Delete"
		                	@click = "Confirm('multideleteclasstc','')"
		                	outlined
							:disabled = "access.delete === 0 ? true:false">
		                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
		                	</v-btn>
		                	<v-switch v-model="singleselect_classtc" label="Single select" class="pa-3"></v-switch>
					    </template>
					    <template v-slot:item.action="{ item }">
					      <v-icon
					        small
					        class="mr-2"
					        @click="EditClassTC(item.classtc_id, 'editclasstc')"
							:disabled = "access.edit === 0 ? true:false"
					      >
					        edit
					      </v-icon>
					      <v-icon
					        small
					        @click="Confirm('deleteclasstc',item.classtc_id)"
							:disabled = "access.delete === 0 ? true:false"
					      >
					        delete
					      </v-icon>
					    </template>
					  </v-data-table>
					</template>
                </v-col>
           		</v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  	<v-card-title
			  		class="ml-n4"
				>
					<v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	:title="$vars.V('txt_select_all_page')"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
					</v-btn>
	                <v-btn 
	                	color 		= "error"
	                	title 		= "delete selected"
	                	@click 		= "Confirm('multidelete','')"
						class		= "mr-2"
	                	v-if  		= "selected.length > 0"
						:disabled 	= "access.delete === 0 ? true:false"
						small>
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-btn 
	                	color  = "primary"
	                	title  = "Search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true"
						small>
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
					<v-spacer></v-spacer>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    v-model="selected"
			    :headers="headers"
			    :items="items"
			    :search="search"
			    @input="Check($event)"
			    item-key="class_id"
			    show-select
			    class="elevation-1"
			    :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
				dense
			    :loading 	= "loading.list"
    			loading-text="Loading... Please wait"
			  >
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class		="mr-2"
			        @click		="Edit(item.class_id)"
					:disabled 	= "access.edit === 0 ? true:false"
					title		= "edit"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click		="Confirm('delete',item.class_id)"
					:disabled 	= "access.delete === 0 ? true:false"
					title 		= "delete"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
		</v-tab-item>
  	</v-tabs>
    <v-dialog
      v-model="dialog.classtc"
      scrollable
      fullscreen 
      persistent
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="primary white--text"><span @click="dialog.classtc = false"><v-icon color="white" class="mt-n1">arrow_back_ios</v-icon> {{ form.class_id  }} - {{form.class_desc}}</span><v-spacer></v-spacer><v-btn fab x-small outlined @click="Confirm(url_type,'')"><v-icon color="white">save</v-icon></v-btn></v-card-title>
        <v-card-text>
			<v-row class="mx-2">
				<v-col cols="12" sm="6" md="6">
					<v-text-field 
					v-model 	  	= "form.classtc_id"
					required 
					:rules 	  		="form.classtc_id_rules"
					:readonly    	= "form.classtc_id_readonly"
					:counter		="maxinput.id"
					:maxlength		="maxinput.id"
					label       	= "Code *"
					placeholder 	= "Code"
					id 				= "classtc_id"
					@keyup.enter 	= "Confirm('write','','')"
					>
					</v-text-field>
				</v-col>
				<v-col cols="12" sm="6" md="6" class="mt-n3">
					<label>Active Status</label>
					<v-switch
						v-model		="form.classtc_actived"
						color  		= "primary"
						id 			= "form.classtc_actived"
						:label 		="form.classtc_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
				<v-col cols="12" sm="6" md="6">
					<v-text-field 
					v-model 	  	= "form.classtc_shortdesc_eng"
					required 
					:rules 	  		="form.classtc_shortdesc_eng_rules"
					:readonly    	= "form.classtc_shortdesc_eng_readonly"
					label       	= "Short Desc Eng *"
					placeholder 	= "Short Desc Eng"
					id 				= "classtc_shortdesc_eng"
					>
					</v-text-field>
				</v-col>
				<v-col cols="12" sm="6" md="6">
					<v-text-field 
					v-model 	  	= "form.classtc_shortdesc_ind"
					required 
					:rules 	  		="form.classtc_shortdesc_ind_rules"
					:readonly    	= "form.classtc_shortdesc_ind_readonly"
					label       	= "Short Desc ind *"
					placeholder 	= "Short Desc ind"
					id 				= "classtc_shortdesc_ind"
					@keyup.enter 	= "Confirm('write','','')"
					>
					</v-text-field>
				</v-col>
				<v-col cols="12" sm="6" md="6">
					 	<label>Long Description Eng * </label>
						<quill-editor ref="classtc_longdesc_eng"
							v-model="form.classtc_longdesc_eng"
							:config="editorOption">
					</quill-editor> 
				</v-col>
				<v-col cols="12" sm="6" md="6">
					 	<label>Long Description Ind * </label>
						<quill-editor ref="classtc_longdesc_ind"
							v-model="form.classtc_longdesc_ind"
							:config="editorOption">
					</quill-editor> 
				</v-col>
			</v-row>
        </v-card-text>
        <v-card-actions>
        	
        </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth">
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      absolute
      top
      style 	= "z-index: 9999;"
	>
	{{ snackbar.text }}
		<template v-slot:action="{ attrs }">
			<v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
			<v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
		</template>
	</v-snackbar>
	<v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" color="primary"
            		   :disabled   = "confirm.text === 'Ok' ? false:true"
      				   @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  	data: () => ({
		  url_type: 'add',
		  url_classtc: '',
		  content: '<p>example content</p>',
      	  editorOption: { /* quill options */ },
		  cont: false,
		  access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
  		},
  		snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	text: 'Ok',
		},
		remarks: {
			dialog: false,
			feedback: '',
		},
        singleselect: false,
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            value: 'class_id',
          },
          { text: 'Description', value: 'class_desc' },
          { text: 'Actived', value: 'class_actived' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
		items: [],
		singleselect_classtc: false,
		selected_classtc: [],
        search_classtc: '',
        header_classtc: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'classtc_id',
          },
          { text: 'Sub Class ID', value: 'classtc_classid' },
		  { text: 'Category', value: 'classtc_category' },
		  { text: 'Actived', value: 'classtc_actived' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
		items_classtc: [],
        selected_subclass: [],
		items_subclass: [],
		selected_segment: [],
		items_segment: [],
		feeds: [],
		feedstc: [],
		accessmenu: [],
		maxinput:{
			id:6,
			desc:150,
		},
        loading: {
        	list : false,
        	subclass: false,
			page: true,
		},
        btn_search: true,
		form: {
        	class_id: '',
			class_id_rules: [v => !!v || 'Please fill ID'],
			class_desc: '',
			class_desc_rules: [v => !!v || 'Please fill Description'],
			class_actived: true,
			classtc_id: '',
			classtc_id_rules: [v => !!v || 'Please fill ClassTC ID'],
			classtc_classid: '',
			classtc_classid_rules: [v => !!v || 'Please fill ClassTC ClassID'],
			classtc_classdesc: '',
			classtc_category: 0,
			classtc_actived: true,
			classtc_shortdesc_eng: '',
			classtc_longdesc_eng: '',
			classtc_shortdesc_ind: '',
			classtc_longdesc_ind: '',
        },
        attributes: false,
        dialog: {
			classtc:false,
			shorteng:false,
        },
		modal: {
			date:false,
		},
        flag: {
        	detail: false,
        	showdetail: false,
        },
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			this.Access()
  			setTimeout(function(){
                document.getElementById('class_id').focus()
			}, 500)
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "classbussiness"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let priv = response.data
                if (priv.length > 0) {
                    this.access.read        = priv[0].usersmenu_read
                    this.access.add         = priv[0].usersmenu_add
                    this.access.edit        = priv[0].usersmenu_edit
                    this.access.delete      = priv[0].usersmenu_delete
                    if (priv[0].usersmenu_read === 0) {
                        this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                    }
                } else {
                    this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                }
                this.loading.page = false
            })
            .catch((e) => {
                this.SnackBar(true, "error", e, 0)
                this.loading.page = false
            })
        },
		NoPriviledge () {
			this.SnackBar(true, 'error', 'You dont have access `Class of Bussiness` modul, please contact Administrator', 30000)
		},
		SelectAllPage () {
	      this.selected = this.selected === this.items ? [] : this.items
	    },
	    Check (value) {
	      this.selected = value
	    },
	  	Add () {
	  		this.url_type 		 			= 'add'
	  		this.form.class_id 	 			= ''
	  		this.form.class_desc 	 		= ''
	  		this.form.class_fieldtype 		= 0
	  		this.form.class_compulsoryf 	= 0
	  		this.form.class_selectf 		= 0
	  		this.form.class_actived 		= true
	  		this.form.class_id_readonly 	= false
	  		setTimeout(function(){
                document.getElementById('class_id').focus()
            }, 500)
	  	},
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_classbussiness.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		  = 'delete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ id + '`?'
	  			this.class_id 	  	  = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'addclasstc') {
				this.url_type 		  = 'addclasstc'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Save Class TC'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'editclasstc') {
				this.url_type 		  = 'editclasstc'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Save Class TC'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'deleteclasstc') {
				this.url_type 			= 'deleteclasstc'
	  			this.confirm.dialog   	= true
	  			this.confirm.title    	= 'Delete `'+ id + '`?'
	  			this.classtc_id 	  	= id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multideleteclasstc') {
				this.url_type 			= 'multideleteclasstc'
	  			this.confirm.dialog   	= true
	  			this.confirm.title    	= 'Delete `'+ this.selected_classtc.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		},
		// Loading
	  	Loading () {
	  		this.confirm.ok_text 	 = 'Loading...'
	  		this.confirm.ok_disabled = true
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.class_id)
	  		} else if (this.url_type === 'multidelete') {
				  this.MultiProcess()
			} else if (this.url_type === 'addclasstc' || this.url_type === 'editclasstc'){
				this.WriteClassTC()
	  		} else if (this.url_type === 'deleteclasstc') {
	  			this.DeleteClassTC(this.classtc_id)
	  		} else if (this.url_type === 'multideleteclasstc') {
				  this.MultiProcessClassTC()
	  		}
	  	},
		List() {
			this.loading.list = true
			let formdata = {
				class_id: '',
				class_desc: '',
				order_by: 'class_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListClass"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback !== "Y") {
                        this.SnackBar(
                            true,
                            "error",
                            feedback[0].feedback,
                            0
                        )
                    } else {
                        this.items = feedback
                    }
                } else {
                    this.SnackBar(true, "error", feedback, 0)
                }
                this.loading.list = false
            })
            .catch((e) => {
            this.SnackBar(true, "error", e, 3000)
                this.loading.list = false
            })
		},
		ListClassTC (id, classid, category) {
			let formdata = {
				classtc_id: id,
				classtc_classid : classid,
				classtc_category: category,
				order_by: 'classtc_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListClassTC"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback !== "Y") {
						this.items_classtc = []
                        this.SnackBar(true, "error", feedback[0].feedback, 0)
                    } else {
                        this.items_classtc = feedback
                    }
                } else {
                    this.SnackBar(true, "error", feedback, 0)
                }
                this.loading.list = false
            })
            .catch((e) => {
            this.SnackBar(true, "error", e, 3000)
                this.loading.list = false
            })
		},
		Classtc(flag){
			this.url_type 	= flag
			if (this.url_type === 'addclasstc') {
				this.ClearClasstc()
			this.dialog.classtc = true
			}
		},
		Edit(id) {
			this.loading.list = true
			let formdata = {
				class_id: id,
				class_desc: '',
				order_by: 'class_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListClass"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === "Y") {
                        document.getElementById("tabform").click()
                        this.url_type 			    = 'edit'
						this.form.class_id   	    = feedback[0].class_id
						this.form.class_desc 	    = feedback[0].class_desc
						this.form.class_actived    	= this.$functions.TrueOrFalse(feedback[0].class_actived)
						this.form.class_cdate		= feedback[0].class_cdate
						this.form.class_cuser		= feedback[0].class_cuser
						this.form.class_ldate		= feedback[0].class_ldate
						this.form.class_luser		= feedback[0].class_luser
						this.form.class_id_readonly  = true
						this.ListClassTC('', id, 'co')
						setTimeout(function(){
							document.getElementById('class_desc').focus()
						}, 500)
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback[0].feedback
                    }
                } else {
                    this.SnackBar(true, "error", feedback, 0)
                }
                this.loading.list = false
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
                this.loading.list = false
            })
		},
		EditClassTC(id, flag) {
			this.loading.list = true
			let formdata = {
				classtc_id: id,
				order_by: 'classtc_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListClassTC"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback_classtc = response.data
                if (feedback_classtc.length > 0) {
                    if (feedback_classtc[0].feedback === "Y") {
                        document.getElementById("tabform").click()
                        this.dialog.classtc 				= true
						this.url_type 						= flag
						this.form.classtc_id   	    		= feedback_classtc[0].classtc_id
						this.form.classtc_classid 			= feedback_classtc[0].classtc_classid
						this.form.classtc_classdesc 		= feedback_classtc[0].classtc_classdesc
						this.form.classtc_actived   		= this.$functions.TrueOrFalse(feedback_classtc[0].classtc_actived)
						this.form.classtc_shortdesc_eng 	= feedback_classtc[0].classtc_shortdesc_eng
						this.form.classtc_longdesc_eng  	= feedback_classtc[0].classtc_longdesc_eng
						this.form.classtc_shortdesc_ind 	= feedback_classtc[0].classtc_shortdesc_ind
						this.form.classtc_longdesc_ind 		= feedback_classtc[0].classtc_longdesc_ind
						this.form.classtc_cdate				= feedback_classtc[0].classtc_cdate
						this.form.classtc_cuser				= feedback_classtc[0].classtc_cuser
						this.form.classtc_ldate				= feedback_classtc[0].classtc_ldate
						this.form.classtc_luser				= feedback_classtc[0].classtc_luser
						this.form.class_id_readonly  = true
						setTimeout(function(){
								document.getElementById('classtc_desc').focus()
						}, 500)
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog = true
                        this.remarks.feedback = feedback_classtc[0].feedback
                    }
                } else {
                    this.SnackBar(true, "error", feedback_classtc, 0)
                }
                this.loading.list = false
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
                this.loading.list = false
            })
		},
		Delete (id) {
			let formdata = {
				url_type: 'delete',
				class_id: id
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteClass'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Delete Success', 3000)
		            	this.confirm.dialog  	 = false
		                this.confirm.text 	 	 = 'Ok'
		                this.selected 			 = []
						this.List()
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		DeleteClassTC (id) {
			let formdata = {
				url_type: 'deleteclasstc',
				classtc_id: id,
			}
	  		let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteClass'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	            		this.SnackBar(true, 'success', 'Delete Success', 3000)
		            	this.confirm.dialog  	 = false
		                this.confirm.text 	 	 = 'Ok'
		                this.selected 			 = []
						this.List()
		  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	// Multi Process
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteClass"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback[0].feedback === "Y") {
                    let feed
                    if (feedback[0].feedback_failed == 0) {
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                    } else {
                        this.remarks.feedback = feedback[0].feedback_failed_remarks
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                        this.remarks.dialog = true
                    }
                    this.SnackBar(true, "primary", feed, 0)
                    this.confirm.dialog = false
                    this.confirm.text 	= "Ok"
                    this.selected 		= []
                    this.List()
                } else {
                    this.SnackBar(
                        true,
                        "error",
                        this.$functions.ErrorCode(),
                        0
                    )
                    this.remarks.dialog = true
                    this.remarks.feedback = feedback[0].feedback
                }
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
            })
		},
		MultiProcessClassTC() {
			let multiData = JSON.stringify(this.selected_classtc)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteClass"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback[0].feedback === "Y") {
                    let feed
                    if (feedback[0].feedback_failed == 0) {
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                    } else {
                        this.remarks.feedback = feedback[0].feedback_failed_remarks
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                        this.remarks.dialog = true
                    }
                    this.SnackBar(true, "primary", feed, 0)
                    this.confirm.dialog 	= false
                    this.confirm.text 		= "Ok"
                    this.selected_classtc 	= []
                    this.ListClassTC()
                } else {
                    this.SnackBar(
                        true,
                        "error",
                        this.$functions.ErrorCode(),
                        0
                    )
                    this.remarks.dialog = true
                    this.remarks.feedback = feedback[0].feedback
                }
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
            })
		},
        Write() {
            let formdata = {
				url_type: this.url_type,
				class_id: this.form.class_id,
				class_desc: this.form.class_desc,
				class_actived: this.$functions.ActivedFlag(this.form.class_actived),
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteClass"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.class_cuser = feedback[0].feedback_users_id
							this.form.class_cdate = feedback[0].feedback_users_date
						}
						this.form.class_luser = feedback[0].feedback_users_id
						this.form.class_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
        WriteClassTC() {
            let category  = ''
			if (this.form.classtc_category == 1) {
				category = 'CL'
			} else if (this.form.classtc_category == 2) {
				category = 'EX'
			} else if (this.form.classtc_category == 0) {
				category = 'CO'
			} else {
				alert("Category is null")
			}
	  		let formdata = {
				url_type: this.url_type,
				classtc_id: this.form.classtc_id,
				classtc_classid: this.form.class_id,
				classtc_category: category,
				classtc_actived: this.form.classtc_actived,
				classtc_shortdesc_eng: this.form.classtc_shortdesc_eng,
				classtc_longdesc_eng: this.form.classtc_longdesc_eng,
				classtc_shortdesc_ind: this.form.classtc_shortdesc_ind,
				classtc_longdesc_ind: this.form.classtc_longdesc_ind
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteClass"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.classtc__cuser = feedback[0].feedback_users_id
							this.form.classtc__cdate = feedback[0].feedback_users_date
						}
						this.form.classtc__luser = feedback[0].feedback_users_id
						this.form.classtc__ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
						this.ListClassTC('', this.form.class_id, category)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		ClearClasstc() {
			this.dialog.classtc 			= false
			this.form.classtc_id 			= ''
			this.form.classtc_classid 		= ''
			this.form.classtc_classdesc 	= ''
			this.form.classtc_shortdesc_eng = ''
			this.form.classtc_longdesc_eng 	= ''
			this.form.classtc_shortdesc_ind = ''
			this.form.classtc_longdesc_ind 	= ''
		},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar = {
	      	dialog: dialog,
	      	color: color,
	      	text: text,
	      	timeout: timeout
	      }
	      if (color === 'error') {
	      	this.confirm.dialog 	 = false
	      	this.confirm.text 	 	 = 'Ok'
	      }
	    }
	}
}
</script>