<template>
    <v-row>
       <v-col v-if="oi_type === 'L'" cols="12" sm="12" md="12">
            <app-cb
                cb_type           ="gendet"
                cb_url            ="apiListGenDetailShort"
                :cb_url_parameter ="`gendet_gentab_id=${oi_refid}&gendet_actived=Y`"
                :cb_title         ="oi_title"
                :cb_id            ="oi_id"
                :cb_desc          ="oi_desc"
                :cb_rules         ="oi_rules"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="gendet_id"
                cb_items_desc     ="gendet_desc"
                :cb_value_id	  ="oi_value_id"
                :cb_value_desc	  ="oi_value_desc"
              ></app-cb>
       </v-col>
      
       <v-col v-if="oi_type === 'S'" cols="12" sm="12" md="12">
           <v-text-field :id="oi_id" :value="oi_value_id" v-show="false"></v-text-field>
           <v-text-field
               :id                 ="oi_desc" 
               :name				="oi_desc"
               :label				="oi_rules.length > 0 ? `${oi_title} *`: oi_title"
               maxlength			="255"
               prepend-inner-icon	="title"
               :placeholder 		="oi_id_placeholder === undefined ? 'Description' : oi_id_placeholder"
               :required           ="oi_rules.length > 0 ? true : false" 
               :rules              ="oi_rules.length > 0 && !desc[oi_desc] ? [oi_rules] : []" 
               :readonly           ="oi_id_readonly === undefined ? false : oi_id_readonly"
               v-bind              ="$attrs"
               :value              ="oi_value_desc">
           </v-text-field>
       </v-col>
       <v-col v-if="oi_type ==='N'" cols="12" sm="12" md="12">
           <v-text-field :id="oi_id" :value="oi_value_id" v-show="false"></v-text-field>
           <v-text-field
               :id                 ="oi_desc" 
               :name               ="oi_desc"
               :label              ="oi_rules.length > 0 ? `${oi_title} *`: oi_title"
               maxlength           ="255"
               prepend-inner-icon  ="money"
               :placeholder        ="oi_id_placeholder === undefined ? 'Input Number' : oi_id_placeholder"
               :required           ="oi_rules.length > 0 ? true : false" 
               :rules              ="oi_rules.length > 0 && !desc[oi_desc] ? [oi_rules] : []" 
               :readonly           ="oi_id_readonly === undefined ? false : oi_id_readonly"
               background-color    ="numeric"
               :value              ="oi_value_desc"
               v-bind              ="$attrs"
               reverse>
           </v-text-field>
       </v-col>
       <v-col v-if="oi_type ==='D'" cols="12" sm="12" md="12">
           <v-text-field :id="oi_id" :value="oi_value_id" v-if="false"></v-text-field>
           <v-text-field
               :id                 ="oi_desc" 
               :name				="oi_desc"
               :label				="oi_rules.length > 0 ? `${oi_title} *`: oi_title" 
               :required           ="oi_rules.length > 0 ? true : false" 
               :rules              ="oi_rules.length > 0 && !desc[oi_desc] ? [oi_rules] : []" 
               :readonly           ="oi_id_readonly === undefined ? false : oi_id_readonly"
               prepend-inner-icon	="calendar_today"
               placeholder	  		="YYYY-MM-DD"
               background-color    ="date"
               v-mask				="'####-##-##'"
               v-bind              ="$attrs"
               :value              ="oi_value_desc">
               </v-text-field>
       </v-col>
   </v-row>
</template>
<script>
   export default {
       data: () => ({
           loading: false,
           format_money: {
               decimal: ',',
               thousands: '.',
               precision: 0
           },
           id: {},
           desc: {},
           search: {},
           items: [],
           modal: {
               generaltable: false
           },
           oi_header:
           [
               {
                   text: 'ID',
                   value: 'gendet_id',
                   align: 'left'
               },
               { text: 'Name', value: 'gendet_desc' }
           ],
       }),
       props: ['oi_title',
               'oi_id',
               'oi_desc',
               'oi_type',
               'oi_rules',
               'oi_id_readonly',
               'oi_desc_readonly',
               'oi_value_id',
               'oi_value_desc',
               'oi_url_parameter',
               'oi_id_placeholder',
               'oi_refid'],
   }
</script>