<!-- Modul Receipt Request -->
<template>
  <div>
    <app-drawer_menu drawer_menu_title="Receipt Request"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="white" color="fourth" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
        Form
      </v-tab>
      <v-tab href="#tab-list" @click="List">
        List
      </v-tab>
      <v-tab-item value="tab-form">
        <!-- Form Receipt Request -->
        <v-form
          enctype="multipart/form-data"
          ref="form_receiptrequest"
          lazy-validation
        >
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>

            <v-col cols="12" sm="12" md="9" class="mb-n5">
              <v-card class="mb-12" outlined>
                <v-card-text>
                  <v-row>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <v-text-field
                        label="Request No."
                        placeholder="Request No."
                        v-model="form.request_id"
                        required
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <app-cb
                        cb_type="vouchertype"
                        cb_url="apiListVouchertype"
                        cb_url_parameter="vouchertype_actived=Y"
                        cb_title="Vouchertype"
                        cb_id="request_vtype"
                        cb_desc="request_vtypedesc"
                        cb_rules="Please fill voucher type"
                        cb_desc_readonly="readonly"
                        cb_items_id="vouchertype_id"
                        cb_items_desc="vouchertype_desc"
                        :cb_value_id="form.request_vtype"
                        :cb_value_desc="form.request_vtypedesc"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <v-dialog
                        max-width="290px"
                        persistent
                        v-model="modal_daterequest"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.request_date"
                            prepend-inner-icon="event"
                            placeholder="Date For Request"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.request_date"
                          scrollable
                          actions
                        >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="modal_daterequest = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="modal_daterequest = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <v-text-field
                        label="ProAcc"
                        placeholder="ProAcc"
                        v-model="form.request_proacc"
                        required
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <app-cb
                        cb_type="companyaccount"
                        cb_url="apiListCompacc"
                        cb_url_parameter="compacc_actived=Y"
                        cb_title="Company Account"
                        cb_id="request_compacc"
                        cb_desc="request_compaccdesc"
                        cb_rules="Please fill compacc"
                        cb_desc_readonly="readonly"
                        cb_items_id="compacc_id"
                        cb_items_desc="compacc_desc"
                        :cb_value_id="form.request_compacc"
                        :cb_value_desc="form.request_compaccdesc"
                      >
                      </app-cb>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <v-text-field
                        label="Remarks"
                        placeholder="Remarks"
                        v-model="form.request_remarks"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <app-cb
                        cb_type="branch"
                        cb_url="apiListBranch"
                        cb_url_parameter="branch_actived=Y"
                        cb_title="Branch"
                        cb_id="request_branch"
                        cb_desc="request_branchdesc"
                        cb_rules="Please fill branch"
                        cb_desc_readonly="readonly"
                        cb_items_id="branch_id"
                        cb_items_desc="branch_desc"
                        :cb_value_id="form.request_branch"
                        :cb_value_desc="form.request_branchdesc"
                      >
                      </app-cb>
                    </v-col>

                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <v-text-field
                        label="Voucher"
                        placeholder="Voucher"
                        v-model="form.request_voucher"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <v-dialog
                        max-width="290px"
                        persistent
                        v-model="modal_reqduedate"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="form.request_duedate"
                            prepend-inner-icon="event"
                            placeholder="Due Date"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="form.request_duedate"
                          scrollable
                          actions
                        >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="modal_reqduedate = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="primary"
                              @click="modal_reqduedate = false"
                              >OK</v-btn
                            >
                          </v-card-actions>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <small>Debit/Credit</small><br />
                      <v-btn-toggle dense v-model="form.request_dc" rounded>
                        <v-btn small>
                          Debit
                        </v-btn>
                        <v-btn small>
                          Credit
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="3" sm="3" md="3" class="mb-n5">
                      <label class="caption">Active Status</label>
                      <v-switch
                        v-model="form.request_status"
                        color="primary"
                        id="form.request_status"
                        :label="form.request_status !== true ? '' : 'Active'"
                      ></v-switch>
                    </v-col>
                  </v-row>

                  <v-row class="mx-2" v-if="url_type !== 'add'">
                    <!-- List Button -->
                    <v-col cols="12" sm="12" md="12">
                      <v-btn-toggle>
                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>cached</v-icon>
                          CLEAR
                        </v-btn>
                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>print</v-icon>
                          PRINTSLIP
                        </v-btn>

                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>check</v-icon>
                          APPROVE
                        </v-btn>

                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>close</v-icon>
                          UNAPPROVE
                        </v-btn>

                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>lock_open</v-icon>
                          OPEN VOUCHER
                        </v-btn>
                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>folder_delete</v-icon>
                          DROP
                        </v-btn>
                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>file_upload</v-icon>
                          LOAD
                        </v-btn>
                        <v-btn small tile color="primary" class="white--text">
                          <v-icon color="white" left>file_download</v-icon>
                          DOWNLOAD
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <!-- End List Button -->
                    <!-- Button For Detail (Requestsl) -->
                    <v-col cols="12" sm="12" md="12" v-if="url_type !== 'add'">
                      <v-btn-toggle>
                        <v-btn
                          :color="
                            reqsl.selected.length === reqsl.items.length
                              ? 'fourth'
                              : ''
                          "
                          @click="SelectAllDetail"
                          title="select all"
                          small
                          >Select all detail</v-btn
                        >
                      </v-btn-toggle>
                      <v-btn-toggle>
                        <v-btn small color="darkgrey" @click="OpenDetail"
                          >Add Detail</v-btn
                        >
                      </v-btn-toggle>
                      <v-btn-toggle>
                        <v-btn small @click="OpenDocument">Document</v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <!-- End Button For Detail (Requestsl) -->

                    <!-- Table List Requestsl-->
                    <template v-if="url_type !== 'add'">
                      <v-row>
                        <v-col cols="12" sm="12" md="12" class="mt-n5">
                          <v-data-table
                            :value="reqsl.selected"
                            :headers="reqsl.headers"
                            :items="reqsl.items"
                            @input="CheckDetail($event)"
                            item-key="requestsl_requestid"
                            show-select
                            class="elevation-1"
                            :footer-props="{
                              showFirstLastPage: true,
                              firstIcon: 'first_page',
                              lastIcon: 'last_page',
                              prevIcon: 'keyboard_arrow_left',
                              nextIcon: 'keyboard_arrow_right',
                            }"
                            dense
                            :loading="loading.reqsl"
                            loading-text="Loading... Please wait"
                          >
                            <template v-slot:item.action="{ item }">
                              <v-icon
                                small
                                class="mr-2"
                                @click="
                                  EditDetail(
                                    item.requestsl_requestid,
                                    item.requestsl_docno
                                  )
                                "
                                :disabled="access.edit === 0 ? true : false"
                                title="edit"
                              >
                                edit
                              </v-icon>
                              <v-icon
                                small
                                @click="
                                  Confirm(
                                    'deletedetail',
                                    item.requestsl_requestid,
                                    item.requestsl_docno
                                  )
                                "
                                :disabled="access.delete === 0 ? true : false"
                                title="delete"
                              >
                                delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </template>
                    <!-- End List Table RequestSL-->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="3" class="mb-n5">
              <v-card class="mb-4" outlined>
                <v-card-text>
                  <app-cb
                    cb_type="currency"
                    cb_url="apiListCurrency"
                    cb_url_parameter="currency_actived=Y"
                    cb_title="Currency"
                    cb_id="request_ccy"
                    cb_desc="request_ccydesc"
                    cb_rules="Please fill currency"
                    cb_desc_readonly="readonly"
                    cb_items_id="currency_id"
                    cb_items_desc="currency_desc"
                    :cb_value_id="form.request_ccy"
                    :cb_value_desc="form.request_ccydesc"
                  >
                  </app-cb>
                  <v-text-field
                    class="mb-1"
                    label="Nominal In C.C"
                    background-color="numeric"
                    v-money="format_money"
                    v-model="form.request_nominalcc"
                    reverse
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    class="mb-1"
                    label="Nominal"
                    background-color="numeric"
                    v-money="format_money"
                    v-model="form.request_nominal"
                    reverse
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    class="mb-1"
                    label="Difference In C.C"
                    background-color="numeric"
                    v-money="format_money"
                    v-model="form.request_diffcc"
                    reverse
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    class="mb-1"
                    label="Difference"
                    background-color="numeric"
                    v-money="format_money"
                    v-model="form.request_diff"
                    reverse
                    readonly
                  >
                  </v-text-field>

                  <v-text-field
                    class="mb-1"
                    label="Rate"
                    background-color="numeric"
                    v-money="format_money"
                    v-model="form.request_rate"
                    reverse
                    readonly
                  >
                  </v-text-field>
                </v-card-text>
                <v-checkbox
                  class="ml-4"
                  v-model="cont"
                  label="Show Info"
                ></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>
Created By	 :{{ form.request_cuser }}
Last Update By : {{ form.request_luser }}
Un/Verified By : 
Un/Approved By :
Close/Canceled By : 
                        </pre
                  >
                </v-banner>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <!-- End Form Receipt Request -->

        <!-- Dialog Detail For Create/Add RequestSL -->
        <v-dialog
          v-model="dialog.detail"
          scrollable
          transition="dialog-bottom-transition"
          persistent
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="dialog.detail = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Add Detail</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn icon dark title="Add" @click="AddRowDetail"
                ><v-icon color="white">add</v-icon></v-btn
              >
              <v-btn icon dark title="Save" @click="Confirm('addrequestsl', '')"
                ><v-icon color="white">save</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <!-- Form Detail For Add/Create RequestSL -->
              <v-form
                enctype="multipart/form-data"
                ref="FormDetail"
                lazy-validation
              >
                <v-row class="mx-2">
                  <v-col cols="12" sm="12" md="12">
                    <v-card class="mb-12" outlined>
                      <template>
                        <div class="tab-custom">
                          <div class="tab-custom_">
                            <table class="table-requestsl">
                              <thead>
                                <tr>
                                  <th>
                                    ID
                                  </th>
                                  <th>
                                    Docno
                                  </th>
                                  <th>
                                    Date
                                  </th>
                                  <th>
                                    Currency
                                  </th>
                                  <th>
                                    Nominaloc
                                  </th>
                                  <th>
                                    Nominal
                                  </th>
                                  <th>
                                    Rate
                                  </th>
                                  <th>
                                    Nominator
                                  </th>
                                  <th>
                                    Denominator
                                  </th>
                                  <th>
                                    Account
                                  </th>
                                  <th>
                                    Hint
                                  </th>
                                  <th>
                                    Payment
                                  </th>
                                  <th>
                                    Remarks
                                  </th>
                                  <th>
                                    CT
                                  </th>
                                  <th>
                                    Branch
                                  </th>
                                  <th>
                                    Sortno
                                  </th>
                                  <th>
                                    Funding
                                  </th>
                                </tr>
                              </thead>
                              <tr v-for="(req, k) in requestsls" :key="k">
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_requestid"
                                    placeholder="ID"
                                    readonly
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_docno"
                                    placeholder="Document Number"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    v-model="req.requestsl_date"
                                  />
                                </td>
                                <td>
                                  <app-cb
                                    cb_type="currency"
                                    cb_url="apiListCurrency"
                                    cb_url_parameter="currency_actived=Y"
                                    cb_title="Currency"
                                    cb_id="requestsl_ccy"
                                    cb_single="Y"
                                    cb_desc="requestsl_ccydesc"
                                    cb_rules="Please fill Currency"
                                    cb_desc_readonly="readonly"
                                    cb_icon="false"
                                    cb_items_id="currency_id"
                                    cb_items_desc="currency_desc"
                                    :cb_value_id="req.requestsl_ccy"
                                    :cb_value_desc="req.requestsl_ccydesc"
                                    style="width:250pxmargin-top:17px"
                                  >
                                  </app-cb>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="req.requestsl_nominaloc"
                                    placeholder="0"
                                    v-money="format_money"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="req.requestsl_nominal"
                                    placeholder="0"
                                    v-money="format_money"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="req.requestsl_rate"
                                    placeholder="0"
                                    v-money="format_money"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="req.requestsl_nominator"
                                    placeholder="0"
                                    v-money="format_money"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="req.requestsl_denominator"
                                    placeholder="0"
                                    v-money="format_money"
                                  />
                                </td>
                                <td>
                                <app-cb
                                    cb_type="account"
                                    cb_url="apiListAccount"
                                    cb_url_parameter="account_actived=Y"
                                    cb_title="Account"
                                    cb_id="requestsl_account"
                                    cb_single="Y"
                                    cb_desc="requestsl_accountdesc"
                                    cb_rules="Please fill Currency"
                                    cb_desc_readonly="readonly"
                                    cb_icon="false"
                                    cb_items_id="account_id"
                                    cb_items_desc="account_desc"
                                    :cb_value_id="req.requestsl_account"
                                    :cb_value_desc="req.requestsl_accountdesc"
                                    style="width:250pxmargin-top:17px"
                                  >
                                  </app-cb>
                                  </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_hint"
                                    placeholder="Hint"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    v-model="req.requestsl_paymentf"
                                    placeholder="0"
                                    v-money="format_money"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_remarks"
                                    placeholder="Remarks"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_ct"
                                    placeholder="City"
                                  />
                                </td>
                                <td>
                                  <app-cb
                                    cb_type="branch"
                                    cb_url="apiListBranch"
                                    cb_url_parameter="branch_actived=Y"
                                    cb_title="Branch"
                                    cb_id="requestsl_branch"
                                    cb_single="Y"
                                    cb_desc="requestsl_branchdesc"
                                    cb_rules="Please fill Branch"
                                    cb_desc_readonly="readonly"
                                    cb_items_id="branch_id"
                                    cb_items_desc="branch_desc"
                                    :cb_value_id="req.requestsl_branch"
                                    :cb_value_desc="req.requestsl_branchdesc"
                                    cb_icon="false"
                                    style="width:250pxmargin-top:17px"
                                  >
                                  </app-cb>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_sortno"
                                    placeholder="Sortno"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    v-model="req.requestsl_fundingf"
                                    placeholder="Funding"
                                  />
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </template>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
              <!-- End Form Detail Form Add/Create RequestSL -->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- End Dialog Detail Form Add/Create RequestSL -->

        <!-- Dialog Edit Detail For Update Data RequestSL -->
        <v-dialog
          v-model="dialog.editdetails"
          scrollable
          transition="dialog-bottom-transition"
          persistent
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="dialog.editdetails = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Edit Detail </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="Confirm('updatedetail', '')"
                ><v-icon color="white">save</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <!-- Form Detail For Update Data RequestSL -->
              <v-form
                enctype="multipart/form-data"
                ref="form_edit_detail"
                lazy-validation
              >
                <v-row class="mx-2">
                  <v-col cols="12" sm="12" md="12">
                    <v-card class="mb-12" outlined>
                      <template>
                        <v-row class="mx-2">
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              class="mb-1"
                              label="ID"
                              v-model="form.requestsl_requestid"
                              readonly
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              class="mb-1"
                              label="Docno"
                              v-model="form.requestsl_docno"
                              readonly
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_date"
                              mask="date"
                              type="date"
                              background-color="date"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <app-cb
                              cb_type="currency"
                              cb_url="apiListCurrency"
                              cb_url_parameter="currency_actived=Y"
                              cb_title="Currency"
                              cb_id="requestsl_ccy"
                              cb_single="Y"
                              cb_desc="requestsl_ccydesc"
                              cb_rules="Please fill Currency"
                              cb_desc_readonly="readonly"
                              cb_items_id="currency_id"
                              cb_items_desc="currency_desc"
                              :cb_value_id="form.requestsl_ccy"
                              :cb_value_desc="form.requestsl_ccydesc"
                            >
                            </app-cb>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_nominaloc"
                              required
                              placeholder="Nominaloc"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_nominal"
                              required
                              placeholder="Nominal"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_rate"
                              required
                              placeholder="Rate"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_nominator"
                              required
                              placeholder="Nominator"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_denominator"
                              required
                              placeholder="Denominator"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                          
                            <v-text-field
                              v-model="form.requestsl_account"
                              required
                              placeholder="account"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_hint"
                              required
                              placeholder="Hint"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_paymentf"
                              required
                              placeholder="Payment"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_remarks"
                              required
                              placeholder="Remarks"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_ct"
                              required
                              placeholder="CT"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <app-cb
                              cb_type="branch"
                              cb_url="apiListBranch"
                              cb_url_parameter="branch_actived=Y"
                              cb_title="Branch"
                              cb_id="requestsl_branch"
                              cb_single="Y"
                              cb_desc="requestsl_branchdesc"
                              cb_rules="Please fill Branch"
                              cb_desc_readonly="readonly"
                              cb_items_id="branch_id"
                              cb_items_desc="branch_desc"
                              :cb_value_id="form.requestsl_branch"
                              :cb_value_desc="form.requestsl_branchdesc"
                            >
                            </app-cb>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_sortno"
                              required
                              placeholder="Sortno"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="3" sm="3" md="3">
                            <v-text-field
                              v-model="form.requestsl_fundingf"
                              required
                              placeholder="Funding"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
              <!-- End Form For Update Data RequestSL -->
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- End Dialog Edit Detail Form Update RequesSL-->

        <!-- Dialog Get Document -->
        <v-dialog
          v-model="dialog.document"
          scrollable
          transition="dialog-bottom-transition"
          persistent
          fullscreen
        >
          <v-card>
            <v-toolbar dark color="primary" dense class="mb-2">
              <v-btn icon dark @click="dialog.document = false">
                <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Get Document</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn icon dark title="Add"
                ><v-icon color="white">add</v-icon></v-btn
              >
              <v-btn icon dark title="Save"
                ><v-icon color="white">save</v-icon></v-btn
              >

              <v-btn icon dark title="Clear" @click="clearFormGetDocument"
                ><v-icon color="white">cached</v-icon></v-btn
              >

              <v-btn icon dark title="Retrieve"
                ><v-icon color="white">wysiwyg</v-icon></v-btn
              >

              <v-btn icon dark title="Load"
                ><v-icon color="white">file_upload</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text>
              <!-- Form Get Document-->
              <v-form
                enctype="multipart/form-data"
                ref="FormGetDocument"
                lazy-validation
              >
                <v-row class="mx-2 mt-2">
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-row>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-select label="Query type" placeholder="CN/DN">
                        </v-select>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_type="profile"
                          cb_url="apiListProfile"
                          cb_url_parameter="profile_actived=Y"
                          cb_title="Profile Name"
                          cb_single="Y"
                          cb_id="receiptrequest_doc_profile"
                          cb_desc="receiptrequest_doc_profiledesc"
                          cb_rules="Please fill Profile Name"
                          cb_desc_readonly="readonly"
                          cb_items_id="profile_id"
                          cb_items_desc="profile_name"
                          :cb_value_id="formdocument.receiptrequest_doc_profile"
                          :cb_value_desc="
                            formdocument.receiptrequest_doc_profiledesc
                          "
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.document_id"
                          required
                          :rules="form.document_id_rules"
                          :readonly="flag.document"
                          :counter="maxinput.id"
                          :maxlength="maxinput.id"
                          label="Profile Ref ID"
                          placeholder="Profile Ref ID"
                          id="document_id"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Document No"
                          cb_rules="Please Fill Document No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Refference No"
                          cb_rules="Please Fill Refference No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Bussines Source"
                          cb_rules="Please Fill Bussines Source"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Company Group"
                          cb_rules="Please Fill Company Group"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_type="branch"
                          cb_url="apiListBranch"
                          cb_url_parameter="branch_actived=Y"
                          cb_title="Branch"
                          cb_single="Y"
                          cb_id="receiptrequest_doc_branch"
                          cb_desc="receiptrequest_doc_branchdesc"
                          cb_rules="Please fill Branch"
                          cb_desc_readonly="readonly"
                          cb_items_id="branch_id"
                          cb_items_desc="branch_desc"
                          :cb_value_id="formdocument.receiptrequest_doc_branch"
                          :cb_value_desc="
                            formdocument.receiptrequest_doc_branchdesc
                          "
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.account_no"
                          required
                          label="Account No"
                          placeholder="Account No"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.account_no"
                          required
                          label="Account No"
                          placeholder="Account No"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.document_no_first"
                          required
                          label="Original Doc No"
                          placeholder="Original Doc No"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          v-model="form.document_no_second"
                          required
                          label="Original Doc No"
                          placeholder="Original Doc No"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" sm="6" md="6" class="mb-n5">
                        <v-text-field label="Remarks" placeholder="Remarks">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-row>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Document No"
                          cb_rules="Please Fill Document No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Voucher No"
                          cb_rules="Please Fill Voucher No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Voucher No"
                          cb_rules="Please Fill Voucher No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Refference No"
                          cb_rules="Please Fill Refference No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Collection No"
                          cb_rules="Please Fill Collection No"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_type="currency"
                          cb_url="apiListCurrency"
                          cb_url_parameter="currency_actived=Y"
                          cb_title="Currency"
                          cb_id="receiptrequest_doc_currency"
                          cb_desc="receiptrequest_doc_currencydesc"
                          cb_rules="Please fill Currency"
                          cb_desc_readonly="readonly"
                          cb_items_id="currency_id"
                          cb_items_desc="currency_desc"
                          :cb_value_id="
                            formdocument.receiptrequest_doc_currency
                          "
                          :cb_value_desc="
                            formdocument.receiptrequest_doc_currencydesc
                          "
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <app-cb
                          cb_title="Suspend/Open Account"
                          cb_rules="Please Fill"
                          cb_single="Y"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field label="Batch No" placeholder="Batch No">
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          label="Bussines Sourec Ref ID"
                          placeholder="Bussines Sourec Ref ID"
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          label="Application Source"
                          placeholder="Application Source"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" sm="3" md="3" class="mb-n5">
                        <v-text-field
                          mask="date"
                          type="date"
                          background-color="date"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="6" md="6" class="mb-n5">
                        <v-select
                          label="Reference type"
                          placeholder="Reference type"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="6" sm="6" md="6" class="mb-n5">
                        <v-select label="Sort By" placeholder="(NONE)">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Not In Collection`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Not In Request`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Paid Policy`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Pair Data`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-select
                      label="Condition"
                      placeholder="Not In Policy Cancellation"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-checkbox
                      :label="`Exclude Tax`"
                      false-value="N"
                      true-value="Y"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="8" sm="8" md="8" class="mb-n5">
                    <v-text-field
                      label="Total Amount Selected"
                      placeholder="0"
                      background-color="numeric"
                      v-money="format_money"
                      reverse
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" md="4" class="mb-n5">
                    <v-text-field
                      placeholder="0"
                      background-color="numeric"
                      v-money="format_money"
                      reverse
                      readonly
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="10" sm="10" md="10" class="mb-6 mt-6">
                    <v-btn-toggle>
                      <v-btn small color="darkgrey">select all</v-btn>
                    </v-btn-toggle>
                    <v-btn-toggle>
                      <v-btn small color="darkgrey">deselect all</v-btn>
                    </v-btn-toggle>
                    <!-- Table On Dialog Document -->
                    <v-data-table
                      :headers="tbldocument"
                      :items-per-page="5"
                      class="elevation-1"
                      loading
                    >
                      <template> </template>
                    </v-data-table>
                    <!-- End Table -->
                  </v-col>
                  <v-col cols="2" sm="2" md="2" class="mb-10 mt-12">
                    <v-card outlined>
                      <p
                        class="text-center font-weight-medium"
                        style="background-color:#ff5f0a"
                      >
                        SELECTED DATA
                      </p>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" align="center">
                          <v-btn elevation="2">REMOVE</v-btn>
                          <v-btn elevation="2">CLEAR ALL</v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
              <!-- End Form Document-->
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- End Dialog-->
      </v-tab-item>

      <v-tab-item value="tab-list">
        <!-- List Data Receipt Request -->
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{$vars.V('txt_select_all_page')}}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="request_id"
            show-select
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.request_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click="Confirm('delete', item.request_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
        <!--End List Data Receipt Request -->
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="primary"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    ready: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      id: 10,
      desc: 150,
    },
    snackbar: {
      dialog: false,
      color: "primary",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    selected: [],
    search: "",
    // list receipt request
    headers: [
      {
        text: "ID",
        align: "left",
        value: "request_id",
      },
      { text: "Vouchertype", value: "request_vtype" },
      { text: "Currency", value: "request_ccy" },
      { text: "Actived", value: "request_status" },
      { text: "Actions", value: "action", sortable: false },
    ],
    // End list receipt request

    // list requestsl
    reqsl: {
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: false,
          value: "requestsl_requestid",
        },
        { text: "Docno", value: "requestsl_docno" },
        { text: "Date", value: "requestsl_date" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end list requestsl
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      reqsl: false,
      editdetails: false,
    },
    btn_search: true,
    // table get document
    tbldocument: [
      {
        text: "voucher",
        sortable: false,
        value: "voucher",
        width: "300px",
      },
      {
        text: "voucher type",
        value: "voucher_type",
        width: "200px",
      },

      {
        text: "Doc.No",
        value: "doc_no",
        width: "300px",
      },

      {
        text: "Ref.No",
        value: "ref_no",
        width: "300px",
      },
      {
        text: "Ref.Date",
        value: "ref_date",
        width: "200px",
      },

      {
        text: "ProAcc",
        value: "pro_acc",
        width: "300px",
      },
      {
        text: "Name",
        value: "name",
        width: "300px",
      },
      {
        text: "Remarks",
        value: "remarks",
        width: "300px",
      },
    ],
    // end table get document

    flag: {
      document: false,
    },

    modal_daterequest: false,
    modal_reqduedate: false,
    modal_detailsdate: false,
    details_date: null,

    format_money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
    },
    form: {
      // form for receipt request update or create form
      request_id: "",
      request_id_rules: [(v) => !!v || "Please fill ID"],
      request_id_readonly: false,

      request_vtype: "",
      request_vtypedesc: "",
      request_date: null,
      request_ccy: "",
      request_ccydesc: "",
      request_proacc: "",
      request_compacc: "",
      request_compaccdesc: "",
      request_remarks: "",
      request_nominalcc: "",
      request_nominal: "",
      request_diffcc: "",
      request_diff: "",
      request_rate: "",
      request_numdoc: "",
      request_npayment: "",
      request_branch: "",
      request_branchdesc: "",
      request_voucher: "",
      request_dc: 0,
      request_duedate: null,
      request_status: true,
      request_cuser: "",
      request_cdate: "",
      request_ldate: "",
      request_luser: "",
      // end
      // form for requestsl update form
      requestsl_requestid: "",
      requestsl_docno: "",
      requestsl_docno_par: "",
      requestsl_date: "",
      requestsl_ccy: "",
      requestsl_nominaloc: "",
      requestsl_nominal: "",
      requestsl_rate: "",
      requestsl_nominator: "",
      requestsl_denominator: "",
      requestsl_account: "",
      requestsl_hint: "",
      requestsl_paymentf: "",
      requestsl_remarks: "",
      requestsl_ct: "",
      requestsl_branch: "",
      requestsl_sortno: "",
      requestsl_fundingf: "",
    },
    // for create requestsl
    requestsls: [
      {
        requestsl_requestid: null,
        requestsl_docno: null,
        requestsl_date: null,
        requestsl_ccy: null,
        requestsl_nominaloc: null,
        requestsl_nominal: null,
        requestsl_rate: null,
        requestsl_nominator: null,
        requestsl_denominator: null,
        requestsl_account: null,
        requestsl_hint: null,
        requestsl_paymentf: null,
        requestsl_remarks: null,
        requestsl_ct: null,
        requestsl_branch: null,
        requestsl_sortno: null,
        requestsl_fundingf: null,
      },
    ],
    // end
    dialog: {
      document: false,
      detail: false,
      editdetails: false,
    },
    formdocument: {
      receiptrequest_doc_profile: "",
      receiptrequest_doc_currency: "",
      receiptrequest_doc_branch: "",
    },
  }),
  created() {
    this.FirstLoad()
  },

  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.Access()
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "receiptrequest"
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data
          if (priv.length > 0) {
            if (priv[0].feedback !== "Y") {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = priv[0].feedback
            } else {
              this.access.read = priv[0].usersmenu_read
              this.access.add = priv[0].usersmenu_add
              this.access.edit = priv[0].usersmenu_edit
              this.access.delete = priv[0].usersmenu_delete
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.NoPriviledge(modul),
                  0
                )
              }
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            )
          }
          this.loading.page = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0)
          this.loading.page = false
        })
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items
    },
    // select all requestsl
    SelectAllDetail() {
      this.reqsl.selected =
        this.reqsl.selected === this.reqsl.items ? [] : this.reqsl.items
    },
    CheckDetail(value) {
      this.reqsl.selected = value
    },
    Check(value) {
      this.selected = value
    },
    GetData(value) {
      if (value !== undefined) {
        //let splits = this.$functions.Split(value, "|")
        //this.$set(this.voucherpattern_account, splits[2], splits[1])
      }
    },
    //Function for get all data from table request with API in mod_receiptrequest.go
    List() {
      this.loading.list = true
      let formdata = {
        request_id: "",
        request_vtype: "",
        order_by: "request_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListReceiptRequest"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          console.log(response.data)
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.items = feedback
            }
          } else {
            this.items = feedback
            this.SnackBar(true, "error", this.$functions.NoData(), 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.list = false
        })
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id, par1) {
      if (flag === "write") {
        if (this.$refs.form_receiptrequest.validate()) {
          this.confirm.dialog = true
          this.confirm.title = "Save"
          setTimeout(function() {
            document.getElementById("dialog").focus()
          }, 500)
        }
      } else if (flag === "delete") {
        this.url_type = "delete"
        this.confirm.dialog = true
        this.confirm.title = "Delete `" + id + "`"
        this.request_id = id
        setTimeout(function() {
          document.getElementById("dialog").focus()
        }, 500)
      } else if (flag === "multidelete") {
        this.url_type = "multidelete"
        this.confirm.dialog = true
        this.confirm.title = "Delete `" + this.selected.length + "` data"
        setTimeout(function() {
          document.getElementById("dialog").focus()
        }, 500)
      } else if (flag === "addrequestsl") {
        this.url_type = "addrequestsl"
        this.confirm.dialog = true
        this.confirm.title = "Save Detail"
        setTimeout(function() {
          document.getElementById("request_id").focus()
        }, 500)
      } else if (flag === "editdetail") {
        if (this.$refs.form_detail.validate()) {
          this.url_type = "editdetail"
          this.confirm.dialog = true
          this.confirm.title = "Edit Detail"
          setTimeout(function() {
            document.getElementById("dialog").focus()
          }, 500)
        }
      } else if (flag === "updatedetail") {
        if (this.$refs.form_edit_detail.validate()) {
          this.url_type = "updatedetail"
          this.confirm.dialog = true
          this.confirm.title = "Edit Detail"
          setTimeout(function() {
            document.getElementById("request_id").focus()
          }, 500)
        }
      }else if (flag === "deletedetail") {
        this.url_type = "deletedetail"
        this.confirm.dialog = true
        this.confirm.title = "Delete `" + par1 + "`"
        this.requestsl_requestid = id
        this.requestsl_docno = par1
        setTimeout(function() {
          document.getElementById("dialog").focus()
        }, 500)
      }
    },
    //Function to request insert data to table Request 'The API will validating your data, include the user who process the data' (go to APIWriteReceiptRequest with url_type = "add")
    Add() {
      this.url_type = "add"
      this.form.request_id = ""
      this.form.request_vtype = ""
      this.form.request_date = ""
      this.form.request_ccy = ""
      this.form.request_proacc = ""
      this.form.request_compacc = ""
      this.form.request_remarks = ""
      this.form.request_nominalcc = ""
      this.form.request_nominal = ""
      this.form.request_diffcc = ""
      this.form.request_diff = ""
      this.form.request_rate = ""
      this.form.request_numdoc = 0
      this.form.request_npayment = 0
      this.form.request_branch = ""
      this.form.request_voucher = ""
      this.form.request_dc = 0
      this.form.request_duedate = ""
      this.form.request_status = true
      this.form.request_id_readonly = false
      setTimeout(function() {
        document.getElementById("request_id").focus()
      }, 500)
    },

    Write() {
      let reqdc = "D"
      let reqbranchid = document.getElementById("request_branch").value
      let reqvtypeid = document.getElementById("request_vtype").value
      let reqccyid = document.getElementById("request_ccy").value
      let reqcompaccid = document.getElementById("request_compacc").value
      if (this.form.request_dc === 1) {
        reqdc = "C"
      }
      let formdata = {
        url_type: this.url_type,
        request_id: this.form.request_id,
        request_vtype: reqvtypeid,
        request_date: this.form.request_date,
        request_ccy: reqccyid,
        request_proacc: this.form.request_proacc,
        request_compacc: reqcompaccid,
        request_remarks: this.form.request_remarks,
        request_nominalcc: this.form.request_nominalcc,
        request_nominal: this.form.request_nominal,
        request_diffcc: this.form.request_diffcc,
        request_diff: this.form.request_diff,
        request_rate: this.form.request_rate,
        request_numdoc: this.form.request_numdoc,
        request_npayment: this.form.request_npayment,
        request_branch: reqbranchid,
        request_voucher: this.form.request_voucher,
        request_dc: reqdc,
        request_duedate: this.form.request_duedate,
        request_status: this.$functions.ActivedFlag(this.form.request_status),
      }

      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReceiptRequest"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })

        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              if (this.url_type === "add") {
                this.form.request_cuser = feedback[0].feedback_users_id
                this.form.request_cdate = feedback[0].feedback_users_date
              }
              this.form.request_luser = feedback[0].feedback_users_id
              this.form.request_ldate = feedback[0].feedback_users_date
              this.url_type = "edit"
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback
          }
        })

        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    //Function to request update data to table ReceiptRequest 'The API will validating your data, include the user who process the data' (go to APIWriteReceiptRequest with url_type = "edit")
    Edit(id) {
      this.loading.list = true
      let formdata = {
        request_id: id,
        order_by: "request_id",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListReceiptRequest"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click()
              this.url_type = "edit"
              this.form.request_id = feedback[0].request_id
              this.form.request_vtype = feedback[0].request_vtype
              this.form.request_vtypedesc = feedback[0].request_vtypedesc
              this.form.request_date = feedback[0].request_date
              this.form.request_ccy = feedback[0].request_ccy
              this.form.request_ccydesc = feedback[0].request_ccydesc
              this.form.request_proacc = feedback[0].request_proacc
              this.form.request_compacc = feedback[0].request_compacc
              this.form.request_compaccdesc = feedback[0].request_compaccdesc
              this.form.request_remarks = feedback[0].request_remarks
              this.form.request_nominalcc = feedback[0].request_nominalcc
              this.form.request_nominal = feedback[0].request_nominal
              this.form.request_diff = feedback[0].request_diff
              this.form.request_diffcc = feedback[0].request_diffcc
              this.form.request_numdoc = feedback[0].request_numdoc
              this.form.request_npayment = feedback[0].request_npayment
              this.form.request_branch = feedback[0].request_branch
              this.form.request_branchdesc = feedback[0].request_branchdesc
              this.form.request_voucher = feedback[0].request_voucher
              this.form.request_dc = this.$functions.YesOrNo(
                feedback[0].request_dc
              )
              this.form.request_duedate = feedback[0].request_duedate
              this.form.request_status = this.$functions.TrueOrFalse(
                feedback[0].request_status
              )
              this.form.request_cdate = feedback[0].request_cdate
              this.form.request_cuser = feedback[0].request_cuser
              this.form.request_ldate = feedback[0].request_ldate
              this.form.request_luser = feedback[0].request_luser
              this.form.request_id_readonly = true
              this.ListRequestSL(id)
              setTimeout(function() {
                document.getElementById("request_vtype").focus()
              }, 500)
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.list = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.list = false
        })
    },
    //Function for delete data receiptrequest,request with API in mod_receiptrequest.go
    Delete(id) {
      let formdata = {
        url_type: "delete",
        request_id: id,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReceiptRequest"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Delete Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              this.selected = []
              this.List()
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },

    MultiProcess() {
      let multiData = JSON.stringify(this.selected)
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReceiptRequest"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback[0].feedback === "Y") {
            let feed
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
              this.remarks.dialog = true
            }
            this.SnackBar(true, "primary", feed, 0)
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            this.selected = []
            this.List()
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    // START REQUEST SL
    //This function is to display a dialog to create data for request sl
    OpenDetail() {
      this.dialog.detail = true
      this.requestsls.splice(0)
      console.log("idreq", this.form.request_id)
      this.requestsls.push({ requestsl_requestid: this.form.request_id })
      this.$refs.FormDetail.reset()
    },
    //This function is to add a new row when creating data for requestsl
    AddRowDetail() {
      let reqbranch = document.getElementById("requestsl_branch").value
      let reqaccount = document.getElementById("requestsl_account").value
      let reqccy = document.getElementById("requestsl_ccy").value
      this.requestsls.push({
        requestsl_requestid: this.form.request_id,
        requestsl_docno: "",
        requestsl_date: "",
        requestsl_ccy: reqccy,
        requestsl_nominaloc: "",
        requestsl_nominal: "",
        requestsl_rate: "",
        requestsl_nominator: "",
        requestsl_denominator: "",
        requestsl_account: reqaccount,
        requestsl_hint: "",
        requestsl_paymentf: "",
        requestsl_remarks: "",
        requestsl_ct: "",
        requestsl_branch: reqbranch,
        requestsl_sortno: "",
        requestsl_fundingf: "",
      })
    },
    //Function to request insert data to table RequestSl 'The API will validating your data, include the user who process the data' (go to APIWriteRequestSL with url_type = "add")
    WriteDetail() {
      //let id = document.getElementById("request_id").value
      let formdetail = {
        url_type: "addrequestsl",
        multi_request: JSON.stringify(this.requestsls),
      }
      let params = this.$functions.ParamPOST(formdetail)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteRequestSL"), params, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000)
              this.requestsls.slice(0)
              this.confirm.dialog = false
              this.dialog.detail = false
              //this.ListRequestSL(id)
              setTimeout(function() {
                document.getElementById("request_id").focus()
              }, 500)
              this.confirm.text = "Ok"
              if (this.url_type === "add") {
                this.requestsls.requestsl_cuser = feedback[0].feedback_users_id
                this.requestsls.requestsl_cdate =
                  feedback[0].feedback_users_date
              }
              this.form.requestsls_luser = feedback[0].feedback_users_id
              this.form.requestsls_ldate = feedback[0].feedback_users_date
              this.url_type = "editdetail"
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    // This function is to display data based on request_id
    ListRequestSL(id) {
      this.loading.reqsl = true
      let formdata = {
        requestsl_requestid: id,
        order_by: "requestsl_requestid",
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListRequestSL"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0)
            } else {
              this.reqsl.items = feedback
              console.log("IDREQUESTSL", id)
            }
          } else {
            this.items = feedback
            this.SnackBar(true, "error", this.$functions.NoData(), 0)
          }
          this.loading.reqsl = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000)
          this.loading.reqsl = false
        })
    },
    //This function is to change data based on id requestsl
    EditDetail(id, docno) {
      let formdata = {
        requestsl_requestid: id,
        requestsl_docno: docno,
        order_type: "ASC",
        limit: this.limit,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiListRequestSL"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.requestsl_requestid = feedback[0].requestsl_requestid
              this.form.requestsl_docno = feedback[0].requestsl_docno
              this.form.requestsl_date = feedback[0].requestsl_date
              this.form.requestsl_ccy = feedback[0].requestsl_ccy
              this.form.requestsl_nominaloc = feedback[0].requestsl_nominaloc
              this.form.requestsl_nominal = feedback[0].requestsl_nominal
              this.form.requestsl_rate = feedback[0].requestsl_rate
              this.form.requestsl_nominator = feedback[0].requestsl_nominator
              this.form.requestsl_denominator =
                feedback[0].requestsl_denominator
              this.form.requestsl_account = feedback[0].requestsl_account
              this.form.requestsl_hint = feedback[0].requestsl_hint
              this.form.requestsl_paymentf = feedback[0].requestsl_paymentf
              this.form.requestsl_remarks = feedback[0].requestsl_remarks
              this.form.requestsl_ct = feedback[0].requestsl_ct
              this.form.requestsl_branch = feedback[0].requestsl_branch
              this.form.requestsl_sortno = feedback[0].requestsl_sortno
              this.form.requestsl_fundingf = feedback[0].requestsl_fundingf
              this.form.requestsl_docno_par = feedback[0].requestsl_docno
              this.dialog.editdetails = true
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
          this.loading.editdetails = false
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
          this.loading.editdetails = false
        })
    },
    //  update requestsl
    UpdateDetail() {
      let id = document.getElementById("request_id").value
      let formdata = {
        url_type: "edit",
        requestsl_requestid: id,
        requestsl_docno: this.form.requestsl_docno,
        requestsl_date: this.form.requestsl_date,
        requestsl_ccy: this.form.requestsl_ccy,
        requestsl_nominaloc: this.form.requestsl_nominaloc,
        requestsl_nominal: this.form.requestsl_nominal,
        requestsl_rate: this.form.requestsl_rate,
        requestsl_nominator: this.form.requestsl_nominator,
        requestsl_denominator: this.form.requestsl_denominator,
        requestsl_account: this.form.requestsl_account,
        requestsl_hint: this.form.requestsl_hint,
        requestsl_paymentf: this.form.requestsl_paymentf,
        requestsl_remarks: this.form.requestsl_remarks,
        requestsl_ct: this.form.requestsl_ct,
         requestsl_branch: this.form.requestsl_branch,
          requestsl_fundingf: this.form.requestsl_fundingf,
        requestsl_docno_par: this.form.requestsl_docno_par,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteRequestSL"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })

        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000)
              this.confirm.dialog = false
              this.dialog.editdetails = false
              this.ListRequestSL(id)
              setTimeout(function() {
                document.getElementById("request_id").focus()
              }, 500)
              this.confirm.text = "Ok"
              if (this.url_type === "add") {
                this.form.vouchertype_cuser = feedback[0].feedback_users_id
                this.form.vouchertype_cdate = feedback[0].feedback_users_date
              }
              this.form.vouchertype_luser = feedback[0].feedback_users_id
              this.form.vouchertype_ldate = feedback[0].feedback_users_date
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback
          }
        })

        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    //This function is to delete data based on requestsl id
    DeleteDetail(id, docno) {
      let formdata = {
        url_type: "deletedetail",
        requestsl_requestid: id,
        requestsl_docno: docno,
      }
      let param = this.$functions.ParamPOST(formdata)
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteRequestSL"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Delete Success", 3000)
              this.confirm.dialog = false
              this.confirm.text = "Ok"
              this.ListRequestSL(id)
              setTimeout(function() {
                document.getElementById("request_id").focus()
              }, 500)
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = e
        })
    },
    OpenDocument() {
      this.dialog.document = true
      this.$refs.FormGetDocument.reset()
    },
    clearFormGetDocument() {
      this.$refs.FormGetDocument.reset()
    },
    Loading() {
      this.confirm.text = "Loading..."
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write()
      } else if (this.url_type === "delete") {
        this.Delete(this.request_id)
      } else if (this.url_type === "multidelete") {
        this.MultiProcess("delete")
      } else if (this.url_type === "addrequestsl") {
        this.WriteDetail()
      } else if (this.url_type === "updatedetail") {
        this.UpdateDetail()
      }else if (this.url_type === "deletedetail") {
        this.DeleteDetail(this.requestsl_requestid, this.requestsl_docno)
      }
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      if (color === "error") {
        this.confirm.dialog = false
        this.confirm.text = "Ok"
      }
    },
  },
}
</script>
