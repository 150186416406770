<template>
  <div>
    <app-drawer_menu drawer_menu_title="Rate Tab"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color ="fourth"
      rounded
      v-if  ="loading.page"
    ></v-progress-linear>

    <v-tabs
      background-color  ="white"
      color             ="fourth"
      v-if              ="this.access.read === 1"
    >
      <v-tab
        id       ="tabform"
        href   	 ="#tab-form"
        @click 	 ="FirstLoad"
      >
        Form
      </v-tab>
      <v-tab 
        href    ="#tab-list"
        @click  ="List"
      >
        List
      </v-tab>
			<v-tab-item
        value  ="tab-form"
			>
        <v-form enctype="multipart/form-data" ref="form_ratetab" lazy-validation>
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color	    ="tertiary"
                class     ="white--text"
                :disabled ="access.add === 0 ? true:false" 
                depressed
                @click 	  ="Add" 
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn> &nbsp;
              <v-btn
                  color		  ="primary"
                  class		  ="white--text"
                  :disabled ="access.add === 1 || access.edit === 1 ? false:true"
                  rounded
                  @click 		="Confirm('write','')" 
                  small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>

            <!-- ID -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field 
                v-model 	  	="form.ratetab_id"
                required 
                :rules 	  		="form.ratetab_id_rules"
                :readonly    	="form.ratetab_id_readonly"
                :counter		  ="maxinput.id"
                :maxlength    ="maxinput.id"
                label       	="ID *"
                placeholder   ="ID"
                id 				    ="ratetab_id"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-text-field>
            </v-col>

            <!-- Desc -->
            <v-col cols="12" sm="4" md="4">
              <v-text-field 
                v-model 	  	="form.ratetab_desc"
                required 
                :rules 	  		="form.ratetab_desc_rules"
                label       	="Description *"
                placeholder 	="Description"
                id 				    ="ratetab_desc"
                @keyup.enter 	="Confirm('write','')"
              >
              </v-text-field>
            </v-col>

            <!-- Class -->
            <v-col cols="12" sm="4" md="4">
              <app-cb
                cb_type           ="classsub"
                cb_url            ="apiListClassSubClass"
                cb_url_parameter  ="classsub_actived=Y"
                cb_title          ="Class"
                cb_id             ="ratetab_class"
                cb_desc           ="ratetab_class_desc"
                cb_rules          ="Please fill Class"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="classsub_id"
                cb_items_desc     ="classsub_desc"
                :cb_value_id		  ="form.ratetab_class"
                :cb_value_desc		="form.ratetab_class_desc"
              >
              </app-cb>
            </v-col>                           

            <!-- Compulsory -->            
            <v-col cols="12" sm="2" md="2">
              <small>Compulsory</small><br>
              <v-btn-toggle dense v-model="form.ratetab_compulsory" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- Effective Date -->
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref         ="dialog_effdate"
                v-model     ="modal.effdate"
                transition  ="scale-transition"
                persistent
                width       ="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model				      ="form.ratetab_effdate"
                    label				        ="Effective Date"
                    prepend-inner-icon  ="event"
                    background-color 	  ="date"
                    v-on				        ="on"
                    v-mask				      ="'####-##-##'"
                    placeholder			    ="YYYY-MM-DD"
                    :rules 	  			    ="form.ratetab_effdate_rules"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="form.ratetab_effdate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.effdate =false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog_effdate.save(form.ratetab_effdate)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <!-- Unit -->
            <v-col cols="12" sm="2" md="2">
              <v-combobox
                v-model 	  	  ="form.ratetab_unit"
                required 
                :rules 	  		  ="form.ratetab_unit_rules"
                :items        	="ratetab_unit"
                label       	  ="Unit"
                placeholder 	  ="Unit"                
                id 				      ="ratetab_unit"
                @keyup.enter 	  ="Confirm('write','')"
              >
              </v-combobox>
            </v-col>

            <!-- Rate -->
            <v-col cols="12" sm="2" md="2">
							<v-text-field 
                v-model.lazy 		  ="form.ratetab_rate"
                ref 				      ="ratetab_rate"
                label 				    ="Rate"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_real"
                id 		      	    ="ratetab_rate"
                reverse
							>
							</v-text-field>
						</v-col>                      

            <!-- Coverperiod -->
            <v-col cols="12" sm="2" md="2">
              <v-combobox
                v-model 	  	  ="form.ratetab_coverperiod"
                required 
                :rules 	  		  ="form.ratetab_coverperiod_rules"
                :items        	="ratetab_coverperiod"
                label       	  ="Coverperiod"
                placeholder 	  ="Coverperiod"                
                id 				      ="ratetab_coverperiod"
                @keyup.enter 	  ="Confirm('write','')"
              >
              </v-combobox>
            </v-col>  

            <!-- Max Out Go -->
            <v-col cols="12" sm="2" md="2">
							<v-text-field 
                v-model.lazy 		  ="form.ratetab_maxoutgo"
                ref 				      ="ratetab_maxoutgo"
                label 				    ="Max Out Go"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_decimal_4"
                id 		      	    ="ratetab_maxoutgo"
                reverse
							>
							</v-text-field>
						</v-col>         

            <!-- No Refund -->            
            <v-col cols="12" sm="2" md="2">
              <small>No Refund</small><br>
              <v-btn-toggle dense v-model="form.ratetab_norefund" rounded>
                <v-btn small>
                  No
                </v-btn>
                <v-btn small>
                  Yes
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <!-- SI Based -->
            <v-col cols="12" sm="2" md="2">
              <v-combobox
                v-model 	  	  ="form.ratetab_sibased"
                required 
                :rules 	  		  ="form.ratetab_sibased_rules"
                :items        	="ratetab_sibased"
                label       	  ="SI Based"
                placeholder 	  ="SI Based"                
                id 				      ="ratetab_sibased"
                @keyup.enter 	  ="Confirm('write','')"
              >
              </v-combobox>
            </v-col>

            <!-- Active -->
            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
                <v-switch
                  v-model			  ="form.ratetab_actived"
                  color  			  ="primary"
                  id 				    ="form.ratetab_actived"
                  :label 			  ="form.ratetab_actived !== true ? '' : 'Active'" 
                  @keyup.enter  ="Confirm('write','')"
                >
              </v-switch>
            </v-col>
          </v-row>

          <!-- Info Rate Tab -->
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
            <div v-show="url_type !== 'add' ? true:false ">
              <v-checkbox v-model="cont.ratetab" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.ratetab" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.ratetab_cdate}}<br>Created User 	 : {{form.ratetab_cuser}}<br>Last Update Date : {{form.ratetab_ldate}}<br>Last Update User : {{form.ratetab_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Rate Tab -->
        </v-form>

        <template v-if="url_type !== 'add'">
          <!-- Tab Sub Module -->
          <v-tabs
            background-color="white"
            color="fourth"
            v-if = "access.read === 1"
          >
            <v-tab
              id      ="tabratetabrisk"
              href   	="#tab-ratetabrisk"	
              @click	="ListRateTabRisk(form.ratetab_id)"
            >
              Rate Tab Risk
					  </v-tab>
            <v-tab-item
              value  ="tab-ratetabrisk"
            >
              <!-- Data Table Rate Tab Risk -->
              <template>
                <!-- Main Button Rate Tab Risk -->
                <v-card-title
                  class="ml-n4"
                >
                  <v-btn 
                    :color 	="ratetabrisk.selected.length === ratetabrisk.items.length ? 'fourth':''"
                    @click	="SelectAllRateTabRisk"
                    class	  ="mr-2"
                    title	  ="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn 
                    @click  ="OpenRateTabRisk"
                    class	  ="mr-2"
                    title	  ="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn 
                    color     ="error"
                    title 	  ="delete selected"
                    @click 	  ="Confirm('multideleteratetabrisk','')"
                    class	    ="mr-2"
                    v-if  	  ="ratetabrisk.selected.length > 0"
                    :disabled ="access.delete === 0 ? true:false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn 
                    color  ="primary"
                    title  ="search"
                    v-if   ="ratetabrisk.searchbutton === false"
                    @click ="SearchRateTabRisk"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model				="ratetabrisk.search"
                    append-icon		="search"
                    @click:append ="ratetabrisk.searchbutton = false"
                    label				  ="Type and Enter"
                    single-line
                    hide-details
                    v-if 				  ="ratetabrisk.searchbutton"
                    id   				  ="searchratetabrisk"
                    clearable
                    @keyup.enter 	="ListRateTabRisk(form.ratetab_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Rate Tab Risk  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value        ="ratetabrisk.selected"
                  :headers      ="ratetabrisk.headers"
                  :items        ="ItemsRateTabRisk"
                  :search       ="ratetabrisk.search"
                  @input        ="CheckRateTabRisk($event)"
                  item-key      ="ratetabrisk_row"
                  show-select
                  class         ="elevation-1"
                  :footer-props ="{
                    showFirstLastPage : true,
                    firstIcon         : 'first_page',
                    lastIcon          : 'last_page',
                    prevIcon          : 'keyboard_arrow_left',
                    nextIcon          : 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	    ="loading.listratetabrisk"
                  loading-text  ="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	    ="mr-2"
                      @click	  ="EditRateTabRisk(item.ratetabrisk_ratetabid, item.ratetabrisk_riskcovid)"
                      :disabled ="access.edit === 0 ? true:false"
                      title 	  ="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click	  ="Confirm('deleteratetabrisk',item.ratetabrisk_ratetabid, item.ratetabrisk_riskcovid)"
                      :disabled ="access.delete === 0 ? true:false"
                      title 	  ="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Rate Tab Risk -->
            </v-tab-item>            
          </v-tabs>
          <!-- end: Tab Sub Module --> 
        </template>
      </v-tab-item>

      <v-tab-item
        value="tab-list"
      >
        <template>
          <v-card-title 
            class="ml-n4"
          >
            <v-btn 
              :color 	="selected.length === items.length ? 'secondary':''"
              @click  ="SelectAllPage"
              class	  ="mr-2"
              title	  ="select all page"
              small
            >
              select all page
            </v-btn>

            <v-btn 
              color     ="error"
              title 	  ="delete selected"
              @click 	  ="Confirm('multidelete','')"
              class	    ="mr-2"
              v-if  	  ="selected.length > 0"
              :disabled ="access.delete === 0 ? true:false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>

            <v-btn 
              color  ="primary"
              title  ="search"
              v-if   ="btn_search === false"
              @click ="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="search"
              append-icon   ="search"
              @click:append ="btn_search = false"
              label         ="Search"
              single-line
              hide-details
              v-if          ="btn_search"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :value        ="selected"
            :headers      ="headers"
            :items        ="items"
            :search       ="search"
            @input        ="Check($event)"
            item-key      ="ratetab_id"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
            dense
            :loading 	    ="loading.list"
            loading-text  ="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class	    ="mr-2"
                @click	  ="Edit(item.ratetab_id)"
                :disabled ="access.edit === 0 ? true:false"
                title 	  ="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click	  ="Confirm('delete',item.ratetab_id)"
                :disabled ="access.delete === 0 ? true:false"
                title 	  ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
  	</v-tabs>

    <!-- Dialog Rate Tab Risk -->
    <v-dialog
      v-model="dialog.ratetabrisk"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="750"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('ratetabrisk')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Rate Tab Risk</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddRateTabRisk" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.ratetabriskdialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_ratetabrisk" lazy-validation>          
          <v-row class="mx-2 mt-2">
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
								cb_type           ="riskcov"
								cb_url            ="apiListRiskcov"
								cb_url_parameter  ="riskcov_actived=Y"
								cb_title          ="Risk Coverage"
								cb_id             ="ratetabrisk_riskcovid"
								cb_rules          ="Please fill Risk Coverage"
								cb_items_id       ="riskcov_id"
								:cb_value_id      ="form.ratetabrisk_riskcovid"
								cb_single			    ="Y"
							>
							</app-cb>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-combobox
                v-model 	  	  ="form.ratetabrisk_category"
                required 
                :rules 	  		  ="form.ratetabrisk_category_rules"
                :items        	="ratetabrisk_category"
                label       	  ="Category"
                placeholder 	  ="Category"
                id 				      ="ratetabrisk_category"
                @keyup.enter 	  ="Confirm('write','')"
              >
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-text-field
                v-model.lazy 		  ="form.ratetabrisk_portion"
                ref 				      ="ratetabrisk_portion"
                label 				    ="Portion"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_decimal_5"
                id 		      	    ="ratetabrisk_portion"
                reverse
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <label class="caption">Active Status</label>
                <v-switch
                  v-model			  ="form.ratetabrisk_actived"
                  color  			  ="primary"
                  id 				    ="form.ratetabrisk_actived"
                  :label 			  ="form.ratetabrisk_actived !== true ? '' : 'Active'" 
                  @keyup.enter  ="Confirm('write','')"
                >
              </v-switch>
            </v-col>
          </v-row>

          <!-- Info Rate Tab Risk -->
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
            <div v-show="url_type !== 'addratetabrisk' ? true:false ">
              <v-checkbox v-model="cont.ratetabrisk" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.ratetabrisk" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.ratetabrisk_cdate}}<br>Created User 	 : {{form.ratetabrisk_cuser}}<br>Last Update Date : {{form.ratetabrisk_ldate}}<br>Last Update User : {{form.ratetabrisk_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Rate Tab Risk -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>        	
      </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end: Dialog Rate Tab Risk -->

    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
      <v-card>
        <v-toolbar dark color="fourth">
        <v-btn icon dark @click="remarks.dialog = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
      style 	  ="z-index: 9999;"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
        <v-card-text></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="dialog" color="primary"
              :disabled ="confirm.text === 'Ok' ? false:true"
              @click    ="Loading">{{confirm.text}}
            </v-btn>
            <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: 'add',
    cont: {
		  ratetab     : false,
		  ratetabrisk : false
    },
    access: {
      read    : 0,
      add     : 0,
      edit    : 0,
      delete  : 0
    },
    maxinput: {
			id    :	20,
			desc  : 150
		},
    snackbar: {
			dialog  : false,
			color   : 'success',
			text    : '',
			timeout : 3000
		},
    confirm: {
      dialog  : false,
      title   : '',
      text    : 'Ok',
		},
    dialog: {
			ratetabrisk : false
		},
    flag: {
      ratetabrisk     : false,
			showratetabrisk : false
    },
    format_real: {
			decimal   : '',
			thousands : '',
			precision : 0,
		},
    format_decimal_4: {
			decimal   : ',',
			thousands : '.',
			precision : 2,
		},
    format_decimal_5: {
			decimal   : ',',
			thousands : '.',
			precision : 2,
		},
    // Data Table Rate Tab
    headers           : [
      {
        text      : 'ID',
        align     : 'left',
        sortable  : false,
        value     : 'ratetab_id',
      },
      { text: 'Description', value: 'ratetab_desc' },
      { text: 'Rate', value: 'ratetab_rate' },
      { text: 'Unit', value: 'ratetab_unit' },
      { text: 'Actived', value: 'ratetab_actived' },
      { text: 'Actions', value: 'action', sortable: false }
		],
    items         : [],
    selected      : [],
    search        : '',
    searchbutton  : false,
    // end: Data Table Rate Tab

    // Data Table Rate Tab Risk
    ratetabrisk: {
      headers: [				
        { text: 'Risk Coverage', value: 'ratetabrisk_riskcovid' },
        { text: 'Category', value: 'ratetabrisk_category' },
        { text: 'Portion', value: 'ratetabrisk_portion' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      items         : [],
      selected      : [],
      search        : "",
      searchbutton  : false,
    },
    // end: Data Table Rate Tab Risk

    remarks: {
			feedback  : '',
			dialog    : false,
    },
    accessmenu: [],
    loading: {
      page              : true,
      list              : false,
      listratetabrisk   : false,
      ratetabriskdialog : false
    },  
    btn_search: true,
    ratetab_unit: [
      'C',
      'M',
      'F'
    ],
    ratetab_coverperiod: [
      'M',
      'D'
    ],
    ratetab_sibased: [
      'SI',
      'LOL'
    ],
    ratetabrisk_category: [
      'M',
      'A'
    ],
    modal:{
			effdate:false
		},
    form: {
      ratetab_id          : '',
      ratetab_desc        : '',
      ratetab_rate        : '',
      ratetab_unit        : '',
      ratetab_maxoutgo    : '',
      ratetab_class       : '',
      ratetab_class_desc  : '',
      ratetab_coverperiod : '',
      ratetab_actived     : true,
      ratetab_effdate     : '',
      ratetab_compulsory  : 0,
      ratetab_norefund    : 0,
      ratetab_sibased     : '',

      ratetab_id_rules          : [v => !!v || 'Please fill ID'],
      ratetab_desc_rules        : [v => !!v || 'Please fill Description'],
      ratetab_rate_rules        : [v => !!v || 'Please fill Rate'],
      ratetab_unit_rules        : [v => !!v || 'Please fill Unit'],
      ratetab_maxoutgo_rules    : [v => !!v || 'Please fill Max Out Go'],
      ratetab_class_rules       : [v => !!v || 'Please fill Class'],
      ratetab_coverperiod_rules : [v => !!v || 'Please fill Class'],
      ratetab_effdate_rules     : [v => !!v || 'Please fill Effective Date'],      
      ratetab_sibased_rules     : [v => !!v || 'Please fill SI Based'],      
      
      ratetab_id_readonly : false,

			ratetab_button      : 0,

      // Rate Tab Risk
      ratetabrisk_ratetabid     : '',
      ratetabrisk_riskcovid     : '',
      ratetabrisk_category      : '',
      ratetabrisk_portion       : '',
      ratetabrisk_actived       : true,
      ratetabrisk_riskcovid_par : '',
      ratetabrisk_cdate         : '',
      ratetabrisk_cuser         : '',
      ratetabrisk_ldate         : '',
      ratetabrisk_luser         : '',

      ratetabrisk_riskcovid_rules : [v => !!v || 'Please fill Risk Coverage'],
      ratetabrisk_category_rules  : [v => !!v || 'Please fill Category'],
      ratetabrisk_portion_rules   : [v => !!v || 'Please fill Portion']
    },
    loader        : null,
    loading_save  : false
  }),

  created () {
    this.FirstLoad()
  },

  computed: {
    ItemsRateTabRisk() {
      for (let i = 0 ;i <= this.ratetabrisk.items.length - 1 ;i++) {
        const ratetabriskindex = this.ratetabrisk.items[i]
        ratetabriskindex.ratetabrisk_row = this.ratetabrisk.items[i].ratetabrisk_ratetabid+'|'+this.ratetabrisk.items[i].ratetabrisk_riskcovid+'|'
      }
      return this.ratetabrisk.items
    },
  },

  methods: {
    FirstLoad () {
      //this function use for validating the screen with user privilleges
      this.Access()
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "ratetab"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
          let priv = response.data
          if (priv.length > 0) {
            this.access.read        = priv[0].usersmenu_read
            this.access.add         = priv[0].usersmenu_add
            this.access.edit        = priv[0].usersmenu_edit
            this.access.delete      = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
            }
          } else {
            this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
          }
          this.loading.page = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 0)
        this.loading.page = false
      })
    },

		//This function use for give feedback when user has no access with some feature
    NoPriviledge () {
      this.SnackBar(true, 'error', 'You dont have access `Rate Tab` modul, please contact Administrator', 0)
    },

    SelectAllPage () {
      this.selected = this.selected === this.items ? [] : this.items
    },    

    Check (value) {
      this.selected = value
    },

		//Function for get all data from table ratetab with API in mod_ratetab.go
		List() {
			this.loading.list = true
			let formdata = {
				ratetab_id: '',
				ratetab_desc: '',
				order_by: 'ratetab_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRateTab"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
              this.SnackBar(
                true,
                "error",
                feedback[0].feedback,
                0
              )
          } else {
            this.items = feedback
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.list = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.list = false
      })
		},

    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm (flag, id, par1) {
      if (flag === 'write') {
        if (this.$refs.form_ratetab.validate()) {
          this.confirm.dialog   = true
          this.confirm.title    = 'Save'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type 			= 'delete'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ id
        this.ratetab_id 		= id
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'addratetabrisk') {
				this.url_type 			= 'addratetabrisk'
				this.confirm.dialog = true
				this.confirm.title  = 'Save'
				setTimeout(function(){
				  document.getElementById('dialog').focus()
				}, 500)
      } else if (flag === 'editratetabrisk') {
				this.url_type 			= 'editratetabrisk'
				this.confirm.dialog = true
				this.confirm.title  = 'Save'
				setTimeout(function(){
				  document.getElementById('dialog').focus()
				}, 500)
      } else if (flag === 'deleteratetabrisk') {
        this.url_type 			        = 'deleteratetabrisk'
        this.confirm.dialog         = true
        this.confirm.title    	    = 'Delete Rate Tab Risk `'+ id +' & Riskcov ' + par1
        this.ratetabrisk_ratetabid  = id
        this.ratetabrisk_riskcovid  = par1
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multideleteratetabrisk') {
        this.url_type 			= 'multideleteratetabrisk'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ this.ratetabrisk.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 			= 'multidelete'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
		},

    Loading () {
      this.confirm.text = 'Loading...'
      if (this.url_type === 'add' || this.url_type === 'edit') {
        this.Write()
      } else if (this.url_type === 'delete') {
        this.Delete(this.ratetab_id)
      } else if (this.url_type === 'multidelete') {
        this.MultiProcess('delete')
			} else if (this.url_type === 'addratetabrisk' || this.url_type === 'editratetabrisk') {
        this.WriteRateTabRisk()
      } else if (this.url_type === 'deleteratetabrisk') {
        this.DeleteRateTabRisk(this.ratetabrisk_ratetabid, this.ratetabrisk_riskcovid)
      } else if (this.url_type === 'multideleteratetabrisk') {
        this.MultiProcessRateTabRisk()
      }
    },

    Close (flag) {
      if (flag === 'attributes') {
        this.url_type = 'edit'
        this.dialog.attributes = false
      } else if (flag === 'ratetabrisk') {
        this.url_type = 'edit'
	  		this.dialog.ratetabrisk = false
        this.ListRateTabRisk(this.form.ratetab_id)
      } else if (flag === 'confirm') {
        this.confirm.dialog = false
        if (this.url_type !== 'add') {
          this.url_type = 'edit'
        }
      }
    },

    // Function RateTab
		Write() {      
      let value_rate      = document.getElementById('ratetab_rate').value
      let value_class     = document.getElementById('ratetab_class').value
      let value_maxoutgo  = document.getElementById('ratetab_maxoutgo').value

      let compulsory = 'N'
			let norefund   = 'N'

      let formdata = {
        url_type			      : this.url_type,
        ratetab_id			    : this.form.ratetab_id,
        ratetab_desc		    : this.form.ratetab_desc,
        ratetab_rate	      : value_rate,
        ratetab_unit	      : this.form.ratetab_unit,				
        ratetab_maxoutgo	  : value_maxoutgo,
        ratetab_class		    : value_class,
        ratetab_coverperiod : this.form.ratetab_coverperiod,
        ratetab_actived		  : this.$functions.ActivedFlag(this.form.ratetab_actived),
        ratetab_effdate		  : this.form.ratetab_effdate,
        ratetab_compulsory	: compulsory,
        ratetab_norefund	  : norefund,
        ratetab_sibased		  : this.form.ratetab_sibased
      }
      console.log("Form Data", formdata)
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
			.post(this.$functions.UrlPOST("apiWriteRateTab"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.ratetab_cuser = feedback[0].feedback_users_id
							this.form.ratetab_cdate = feedback[0].feedback_users_date
						}
						this.form.ratetab_luser = feedback[0].feedback_users_id
						this.form.ratetab_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback = feedback
				}
			})
			.catch((e) => {
				this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback = e
			})
		},

    //Function to request insert data to table ratetab 'The API will validating your data, include the user who process the data' (go to APIWriteratetab with url_type = "add") 
    Add () {      
      this.url_type 		 		        = 'add'
      this.form.ratetab_id 	 	      = ''
      this.form.ratetab_desc 	 	    = ''
      this.form.ratetab_rate        = ''
      this.form.ratetab_unit        = ''
      this.form.ratetab_maxoutgo    = ''
      this.form.ratetab_class       = ''
      this.form.ratetab_class_desc  = ''
      this.form.ratetab_coverperiod = ''
      this.form.ratetab_actived     = true
      this.form.ratetab_effdate     = ''
      this.form.ratetab_compulsory  = 0
      this.form.ratetab_norefund    = 0
      this.form.ratetab_sibased     = ''

      document.getElementById('ratetab_rate').value = ''
      document.getElementById('ratetab_maxoutgo').value = ''
      document.getElementById('ratetab_class').value = ''

      this.form.ratetab_id_readonly = false
      setTimeout(function(){
        document.getElementById('ratetab_id').focus()
      }, 500)
		},

    //Function to request update data to table ratetab 'The API will validating your data, include the user who process the data' (go to APIWriteratetab with url_type = "edit") 
		Edit(id) {      
			this.loading.list = true     
      
      this.ListRateTabRisk(id)

			let formdata = {
				ratetab_id  : id,
				order_by    : 'ratetab_id',
				order_type  : 'ASC',
				limit       : this.limit,
			}
      console.log("ID", id)
      console.log("Form Data", formdata)
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListRateTab"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            document.getElementById("tabform").click()
            this.url_type 			    	    = 'edit'
						this.form.ratetab_id   	      = feedback[0].ratetab_id
						this.form.ratetab_desc 	      = feedback[0].ratetab_desc

            this.$refs.ratetab_rate.$el.getElementsByTagName('input')[0].value = feedback[0].ratetab_rate
						
						this.form.ratetab_unit        = feedback[0].ratetab_unit

            this.$refs.ratetab_maxoutgo.$el.getElementsByTagName('input')[0].value = feedback[0].ratetab_maxoutgo
						
						this.form.ratetab_class       = feedback[0].ratetab_class
						this.form.ratetab_class_desc  = feedback[0].ratetab_class_desc
						this.form.ratetab_coverperiod = feedback[0].ratetab_coverperiod
						this.form.ratetab_actived     = this.$functions.TrueOrFalse(feedback[0].ratetab_actived)
						this.form.ratetab_effdate     = feedback[0].ratetab_effdate						
						this.form.ratetab_compulsory  = this.$functions.YesOrNo(feedback[0].ratetab_compulsory)
						this.form.ratetab_norefund    = this.$functions.YesOrNo(feedback[0].ratetab_norefund)
            this.form.ratetab_sibased     = feedback[0].ratetab_sibased	
						this.form.ratetab_cdate		    = feedback[0].ratetab_cdate
						this.form.ratetab_cuser		    = feedback[0].ratetab_cuser
						this.form.ratetab_ldate		    = feedback[0].ratetab_ldate
						this.form.ratetab_luser		    = feedback[0].ratetab_luser
						this.form.ratetab_id_readonly = true						
            setTimeout(function() {
              document.getElementById("ratetab_desc").focus()
            }, 500)
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", feedback, 0)
        }
          this.loading.list = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.list = false
      })
		},

    Delete(id) {      
			let formdata = {
        url_type    : 'delete',
				ratetab_id  : id
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRateTab"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(
              true,
              "primary",
              "Delete Success",
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            this.selected = []
            this.List()
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
		},

    MultiProcess() {
      let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: 'multidelete',
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRateTab"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback[0].feedback === "Y") {
            let feed
            if (feedback[0].feedback_failed == 0) {
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
                this.remarks.feedback = feedback[0].feedback_failed_remarks
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                this.remarks.dialog = true
            }
            this.SnackBar(true, "primary", feed, 0)
            this.confirm.dialog = false
            this.confirm.text 	= "Ok"
            this.selected = []
             this.List()          
          } else {
              this.SnackBar(
                  true,
                  "error",
                  this.$functions.ErrorCode(),
                  0
              )
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
		},

    // Function Rate Tab Risk
    SelectAllRateTabRisk() {
			this.ratetabrisk.selected = this.ratetabrisk.selected === this.ratetabrisk.items ? [] : this.ratetabrisk.items
		},

    ListRateTabRisk (id) {
      this.loading.listratetabrisk  = true
			let formdata            = {
				ratetabrisk_ratetabid : id,
				order_by              : 'ratetabrisk_ratetabid',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRateTabRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(
              true,
              "error",
              feedback[0].feedback,
              0
            )
          } else {
            this.ratetabrisk.items = feedback
          }
        } else {
          this.ratetabrisk.items = feedback
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.listratetabrisk = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.listratetabrisk = false
      })
		},

    CheckRateTabRisk(value) {
			this.ratetabrisk.selected = value
		},

    SearchRateTabRisk () {
      this.ratetabrisk.searchbutton = true
      setTimeout(function() {
				document.getElementById("searchratetabrisk").focus()
			}, 500)
    },

    OpenRateTabRisk () {
			this.dialog.ratetabrisk = true
			this.AddRateTabRisk()
		},

    AddRateTabRisk () {
      this.url_type                   = 'addratetabrisk'
      this.form.ratetabrisk_ratetabid = ''
      this.form.ratetabrisk_riskcovid	= ''
      this.form.ratetabrisk_category  = ''
      this.form.ratetabrisk_portion   = 0

      this.flag.ratetabrisk		= true
			this.dialog.ratetabrisk = true
      
      this.$refs.ratetabrisk_portion.$el.getElementsByTagName('input')[0].value = 0
    },

    WriteRateTabRisk() {      
      let portion			= document.getElementById('ratetabrisk_portion').value      
      let riskcovid		= document.getElementById('ratetabrisk_riskcovid').value

      let formdata = {
				url_type              : this.url_type,
				ratetabrisk_ratetabid : this.form.ratetab_id,
				ratetabrisk_riskcovid : riskcovid,
				ratetabrisk_category  : this.form.ratetabrisk_category,
        ratetabrisk_portion   : portion,
        ratetabrisk_actived   : this.$functions.ActivedFlag(this.form.ratetabrisk_actived),

        ratetabrisk_riskcovid_par : this.form.ratetabrisk_riskcovid_par
			}     
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
			.post(this.$functions.UrlPOST("apiWriteRateTabRisk"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "addratetabrisk") {
							this.form.expense_cuser = feedback[0].feedback_users_id
							this.form.expense_cdate = feedback[0].feedback_users_date
						}
						this.form.expense_luser = feedback[0].feedback_users_id
						this.form.expense_ldate = feedback[0].feedback_users_date
						this.url_type 		      = "editratetabrisk"
						this.dialog.ratetabrisk = false
						this.ListRateTabRisk(this.form.ratetab_id)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
    },

    EditRateTabRisk(id, riskcovid) {
      this.flag.ratetabrisk			      = true
			this.dialog.ratetabrisk			    = true
			this.loading.ratetabriskdialog 	= true
			let formdata = {
				ratetabrisk_ratetabid : id,
				ratetabrisk_riskcovid : riskcovid,
				order_by              : 'ratetabrisk_ratetabid',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRateTabRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data  
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {              
              this.url_type = 'editratetabrisk'

              this.form.ratetabrisk_ratetabid     = feedback[0].ratetabrisk_ratetabid
              this.form.ratetabrisk_riskcovid     = feedback[0].ratetabrisk_riskcovid
              this.form.ratetabrisk_category      = feedback[0].ratetabrisk_category

              this.$refs.ratetabrisk_portion.$el.getElementsByTagName('input')[0].value = feedback[0].ratetabrisk_portion

              this.form.ratetabrisk_actived       = feedback[0].ratetabrisk_actived
              this.form.ratetabrisk_riskcovid_par = feedback[0].ratetabrisk_riskcovid

              this.form.ratetabrisk_cdate = feedback[0].ratetabrisk_cdate
              this.form.ratetabrisk_cuser = feedback[0].ratetabrisk_cuser
              this.form.ratetabrisk_ldate = feedback[0].ratetabrisk_ldate
              this.form.ratetabrisk_luser = feedback[0].ratetabrisk_luser
              this.dialog.ratetabrisk = true              
            } else {
              this.SnackBar(
                true,
                "error",
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
        this.loading.ratetabriskdialog = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.ratetabriskdialog = false
      })
    },

    DeleteRateTabRisk(id, riskcovid) {
      let formdata = {
				url_type: 'deleteratetabrisk',
				ratetabrisk_ratetabid: id,
				ratetabrisk_riskcovid: riskcovid
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRateTabRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
          let feedback = response.data          
          if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                  this.SnackBar(
                      true,
                      "primary",
                      "Delete Success",
                      3000
                  )
                  this.confirm.dialog = false
                  this.confirm.text = "Ok"
                  this.ratetabrisk.selected = []
                  this.ListRateTabRisk(this.form.ratetab_id)
              } else {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.ErrorCode(),
                  0
                )
                this.remarks.dialog 	= true
                this.remarks.feedback 	= feedback[0].feedback
              }
          }
      })
      .catch((e) => {
          this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
          )
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
      })
    },

    MultiProcessRateTabRisk() {
			let multiData = JSON.stringify(this.ratetabrisk.selected)
			let formdata  = {
				url_type        : this.url_type,				
				multi_data      : multiData
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteRateTabRisk"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback[0].feedback === "Y") {
						let feed
						if (feedback[0].feedback_failed == 0) {
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						} else {
							this.remarks.feedback = feedback[0].feedback_failed_remarks
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
							this.remarks.dialog = true
						}
						this.SnackBar(true, "primary", feed, 0)
						this.confirm.dialog 		= false
						this.confirm.text 			= "Ok"
						this.ratetabrisk.selected	= []
						if (this.url_type === 'multideleteratetabrisk') {
							this.ListRateTabRisk(this.form.ratetab_id)
						}
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
				})
		},
    // end: Function Rate Tab Risk

    SnackBar (dialog, color, text, timeout) {
      this.snackbar = {
        dialog  : dialog,
        color   : color,
        text    : text,
        timeout : timeout
      }
      if (color === 'error') {
        this.confirm.dialog 	 = false
        this.confirm.text 	 	 = 'Ok'
      }
    }
    
  }
}
</script>