<template>
  <div>
    <app-drawer_menu drawer_menu_title="Users"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color ="fourth"
      rounded
      v-if  ="loading.page"
    ></v-progress-linear>
    <v-tabs
      background-color="white"
      color ="fourth"
      v-if  ="access.read === 1"
    >
      <v-tab
        id      ="tabform"
        href   	="#tab-form"
        @click  ="FirstLoad"
      >
        Form
      </v-tab>
      <v-tab 
        href    ="#tab-list"
        @click  ="List"
      >
        List
      </v-tab>

      <v-tab-item
        value  ="tab-form"
      >
        <v-form enctype="multipart/form-data" ref="form_users" lazy-validation>
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color		  ="tertiary"
                class		  ="white--text"
                :disabled ="access.add === 0 ? true:false" 
                depressed
                @click 	  ="Add" 
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn> &nbsp;
              <v-btn
                color		  ="primary"
                class		  ="white--text"
                :disabled ="access.add === 0 || access.edit === 0 ? true:false"
                depressed
                @click 	  ="Confirm('write','')" 
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-avatar
                color="primary"
                size="100"
                @click="Upload"
              >
                <img 
                  :src="form.users_avatar_url" 
                  v-if="form.users_avatar !== '' "
                >
                <input type="file" ref="users_avatar" @change="UploadAvatar" accept="image/*" style="display:none">
              </v-avatar>
            </v-col>

            <!-- ID -->
            <v-col cols="12" sm="4" md="2" class="mt-n3">
              <v-text-field
                v-model 	  	    ="form.users_id"
                required
                :rules 	  	      ="form.users_id_rules"
                :readonly         ="form.users_id_readonly"
                :background-color ="form.users_id_readonly ? 'readonly' : ''"
                :maxlength 	      ="maxinput.id"
                :counter		      ="maxinput.id"
                label       	    ="ID *"
                placeholder 	    ="ID"
                id 			          ="users_id"
                @keyup.enter 	    ="Confirm('write','')"
              >
              </v-text-field>
            </v-col>
            <!-- Name -->
            <v-col cols="12" sm="4" md="2" class="mt-n3">
              <v-text-field
                v-model 	  	="form.users_name"
                required
                :rules        ="form.users_name_rules"
                :maxlength 	  ="maxinput.desc"
                label       	="Name *"
                placeholder 	="Name"
                id 			      ="users_name"
                @keyup.enter 	="Confirm('write','')"
              >
              </v-text-field>
            </v-col>
            <!-- Title -->
            <v-col cols="12" sm="4" md="2" class="mt-n3">
              <v-text-field
                v-model 	  	="form.users_title"
                required
                :rules        ="form.users_title_rules"
                label       	="Title *"
                placeholder 	="Title"
                id 			      ="users_title"
                @keyup.enter 	="Confirm('write','')"
              >
              </v-text-field>
            </v-col>
            <!-- Email -->
            <v-col cols="12" sm="12" md="2" class="mt-n3">
              <v-text-field
                v-model 	  	="form.users_email"
                required
                :rules        ="form.users_email_rules"
                label       	="Email *"
                placeholder 	="Email"
                id 			      ="users_email"
                @keyup.enter 	="Confirm('write','')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <app-cb
                cb_type           	="userstype"
                cb_url            	="apiListUsersType"
                cb_url_parameter  	="userstype_actived=Y"
                cb_title          	="Type"
                cb_id             	="users_type"
                cb_desc          	  ="users_typedesc"
                cb_rules          	="Please fill Users Type"
                cb_desc_readonly  	="readonly"
                cb_items_id       	="userstype_id"
                cb_items_desc     	="userstype_desc"
                :cb_value_id        ="form.users_type"
                :cb_value_desc      ="form.users_typedesc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <div v-show="url_type !== 'add' ? true:false ">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.users_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.users_cuser)">{{form.users_cuser}}</span>
Last Update Date : {{form.users_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.users_luser)">{{form.users_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
          <v-tabs
            background-color  ="white"
            color             ="secondary"
          >
            <v-tab
              id    ="tabgeneral"
              ref   ="tabgeneral"
              href  ="#tab-general"
            >
              General
            </v-tab>
            <v-tab
              id    ="tabadditional"
              ref   ="tabadditional"
              href  ="#tab-additional"
            >
              Additional
            </v-tab>
            <v-tab
              id    ="tabfamily"
              ref   ="tabfamily"
              href  ="#tab-family"
              v-if  ="url_type !== 'add'"
            >
              Family
            </v-tab>
            <v-tab
              id    ="tabsalary"
              ref   ="tabsalary"
              href  ="#tab-salary"
            >
              Salary
            </v-tab>
            <v-tab
              id    ="tabprivilidges"
              ref   ="tabprivilidges"
              href  ="#tab-privilidges"
              v-if  ="url_type !== 'add'"
            >
              Privileges
            </v-tab>

            <v-tab-item
              :eager ="true"
              value ="tab-general"
            >
              <!-- General -->              
              <v-row class ="mx-2 ma-1">
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type           ="branch"
                    cb_url            ="apiListBranch"
                    cb_url_parameter  ="branch_actived=Y"
                    cb_title          ="Branch"
                    cb_id             ="users_branch"
                    cb_desc          	="users_branchdesc"
                    cb_rules          ="Please fill Branch"
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="branch_id"
                    cb_items_desc     ="branch_desc"
                    :cb_value_id      ="form.users_branch"
                    :cb_value_desc    ="form.users_branchdesc"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field 
                    v-model 	  	   	="form.users_exp"
                    required 
                    :rules 	  	   	  ="form.users_exp_rules"
                    label       		  ="User Expiry"
                    placeholder 		  ="YYYY-MM-DD"
                    id 			   	      ="users_exp"
                    @keyup.enter 		  ="Confirm('write','')"
                    v-mask         	  ="'####-##-##'"
                    background-color  ="date"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field 
                    v-model 	  	    ="form.users_pass_exp"
                    required 
                    :rules 	  	   	  ="form.users_pass_exp_rules"
                    label       		  ="Password Expiry"
                    placeholder 		  ="YYYY-MM-DD"
                    id 			   	      ="users_pass_exp"
                    @keyup.enter 		  ="Confirm('write','')"
                    v-mask         	  ="'####-##-##'"
                    background-color  ="date"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_otherid"
                    label       	="Other ID"
                    placeholder 	="Other ID"
                    id 			      ="users_otherid"
                    @keyup.enter 	="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2" class="mt-2">
                  <v-select
                    v-model 	  ="form.users_nationality"
                    required 
                    :items      ="rows.nationality"
                    :item-text  ="rows.nationality.text"
                    :item-value ="rows.nationality.value"
                    label       ="Nationality"
                    placeholder ="Nationality"
                    id 			    ="users_nationality"
                    dense
                  >
                  </v-select>
                </v-col> 
                <v-col cols="12" sm="8" md="4" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_address"
                    label       	="Address"
                    placeholder 	="Address"
                    id 				    ="users_address"
                    @keyup.enter  ="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_zipcode"
                    label       	="Zipcode"
                    placeholder 	="Zipcode"
                    id 				    ="users_zipcode"
                    @keyup.enter  ="Confirm('write','')"
                    :counter 			="maxinput.zipcode"
                    :maxlength 		="maxinput.zipcode"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_telegramid"
                    label       	="Telegram ID"
                    placeholder 	="Telegram ID"
                    id 			      ="users_telegramid"
                    @keyup.enter 	="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  		  ="form.users_phone_1"
                    label       		  ="Phone 1"
                    placeholder 		  ="62XXXXXXXXXXXX"
                    id 				        ="users_phone_1"
                    @keyup.enter 		  ="Confirm('write','')"
                    background-color  ="numeric"
                    :counter 			    ="maxinput.phone"
                    :maxlength 		    ="maxinput.phone"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  		  ="form.users_phone_2"
                    type 				      ="number"
                    label       		  ="Phone 2"
                    placeholder 		  ="62XXXXXXXXXXXX"
                    id 				        ="users_phone_2"
                    @keyup.enter 		  ="Confirm('write','')"
                    background-color  ="numeric"
                    :counter 			    ="maxinput.phone"
                    :maxlength 		    ="maxinput.phone"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  		  ="form.users_mobile_1"
                    type 				      ="number"
                    label       		  ="Mobile 1"
                    placeholder 		  ="628XXXXXXXXXXX"
                    id 				        ="users_mobile_1"
                    @keyup.enter 		  ="Confirm('write','')"
                    background-color  ="numeric"
                    :counter 			    ="maxinput.phone"
                    :maxlength 		    ="maxinput.phone"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  		  ="form.users_mobile_2"
                    type 				      ="number"
                    label       		  ="Mobile 2"
                    placeholder 		  ="628XXXXXXXXXXX"
                    id 				        ="users_mobile_2"
                    @keyup.enter 		  ="Confirm('write','')"
                    background-color  ="numeric"
                    :counter 			    ="maxinput.phone"
                    :maxlength 		    ="maxinput.phone"
                  >
                  </v-text-field>
                </v-col>                
                <v-col cols="12" sm="1" md="1">
                  <v-switch
                    v-model	="form.users_actived"
                    color  	= "primary"
                    id 		= "form.users_actived"
                    :label 	="form.users_actived !== true ? 'Non Active' : 'Active'" 
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-switch
                    v-model	="form.users_locked"
                    color  	= "primary"
                    id 		= "form.users_locked"
                    :label 	="form.users_locked !== true ? 'Non Locked' : 'Locked'" 
                  ></v-switch>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              :eager ="true"
              value ="tab-additional"
            >
              <v-row class ="mx-2 ma-1">
                <v-col cols="12" sm="6" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_employeeid"
                    label       	="Employee ID"
                    placeholder 	="Employee ID"
                    id 			      ="users_employeeid"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-1">
                  <v-select
                    v-model 	  ="form.users_employeestatus"
                    :items      ="rows.empstatus"
                    :item-text  ="rows.empstatus.text"
                    :item-value ="rows.empstatus.value"
                    label       ="Status"
                    placeholder ="Status"
                    id 			    ="users_employeestatus"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model 	  	    ="form.users_entrydate"
                    label       	    ="User Entry Date"
                    placeholder 	    ="YYYY-MM-DD"
                    id 			   	      ="users_entrydate"
                    @keyup.enter      ="Confirm('write','')"
                    v-mask            ="'####-##-##'"
                    background-color 	="date"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model 	  	   	="form.users_enddate"
                    label       		  ="User End Date"
                    placeholder 		  ="YYYY-MM-DD"
                    id 			   	      ="users_enddate"
                    @keyup.enter 		  ="Confirm('write','')"
                    v-mask         	  ="'####-##-##'"
                    background-color  ="date"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <app-cb
                    cb_type           ="department"
                    cb_url            ="apiListDepartment"
                    cb_url_parameter  ="department_actived=Y"
                    cb_title          ="Department"
                    cb_id             ="users_department"
                    cb_desc          	="users_departmentdesc"
                    cb_desc_readonly  ="readonly"
                    cb_rules          =""
                    cb_items_id       ="department_id"
                    cb_items_desc     ="department_desc"
                    :cb_value_id      ="form.users_department"
                    :cb_value_desc    ="form.users_departmentdesc"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <app-cb
                    cb_type           ="users"
                    cb_url            ="apiListUsersShort"
                    cb_url_parameter  ="users_actived=Y&users_employeeflag=Y"
                    cb_title          ="Leader"
                    cb_id             ="users_leader"
                    cb_desc           ="users_leaderdesc"
                    cb_rules          =""
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="users_id"
                    cb_items_desc     ="users_name"
                    :cb_value_id      ="form.users_leader"
                    :cb_value_desc    ="form.users_leaderdesc"
                    cb_single         ="Y"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-2">
                  <v-select
                    v-model 	  ="form.users_religion"
                    :items      ="rows.religion"
                    :item-text  ="rows.religion.text"
                    :item-value ="rows.religion.value"
                    label       ="Religion"
                    placeholder ="Religion"
                    id 			    ="users_religion"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-n3">
                  <v-text-field 
                    v-model 	  	    ="form.users_birthdate"
                    label       		  ="Birth Date"
                    placeholder 		  ="YYYY-MM-DD"
                    id 			   	      ="users_birthdate"
                    v-mask         	  ="'####-##-##'"
                    background-color  ="date"
                  >
                  </v-text-field>
                </v-col>  
                <v-col cols="12" sm="6" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_birthplace"
                    label       	="Birth Place"
                    placeholder 	="Birth Place"
                    id 			      ="users_birthplace"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="1" class="mt-2">
                  <v-select
                    v-model 	  ="form.users_reftype"
                    :items      ="rows.reftype"
                    :item-text  ="rows.reftype.text"
                    :item-value ="rows.reftype.value"
                    label       ="RefType"
                    placeholder ="RefType"
                    id 			    ="users_reftype"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="8" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_refno"
                    label       	="Reference No"
                    placeholder 	="Reference No"
                    id 			      ="users_refno"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="1" class="mt-2">
                  <v-select
                    v-model 	  ="form.users_gender" 
                    :items      ="rows.gender"
                    :item-text  ="rows.gender.text"
                    :item-value ="rows.gender.value"
                    label       ="Gender"
                    placeholder ="Gender"
                    id 			    ="users_gender"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_npwpno"
                    label       	="NPWP No"
                    placeholder 	="NPWP No"
                    id 			      ="users_npwpno"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="5" md="4" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_npwpaddr"
                    label       	="NPWP Address"
                    placeholder 	="NPWP Address"
                    id 			      ="users_npwpaddr"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  		  ="form.users_phone_ext"
                    type 				      ="number"
                    label       		  ="Ext."
                    placeholder 		  ="XXXX"
                    id 				        ="users_phone_ext"
                    background-color  ="numeric"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n2">
                  <v-switch
                    v-model	="form.users_employeeflag"
                    color  	="primary"
                    id 		  ="form.users_employeeflag"
                    :label 	="form.users_employeeflag !== true ? 'Non Employee' : 'Employee'" 
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n2">
                  <v-switch
                    v-model	="form.users_moving"
                    color  	="primary"
                    id 		  ="form.users_moving"
                    :label 	="form.users_moving !== true ? 'Non Moving' : 'Moving'" 
                  ></v-switch>
                </v-col>                
                <v-col cols="12" sm="12" md="12" class="mt-n3">
                  <v-autocomplete
                    v-model			        ="users_autocc"
                    :items			        ="rows.autocc"
                    :search-input.sync  ="searchautocc"
                    :loading			      ="loading.autocc"
                    label				        ="Auto CC"
                    item-text			      ="users_email"
                    item-value		      ="users_email"
                    cache-items
                    multiple
                    dense
                    chips
                    hide-no-data
                    class="mt-2"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind		    ="data.attrs"
                        :input-value	="data.selected"
                        close
                        @click		    ="data.select"
                        @click:close  ="RemoveAutoCC(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.users_avatar_url"></v-img>
                        </v-avatar>
                        {{ data.item.users_email }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="data.item.users_avatar_url === ''">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <img :src="data.item.users_avatar_url" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.users_email"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type           ="bank"
                    cb_url            ="apiListBank"
                    cb_url_parameter  ="bank_actived=Y"
                    cb_title          ="Bank Account 1"
                    cb_id             ="users_accountbank"
                    cb_desc           ="users_accountbankdesc"
                    cb_rules          =""
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="bank_id"
                    cb_items_desc     ="bank_desc"
                    :cb_value_id      ="form.users_accountbank"
                    :cb_value_desc    ="form.users_accountbankdesc"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_accountno"
                    label       	="Bank Account No 1"
                    placeholder 	="Bank Account No 1"
                    id 			      ="users_accountno"
                    @keyup.enter 	="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="4" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_accountname"
                    label       	="Bank Account Name 1"
                    placeholder 	="Bank Account Name 1"
                    id 			      ="users_accountname"
                    @keyup.enter 	="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type           ="bank"
                    cb_url            ="apiListBank"
                    cb_url_parameter  ="bank_actived=Y"
                    cb_title          ="Bank Account 2"
                    cb_id             ="users_accountbank_2"
                    cb_desc           ="users_accountbankdesc_2"
                    cb_rules          =""
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="bank_id"
                    cb_items_desc     ="bank_desc"
                    :cb_value_id      ="form.users_accountbank_2"
                    :cb_value_desc    ="form.users_accountbankdesc_2"
                  >
                  </app-cb>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_accountno_2"
                    label       	="Bank Account No 2"
                    placeholder 	="Bank Account No 2"
                    id 			      ="users_accountno_2"
                    @keyup.enter  ="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="4" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_accountname_2"
                    label       	="Bank Account Name 2"
                    placeholder 	="Bank Account Name 2"
                    id 			      ="users_accountname_2"
                    @keyup.enter  ="Confirm('write','')"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              :eager ="true"
              value ="tab-family"
            >
              <v-row class ="mx-2">
                <v-col cols="12" sm="2" md="2" class="mt-4">
                  <v-select
                    v-model 	  ="form.users_marital" 
                    :items      ="rows.marital"
                    :item-text  ="rows.marital.text"
                    :item-value ="rows.marital.value"
                    label       ="Marital"
                    placeholder ="Marital"
                    id 			    ="users_marital"
                    dense
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-model 	  	    ="form.users_ptkpflag"
                    label       	    ="PTKP"
                    placeholder 	    ="PTKP"
                    id 			          ="users_ptkpflag"
                    :readonly         ="true"
                    background-color  ="readonly"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <template>
                    <v-card-title
                      class="ml-n4"
                    >
                      <v-btn 
                        :color 	="family.selected.length === family.items.length ? 'fourth':''"
                        @click	="SelectAllFamily"
                        class	  ="mr-2"
                        title	  ="select all"
                        small
                      >select all
                      </v-btn>
                      <v-btn 
                        @click  ="OpenFamily"
                        class	  ="mr-2"
                        title	  ="select all"
                        small
                      >Add
                      </v-btn>
                      <v-btn 
                        color     ="error"
                        title 	  ="delete selected"
                        @click 	  ="Confirm('multideletefamily','')"
                        class	    ="mr-2"
                        v-if  	  ="family.selected.length > 0"
                        :disabled ="access.delete === 0 ? true:false"
                        small
                      >
                        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                      </v-btn>
                      <v-btn 
                        color  ="primary"
                        title  ="search"
                        v-if   ="family.searchfamilybutton === false"
                        @click ="SearchFamily"
                        small
                      >
                        <v-icon>{{$vars.V('icon_search')}}</v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model				="family.searchfamily"
                        append-icon		="search"
                        @click:append ="family.searchfamilybutton = false"
                        label				  ="Type and Enter"
                        single-line
                        hide-details
                        v-if 				  ="family.searchfamilybutton"
                        id   				  = "searchfamily"
                        clearable
                        @keyup.enter 	= "ListFamily(form.users_id)"
                      >
                      </v-text-field>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    
                    <v-data-table
                      :value    ="family.selected"
                      :headers  ="family.headers"
                      :items    ="family.items"
                      @input    ="CheckFamily($event)"
                      item-key  ="usersfamily_type"
                      show-select
                      class     ="elevation-1"
                      :footer-props="{
                        showFirstLastPage: true,
                        firstIcon: 'first_page',
                        lastIcon: 'last_page',
                        prevIcon: 'keyboard_arrow_left',
                        nextIcon: 'keyboard_arrow_right'
                      }"
                      dense
                      :loading 	    ="loading.family"
                      loading-text  ="Loading... Please wait"
                    >
                      <template v-slot:item.action="{ item }">
                        <v-icon
                          small
                          class	    ="mr-2"
                          @click    ="EditFamily(item.usersfamily_type, item.usersfamily_category, item.usersfamily_categorynumber, item.usersfamily_gender, item.usersfamily_name, item.usersfamily_address, item.usersfamily_birthplace, item.usersfamily_birthdate, item.usersfamily_bloodtype, item.usersfamily_reftype, item.usersfamily_refno, item.usersfamily_mobile, item.usersfamily_actived, item.usersfamily_cdate, item.usersfamily_cuser, item.usersfamily_ldate, item.usersfamily_luser)"
                          :disabled ="access.edit === 0 ? true:false"
                          title 	  ="edit"
                        >
                          edit
                        </v-icon>
                        <v-icon
                          small
                          @click	  ="Confirm('deletefamily',item.usersfamily_type)"
                          :disabled ="access.delete === 0 ? true:false"
                          title 	  ="delete"
                        >
                          delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              :eager ="true"
              value ="tab-salary"
            >
              <v-row class ="mx-2 ma-1">
                <v-col cols="12" sm="8" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_bpjsno"
                    label       	="BPJS Kesehatan No"
                    placeholder 	="BPJS Kesehatan No"
                    id 			      ="users_bpjsno"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mt-n3">
                  <v-text-field
                    v-model 	  	="form.users_absenceid"
                    label       	="Absence ID"
                    placeholder 	="Absence ID"
                    id 			      ="users_absenceid"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <app-cb
                    cb_type           ="grade"
                    cb_url            ="apiListGrade"
                    cb_url_parameter  ="grade_actived=Y"
                    cb_title          ="Grade"
                    cb_id             ="users_grade"
                    cb_desc           ="users_gradedesc"
                    cb_rules          =""
                    cb_desc_readonly  ="readonly"
                    cb_items_id       ="grade_id"
                    cb_items_desc     ="grade_desc"
                    :cb_value_id      ="form.users_grade"
                    :cb_value_desc    ="form.users_gradedesc"
                  >
                  </app-cb>
                </v-col>                
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_ptkp"
                    ref 				      ="users_ptkp"
                    label 				    ="PTKP"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.ptkp"
                    id 		      	    ="users_ptkp"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                  <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_basicsalary"
                    ref 				      ="users_basicsalary"
                    label 				    ="Basic Salary"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.basicsalary"
                    id 		      	    ="users_basicsalary"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_mealallowance"
                    ref 				      ="users_mealallowance"
                    label 				    ="Meal Allowance"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.allowance"
                    id 		      	    ="users_mealallowance"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_transportationallowance"
                    ref 				      ="users_transportationallowance"
                    label 				    ="Transportation Allowance"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.allowance"
                    id 		      	    ="users_transportationallowance"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_positionallowance"
                    ref 				      ="users_positionallowance"
                    label 				    ="Position Allowance"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.allowance"
                    id 		      	    ="users_positionallowance"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_carallowance"
                    ref 				      ="users_carallowance"
                    label 				    ="Car Allowance"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.allowance"
                    id 		      	    ="users_carallowance"
                    reverse
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="2" class="mt-n3">
                  <v-text-field 
                    v-model.lazy 		  ="form.users_mobileallowance"
                    ref 				      ="users_mobileallowance"
                    label 				    ="Mobile Allowance"
                    placeholder 		  ="Rp 0"
                    background-color  ="numeric"
                    v-money 			    ="format_users.allowance"
                    id 		      	    ="users_mobileallowance"
                    reverse
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item
              :eager ="true"
              value ="tab-privilidges"
            >
              <v-row class ="mx-2 ma-1">
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    tile 
                    color="theme--dark primary" 
                    class="mr-2" 
                    @click="Dialog('Role')"
                  >
                    Role
                  </v-btn>
                  <v-btn
                    tile 
                    color="theme--dark primary" 
                    class="mr-2" 
                    @click="Dialog('Account')"
                  >
                    Account
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    tile 
                    color="theme--dark primary" 
                    class="mr-2" 
                    @click="Dialog('Channel')"
                  >
                    Channel
                  </v-btn>
                  <v-btn
                    tile 
                    color="theme--dark primary" 
                    class="mr-2" 
                    @click="Dialog('SubClass')"
                  >
                    Sub Class
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-btn
                    tile 
                    color="theme--dark primary" 
                    class="mr-2" 
                    @click="Dialog('CompanyAccount')"
                  >
                    Company Account
                  </v-btn>
                  <v-btn
                    tile 
                    color="theme--dark primary" 
                    class="mr-2" 
                    @click="Dialog('Branch')"
                  >
                    Branch
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-tab-item>
      <v-tab-item
        value="tab-list"
      >
        <template>
          <v-card-title 
            class ="ml-n4"
          >
            <v-btn 
              :color 	="selected.length === items.length ? 'secondary':''"
              @click  ="SelectAllPage"
              class   ="mr-2"
              title	  ="select all page"
              small
            >{{$vars.V('txt_select_all_page')}}
            </v-btn>
            <v-btn 
              color     ="error"
              title 	  ="delete selected"
              @click 	  ="Confirm('multidelete','')"
              class	    ="mr-2"
              v-if  	  ="selected.length > 0"
              :disabled ="access.delete === 0 ? true:false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn 
              color  ="primary"
              title  ="search"
              v-if   ="btn_search === false"
              @click ="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if ="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value    ="selected"
            :headers  ="headers"
            :items    ="items"
            :search   ="search"
            @input    ="Check($event)"
            item-key  ="users_id"
            show-select
            class     ="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
            dense
            :loading 	    = "loading.list"
            loading-text  ="Loading... Please wait"
          >
            <template v-slot:item.users_avatar="{ item }">
              <v-avatar size="60">
                <img :src="item.users_avatar_url" :alt="item.users_avatar">
              </v-avatar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class	    ="mr-2"
                @click	  ="Edit(item.users_id)"
                :disabled ="access.edit === 0 ? true:false"
                title 	  ="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click	  ="Confirm('delete',item.users_id)"
                :disabled ="access.delete === 0 ? true:false"
                title 	  ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
      <v-card>
        <v-toolbar dark color="fourth" dense>
        <v-btn icon dark @click="remarks.dialog = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
        <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              id        ="dialog" 
              :color    ="Variable('confirm',1)"
              :disabled ="confirm.text === 'Ok' ? false:true"
              @click    ="Loading">{{confirm.text}}
            </v-btn>
            <v-btn
              :color="Variable('confirm',2)"
              @click="confirm.dialog=false"
            >
              Close
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
      style 	  ="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog Family -->
    <v-dialog
      v-model     ="dialog.family"
      scrollable
      transition  ="dialog-bottom-transition"
      persistent
      max-width   ="890"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('family')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Family - {{ form.users_id  }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddFamily" title="Add New Family"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Family"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.detaildialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_users_family" lazy-validation>
          <v-row class="mx-2 mt-2">
            <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-select
                v-model 	    ="form.usersfamily_category"
                required 
                :rules 	  	  ="form.usersfamily_category_rules"
                :readonly	  	="form.usersfamily_category_readonly"
                :items        ="rows.category"
                :item-text    ="rows.category.text"
                :item-value   ="rows.category.value"
                label       	="Category"
                placeholder 	="Category"
                id 			   	  ="usersfamily_category"
                @keyup.enter  ="Confirm(url_type,'')"
                @change 			="FamilyCategory"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mt-n3">
              <v-text-field 
                v-model 	  	="form.usersfamily_categorynumber"
                required 
                :rules 	  		="form.usersfamily_categorynumber_rules"
                :readonly	  	="form.usersfamily_category_readonly"
                :counter		  ="maxinput.usersfamily_categorynumber"
                :maxlength		="maxinput.usersfamily_categorynumber"
                label       	="# of Sort *"
                placeholder 	="# of Sort"
                id 				    ="usersfamily_categorynumber"
                @keyup.enter 	="Confirm(url_type,'')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-radio-group
                v-model="form.usersfamily_gender"
                row
                dense
              >
                <v-radio
                  label="MALE"
                  value="MALE"
                >
                </v-radio>
                <v-radio
                  label="FEMALE"
                  value="FEMALE"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-text-field 
                  v-model 	  	="form.usersfamily_name"
                  required 
                  :rules 	  		="form.usersfamily_name_rules"
                  label       	="Name *"
                  placeholder 	="Name"
                  id 				    ="usersfamily_name"
                  @keyup.enter 	="Confirm(url_type,'')"
                >
                </v-text-field>
            </v-col>
            <v-col cols="12" sm="8" md="8">
              <v-text-field 
                v-model 	  	="form.usersfamily_address"
                required 
                :rules 	  		="form.usersfamily_address_rules"
                label       	="Address *"
                placeholder 	="Address"
                id 				    ="usersfamily_address"
                @keyup.enter 	="Confirm(url_type,'')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field 
                v-model 	  	="form.usersfamily_birthplace"
                required 
                :rules 	  		="form.usersfamily_birthplace_rules"
                label       	="Birth Place *"
                placeholder 	="Birth Place"
                id 				    ="usersfamily_birthplace"
                @keyup.enter 	="Confirm(url_type,'')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field 
                v-model 	  	   	="form.usersfamily_birthdate"
                required 
                :rules 	  	   	  ="form.usersfamily_birthdate_rules"
                label       		  ="Birth Date"
                placeholder 		  ="YYYY-MM-DD"
                id 			   	      ="usersfamily_birthdate"
                @keyup.enter 		  ="Confirm('write','')"
                v-mask         	  ="'####-##-##'"
                background-color  ="date"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3" :class="$vuetify.breakpoint.xs?'':'mt-4'">
              <v-select
                v-model     ="form.usersfamily_bloodtype"
                required 
                :items      ="rows.bloodtype"
                :item-text  ="rows.bloodtype.text"
                :item-value ="rows.bloodtype.value"
                label       ="Blood Type"
                placeholder ="Blood Type"
                id 			    ="usersfamily_bloodtype"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2" :class="$vuetify.breakpoint.xs?'':'mt-4'">
              <v-select
                v-model 	  ="form.usersfamily_reftype"
                required 
                :items      ="rows.reftype"
                :item-text  ="rows.reftype.text"
                :item-value ="rows.reftype.value"
                label       ="Ref Type"
                placeholder ="Ref Type"
                id 			    ="usersfamily_reftype"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field 
                v-model 	  	="form.usersfamily_refno"
                required 
                :rules 	  		="form.usersfamily_refno_rules"
                label       	="Ref No *"
                placeholder 	="Ref No"
                id 				    ="usersfamily_refno"
                @keyup.enter 	="Confirm(url_type,'')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field 
                v-model 	  	="form.usersfamily_mobile"
                required 
                :rules 	  		="form.usersfamily_mobile_rules"
                label       	="Mobile Phone *"
                placeholder 	="62XXXXXXXXX"
                id 				    ="usersfamily_mobile"
                @keyup.enter 	="Confirm(url_type,'')"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-switch
                v-model ="form.usersfamily_actived"
                color   ="primary"
                :label  ="form.usersfamily_actived !== true ? 'Non Active' : 'Active'" 
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
        <v-col cols="12" sm="12" md="12" class="mb-7 mt-n7">
          <div v-show="url_type === 'editfamily' ? true:false ">
            <v-checkbox v-model="flag.family" label="Show Info"></v-checkbox>
            <v-banner v-model="flag.family" single-line transition="slide-y-transition">
              <pre>
              Created Date 	 : {{form.usersfamily_cdate}}
              Created User 	 : {{form.usersfamily_cuser}}
              Last Update Date : {{form.usersfamily_ldate}}
              Last Update User : {{form.usersfamily_luser}}
              </pre>
            </v-banner>
          </div>
        </v-col>
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>        	
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Family -->

    <!-- Dialog Users Role -->
    <v-dialog
      v-model="dialog.role"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490" 
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.role"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.role = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
        <v-toolbar-title>Users Role - {{ form.usersrole_usersid }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn 
            @click  ="CheckRole()"
            class   ="mr-2"
            small
            icon
            dark
          ><v-icon>done_all</v-icon></v-btn>
          <v-btn icon dark @click="Confirm('usersrole', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingRole">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr
                    v-for ="item in usersrole"
                    :key  ="item.usersrole_id"
                  >
                    <td>
                      <input
                        type 	    ="checkbox"
                        dense
                        :id		    ="`id${item.usersrole_id}`"
                        :checked  = "item.usersrole_usersid != '' ? true : false"
                      >
                      <label class="cursor" :for="`id${item.usersrole_id}`">
                        {{ item.usersrole_id }}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Users Role -->

    <!-- Dialog Users Account -->
    <v-dialog
      v-model     ="dialog.account"
      scrollable
      persistent
      transition  ="dialog-bottom-transition"
      max-width   ="490" 
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.account"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.account = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Users Account - {{ form.usersaccount_usersid }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn 
            @click  ="CheckAccount()"
            class   ="mr-2"
            small
            icon
            dark
          ><v-icon>done_all</v-icon></v-btn>
          <v-btn icon dark @click="Confirm('usersaccount', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingAccount">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr
                    v-for ="item in usersaccount"
                    :key  ="item.usersaccount_id"
                  >
                    <td>
                      <input
                        type 	    ="checkbox"
                        dense
                        :id		    ="`id${item.usersaccount_id}`"
                        :checked  = "item.usersaccount_usersid != '' ? true : false"
                      >
                      <label class="cursor" :for="`id${item.usersaccount_id}`">
                        {{ item.usersaccount_id }} - {{ item.usersaccount_desc }}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Users Account -->

    <!-- Dialog Users Channel -->
    <v-dialog
      v-model="dialog.channel"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490" 
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.channel"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.channel = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Users Channel - {{ form.userschannel_usersid }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn 
            @click  ="CheckChannel()"
            class   ="mr-2"
            small
            icon
            dark
          ><v-icon>done_all</v-icon></v-btn>
          <v-btn icon dark @click="Confirm('userschannel', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingChannel">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr
                    v-for ="item in userschannel"
                    :key  ="item.userschannel_id"
                  >
                    <td>
                      <input
                        type 	    ="checkbox"
                        dense
                        :id		    ="`id${item.userschannel_id}`"
                        :checked  = "item.userschannel_usersid != '' ? true : false"
                      >
                      <label class="cursor" :for="`id${item.userschannel_id}`">
                        {{ item.userschannel_id }}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Users Channel -->

    <!-- Dialog Users Sub Class -->
    <v-dialog
      v-model="dialog.subclass"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="690" 
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.subclass"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.subclass = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Users Sub Class - {{ form.userssubclass_usersid }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn 
            @click  ="CheckSubClass()"
            class   ="mr-2"
            small
            icon
            dark
          ><v-icon>done_all</v-icon></v-btn>
          <v-btn icon dark @click="Confirm('userssubclass', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingSubClass">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr
                    v-for ="item in userssubclass"
                    :key  ="item.userssubclass_id"
                  >
                    <td>
                      <input
                        type 	    ="checkbox"
                        dense
                        :id		    ="`id${item.userssubclass_id}`"
                        :checked  = "item.userssubclass_usersid != '' ? true : false"
                      >
                      <label class="cursor" :for="`id${item.userssubclass_id}`">
                        {{ item.userssubclass_id }} - {{ item.userssubclass_desc }} 
                      </label>
                    </td>
                    <td>
                      <vuetify-money
                        v-model           ="item.userssubclass_limitin"
                        v-bind:options    ="formatamount"
                        label             ="Limit In"
                        placeholder       ="Limit In"
                        background-color  ="numeric"
                        v-bind:properties ="{reverse:true,id:'limitin'+item.userssubclass_id}"
                      />
                    </td>
                    <td>
                      <vuetify-money
                        v-model           ="item.userssubclass_limitout"
                        v-bind:options    ="formatamount"
                        label             ="Limit Out"
                        placeholder       ="Limit Out"
                        background-color  ="numeric"
                        v-bind:properties ="{reverse:true,id:'limitout'+item.userssubclass_id}"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Users Sub Class -->

    <!-- Dialog Users Company Account -->
    <v-dialog
      v-model="dialog.compacc"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="490" 
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.compacc"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.compacc = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Users Company Account - {{ form.userscompacc_usersid }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn 
            @click  ="CheckCompanyAccount()"
            class   ="mr-2"
            small
            icon
            dark
          ><v-icon>done_all</v-icon></v-btn>
          <v-btn icon dark @click="Confirm('userscompacc', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingCompanyAccount">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr
                    v-for ="item in userscompacc"
                    :key  ="item.userscompacc_id"
                  >
                    <td>
                      <input
                        type 	    ="checkbox"
                        dense
                        :id		    ="`id${item.userscompacc_id}`"
                        :checked  = "item.userscompacc_usersid != '' ? true : false"
                      >
                      <label class="cursor" :for="`id${item.userscompacc_id}`">
                        {{ item.userscompacc_id }}
                      </label>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Users Company Account -->

    <!-- Dialog Users Branch -->
    <v-dialog
      v-model="dialog.branch"
      scrollable
      persistent
      transition="dialog-bottom-transition"
      max-width="720" 
    >
      <v-card>
        <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.branch"
        ></v-progress-linear>
        <v-toolbar dark color="secondary" dense>
          <v-btn icon dark @click="dialog.branch = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Branch Access - {{ form.userstype_id }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Confirm('usersbranch', '')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-btn icon dark @click="LoadingBranch">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <template>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Branch</th>
                    <th>Policy [<span @click  ="CheckBranch('policy')" class="cursor"><u>check</u></span>]</th>
                    <th>Claim [<span @click  ="CheckBranch('claim')" class="cursor"><u>check</u></span>]</th>
                    <th>Finance [<span @click  ="CheckBranch('finance')" class="cursor"><u>check</u></span>]</th>
                    <th>Accounting [<span @click  ="CheckBranch('accounting')" class="cursor"><u>check</u></span>]</th>
                  </tr>
                </thead>
                <tbody>
                    <tr
                      v-for="items in usersbranch"
                      :key="items.usersbranch_branchid"
                    >
                      <td><label class="cursor" :for="`id${items.usersbranch_usersid}`"> {{ items.usersbranch_branchid }} - {{ items.usersbranch_branchdesc }}</label></td>
                  <td>
                    <input
                      type 	    ="checkbox"
                      dense
                      :id		    ="`idpolicy${items.usersbranch_branchid}`"
                      :checked  = "items.usersbranch_policy == 1 ? true : false"
                    >
                  </td>
                  <td>
                    <input
                      type 	    ="checkbox"
                      dense
                      :id		    ="`idclaim${items.usersbranch_branchid}`"
                      :checked  = "items.usersbranch_claim == 1 ? true : false"
                    >
                  </td>
                  <td>
                    <input
                      type 	    ="checkbox"
                      dense
                      :id		    ="`idfinance${items.usersbranch_branchid}`"
                      :checked  = "items.usersbranch_finance == 1 ? true : false"
                    >
                  </td>
                  <td>
                    <input
                      type 	    ="checkbox"
                      dense
                      :id		    ="`idaccounting${items.usersbranch_branchid}`"
                      :checked  = "items.usersbranch_accounting == 1 ? true : false"
                    >
                  </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Users Branch -->
    
    <!-- Dialog Users -->
    <app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>

<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
    maxinput: {
			id: 20,
			desc: 150,
			phone: 20,
			zipcode: 10,
			usersfamily_categorynumber: 2
		},
		snackbar: {
			dialog: false,
			color: "primary",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},
		selected: [],
		search: "",
		headers: [
      { text: "Actions", value: "action", sortable: false },
      { text: "Avatar", value: "users_avatar", align: "center" },
			{
				text: "ID",
				align: "left",
				value: "users_id",
			},
			{ text: "Name", value: "users_name" },
			{ text: "Type", value: "users_type" },
			{ text: "Actived", value: "users_actived" },
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		accessmenu: [],
    btn_search: true,
    loading: {
			page      : true,
			list      : false,
			autocc    : false,
			family    : false,
			role      : false,
			account   : false,
			channel   : false,
			subclass  : false,
			compacc   : false,
			branch    : false,

			dialogfamily  : false
		},
    flag: {
      family: false,
    },
    form: {
      // Form Users
      users_test      : "",
      users_avatar      : "",
			users_avatar_url  : "", 
			users_id          : "",
			users_name        : "",
      users_title       : "",
      users_email       : "",
      users_type        : "",
			users_typedesc    : "",

      users_id_readonly : false,

      users_id_rules        : [(v) => !!v || "Please fill ID"],
			users_name_rules      : [(v) => !!v || "Please fill Name"],
			users_title_rules     : [(v) => !!v || "Please fill Title"],
			users_email_rules     : [(v) => !!v || "Please fill Email"],
			users_pass_exp_rules  : [(v) => !!v || "Please fill Password Expiry"],
			users_exp_rules       : [(v) => !!v || "Please fill User Expiry"],

      // Form Users General
      users_branch      : "",
			users_branchdesc  : "",
			users_exp         : "",
			users_pass_exp    : "",
      users_otherid     : "",
      users_nationality : "",
			users_address     : "",
			users_zipcode     : "",
      users_telegramid  : "",
      users_phone_1     : "",
			users_phone_2     : "",
			users_mobile_1    : "",
			users_mobile_2    : "",
			users_actived     : true,
			users_locked      : false,

      // Form Users Additional
			users_employeeid        : "",
			users_employeestatus    : "",
			users_entrydate         : "",      
			users_enddate           : "",
      users_department        : "",
			users_departmentdesc    : "",
      users_leader            : "",
			users_leaderdesc        : "",
      users_religion          : "",
      users_birthdate         : "",
      users_birthplace        : "", 
      users_reftype           : "",
      users_refno             : "",
      users_gender            : "",
      users_npwpno            : "",
      users_npwpaddr          : "",
      users_phone_ext         : "",
			users_employeeflag      : false,
			users_moving            : false,   
      users_autocc            : "",
      users_accountbank       : "",
      users_accountbankdesc   : "",
      users_accountno         : "",
      users_accountname       : "",
      users_accountbank_2     : "",
      users_accountbankdesc_2 : "",
      users_accountno_2       : "",
      users_accountname_2     : "",

      // Form Users Family
      users_marital   : "",			
			users_ptkpflag  : "",

      usersfamily_usersid         : '',
			usersfamily_category        : '',
			usersfamily_categorynumber  : '1',
			usersfamily_gender          : 'MALE',
			usersfamily_name            : '',
			usersfamily_address         : '',
			usersfamily_birthplace      : '',
			usersfamily_birthdate       : '',
			usersfamily_bloodtype       : '',
			usersfamily_reftype         : '',
			usersfamily_refno           : '',
			usersfamily_mobile          : '',
			usersfamily_actived         : true,
			usersfamily_type            : '',
			usersfamily_cdate           : '',
			usersfamily_cuser           : '',
			usersfamily_ldate           : '',
			usersfamily_luser           : '',

			usersfamily_category_readonly       : false,
			usersfamily_categorynumber_readonly : false,

			usersfamily_name_rules            : [(v) => !!v || "Please fill Name"],
			usersfamily_type_rules            : [(v) => !!v || "Please fill Type"],
			usersfamily_address_rules         : [(v) => !!v || "Please fill Address"],
			usersfamily_category_rules        : [(v) => !!v || "Please fill Category"],
			usersfamily_categorynumber_rules  : [(v) => !!v || "Please fill Number"],
			usersfamily_reftype_rules         : [(v) => !!v || "Please fill Ref Type"],
			usersfamily_refno_rules           : [(v) => !!v || "Please fill Ref No"],
			usersfamily_mobile_rules          : [(v) => !!v || "Please fill Mobile Phone"],
			usersfamily_birthplace_rules      : [(v) => !!v || "Please fill Birth Place"],
			usersfamily_birthdate_rules       : [(v) => !!v || "Please fill Birth Date"],
			usersfamily_bloodtype_rules       : [(v) => !!v || "Please fill Blood Type"],

      // Form Users Salary
      users_bpjsno                  : "",
      users_grade                   : "",
      users_gradedesc               : "",
      users_basicsalary             : 0,
      users_ptkp                    : 0,
      users_absenceid               : "",
      users_mealallowance           : 0,
      users_transportationallowance : 0,
      users_positionallowance       : 0,
      users_carallowance            : 0,
      users_mobileallowance         : 0,

      // Form Users Privileges      
      usersrole_usersid     : '',
      usersaccount_usersid  : '',
      userschannel_usersid  : '',
      userssubclass_usersid : '',
      userscompacc_usersid  : '',
      usersbranch_usersid   : '',

      display_users         : '',
    },
    format_users: {
      ptkp: {
        decimal: '',
        thousands: '',
        precision: 0,
      },
      basicsalary: {
        decimal: '.',
        thousands: ',',
        precision: 0,
      },
      allowance: {
        decimal: '.',
        thousands: ',',
        precision: 0,
      },
    },
    rows: {
			gender: [
        {text: '', value: ''},
        {text: 'Male', value: 'M'},
        {text: 'Female', value: 'F'}
      ],
      reftype: [
        {text: '', value: ''},
        {text: 'KTP', value: 'KTP'},
        {text: 'SIM', value: 'SIM'}
      ],
      nationality: [
        {text: 'WNI', value: 'WNI'},
        {text: 'WNA', value: 'WNA'}
      ],
      empstatus: [
        {text: '', value: ''},
        {text: 'APPRENTICE', value: 'APPRENTICE'},
        {text: 'CONTRACT', value: 'CONTRACT'},
        {text: 'PERMANENT', value: 'PERMANENT'}
      ],
      marital: [
        {text: '', value: ''},
        {text: 'SINGLE', value: 'SINGLE'},
        {text: 'MARRIED', value: 'MARRIED'}
      ],
      religion: [
        {text: '', value: ''},
        {text: 'ISLAM', value: 'ISLAM'},
        {text: 'PROTESTAN', value: 'PROTESTAN'},
        {text: 'KATOLIK', value: 'KATOLIK'},
        {text: 'HINDU', value: 'HINDU'},
        {text: 'BUDHA', value: 'BUDHA'},
      ],
      category: [
        {text: '', value: ''},
        {text: 'WIFE', value: 'WIFE'},
        {text: 'HUSBAND', value: 'HUSBAND'},
        {text: 'SON', value: 'SON'},
        {text: 'DAUGHTER', value: 'DAUGHTER'},
      ],
      bloodtype: [
        {text: '', value: ''},
        {text: 'A', value: 'A'},
        {text: 'B', value: 'B'},
        {text: 'AB', value: 'AB'},
        {text: 'O', value: 'O'},
      ],
      autocc: [],
      search: null
		},
    searchautocc: null,
    users_autocc: [],
    family: {
      headers: [
        { text: "Category", value: "usersfamily_category" },
        { text: "Number", value: "usersfamily_categorynumber" },
        {
          text: "Name",
          align: "left",
          value: "usersfamily_name",
        },
        { text: "Ref Type", value: "usersfamily_reftype" },
        { text: "Ref No", value: "usersfamily_refno" },
        { text: "Mobile", value: "usersfamily_mobile" },
        { text: "Actions", value: "action", sortable: false },
      ],
      items: [],
      selected: [],
      search: "",
      searchfamilybutton: false,
    },
    dialog: {
      family    : false,
      role      : false,
      account   : false,
      channel   : false,
      subclass  : false,
      compacc   : false,
      branch    : false,
      users     : false,
    },
    privilidges: {
      checkrole               : false,
      checkaccount            : false,
      checkchannel            : false,
      checksubclass           : false,
      checkcompacc            : false,
      checkbranch_policy      : false,
      checkbranch_claim       : false,
      checkbranch_finance     : false,
      checkbranch_accounting  : false,
    },
    usersrole         : [],
    selectedusersrole : [],
    usersaccount         : [],
    selectedusersaccount : [],
    userschannel         : [],
    selecteduserschannel : [],
    userssubclass         : [],
    selecteduserssubclass : [],
    userscompacc         : [],
    selecteduserscompacc : [],
    usersbranch         : [],
    selectedusersbranch : [],
    //Format Number
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2
    },
    properties: {
      reverse: true
    },
  }),

  created() {
		this.FirstLoad()
	},

	watch: {
    searchautocc (val) {
      if (val.length > 3){
        this.AutoCC(val)
      }
    }
  },

  methods: {
    FirstLoad() {
			// Set Default Today
			this.form.users_exp 	    =  this.$functions.TodayYYYYMMDD()
			this.form.users_pass_exp  =  this.$functions.TodayYYYYMMDD()
			this.form.users_entrydate =  this.$functions.TodayYYYYMMDD()
			this.form.users_enddate   =  this.$functions.TodayYYYYMMDD()
			this.form.users_birthdate =  this.$functions.TodayYYYYMMDD()
			//this function use for validating the screen with user privilleges
			this.Access()
		},

    Access() {
			let modul = 'users'
			let formdata = {
				menu_id     : modul,
				users_id    : this.$functions.UsersID(),
				order_by    : 'menu_id',
				order_type  : 'ASC',
				limit       : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListMenuUser'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let priv = response.data
        if (priv.length > 0) {
          this.access.read = priv[0].usersmenu_read
          this.access.add = priv[0].usersmenu_add
          this.access.edit = priv[0].usersmenu_edit
          this.access.delete = priv[0].usersmenu_delete
          if (priv[0].usersmenu_read === 0) {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
        }
        this.loading.page = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', e, 0)
        this.loading.page = false
      })
		},

    //Function for get all data from table Users with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				users_id: "",
				users_name: "",
				order_by: "users_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListUsers"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(
              true,
              "error",
              feedback[0].feedback,
              0
            )
          } else {
            this.items = feedback
          }
        } else {
          this.items = feedback
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.list = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.list = false
      })
		},

    Write() {
      let type          = document.getElementById('users_type').value
      let branch        = document.getElementById('users_branch').value
      let department    = document.getElementById('users_department').value
      let leader        = document.getElementById('users_leader').value
      let accountbank   = document.getElementById('users_accountbank').value
      let accountbank_2 = document.getElementById('users_accountbank_2').value
      let grade         = document.getElementById('users_grade').value

			let formdata = {
				url_type          : this.url_type,
				users_avatar      : this.form.users_avatar,
				users_avatar_url  : this.form.users_avatar_url.replace(/^.+?;base64,/, ''),
				users_id          : this.form.users_id,
				users_name        : this.form.users_name,
				users_title       : this.form.users_title,
				users_email       : this.form.users_email,
				users_type        : type,

        // Form General
				users_branch      : branch,
				users_exp         : this.form.users_exp,
				users_pass_exp    : this.form.users_pass_exp,
				users_otherid     : this.form.users_otherid,
				users_nationality : this.form.users_nationality,
				users_address     : this.form.users_address,
				users_zipcode     : this.form.users_zipcode,
				users_telegramid  : this.form.users_telegramid,
				users_phone_1     : this.form.users_phone_1,
				users_phone_2     : this.form.users_phone_2,
				users_mobile_1    : this.form.users_mobile_1,
				users_mobile_2    : this.form.users_mobile_2,
				users_actived     : this.$functions.ActivedFlag(this.form.users_actived),
				users_locked      : this.$functions.ActivedFlag(this.form.users_locked),

        // Form Additional
        users_employeeid        : this.form.users_employeeid,
        users_employeestatus    : this.form.users_employeestatus,
        users_entrydate         : this.form.users_entrydate,
        users_enddate           : this.form.users_enddate,
        users_department        : department,
        users_departmentdesc    : this.form.users_departmentdesc,
        users_leader            : leader,
        users_leaderdesc        : this.form.users_leaderdesc,
        users_religion          : this.form.users_religion,
        users_birthdate         : this.form.users_birthdate,
        users_birthplace        : this.form.users_birthplace,
        users_reftype           : this.form.users_reftype,
        users_refno             : this.form.users_refno,
        users_gender            : this.form.users_gender,
        users_npwpno            : this.form.users_npwpno,
        users_npwpaddr          : this.form.users_npwpaddr,
        users_phone_ext         : this.form.users_phone_ext,
        users_employeeflag      : this.$functions.ActivedFlag(this.form.users_employeeflag),
        users_moving            : this.$functions.ActivedFlag(this.form.users_moving),
        users_autocc            : this.form.users_autocc,
        users_accountbank       : accountbank,
        users_accountbankdesc   : this.form.users_accountbankdesc,
        users_accountno         : this.form.users_accountno,
        users_accountname       : this.form.users_accountname,
        users_accountbank_2     : accountbank_2,
        users_accountbankdesc_2 : this.form.users_accountbankdesc_2,
        users_accountno_2       : this.form.users_accountno_2,
        users_accountname_2     : this.form.users_accountname_2,

        // Form Family
        users_marital           : this.form.users_marital,

        // Form Salary
        users_bpjsno                  : this.form.users_bpjsno,
        users_grade                   : grade,
        users_basicsalary             : this.form.users_basicsalary,
        users_ptkp                    : this.form.users_ptkp,
        users_absenceid               : this.form.users_absenceid,
        users_mealallowance           : this.form.users_mealallowance,
        users_transportationallowance : this.form.users_transportationallowance,
        users_positionallowance       : this.form.users_positionallowance,
        users_carallowance            : this.form.users_carallowance,
        users_mobileallowance         : this.form.users_mobileallowance,
			}
      console.log('Let Data: ', formdata)
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiWriteUsers"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            this.SnackBar(
              true,
              "primary",
              "Save Success",
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = "Ok"
            if (this.url_type === "add") {
              this.form.users_cuser = feedback[0].feedback_users_id
              this.form.users_cdate = feedback[0].feedback_users_date
            }
            this.form.users_luser = feedback[0].feedback_users_id
            this.form.users_ldate = feedback[0].feedback_users_date
            this.url_type = "edit"
            document.getElementById("tabgeneral").click()
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        } else {
          this.SnackBar(
            true,
            "error",
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog 	= true
          this.remarks.feedback 	= feedback
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
		},

    Delete(id) {
			let formdata = {
				url_type: "delete",
				users_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteUsers"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback === "Y") {
							this.SnackBar(
								true,
								"primary",
								"Delete Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text = "Ok"
							this.selected = []
							this.List()
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},

    //Pop Up message for validating (Ok or Cancel) your proccess
		Confirm (flag, id) {
			if (flag === "write") {
				if (this.$refs.form_users.validate()) {
					this.confirm.dialog = true
					this.confirm.title = "Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag === "delete") {
				this.url_type = "delete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + id + "`"
				this.form.users_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "multidelete") {
				this.url_type = "multidelete"
				this.confirm.dialog = true
				this.confirm.title =
					"Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === 'addfamily') {
				if (this.$refs.form_users_family.validate()) {
          this.url_type 		  = 'addfamily'
          this.confirm.dialog   = true
          this.confirm.title    = 'Save Family'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'editfamily') {
        if (this.$refs.form_users_family.validate()) {
          this.url_type 		  = 'editfamily'
          this.confirm.dialog   = true
          this.confirm.title    = 'Edit Family'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'deletefamily') {
        this.url_type 		  = 'deletefamily'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `' + id + '`'
        this.form.usersfamily_type   = id
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multideletefamily') {
        this.url_type 		  = 'multideletefamily'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.family.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'usersrole') {
        this.url_type 		    = 'usersrole'
        this.confirm.dialog   = true
        this.confirm.title    = 'Save Access Users Role for `' + this.form.usersrole_usersid + '`'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'usersaccount') {
        this.url_type 		    = 'usersaccount'
        this.confirm.dialog   = true
        this.confirm.title    = 'Save Access Users Account for `' + this.form.usersaccount_usersid + '`'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'userschannel') {
        this.url_type 		    = 'userschannel'
        this.confirm.dialog   = true
        this.confirm.title    = 'Save Access Users Channel for `' + this.form.userschannel_usersid + '`'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'userssubclass') {
        this.url_type 		    = 'userssubclass'
        this.confirm.dialog   = true
        this.confirm.title    = 'Save Access Users Sub Class for `' + this.form.userssubclass_usersid + '`'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'userscompacc') {
        this.url_type 		    = 'userscompacc'
        this.confirm.dialog   = true
        this.confirm.title    = 'Save Access Users Company Account for `' + this.form.userscompacc_usersid + '`'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'usersbranch') {
        this.url_type 		    = 'usersbranch'
        this.confirm.dialog   = true
        this.confirm.title    = 'Save Access Users Branch for `' + this.form.usersbranch_usersid + '`'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
		},

    Loading() {
			this.confirm.text = "Loading..."
			if (this.url_type === "add" || this.url_type === "edit") {
				this.Write()
			} else if (this.url_type === "delete") {
				this.Delete(this.form.users_id)
			} else if (this.url_type === "multidelete") {
				this.MultiProcess()
			} else if (this.url_type === 'addfamily' || this.url_type === 'editfamily') {
        this.WriteFamily()
      } else if (this.url_type === 'deletefamily') {
        this.DeleteFamily(this.form.usersfamily_type)
      } else if (this.url_type === 'multideletefamily') {
        this.MultiProcessFamily('multideletefamily')
      } else if (this.url_type === 'usersrole') {
        this.SaveRole()
      } else if (this.url_type === 'usersaccount') {
        this.SaveAccount()
      } else if (this.url_type === 'userschannel') {
        this.SaveChannel()
      } else if (this.url_type === 'userssubclass') {
        this.SaveSubClass()
      } else if (this.url_type === 'userscompacc') {
        this.SaveCompanyAccount()
      } else if (this.url_type === 'usersbranch') {
        this.SaveBranch()
      }
		},

    Close (flag) {
      if (flag === 'family') {
        this.url_type = 'edit'
        this.dialog.family = false
        this.ListFamily(this.form.users_id)
      } else if (flag === 'confirm') {
        this.confirm.dialog = false
        if (this.url_type !== 'add') {
          this.url_type = 'edit'
        }
      }
    },

    SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			if (color === "error") {
				this.confirm.dialog 	= false
				this.confirm.text 		= "Ok"
			}
		},

    Dialog (flag) {
      if (flag === 'Role') {
        this.OpenRole()
      } else if (flag === 'Account') {
        this.OpenAccount()
      } else if (flag === 'Channel') {
        this.OpenChannel()
      } else if (flag === 'SubClass') {
        this.OpenSubClass()
      } else if (flag === 'CompanyAccount') {
        this.OpenCompanyAccount()
      } else if (flag === 'Branch') {
        this.OpenBranch()
      } 
    },

    SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},

		Check(value) {
			this.selected = value
		},

    //Function to request insert data to table Users 'The API will validating your data, include the user who process the data' (go to APIWriteUsers with url_type = "add")
		Add() {
			this.url_type               = 'add'
			this.form.users_id          = ''
			this.form.users_name        = ''
			this.form.users_title       = ''
			this.form.users_email       = ''
			this.form.users_exp         = ''
			this.form.users_pass_exp    = ''
			this.form.users_otherid     = ''
			this.form.users_nationality = ''
			this.form.users_address     = ''
			this.form.users_zipcode     = ''
			this.form.users_telegramid  = ''
			this.form.users_phone_1     = ''
			this.form.users_phone_2     = ''
			this.form.users_mobile_1    = ''
			this.form.users_mobile_2    = ''

			this.form.users_actived = true
			this.form.users_locked  = false

			this.form.users_employeeid      = ''
			this.form.users_employeestatus  = ''
			this.form.users_entrydate       = ''
			this.form.users_enddate         = ''
			this.form.users_religion        = ''
			this.form.users_birthdate       = ''
			this.form.users_birthplace      = ''
			this.form.users_reftype         = ''
			this.form.users_refno           = ''
			this.form.users_gender          = ''
			this.form.users_npwpno          = ''
			this.form.users_npwpaddr        = ''
			this.form.users_phone_ext       = ''
			this.form.users_employeeflag    = false
			this.form.users_moving          = false
			this.form.users_autocc          = ''
			this.form.users_accountno       = ''
			this.form.users_accountname     = ''
			this.form.users_accountno_2     = ''
			this.form.users_accountname_2   = ''

			this.form.users_marital   = ''
			this.form.users_ptkpflag  = ''

			this.form.users_bpjsno                  = ''
			this.form.users_absenceid               = ''
			this.form.users_ptkp                    = 0
			this.form.users_basicsalary             = 0
			this.form.users_mealallowance           = 0
			this.form.users_transportationallowance = 0
			this.form.users_positionallowance       = 0
			this.form.users_carallowance            = 0
			this.form.users_mobileallowance         = 0

      this.$refs.users_ptkp.$el.getElementsByTagName('input')[0].value                    = 0
      this.$refs.users_basicsalary.$el.getElementsByTagName('input')[0].value             = 0
      this.$refs.users_mealallowance.$el.getElementsByTagName('input')[0].value           = 0
      this.$refs.users_transportationallowance.$el.getElementsByTagName('input')[0].value = 0
      this.$refs.users_positionallowance.$el.getElementsByTagName('input')[0].value       = 0
      this.$refs.users_carallowance.$el.getElementsByTagName('input')[0].value            = 0
      this.$refs.users_mobileallowance.$el.getElementsByTagName('input')[0].value         = 0

			this.form.usersrole_usersid     = ''
			this.form.usersaccount_usersid  = ''
			this.form.userschannel_usersid  = ''
			this.form.userssubclass_usersid = ''
			this.form.userscompacc_usersid  = ''
			this.form.usersbranch_usersid   = ''

      this.form.display_users = '',

      this.ClearCB()

			this.form.users_id_readonly = false
			setTimeout(function() {
				document.getElementById("users_id").focus()
			}, 500)
		},

    //Function to request update data to table Users 'The API will validating your data, include the user who process the data' (go to APIWriteUsers with url_type = "edit")
		Edit(id) {
			this.loading.list = true
			let formdata = {
				users_id    : id,
				users_name  : "",
				order_by    : "users_id",
				order_type  : "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListUsers"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click()
              document.getElementById("tabgeneral").click()
							this.url_type 					    = "edit"
              // Form Users
							this.form.users_id 				  = feedback[0].users_id
							this.form.users_name 			  = feedback[0].users_name
							this.form.users_title 			= feedback[0].users_title
							this.form.users_email 			= feedback[0].users_email
							this.form.users_avatar			= feedback[0].users_avatar
							this.form.users_avatar_url	= feedback[0].users_avatar_url
							this.form.users_type			  = feedback[0].users_type
							this.form.users_typedesc		= feedback[0].users_typedesc

              // Form General
							this.form.users_branch			= feedback[0].users_branch
							this.form.users_branchdesc	= feedback[0].users_branchdesc
							this.form.users_exp				  = feedback[0].users_exp
							this.form.users_pass_exp		= feedback[0].users_pass_exp
							this.form.users_otherid			= feedback[0].users_otherid
							this.form.users_nationality	= feedback[0].users_nationality
							this.form.users_address			= feedback[0].users_address
							this.form.users_zipcode			= feedback[0].users_zipcode
							this.form.users_telegramid  = feedback[0].users_telegramid
							this.form.users_phone_1			= feedback[0].users_phone_1
							this.form.users_phone_2			= feedback[0].users_phone_2
							this.form.users_mobile_1		= feedback[0].users_mobile_1
							this.form.users_mobile_2		= feedback[0].users_mobile_2

							this.form.users_locked  = this.$functions.TrueOrFalse(feedback[0].users_locked)
							this.form.users_actived = this.$functions.TrueOrFalse(feedback[0].users_actived)

              // Form Additional
							this.form.users_employeeid		  = feedback[0].users_employeeid
							this.form.users_employeestatus	= feedback[0].users_employeestatus
							this.form.users_entrydate		    = feedback[0].users_entrydate
							this.form.users_enddate			    = feedback[0].users_enddate
							this.form.users_department		  = feedback[0].users_department
							this.form.users_departmentdesc  = feedback[0].users_departmentdesc
							this.form.users_leader			    = feedback[0].users_leader
							this.form.users_religion			  = feedback[0].users_religion
              this.form.users_birthdate       = feedback[0].users_birthdate
              this.form.users_birthplace      = feedback[0].users_birthplace
							this.form.users_reftype			    = feedback[0].users_reftype
							this.form.users_refno			      = feedback[0].users_refno
							this.form.users_gender			    = feedback[0].users_gender
							this.form.users_npwpno			    = feedback[0].users_npwpno
							this.form.users_npwpaddr		    = feedback[0].users_npwpaddr
							this.form.users_phone_ext		    = feedback[0].users_phone_ext

							this.form.users_employeeflag  = this.$functions.TrueOrFalse(feedback[0].users_employeeflag)
							this.form.users_moving 			  = this.$functions.TrueOrFalse(feedback[0].users_moving)

              this.form.users_autocc            = feedback[0].users_autocc
              this.form.users_accountbank       = feedback[0].users_accountbank
              this.form.users_accountbankdesc   = feedback[0].users_accountbankdesc
              this.form.users_accountno         = feedback[0].users_accountno
              this.form.users_accountname       = feedback[0].users_accountname
              this.form.users_accountbank_2     = feedback[0].users_accountbank_2
              this.form.users_accountbankdesc_2 = feedback[0].users_accountbankdesc_2
              this.form.users_accountno_2       = feedback[0].users_accountno_2
              this.form.users_accountname_2     = feedback[0].users_accountname_2

              // Form Family
							this.form.users_marital   = feedback[0].users_marital
							this.form.users_ptkpflag	= feedback[0].users_ptkpflag

              // Form Salary
							this.form.users_bpjsno			            = feedback[0].users_bpjsno
							this.form.users_grade			              = feedback[0].users_grade
							this.form.users_gradedesc			          = feedback[0].users_gradedesc
							this.form.users_basicsalary			        = feedback[0].users_basicsalary
							this.form.users_ptkp			              = feedback[0].users_ptkp
							this.form.users_absenceid			          = feedback[0].users_absenceid
							this.form.users_mealallowance			      = feedback[0].users_mealallowance
							this.form.users_transportationallowance = feedback[0].users_transportationallowance
							this.form.users_positionallowance			  = feedback[0].users_positionallowance
							this.form.users_carallowance			      = feedback[0].users_carallowance
							this.form.users_mobileallowance			    = feedback[0].users_mobileallowance

              this.$refs.users_basicsalary.$el.getElementsByTagName('input')[0].value             = feedback[0].users_basicsalary
              this.$refs.users_ptkp.$el.getElementsByTagName('input')[0].value                    = feedback[0].users_ptkp
              this.$refs.users_mealallowance.$el.getElementsByTagName('input')[0].value           = feedback[0].users_mealallowance
              this.$refs.users_transportationallowance.$el.getElementsByTagName('input')[0].value = feedback[0].users_transportationallowance
              this.$refs.users_positionallowance.$el.getElementsByTagName('input')[0].value       = feedback[0].users_positionallowance
              this.$refs.users_carallowance.$el.getElementsByTagName('input')[0].value            = feedback[0].users_carallowance
              this.$refs.users_mobileallowance.$el.getElementsByTagName('input')[0].value         = feedback[0].users_mobileallowance

              // Form Privileges
              this.form.usersrole_usersid     = feedback[0].users_id
              this.form.usersaccount_usersid  = feedback[0].users_id
              this.form.userschannel_usersid  = feedback[0].users_id
              this.form.userssubclass_usersid = feedback[0].users_id
              this.form.userscompacc_usersid  = feedback[0].users_id
              this.form.usersbranch_usersid   = feedback[0].users_id

              // Set Form Users
							this.form.users_cdate 			= feedback[0].users_cdate
							this.form.users_cuser 			= feedback[0].users_cuser
							this.form.users_ldate 			= feedback[0].users_ldate
							this.form.users_luser 			= feedback[0].users_luser
							this.form.users_id_readonly 	= true

							this.ListFamily(feedback[0].users_id)
							setTimeout(function() {
								document.getElementById("users_name").focus()
							}, 500)
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog = true
							this.remarks.feedback = feedback[0].feedback
						}
					} else {
						this.SnackBar(true, "error", feedback, 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
					this.loading.list = false
				})
		},

    Upload () {
      this.$refs.users_avatar.click()
    },

    UploadAvatar () {
      var input = event.target
      if (input.files && input.files[0]) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.form.users_avatar_url = e.target.result
          // console.log('Base64: ', this.form.users_avatar_url.replace(/^.+?;base64,/, ''))
        }
        this.form.users_avatar = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
		},

    RemoveAutoCC (item) {
      const index = this.users_autocc.indexOf(item.users_email)
      if (index >= 0) this.users_autocc.splice(index, 1)
    },

    // Function Family
    SearchFamily () {
      this.family.searchfamilybutton = true
      setTimeout(function() {
				document.getElementById("searchfamily").focus()
			}, 500)
    },
		OpenFamily () {
			this.dialog.family = true
			this.AddFamily()
		},
		AddFamily () {
			this.url_type      	  	 				        = 'addfamily'
			this.form.usersfamily_name 				      = ''
			this.form.usersfamily_category  		    = ''
			this.form.usersfamily_categorynumber 	  = '1'
			this.form.usersfamily_gender 			      = 'MALE'
			this.form.usersfamily_address   		    = this.form.users_address
			this.form.usersfamily_birthplace 		    = ''
			this.form.usersfamily_birthdate 		    = ''
			this.form.usersfamily_bloodtype 		    = ''
			this.form.usersfamily_reftype 			    = ''
			this.form.usersfamily_refno 			      = ''
			this.form.usersfamily_mobile 			      = ''
			this.form.usersfamily_actived			      = true
			this.form.usersfamily_category_readonly = false

			this.form.usersfamily_categorynumber_readonly = false

			this.flag.family      	 		= false
			setTimeout(function(){
        document.getElementById('usersfamily_category').focus()
      }, 500)
		},
		EditFamily (type, category, number, gender, name, address, birthplace, birthdate, bloodtype, reftype, refno, mobile, active, cdate, cuser, ldate, luser) {
			this.url_type      	  	 	   	    = 'editfamily'
			this.form.usersfamily_type 		    = type
			this.form.usersfamily_category	  = category
			this.form.usersfamily_gender	    = gender
			this.form.usersfamily_name		    = name
			this.form.usersfamily_address	    = address
			this.form.usersfamily_birthplace  = birthplace
			this.form.usersfamily_birthdate	  = birthdate
			this.form.usersfamily_bloodtype	  = bloodtype
			this.form.usersfamily_reftype	    = reftype
			this.form.usersfamily_refno		    = refno
			this.form.usersfamily_mobile	    = mobile
			this.form.usersfamily_cdate		    = cdate
			this.form.usersfamily_cuser		    = cuser
			this.form.usersfamily_ldate		    = ldate
			this.form.usersfamily_luser		    = luser
			this.form.usersfamily_actived	    = this.$functions.TrueOrFalse(active)

			this.form.usersfamily_category_readonly       = true
			this.form.usersfamily_categorynumber_readonly = true

			this.flag.family      	 		= true
			this.dialog.family       		= true
			setTimeout(function(){
        document.getElementById('usersfamily_category').focus()
      }, 500)
		},
		ListFamily (id) {
			this.family.items = []
      this.loading.family = true
			let formdata = {
        usersfamily_usersid : id,
        datavalue           : this.family.search,
        order_by            : 'usersfamily_cdate',
        order_type          : 'ASC',
        limit               : '100',
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersFamily'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback  = response.data
        console.log('feedback family', feedback)
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.family.items = feedback
					}
				}
				this.loading.family = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
				this.loading.family = false
			})
		},
		FamilyCategory (value) {
			if (value === 'WIFE' || value === 'DAUGHTER') {
				this.form.usersfamily_gender = 'FEMALE'
			} else {
				this.form.usersfamily_gender = 'MALE'
			}
		},
		WriteFamily () {
			let type     = this.form.usersfamily_category + '' + this.form.usersfamily_categorynumber
      let formdata = {
				url_type: this.url_type,
				usersfamily_type: type,
				usersfamily_usersid: this.form.users_id,
				usersfamily_category: this.form.usersfamily_category,
				usersfamily_categorynumber: this.form.usersfamily_categorynumber,
				usersfamily_gender: this.form.usersfamily_gender,
				usersfamily_name: this.form.usersfamily_name,
				usersfamily_address: this.form.usersfamily_address,
				usersfamily_reftype: this.form.usersfamily_reftype,
				usersfamily_refno: this.form.usersfamily_refno,
				usersfamily_mobile: this.form.usersfamily_mobile,
				usersfamily_birthplace: this.form.usersfamily_birthplace,
				usersfamily_birthdate: this.form.usersfamily_birthdate,
				usersfamily_bloodtype: this.form.usersfamily_bloodtype,
				usersfamily_actived: this.$functions.ActivedFlag(this.form.usersfamily_actived),
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersFamily'),param,{
      headers: {
        'Content-Type': 'text/plain; charset=ISO-8859-1'
      }
			})
			.then(response => {
				let feedback =response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback ==='Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog =false
            this.confirm.text   ='Ok'
            if (this.url_type ==='addfamily') {
              this.form.campaignded_cuser =feedback[0].feedback_users_id
              this.form.campaignded_cdate =feedback[0].feedback_users_date
            }
            this.form.campaignded_luser =feedback[0].feedback_users_id
            this.form.campaignded_ldate =feedback[0].feedback_users_date

            this.url_type 		  ='editfamily'
            this.dialog.family  = false
						this.ListFamily(this.form.users_id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   =true
            this.remarks.feedback =feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   =true
          this.remarks.feedback =feedback
        }
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   =true
				this.remarks.feedback =e
			})
    },
    DeleteFamily (id) {
			let formdata = {
				url_type: this.url_type,
				usersfamily_type: id,
				usersfamily_usersid: this.form.users_id
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersFamily'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.SnackBar(true, 'success', 'Delete Success', 0)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						this.ListFamily(this.form.users_id)
						this.url_type = "edit"
					}
				}
			})
      .catch(e => {
        this.SnackBar(
        true,
        "error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
      })
    },
    MultiProcessFamily(flag) {
			let multiData = JSON.stringify(this.family.selected)
			let formdata = {
				url_type: flag,
				usersfamily_usersid: this.form.users_id,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteUsersFamily"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback[0].feedback === "Y") {
						let feed
						if (feedback[0].feedback_failed == 0) {
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						} else {
							this.remarks.feedback = feedback[0].feedback_failed_remarks
							feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
							this.remarks.dialog = true
						}
						this.SnackBar(true, "primary", feed, 0)
						this.confirm.dialog = false
						this.confirm.text 	= "Ok"
						this.family.selected = []
						this.ListFamily(this.form.users_id)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
				})
		},
    SelectAllFamily() {
			this.family.selected = this.family.selected === this.family.items ? [] : this.family.items
		},
		CheckFamily(value) {
			this.family.selected = value
		},
    // end: Function Family

    // Module Users Role
    OpenRole () {
      this.dialog.role = true
      this.LoadingRole()
    },
    LoadingRole () {
      this.loading.role = true
      let formdata = {
				usersrole_usersid: this.form.usersrole_usersid
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersRole'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.usersrole = feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.role = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.role = false
			})
    },
    CheckRole () {
      this.privilidges.checkrole = !this.privilidges.checkrole
      let checked = ''
      if (this.privilidges.checkrole === true) {
        checked = this.form.usersrole_usersid
      }
			for (var i = 0; i < this.usersrole.length; i++) {
				this.usersrole[i].usersrole_usersid = checked
			}
		},
    SaveRole () {
      this.loading.role = true
			this.selectedusersrole = []
			for (let i = 0; i < this.usersrole.length; i ++) {
				let menutype = this.usersrole[i].usersrole_id
				if (document.getElementById('id'+menutype).checked === true) {
					this.selectedusersrole.push({'usersrole_id': menutype})
				}
			}
			let multiData = JSON.stringify(this.selectedusersrole)
			let formdata = {
				url_type          : 'addusersrole',
				usersrole_usersid : this.form.usersrole_usersid,
				multi_data        : multiData,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersRole'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            this.url_type 			 = 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
					}
          this.loading.role = false
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
          this.loading.role = false
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
        this.loading.role = false
			})
		},
    // end: Module Users Role

    // Module Users Account
    OpenAccount () {
      this.dialog.account = true
      this.LoadingAccount()
    },
    LoadingAccount () {
      console.log('Loading Account')
      this.loading.account = true
      let formdata = {
				usersaccount_usersid: this.form.usersaccount_usersid
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersAccount'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
        console.log('Data Users Account', feedback)
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
            this.loading.account = false
					} else {
						this.usersaccount = feedback
            this.loading.account = false
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
          this.loading.account = false
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.account = false
			})
    },
    CheckAccount () {      
      this.privilidges.checkaccount = !this.privilidges.checkaccount
      let checked = ''
      if (this.privilidges.checkaccount === true) {
        checked = this.form.usersaccount_usersid
      }
			for (var i = 0; i < this.usersaccount.length; i++) {
				this.usersaccount[i].usersaccount_usersid = checked
			}
		},
    SaveAccount () {
      this.loading.account      = true
			this.selectedusersaccount = []
			for (let i = 0; i < this.usersaccount.length; i ++) {
				let menutype = this.usersaccount[i].usersaccount_id
				if (document.getElementById('id'+menutype).checked === true) {
					this.selectedusersaccount.push({'usersaccount_id': menutype})
				}
			}
			let multiData = JSON.stringify(this.selectedusersaccount)
			let formdata = {
				url_type          : 'addusersaccount',
				usersaccount_usersid : this.form.usersaccount_usersid,
				multi_data        : multiData,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersAccount'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            this.url_type 			 = 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
					}
          this.loading.account = false
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
          this.loading.account = false
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
        this.loading.account = false
			})
		},
    // end: Module Users Account

    // Module Users Channel
    OpenChannel () {
      this.dialog.channel = true
      this.LoadingChannel()
    },
    LoadingChannel () {
      this.loading.channel = true
      let formdata = {
				userschannel_usersid: this.form.userschannel_usersid
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersChannel'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.userschannel = feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.channel = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.channel = false
			})
    },
    CheckChannel () {      
      this.privilidges.checkchannel = !this.privilidges.checkchannel
      let checked = ''
      if (this.privilidges.checkchannel === true) {
        checked = this.form.userschannel_usersid
      }
			for (var i = 0; i < this.userschannel.length; i++) {
				this.userschannel[i].userschannel_usersid = checked
			}
		},
    SaveChannel () {
      this.loading.channel      = true
			this.selecteduserschannel = []
			for (let i = 0; i < this.userschannel.length; i ++) {
				let menutype = this.userschannel[i].userschannel_id
				if (document.getElementById('id'+menutype).checked === true) {
					this.selecteduserschannel.push({'userschannel_id': menutype})
				}
			}
			let multiData = JSON.stringify(this.selecteduserschannel)
			let formdata = {
				url_type          : 'adduserschannel',
				userschannel_usersid : this.form.usersaccount_usersid,
				multi_data        : multiData,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersChannel'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog = false
            this.confirm.text 	= 'Ok'
            this.url_type 			= 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
					}
          this.loading.channel = false
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog   = true
					this.remarks.feedback = feedback
          this.loading.channel  = false
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   = true
				this.remarks.feedback = e
        this.loading.channel  = false
			})
		},
    // end: Module Users Channel

    // Module Sub Class
    OpenSubClass () {
      this.dialog.subclass = true
      this.LoadingSubClass()
    },
    LoadingSubClass () {
      this.loading.subclass = true
      let formdata = {
				userssubclass_usersid: this.form.userssubclass_usersid,
        order_by: 'subclass_id',
        order_type: 'ASC'
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersSubClass'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.userssubclass = feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.subclass = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.subclass = false
			})
    },
    CheckSubClass () {      
      this.privilidges.checksubclass = !this.privilidges.checksubclass
      let checked = ''
      if (this.privilidges.checksubclass === true) {
        checked = this.form.userssubclass_usersid
      }
			for (var i = 0; i < this.userssubclass.length; i++) {
				this.userssubclass[i].userssubclass_usersid = checked
			}
		},
    SaveSubClass () {
      this.loading.subclass       = true
			this.selecteduserssubclass  = []
			for (let i = 0; i < this.userssubclass.length; i ++) {
				let menutype = this.userssubclass[i].userssubclass_id
				if (document.getElementById('id'+menutype).checked === true) {
          let limitin = document.getElementById('limitin'+menutype).value
          let limitout = document.getElementById('limitout'+menutype).value
					this.selecteduserssubclass.push({'userssubclass_id': menutype,'userssubclass_limitin': this.$functions.ReplaceNumber(limitin),'userssubclass_limitout': this.$functions.ReplaceNumber(limitout)})
				}
			}
			let multiData = JSON.stringify(this.selecteduserssubclass)
			let formdata = {
				url_type          : 'adduserssubclass',
				userssubclass_usersid : this.form.usersaccount_usersid,
				multi_data        : multiData,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersSubClass'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog = false
            this.confirm.text 	= 'Ok'
            this.url_type 			= 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
					}
          this.loading.subclass = false
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog   = true
					this.remarks.feedback = feedback
          this.loading.subclass = false
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   = true
				this.remarks.feedback = e
        this.loading.subclass = false
			})
		},
    // end: Module Users Sub Class

    // Module Users Company Account
    OpenCompanyAccount () {
      this.dialog.compacc = true
      this.LoadingCompanyAccount()
    },
    LoadingCompanyAccount () {
      this.loading.compacc = true
      let formdata = {
				userscompacc_usersid: this.form.userscompacc_usersid
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersCompanyAccount'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.userscompacc = feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.compacc = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.compacc = false
			})
    },
    CheckCompanyAccount () {      
      this.privilidges.checkcompacc = !this.privilidges.checkcompacc
      let checked = ''
      if (this.privilidges.checkcompacc === true) {
        checked = this.form.userscompacc_usersid
      }
			for (var i = 0; i < this.userscompacc.length; i++) {
				this.userscompacc[i].userscompacc_usersid = checked
			}
		},
    SaveCompanyAccount () {
      this.loading.compacc      = true
			this.selecteduserscompacc = []
			for (let i = 0; i < this.userscompacc.length; i ++) {
				let menutype = this.userscompacc[i].userscompacc_id
				if (document.getElementById('id'+menutype).checked === true) {
					this.selecteduserscompacc.push({'userscompacc_id': menutype})
				}
			}
			let multiData = JSON.stringify(this.selecteduserscompacc)
			let formdata = {
				url_type          : 'adduserscompacc',
				userscompacc_usersid : this.form.usersaccount_usersid,
				multi_data        : multiData,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersCompanyAccount'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog = false
            this.confirm.text 	= 'Ok'
            this.url_type 			= 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   = true
            this.remarks.feedback = feedback[0].feedback
					}
          this.loading.compacc = false
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog   = true
					this.remarks.feedback = feedback
          this.loading.compacc  = false
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   = true
				this.remarks.feedback = e
        this.loading.compacc  = false
			})
		},
    // end: Module Users Company Account

    // Module Users Branch
    OpenBranch () {
      this.dialog.branch = true
      this.LoadingBranch()
    },
    LoadingBranch () {
      this.loading.branch = true
      let formdata = {
        usersbranch_usersid: this.form.usersbranch_usersid
      }
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersBranch'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.usersbranch = feedback
					}
				} else {
					this.SnackBar(true, 'error', feedback, 0)
				}
				this.loading.branch = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.branch = false
			})
    },
    CheckBranch (flag) {
      if (flag === 'policy') {
        this.privilidges.checkbranch_policy = !this.privilidges.checkbranch_policy
        let checked = 0
        if (this.privilidges.checkbranch_policy === true) {
          checked = 1
        }
				for (var r = 0; r < this.usersbranch.length; r++) {
					this.usersbranch[r].usersbranch_policy = checked
				}
			} else if (flag === 'claim') {
        this.privilidges.checkbranch_claim = !this.privilidges.checkbranch_claim
        let checked = 0
        if (this.privilidges.checkbranch_claim === true) {
          checked = 1
        }
				for (var a = 0; a < this.usersbranch.length; a++) {
					this.usersbranch[a].usersbranch_claim = checked
				}
			} else if (flag === 'finance') {
        this.privilidges.checkbranch_finance = !this.privilidges.checkbranch_finance
        let checked = 0
        if (this.privilidges.checkbranch_finance === true) {
          checked = 1
        }
				for (var e = 0; e < this.usersbranch.length; e++) {
					this.usersbranch[e].usersbranch_finance = checked
				}
			} else if (flag === 'accounting') {
        this.privilidges.checkbranch_accounting = !this.privilidges.checkbranch_accounting
        let checked = 0
        if (this.privilidges.checkbranch_accounting === true) {
          checked = 1
        }
				for (var d = 0; d < this.usersbranch.length; d++) {
					this.usersbranch[d].usersbranch_accounting = checked
				}
			}
		},
		SaveBranch () {
			for (let i = 0; i < this.usersbranch.length; i ++) {
				let menutype = this.usersbranch[i].usersbranch_branchid
				this.usersbranch[i].usersbranch_policy 	    = this.$functions.Checked(document.getElementById('idpolicy'+menutype).checked)
				this.usersbranch[i].usersbranch_claim   	  = this.$functions.Checked(document.getElementById('idclaim'+menutype).checked)
				this.usersbranch[i].usersbranch_finance 	  = this.$functions.Checked(document.getElementById('idfinance'+menutype).checked)
				this.usersbranch[i].usersbranch_accounting  = this.$functions.Checked(document.getElementById('idaccounting'+menutype).checked)
			}
			let multiData = JSON.stringify(this.usersbranch)
			let formdata = {
				url_type: 'add',
				usersbranch_usersid: this.form.usersbranch_usersid,
				multi_data: multiData,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsersBranch'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog = false
            this.confirm.text 	= 'Ok'
            this.url_type 			= 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
    // end: Module Users Branch

    Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		},

    ClearCB () {
			this.form.users_type 	 		        = ''
			this.form.users_typedesc 		      = ''
			this.form.users_branch 			      = ''
			this.form.users_branchdesc 		    = ''
			this.form.users_department 		    = ''
			this.form.users_departmentdesc 	  = ''
			this.form.users_leader 			      = ''
			this.form.users_leaderdesc		    = ''
			this.form.users_accountbank		    = ''
			this.form.users_accountbankdesc	  = ''
			this.form.users_accountbank_2     = ''
			this.form.users_accountbankdesc_2 = ''
      this.form.users_grade             = ''
			this.form.users_gradedesc         = ''
		},

    ShowUsers (id) {
			this.dialog.users       = true
			this.form.display_users = id
		},
  }
}
</script>