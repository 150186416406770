<template>
    <div>
        <v-dialog
            v-model="dialoginterest"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
        <v-card>
            <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click.stop="SearchClose()">
                <v-icon>{{$vars.V('icon_back')}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ dialog_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="AddData"
            >
                Add 
                <v-icon>{{$vars.V('icon_add')}}</v-icon>
            </v-btn> &nbsp;
            <v-btn
                text
                @click="ConfirmData"
            >
                Save
                <v-icon>{{$vars.V('icon_save')}}</v-icon>
            </v-btn>
            </v-toolbar>
            <v-container grid-list-md class="pa-12">
            <v-form
                  enctype="multipart/form-data"
                  ref="form_policyint"
                  lazy-validation
                >
                <v-layout row wrap>
                    <!-- Interest -->
                    <v-col cols="12" sm="6" md="6">
                      <app-cb
                        cb_type           ="riskcovint"
                        cb_url            ="apiListRiskCovInt"
                        :cb_url_parameter  ="`policyrisk_policyrow=${dialog_id}`"
                        cb_title          ="Interest"
                        cb_id             ="policyint_id"
                        cb_desc           ="policyint_desc"
                        cb_rules          ="Please fill Interest"
                        cb_desc_readonly  ="readonly"
                        cb_items_id       ="riskcovint_id"
                        cb_items_desc     ="riskcovint_intdesc"
                        cb_items_additional_1 ="riskcovint_riskcovid"
                        :cb_value_id	  ="register.policyint_intid"
                        :cb_value_desc	  ="register.policyint_intdesc"
                        @clicked          ="GetInterest"
                      >
                      </app-cb>
                    </v-col>
        
                    <!-- Description -->
                    <v-col cols="12" sm="6" md="6" class="mt-n3">
                      <v-text-field
                        v-model       ="register.policyint_desc"
                        required
                        :rules        ="register.policyint_desc_rules"
                        :maxlength    ="maxinput.desc"
                        label         ="Description *"
                        placeholder   ="Description"
                        id            ="policyint_desc"
                        @keyup.enter  ="ConfirmData('write', '')"
                      >
                      </v-text-field>
                    </v-col>
        
                    <!-- Category -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <v-select
                        v-model       ="register.policyint_category"
                        required
                        :rules 	  		="register.policyint_category_rules"
                        :items        ="policyint_category"
                        item-text     ="code_desc"
                        item-value    ="code_id"
                        label         ="Category"
                        placeholder   ="Category"
                        id 				    ="policyint_category"
                        @keyup.enter  ="ConfirmData('write','')"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                        <app-cb
                          cb_type           ="currency"
                          cb_url            ="apiListCurrency"
                          cb_url_parameter  ="currency_actived=Y"
                          cb_title          ="CCY"
                          cb_id             ="policyint_currency"
                          cb_desc           ="policyint_currency_desc"
                          cb_rules          ="Please fill Currency"
                          cb_desc_readonly  ="readonly"
                          cb_items_id       ="currency_id"
                          cb_items_desc     ="currency_desc"
                          :cb_value_id      ="register.policyint_currency"
                          cb_single         ="Y"
                          @clicked          ="GetCurrency"
                        >
                        </app-cb>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="mt-n3">
                        <vuetify-money
                          v-model         ="register.policyint_guaranteevolume"
                          label           ="Guarantee Volume"
                          placeholder     ="Guarantee Volume"
                          backgroundColor ="numeric"
                          :options        ="format_amount"
                          :properties     ="properties"
                          valueWhenIsEmpty ="0"
                          @input          ="CalcGuarantee"
                        >
                        </vuetify-money>
                      </v-col>
                      <v-col cols="12" sm="1" md="1" class="mt-n3">
                        <vuetify-money
                          v-model         ="register.policyint_guaranteepct"
                          label           ="Pct(%)"
                          placeholder     ="Pct(%)"
                          backgroundColor ="numeric"
                          :options        ="format_rate"
                          :properties     ="properties"
                          valueWhenIsEmpty ="0"
                          @input          ="CalcGuarantee"
                        >
                        </vuetify-money>
                      </v-col>
                    <!-- Rate -->
                    <v-col cols="12" sm="4" md="4" class="mt-n3">
                      <vuetify-money
                        v-model         ="register.policyint_guaranteevalue"
                        label           ="Guarantee Value"
                        placeholder     ="Guarantee Value"
                        backgroundColor ="numeric"
                        :options        ="format_amount"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                        :readonly        ="true"
                      >
                      </vuetify-money>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                          @click:append="ListData"
                          ></v-text-field>
                        <v-data-table
                            :headers="header['interest']"
                            :items="items['interest']"
                            :loading="loading.data"
                            :search="search"
                            loading-text="Loading... Please wait"
                            dense
                            item-key="dataid"
                            class="elevation-1 row-pointer"
                        >
                            <template slot="headerCell" slot-scope="props">
                            <v-tooltip bottom>
                                <span slot="activator">
                                {{ props.header.text }}
                                </span>
                                <span>
                                {{ props.header.text }}
                                </span>
                            </v-tooltip>
                            </template>
                            <template v-slot:no-data> No Data Available </template>
                            <template v-slot:item.policyint_currency="{ item }">
                                {{item.policyint_currency}} {{$functions.NewFormatNumber(item.policyint_guaranteevalue)}} 
                            </template>
                            <template v-slot:item.policyint_guaranteevolume="{ item }">
                                {{$functions.NewFormatNumber(item.policyint_guaranteevolume)}} 
                            </template>
                            <template v-slot:item.action="{ item }">
                              <v-icon small class="mr-2" @click="EditData(item.policyint_policyrow, item.policyint_intid)">
                                {{$vars.V('icon_edit')}}
                              </v-icon>
                              <v-icon small @click="Delete(item.policyint_policyrow, item.policyint_intid)">
                                {{$vars.V('icon_delete')}}
                              </v-icon>
                          </template>
                        </v-data-table>
                        </v-col>
            </v-layout>
            </v-form>
            </v-container>
        </v-card>
        </v-dialog>
         <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
            <v-card>
              <v-card-title class="primary white--text"
                >{{ confirm.title }}?</v-card-title
              >
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="dialog"
                  color="primary"
                  :disabled="confirm.text === 'Ok' ? false : true"
                  @click="Loading"
                  >{{ confirm.text }}
                </v-btn>
                <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-snackbar
        :value	  ="snackbar.dialog"
        :timeout  ="snackbar.timeout"
        :color		="snackbar.color"
        rounded	  ="pill"
        top
        style 	  ="z-index: 9999;"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>

<script>
  export default {
    props: {
    dialog: Boolean,
    dialog_title: String,
    dialog_id: String,
    dialog_param: String,
  },
  data: () => ({
    url_type: 'add',
    limit: '1000',
    search: '',
    header: {
      interest: [
        { text: 'Actions', value: 'action', sortable: false },
        {
          text: "ID",
          value: "policyint_intid",
          align: "left",
        },
        { text: "Description", value: "policyint_desc" },
        { text: "Guarantee Value", value: "policyint_currency",align: "right" },
        { text: "Guarantee PCT", value: "policyint_guaranteepct",align: "right" },
        { text: "Guarantee Volume", value: "policyint_guaranteevolume",align: "right" },
        { text: "Category", value: "policyint_category" },
      ],
    },
    items: [],
    loading: {
      data: false
    },
    policyint_category : ['M','A'],
    register: {
     policyint_policyrow: '',
     policyint_intid: '',
     policyint_intid_old: '',
     policyint_intdesc: '',
     policyint_desc: '',
     policyint_desc_rules: [(v) => !!v || "Please fill Description"],
     policyint_currency: 'IDR',
     policyint_category: 'M',
     policyint_category_rules: [(v) => !!v || "Please fill Category"],
     policyint_firstloss: 'N',
     policyint_adjustable: 'N',
     policyint_adjustablerate: 0,
     policyint_sectionid: '',
     policyint_guaranteepct: 0,
     policyint_guaranteevolume: 0,
     policyint_guaranteevalue: 0,
     policyint_riskcovid: ''
    },
    maxinput: {
      desc: 255
    },
    snackbar: {
      dialog: false,
      color: 'primary',
      text: '',
      timeout: 3000
    },
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 4
    },
    // Format Properties
    properties: {
      reverse: true
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
  }),
  computed: {
    dialoginterest: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    }
  },
  watch: {
    dialog: function (newdata) {
      if (newdata !== "") {
        this.ListData()
      }
    },
  },
  methods: {
    SearchClose() {
        this.dialoginterest = false
    },
    AddData() {
      this.url_type = 'add'
      // this.$set(this.policyint_intid, 0, '')
      this.register.policyint_intid    = ''
      this.register.policyint_intdesc  = ''
      this.register.policyint_desc         = ''
      this.register.policyint_guaranteevalue         = 0
    },
    EditData(id,cov) {
      // this.$set(this.policy_ccy, 0, '')
      this.loading.data = true
      let formdata = {
        policyint_policyrow: id,
        policyint_intid: cov,
        order_by: "policyint_intid",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyInt"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.url_type = 'edit'
              this.register.policyint_policyrow = feedback[0].policyint_policyrow
              this.register.policyint_intid = feedback[0].policyint_intid
              this.register.policyint_intid_old = feedback[0].policyint_intid
              this.register.policyint_intdesc = feedback[0].policyint_intdesc
              this.register.policyint_desc = feedback[0].policyint_desc
              this.register.policyint_category = feedback[0].policyint_category
              this.register.policyint_currency = feedback[0].policyint_currency
              this.register.policyint_guaranteevalue = feedback[0].policyint_guaranteevalue
              this.register.policyint_guaranteevolume = feedback[0].policyint_guaranteevolume
              this.register.policyint_guaranteepct = feedback[0].policyint_guaranteepct
              this.register.policyint_riskcovid = feedback[0].policyint_riskcovid
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    ListData() {
      this.items['interest'] = []
      this.loading.data = true
      let formdata = {
        policyint_policyrow: this.dialog_id,
        order_by: "policyint_intid",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyInt"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        console.log(feedback)
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items['interest'] = feedback
          } else {
              this.loading.data = false
              this.items['interest'] = feedback
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          // this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    Delete(id, cov) {
      this.url_type = 'delete'
      this.register.policyint_policyrow = id
      this.register.policyint_intid = cov
      this.ConfirmData()
    },
    ConfirmData () {
      if (this.url_type ==='add' || this.url_type === 'edit') {
        if (this.$refs.form_policyint.validate()) {
          this.confirm.dialog = true
          this.confirm.title  = 'Confirm'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (this.url_type === 'delete') {
        this.confirm.dialog = true
        this.confirm.title  = 'Delete'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    },
    Loading () {
      this.confirm.text ='Loading...'
      if (this.url_type ==='add' || this.url_type ==='edit') {
        this.SaveData()
      } else if (this.url_type ==='delete') {
        this.DeleteData()
      }
    },
    SaveData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        policyint_policyrow: this.dialog_id,
        policyint_intid_old: this.register.policyint_intid_old,
        policyint_intid: this.register.policyint_intid,
        policyint_desc: this.register.policyint_desc,
        policyint_currency: this.register.policyint_currency,
        policyint_currencyoc: this.register.policyint_currency,
        policyint_guaranteevalue: String(this.register.policyint_guaranteevalue),
        policyint_guaranteevolume: String(this.register.policyint_guaranteevolume),
        policyint_guaranteepct: String(this.register.policyint_guaranteepct),
        policyint_aggregate: String(this.register.policyint_guaranteevalue),
        policyint_limitofliability: String(this.register.policyint_guaranteevalue),
        policyint_guaranteevalueoc: String(this.register.policyint_guaranteevalue),
        policyint_limitofliabilityoc: String(this.register.policyint_guaranteevalue),
        policyint_aggregateoc: String(this.register.policyint_guaranteevalue),
        policyint_category: this.register.policyint_category,
        policyint_firstloss: this.register.policyint_firstloss,
        policyint_adjustable: this.register.policyint_adjustable,
        policyint_adjustablerate: String(this.register.policyint_adjustablerate),
        policyint_sectionid: this.register.policyint_sectionid,
        policyint_riskcovid: this.register.policyint_riskcovid,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyInt"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Save Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    DeleteData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        policyint_policyrow: this.register.policyint_policyrow,
        policyint_intid: this.register.policyint_intid,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyInt"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Delete Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    GetInterest (value){
        let split = value.split('|')
        this.register.policyint_intid = split[0]
        this.register.policyint_intdesc = split[1]
        this.register.policyint_desc = split[1]
        this.register.policyint_riskcovid = split[3]
    },
    GetCurrency (value){
        let split = value.split('|')
        this.register.policyint_currency = split[0]
    },
    CalcGuarantee (){
        let guaranteevolume = this.register.policyint_guaranteevolume
        let guaranteepct = this.register.policyint_guaranteepct
        let guaranteeval = guaranteevolume * guaranteepct / 100
        console.log(guaranteeval)
        this.register.policyint_guaranteevalue = guaranteeval
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      this.confirm.dialog = false
      this.confirm.text = "Ok"
    },
  },
};
</script>