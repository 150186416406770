<template>
    <div>
        <v-dialog
            v-model="dialogrisk"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
        <v-card>
            <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click.stop="SearchClose()">
                <v-icon>{{$vars.V('icon_back')}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ dialog_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="AddData"
            >
                Add 
                <v-icon>{{$vars.V('icon_add')}}</v-icon>
            </v-btn> &nbsp;
            <v-btn
                text
                @click="ConfirmData"
            >
                Save
                <v-icon>{{$vars.V('icon_save')}}</v-icon>
            </v-btn>
            </v-toolbar>
            <v-container grid-list-md class="pa-12">
            <v-form
                  enctype="multipart/form-data"
                  ref="form_policyrisk"
                  lazy-validation
                >
                <v-layout row wrap>
                    <!-- Riskcov -->
                    <v-col cols="12" sm="4" md="4">
                      <app-cb
                        cb_type           ="feeduty"
                        cb_url            ="apiListFeeDuty"
                        cb_url_parameter  ="feeduty_actived=Y"
                        cb_title          ="Fee Duty"
                        cb_id             ="policyfeeduty_id"
                        cb_desc           ="policyfeeduty_desc"
                        cb_rules          ="Please fill Riskcov"
                        cb_desc_readonly  ="readonly"
                        cb_items_id       ="feeduty_id"
                        cb_items_desc     ="feeduty_desc"
                        cb_items_additional_1 ="feeduty_currency"
                        cb_items_additional_2 ="feeduty_amount"
                        :cb_value_id	  ="register.policyfeeduty_id"
                        :cb_value_desc	  ="register.policyfeeduty_desc"
                        @clicked          ="GetFeeDuty"
                      >
                      </app-cb>
                    </v-col>
        
                    <!-- Description -->
                    <v-col cols="12" sm="5" md="5" class="mt-n3">
                      <v-text-field
                        v-model       ="register.policyfeeduty_remarks"
                        required
                        :rules        ="register.policyfeeduty_remarks_rules"
                        :maxlength    ="maxinput.desc"
                        label         ="Description *"
                        placeholder   ="Description"
                        id            ="policyfeeduty_desc"
                        @keyup.enter  ="ConfirmData('write', '')"
                      >
                      </v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="1" md="1">
                        <app-cb
                          cb_type           ="currency"
                          cb_url            ="apiListCurrency"
                          cb_url_parameter  ="currency_actived=Y"
                          cb_title          ="CCY"
                          cb_id             ="policyfeeduty_ccy"
                          cb_desc           ="policyfeeduty_ccydesc"
                          cb_rules          ="Please fill Currency"
                          cb_desc_readonly  ="readonly"
                          cb_items_id       ="currency_id"
                          cb_items_desc     ="currency_desc"
                          :cb_value_id      ="register.policyfeeduty_ccy"
                          cb_single         ="Y"
                          @clicked          ="GetCurrency"
                        >
                        </app-cb>
                      </v-col>

                    <!-- Rate -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <vuetify-money
                        v-model         ="register.policyfeeduty_amount"
                        label           ="Amount"
                        placeholder     ="Amount"
                        backgroundColor ="numeric"
                        :options        ="format_amount"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                      </vuetify-money>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model="search"
                          append-icon="search"
                          label="Search"
                          single-line
                          hide-details
                          @click:append="ListData"
                          ></v-text-field>
                        <v-data-table
                            :headers="header['feeduty']"
                            :items="items['feeduty']"
                            :loading="loading.data"
                            :search="search"
                            loading-text="Loading... Please wait"
                            dense
                            item-key="dataid"
                            class="elevation-1 row-pointer"
                        >
                            <template slot="headerCell" slot-scope="props">
                            <v-tooltip bottom>
                                <span slot="activator">
                                {{ props.header.text }}
                                </span>
                                <span>
                                {{ props.header.text }}
                                </span>
                            </v-tooltip>
                            </template>
                            <template v-slot:no-data> No Data Available </template>
                            <template v-slot:item.policyfeeduty_amount="{ item }">
                                {{$functions.NewFormatNumber(item.policyfeeduty_amount)}}
                            </template>
                            <template v-slot:item.action="{ item }">
                              <v-icon small class="mr-2" @click="EditData(item.policyfeeduty_policyrow, item.policyfeeduty_id)">
                                {{$vars.V('icon_edit')}}
                              </v-icon>
                              <v-icon small @click="Delete(item.policyfeeduty_policyrow, item.policyfeeduty_id)">
                                {{$vars.V('icon_delete')}}
                              </v-icon>
                          </template>
                        </v-data-table>
                        </v-col>
            </v-layout>
            </v-form>
            </v-container>
        </v-card>
        </v-dialog>
         <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
            <v-card>
              <v-card-title class="primary white--text"
                >{{ confirm.title }}?</v-card-title
              >
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="dialog"
                  color="primary"
                  :disabled="confirm.text === 'Ok' ? false : true"
                  @click="Loading"
                  >{{ confirm.text }}
                </v-btn>
                <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-snackbar
        :value	  ="snackbar.dialog"
        :timeout  ="snackbar.timeout"
        :color		="snackbar.color"
        rounded	  ="pill"
        top
        style 	  ="z-index: 9999;"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>

<script>
  export default {
    props: {
    dialog: Boolean,
    dialog_title: String,
    dialog_id: String,
    dialog_param: String,
    dialog_sdate: String,
    dialog_edate: String,
  },
  data: () => ({
    url_type: 'add',
    limit: '1000',
    search: '',
    header: {
      feeduty: [
        { text: 'Actions', value: 'action', sortable: false },
        {
          text: "ID",
          value: "policyfeeduty_id",
          align: "left",
        },
        { text: "Description", value: "policyfeeduty_desc" },
        { text: "Currency", value: "policyfeeduty_ccy" },
        { text: "Amount", value: "policyfeeduty_amount",align: "right" },
      ],
    },
    items: [],
    loading: {
      data: false
    },
    register: {
     policyfeeduty_policyrow: '',
     policyfeeduty_id: '',
     policyfeeduty_id_old: '',
     policyfeeduty_payorid: '',
     policyfeeduty_desc: '',
     policyfeeduty_desc_rules: [(v) => !!v || "Please fill Description"],
     policyfeeduty_remarks: '',
     policyfeeduty_ccy: 'IDR',
     policyfeeduty_amount: 0,
    },
    maxinput: {
      desc: 255
    },
    snackbar: {
      dialog: false,
      color: 'primary',
      text: '',
      timeout: 3000
    },
    modal: {
      sdate: false,
      edate: false,
    },
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 4
    },
    // Format Properties
    properties: {
      reverse: true
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
  }),
  computed: {
    dialogrisk: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    }
  },
  watch: {
    dialog: function (newdata) {
      if (newdata !== "") {
        this.ListData()
      }
    },
  },
  methods: {
    SearchClose() {
        this.dialogrisk = false
    },
    AddData() {
      this.url_type = 'add'
      this.register.policyfeeduty_id    = ''
      this.register.policyfeeduty_desc  = ''
      this.register.policyfeeduty_payorid  = ''
      this.register.policyfeeduty_remarks  = ''
      this.register.policyfeeduty_ccy  = 'IDR'
      this.register.policyfeeduty_amount  = 0
    },
    EditData(id,cov) {
      // this.$set(this.policy_ccy, 0, '')
      this.loading.data = true
      let formdata = {
        policyfeeduty_policyrow: id,
        policyfeeduty_id: cov,
        order_by: "policyfeeduty_id",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyFeeDuty"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.url_type = 'edit'
              this.register.policyfeeduty_policyrow = feedback[0].policyfeeduty_policyrow
              this.register.policyfeeduty_id = feedback[0].policyfeeduty_id
              this.register.policyfeeduty_id_old = feedback[0].policyfeeduty_id
              this.register.policyfeeduty_desc = feedback[0].policyfeeduty_desc
              this.register.policyfeeduty_payorid = feedback[0].policyfeeduty_payorid
              this.register.policyfeeduty_payordesc = feedback[0].policyfeeduty_payordesc
              this.register.policyfeeduty_remarks = feedback[0].policyfeeduty_remarks
              this.register.policyfeeduty_ccy = feedback[0].policyfeeduty_ccy
              this.register.policyfeeduty_amount = feedback[0].policyfeeduty_amount
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    ListData() {
      this.items['feeduty'] = []
      this.loading.data = true
      let formdata = {
        policyfeeduty_policyrow: this.dialog_id,
        order_by: "policyfeeduty_id",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyFeeDuty"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items['feeduty'] = feedback
          } else {
              this.loading.data = false
              this.items['feeduty'] = feedback
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          // this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    Delete(id, cov) {
      this.url_type = 'delete'
      this.register.policyfeeduty_policyrow = id
      this.register.policyfeeduty_id = cov
      this.ConfirmData()
    },
    ConfirmData () {
      if (this.url_type ==='add' || this.url_type === 'edit') {
        if (this.$refs.form_policyrisk.validate()) {
          this.confirm.dialog = true
          this.confirm.title  = 'Confirm'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (this.url_type === 'delete') {
        this.confirm.dialog = true
        this.confirm.title  = 'Delete'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    },
    Loading () {
      this.confirm.text ='Loading...'
      if (this.url_type ==='add' || this.url_type ==='edit') {
        this.SaveData()
      } else if (this.url_type ==='delete') {
        this.DeleteData()
      }
    },
    SaveData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        policyfeeduty_policyrow: this.dialog_id,
        policyfeeduty_id_old: this.register.policyfeeduty_id_old,
        policyfeeduty_id: this.register.policyfeeduty_id,
        policyfeeduty_desc: this.register.policyfeeduty_desc,
        policyfeeduty_payorid: this.register.policyfeeduty_payorid,
        policyfeeduty_remarks: this.register.policyfeeduty_remarks,
        policyfeeduty_ccy: this.register.policyfeeduty_ccy,
        policyfeeduty_amount: String(this.register.policyfeeduty_amount),
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyFeeDuty"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Save Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    DeleteData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        policyfeeduty_policyrow: this.register.policyfeeduty_policyrow,
        policyfeeduty_id: this.register.policyfeeduty_id,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyFeeDuty"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Delete Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    GetFeeDuty (value){
        let split = value.split('|')
        this.register.policyfeeduty_id = split[0]
        this.register.policyfeeduty_desc = split[1]
        this.register.policyfeeduty_remarks = split[1]
        this.register.policyfeeduty_ccy = split[3]
        this.register.policyfeeduty_amount = split[4]
    },
    GetCurrency (value){
        let split = value.split('|')
        this.register.policyfeeduty_ccy = split[0]
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      this.confirm.dialog = false
      this.confirm.text = "Ok"
    },
  },
};
</script>