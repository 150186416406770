<template>
  <v-form
    v-bind="accessData"
    class="mx-4"
    enctype="multipart/form-data"
    ref="form_mapupload"
    lazy-validation
  >
    <v-card class="mx-auto my-10 px-4 py-4" max-width="100%">
      <v-form
        enctype="multipart/form-data"
        ref="form_mapupload"
        lazy-validation
      >
        <v-row class="px-3">
          <v-col sm="6" md="6">
            <v-text-field
              v-model="formData.mapheader_code"
              ref="mapheader_code"
              required
              :readonly="rules.mapheader_code_readonly"
              label="Map Code *"
              placeholder="Map Code"
              id="map_code"
            >
            </v-text-field>
          </v-col>
          <v-col sm="6" md="6">
            <v-text-field
              v-model="formData.mapheader_desc"
              required
              :rules="rules.mapheader_desc_rules"
              label="Map Description"
              placeholder="Map Description"
              id="map_desc"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col sm="6" md="6">
            <v-select
              v-model="formData.mapheader_group"
              required
              v-on:change="OnSelectChange"
              :items="mapHeaderGroupChoices"
              messages="UP:Polis, SC:Scheme, UC:Claim, BC:Batch Cancellation, BE:Batch Endorsment"
              label="Group"
            >
            </v-select>
            <p class="font-weight-thin"></p>
          </v-col>
          <v-col md="6" sm="6"> </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col md="3">
            <label class="font-weight-light">Partial Upload :</label>
            <v-checkbox
              v-model="formData.mapheader_partial"
              :label="formData.mapheader_partial === true ? 'Yes' : 'No'"
            >
            </v-checkbox>
          </v-col>
          <v-col md="3">
            <label class="font-weight-light kecil"
              >Must Used Map Reference :</label
            >
            <v-checkbox
              v-model="formData.mapheader_reference"
              :label="formData.mapheader_reference === true ? 'Yes' : 'No'"
            >
            </v-checkbox>
          </v-col>
          <v-col md="3">
            <label class="font-weight-light">Allowed :</label>
            <v-checkbox
              v-model="formData.mapheader_allowed"
              :label="formData.mapheader_allowed === true ? 'Yes' : 'No'"
            >
            </v-checkbox>
          </v-col>
          <v-col md="3">
            <label class="font-weight-light"> Allowed :</label>
            <v-switch
              v-model="formData.mapheader_actived"
              color="primary"
              :label="
                formData.mapheader_actived !== true ? 'Non Active' : 'Active'
              "
            ></v-switch>
          </v-col>
        </v-row>
        <v-row class="mx-2" v-if="mode == 'adding'">
          <!-- UPPER SEGMENT, 2 BUTTONS ADD AND SAVE -->
          <v-col v-if="accessData" cols="6" sm="6" md="6">
            <!-- ADD BUTTON -->
            <v-btn
              color="tertiary"
              class="white--text mx-1"
              :disabled="accessData.add === 0 ? true : false"
              block
              @click="ResetForm"
            >
              <v-icon dark>edit</v-icon>Reset Form
            </v-btn>
          </v-col>
          <v-col v-if="accessData" cols="6" sm="6" md="6">
            <!-- SAVE BUTTON -->
            <v-btn
              color="primary"
              class="white--text mx-1"
              @click="SubmitForm"
              block
            >
              <!-- @click="Confirm('write', '')" -->
              <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-2" v-if="mode == 'editing'">
          <!-- UPPER SEGMENT, 2 BUTTONS ADD AND SAVE -->
          <v-col v-if="accessData" cols="6" sm="6" md="6">
            <!-- ADD BUTTON -->
            <v-btn
              color="tertiary"
              class="white--text mx-1"
              :disabled="accessData.add === 0 ? true : false"
              block
              @click="CancelEdit"
            >
              <v-icon dark>cancel</v-icon>Cancel
            </v-btn>
          </v-col>
          <v-col v-if="accessData" cols="6" sm="6" md="6">
            <!-- SAVE BUTTON -->
            <v-btn
              color="primary"
              class="white--text mx-1"
              @click="SubmitForm"
              block
            >
              <!-- @click="Confirm('write', '')" -->
              <v-icon dark>edit</v-icon>Edit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <app-confirm ref="confirm"></app-confirm>
  </v-form>
</template>
<script>
import api from "./functions.js";
export default {
  methods: {
    CancelEdit: function () {
      this.formData.reset();
      this.mode = "adding";
      this.$parent.$refs.list.mode = "adding";
    },
    Init: function (accessData) {
      this.accessData = accessData;
    },
    ResetForm: function () {
      this.formData.reset();
    },
    OnSelectChange: function (data) {
      this.formData.mapheader_code =
        data + api.loadingZeros(this.recordCount + 1, 8);
    },
    SubmitForm: function () {
      this.$refs.confirm.$emit(
        "Show",
        this,
        "Confirmation",
        "Yakin ingin menyimpan?",
        function (ctx, dialog) {
          console.log(dialog);
          ctx.$refs.confirm.Loading("Menyimpan");
          api.Submit(
            ctx,
            ctx.formData,
            function (ctx, data) {
              ctx.$refs.confirm.Dimiss();

              if (data[0].feedback == "Y") {
                ctx.formData.reset();
                ctx.recordCount++;
                ctx.$parent.$refs.list.refreshData();
                ctx.$parent.$refs.snackbar.ShowSnackbar(
                  "primary",
                  "Data berhasil disimpan"
                );
              } else {
                ctx.$parent.$refs.snackbar.ShowSnackbar(
                  "error",
                  data[0].feedback
                );
              }
            },
            function (ctx, err) {
              console.log(err);
            }
          );
        }
      );
    },
  },
  data: () => ({
    mode: "adding",
    accessData: {},
    recordCount: 0,
    mapHeaderGroupChoices: ["UP", "SC", "UC", "BC", "BE"],
    formData: {
      mapheader_code: "",
      mapheader_desc: "",
      mapheader_group: "",
      mapheader_partial: false,
      mapheader_actived: true,
      mapheader_reference: false,
      mapheader_code_readonly: false,
      mapheader_allowed: false,
      reset: function () {
        this.mapheader_code = "";
        this.mapheader_desc = "";
        this.mapheader_group = "";
        this.mapheader_partial = false;
        this.mapheader_reference = false;
        this.mapheader_actived = true;
        this.mapheader_code_readonly = false;
      },
    },
    rules: {
      mapheader_code_readonly: true,
      mapheader_desc_rule: [(v) => !!v || "Please fill Description"],
    },
  }),
  mounted() {
    this.$refs.confirm.$on("clicked", (a) => {
      console.log(a);
    });
    this.$on("RecordCount", function (count) {
      this.recordCount = count;
    });
    this.$on("Editing", function (selection) {
      this.mode = "editing";
      Object.assign(this.formData, selection);
      this.formData.mapheader_code = selection.mapheader_id;
      this.formData.mapheader_group = selection.mapheader_type;
      this.$refs.mapheader_code.focus()
    });
  },
};
</script>

