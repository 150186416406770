<template>
    <div>
        <v-dialog
            v-model="dialogsubrogation"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
        <v-card>
            <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click.stop="SearchClose()">
                <v-icon>{{$vars.V('icon_back')}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ dialog_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="AddData"
            >
                Add 
                <v-icon>{{$vars.V('icon_add')}}</v-icon>
            </v-btn> &nbsp;
            <v-btn
                text
                @click="ConfirmData"
            >
                Save
                <v-icon>{{$vars.V('icon_save')}}</v-icon>
            </v-btn>
            </v-toolbar>
            <v-container grid-list-md class="pa-12">
            <v-form
                  enctype="multipart/form-data"
                  ref="form_claimsubro"
                  lazy-validation
                >
            <v-layout row wrap>
                  <v-col cols="12" sm="3" md="3" class="mt-n3">
                  <v-text-field
                      v-model="register.claimsubro_description"
                      id="claimsubro_description"
                      prepend-inner-icon="list_alt"
                      label="Description"
                      autofocus
                      @keyup.enter="ConfirmData"
                  >
                  </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="mt-n3">
                  <v-dialog
                    ref="dialog_claimsubro_refdate"
                    v-model="modal.claimsubro_refdate"
                    :return-value.sync="register.claimsubro_refdate"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="register.claimsubro_refdate"
                        prepend-inner-icon="event"
                        background-color="date"
                        readonly
                        label="Ref Date"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="register.claimsubro_refdate"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.claimsubro_refdate = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog_claimsubro_refdate.save(
                            register.claimsubro_refdate
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="mt-n3">
                  <v-text-field
                      v-model="register.claimsubro_refno"
                      id="claimsubro_refno"
                      prepend-inner-icon="list_alt"
                      label="Reference No"
                      @keyup.enter="ConfirmData"
                  >
                  </v-text-field>
                  </v-col>
                   <v-col cols="12" sm="1" md="1">
                    <app-cb
                      cb_type           ="currency"
                      cb_url            ="apiListCurrency"
                      cb_url_parameter  ="currency_actived=Y"
                      cb_title          ="CCY"
                      cb_id             ="claimsubro_ccy"
                      cb_desc           ="claimsubro_ccy_desc"
                      cb_rules          ="Please fill Currency"
                      cb_desc_readonly  ="readonly"
                      cb_items_id       ="currency_id"
                      cb_items_desc     ="currency_desc"
                      :cb_value_id      ="claimsubro_ccy[0]"
                      cb_single         ="Y"
                    >
                    </app-cb>
                  </v-col>
                    <v-col cols="12" sm="3" md="3" class="mt-n3">
                    <vuetify-money
                      v-model         ="register.claimsubro_amount"
                      label           ="Amount"
                      placeholder     ="Amount"
                      backgroundColor ="numeric"
                      :options        ="format_amount"
                      :properties     ="properties">
                    </vuetify-money>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="mt-n3">
                  <v-text-field
                      v-model="register.claimsubro_remarks"
                      id="claimsubro_remarks"
                      prepend-inner-icon="notes"
                      label="Remarks"
                      @keyup.enter="ConfirmData"
                  >
                  </v-text-field>
                  </v-col>
                <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  @click:append="ListData"
                  ></v-text-field>
                <v-data-table
                    :headers="header['subrogation']"
                    :items="items['subrogation']"
                    :loading="loading.data"
                    :search="search"
                    loading-text="Loading... Please wait"
                    dense
                    item-key="dataid"
                    class="elevation-1 row-pointer"
                >
                    <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                        <span slot="activator">
                        {{ props.header.text }}
                        </span>
                        <span>
                        {{ props.header.text }}
                        </span>
                    </v-tooltip>
                    </template>
                    <template v-slot:no-data> No Data Available </template>
                    <template v-slot:item.action="{ item }">
                      <v-icon small class="mr-2" @click="EditData(item.claimsubro_row)">
                        {{$vars.V('icon_edit')}}
                      </v-icon>
                      <v-icon small @click="Delete(item.claimsubro_row)">
                        {{$vars.V('icon_delete')}}
                      </v-icon>
                  </template>
                  <template v-slot:item.claimsubro_amount="{ item }">
                      {{$functions.NewFormatNumber(item.claimsubro_amount)}}
                  </template>
                </v-data-table>
                </v-col>
            </v-layout>
            </v-form>
            </v-container>
        </v-card>
        </v-dialog>
         <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
            <v-card>
              <v-card-title class="primary white--text"
                >{{ confirm.title }}?</v-card-title
              >
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="dialog"
                  color="primary"
                  :disabled="confirm.text === 'Ok' ? false : true"
                  @click="Loading"
                  >{{ confirm.text }}
                </v-btn>
                <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-snackbar
        :value	  ="snackbar.dialog"
        :timeout  ="snackbar.timeout"
        :color		="snackbar.color"
        rounded	  ="pill"
        top
        style 	  ="z-index: 9999;"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>

<script>
  export default {
    props: {
    dialog: Boolean,
    dialog_title: String,
    dialog_id: String,
    dialog_param: String,
  },
  data: () => ({
    url_type: 'add',
    limit: '1000',
    search: '',
    header: {
      subrogation: [
        {
          text: "Row",
          value: "claimsubro_row",
          align: "left",
        },
        { text: "Claim Row", value: "claimsubro_claimrow" },
        { text: "Description", value: "claimsubro_description" },
        { text: "RefNo", value: "claimsubro_refno" },
        { text: "CCY", value: "claimsubro_ccy" },
        { text: "Amount", value: "claimsubro_amount",align: "right" },
        { text: 'Actions', value: 'action', sortable: false }
      ],
    },
    items: [],
    loading: {
      data: false
    },
    claimsubro_ccy: {},
    register: {
     claimsubro_row: '',
     claimsubro_claimrow: '',
     claimsubro_description: '',
     claimsubro_refdate: '',
     claimsubro_refno: '',
     claimsubro_amount: 0,
     claimsubro_remarks: '',
    },
    snackbar: {
      dialog: false,
      color: 'primary',
      text: '',
      timeout: 3000
    },
    modal: {
      claimsubro_refdate: false
    },
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },

    // Format Properties
    properties: {
      reverse: true
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
  }),
  computed: {
    dialogsubrogation: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    }
  },
  watch: {
    dialog: function (newdata) {
      if (newdata !== "") {
        this.ListData()
      }
    },
  },
  methods: {
    SearchClose() {
        this.dialogsubrogation = false
    },
    AddData() {
      this.url_type = 'add'
      this.register.claimsubro_description = ''
      this.register.claimsubro_refdate     = ''
      this.register.claimsubro_refno       = ''
      this.register.claimsubro_ccy         = ''
      this.$set(this.claimsubro_ccy, 0, '')
      this.register.claimsubro_amount      = 0
      this.register.claimsubro_remarks     = ''
    },
    EditData(id) {
      this.$set(this.claimsubro_ccy, 0, '')
      this.loading.data = true
      let formdata = {
        claimsubro_row: id,
        order_by: "claimsubro_row",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListClaimSubro"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.url_type = 'edit'
              this.register.claimsubro_row = feedback[0].claimsubro_row
              this.register.claimsubro_claimrow = feedback[0].claimsubro_claimrow
              this.register.claimsubro_description = feedback[0].claimsubro_description
              this.register.claimsubro_refdate = feedback[0].claimsubro_refdate
              this.register.claimsubro_refno = feedback[0].claimsubro_refno
              this.register.claimsubro_amount = feedback[0].claimsubro_amount
              this.register.claimsubro_remarks = feedback[0].claimsubro_remarks
              this.$set(this.claimsubro_ccy, 0, feedback[0].claimsubro_ccy)
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    ListData() {
      this.items['subrogation'] = []
      this.loading.data = true
      let formdata = {
        claimsubro_claimrow: this.dialog_id,
        order_by: "claimsubro_claimrow",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListClaimSubro"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items['subrogation'] = feedback
          } else {
              this.loading.data = false
              this.items['subrogation'] = feedback
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    Delete(id) {
      this.url_type = 'delete'
      this.register.claimsubro_row = id
      this.ConfirmData()
    },
    ConfirmData () {
      if (this.url_type ==='add' || this.url_type === 'edit') {
        if (this.$refs.form_claimsubro.validate()) {
          this.confirm.dialog = true
          this.confirm.title  = 'Confirm'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (this.url_type === 'delete') {
        this.confirm.dialog = true
        this.confirm.title  = 'Delete'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    },
    Loading () {
      this.confirm.text ='Loading...'
      if (this.url_type ==='add' || this.url_type ==='edit') {
        this.SaveData()
      } else if (this.url_type ==='delete') {
        this.DeleteData()
      }
    },
    SaveData() {
      this.loading.data = true
      let claimsubro_ccy = document.getElementById('claimsubro_ccy').value
      let formdata = {
        url_type: this.url_type,
        claimsubro_row: this.register.claimsubro_row,
        claimsubro_claimrow: this.dialog_id,
        claimsubro_description: this.register.claimsubro_description,
        claimsubro_refdate: this.register.claimsubro_refdate,
        claimsubro_refno: this.register.claimsubro_refno,
        claimsubro_remarks: this.register.claimsubro_remarks,
        claimsubro_ccy: claimsubro_ccy,
        claimsubro_amount: String(this.register.claimsubro_amount),
        claimsubro_status: 'R',
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWriteClaimSubro"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Save Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    DeleteData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        claimsubro_row: this.register.claimsubro_row,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWriteClaimSubro"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Delete Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      this.confirm.dialog = false
      this.confirm.text = "Ok"
    },
  },
};
</script>