<template>
  <v-dialog
    v-model="dialogprint"
    persistent
    transition="dialog-bottom-transition"
    max-width="490" 
  >
    <v-card>
      <v-toolbar dark color="fourth" dense>
        <v-btn icon dark @click.stop="dialogprint = false">
          <v-icon>cancel</v-icon>
        </v-btn>
        <v-toolbar-title>Print Document</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <template>
        <v-list dense>
            <v-list-item-group
            >
                <template v-for="(item, index) in items">
                <v-list-item :key="item.doc" @click="Open(item.doc,modul,id)">
                    <template>
                    <v-list-item-avatar>
                    <v-icon
                        class="grey lighten-1"
                        dark
                    >
                        {{item.icon}}
                    </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>

                        <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                       
                    </v-list-item-action>
                    </template>
                </v-list-item>

                <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
                ></v-divider>
                </template>
            </v-list-item-group>
            </v-list>
      </template>
    </v-card>
    </v-dialog>
</template>
<script>
export default {
  props: {
    value: Boolean,
    modul: String,
    id: String
  },
  data: () => ({
    url_type: "add",
    items: [
        { doc: 'sp3', text: 'SP3', icon: 'description', subtitle: 'Surat Persetujuan Prinsip Penjaminan' },
        { doc: 'certificate', text: 'Certificate', icon: 'description', subtitle: 'Sertifikat Penjaminan' },
        // { doc: 'committee', text: 'Committee Materials', icon: 'description', subtitle: 'Bahan Komite' },
    ],
  }),
  computed: {
    dialogprint: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
  methods: {
    Open (doc,modul,id) {
        let document = 'doc_'+doc+'.php'
        let url = this.$functions.AppDoc()+'/modul/mod_documents/'+document+'?document='+doc+'&login='+this.$functions.UsersID()+'&modul='+modul+'&id='+id
        this.$functions.OpenURL(url)
    }
  }
}
</script>