<template>
    <div v-if="usersmenu_add === 1">
        <app-drawer_menu drawer_menu_title="Upload Reinsurance Currency"></app-drawer_menu>
        <v-tabs
	      background-color="white"
	      color="fourth"
	    >
        <v-tab
            id       = "tabform"
            href   	 ="#tab-form"
        >
            Form
        </v-tab>
        <v-tab-item
            value  ="tab-form"
        >
        <v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
        <!-- Loading Upload -->
        <h4>{{loading.upload}}</h4>
        <!-- Upload Hidden -->
        <input type="file" ref="fileupload" @change="DisplayFile($event)" style="display:none" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
        <v-form enctype="multipart/form-data" ref="form_upload_profile" lazy-validation>
			<v-row class="mx-2">
                <!-- Year -->
                <v-col cols="12" sm="2" md="2">                    
                    <v-text-field
                        v-model 	  	    ="form.reinsrate_year"
                        required
                        :maxlength 	        ="maxinput.year"
                        label       	    ="Year *"
                        placeholder 	    ="Year"
                        background-color 	="numeric"
                        id 			        ="reinsrate_year"
                        v-mask		        ="'####'"
                        reverse
                    >
                    </v-text-field>                    
                </v-col>
                <!-- Upload File -->
				<v-col cols="12" sm="7" md="7">
                    <v-text-field
                    :value ="form.filename"
                    prepend-icon="mdi-file-excel"
                    @click="OpenUpload"
                    placeholder="Choose File Excel">
                    </v-text-field>
                </v-col>
                <!-- Sheetname -->
                <v-col cols="12" sm="3" md="3">
                    <v-select
                    v-model="sheetname"
                    :items="sheetlist"
                    label="Sheet Name"
                    prepend-icon="filter_list"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="2" md="12" class="mt-n3">
                    <small>Notify Me</small><br>
                    <v-btn-toggle dense v-model="form.background" rounded>
                    <v-btn small>
                        No
                    </v-btn>
                    <v-btn small>
                        Yes
                    </v-btn>
                    </v-btn-toggle>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn
				      color		="primary"
				      class		="white--text"
				      rounded
                      :disabled = "loading.upload === 'Loading...'?true:false"
                      @click="Confirm"
				    >
				      <v-icon dark>upload</v-icon>Upload
				    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        </v-tab-item>
        </v-tabs>
        <!-- Dialog -->
        <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	    </v-dialog>
        <!-- Snackbar -->
        <v-snackbar
            v-model	= "snackbar.dialog"
            :timeout= "snackbar.timeout"
            color	= "fourth"
            rounded	= "pill"
            top
            style 	= "z-index: 9999"
            >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
            <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
            <v-card>
                <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
                <v-card-text>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="dialog" color="primary"
                        :disabled   = "confirm.text === 'Ok' ? false:true"
                        @click      = "Loading">{{confirm.text}}</v-btn>
                    <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import XLSX from 'xlsx'
export default {
    data: () => ({
	  	snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
		confirm: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		loading: {
          page: true,
          upload: '',
          list: false,
		  listlogs: false
		},
		form: {
            reinsrate_year:'',
			filename: '',
			background: 0,
			access: 0,
		},
        remarks: {
			feedback: "",
			dialog: false,
		},
        maxinput: {
            year: 4
        },
		sheetlist: [],
        sheetname: '',
        upload: ''
    }),
    created () {
        this.Access()
    },
    methods: {
        //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "profile"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let priv = response.data
				if (priv.length > 0) {
					if (priv[0].usersmenu_add === 0) {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					} else {
                        this.form.access = priv[0].usersmenu_add
                    }
				} else {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
				}
				this.loading.page = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.page = false
			})
		},
        OpenUpload () {
            this.$refs.fileupload.click()
        },
        DisplayFile (e) {
            const file = e.target.files[0]
            var reader = new FileReader()
            reader.fileName = file.name
            let get = this
            get.sheetlist = []
            get.sheetname = ''
            get.loading.upload = 'Loading...'
            reader.onload = function (e) {
                var data = e.target.result
                var workbook = XLSX.read(data, { type: 'binary' })
                get.sheetlist = workbook.SheetNames
                get.form.filename = e.target.fileName
                get.loading.upload = e.target.fileName
            }
            this.upload = file
            reader.readAsBinaryString(file)
        },
        //Pop Up message for validating (Ok or Cancel) your proccess
		Confirm () {
            if (this.form.reinsrate_year === '') {
                this.SnackBar(
                            true,
                            "error",
                            'Please fill Year',
                            0
                        )
            } else if (this.form.filename === '') {
                this.SnackBar(
                            true,
                            "error",
                            'Please choose attachment file(XLSX)',
                            0
                        )
            } else if (this.sheetname === '') {
                this.SnackBar(
                            true,
                            "error",
                            'Please choose sheet name',
                            0
                        )
            } else {
                this.confirm.dialog = true
                this.confirm.title = "Upload"
                setTimeout(function() {
                    document.getElementById("dialog").focus()
                }, 500)
            }
		},
        Loading () {
			this.confirm.text = "Loading..."
			this.Upload()
		},
        Upload ()  {
            let background = this.$functions.SwitchFlag(this.form.background)
            let form = new FormData()
            form.append('year', this.form.reinsrate_year)
            form.append('sheetname', this.sheetname)
            form.append('uploadfile', this.upload)
            form.append('background', background)
            this.$axios.post(this.$functions.SafeURL('apiUploadReinsRate', ``), form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            })
            .then(response => {
                let feedback = response.data
				if (feedback.length > 0) {
                    if (response.data[0].feedback === 'Y') {
                        this.ResetUpload()
                        let message  = "Upload Success"
                        if (background === 'Y') {
                            message = "Upload in background process, we will notify you"
                        } 
                        this.SnackBar(
							true,
							"primary",
							message,
							0
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog 	= true
                        this.remarks.feedback 	= feedback[0].feedback
                    }
                }
            })
            .catch(e => {
                this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
            })
        },
        SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
		},
        ResetUpload(){
            this.form.reinsrate_year = ''
            this.form.filename       = ''
            this.upload              = ''
            this.sheetlist           = []
            this.loading.upload      = ''
        }
    }
}
</script>