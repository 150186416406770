<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Tokens"></app-drawer_menu>
	  	<v-tabs
	      background-color="white"
	      color="fourth"
	      v-if = "this.access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_tokens" lazy-validation>
			<v-row class="mx-2">
				<v-col cols="12" sm="12" md="12">
					<v-btn
                        color		    ="tertiary"
                        class			="white--text"
                        :disabled     = "access.add === 0 ? true:false" 
                        rounded
                        @click 	    = "Add" 
                        small
                    >
                        <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
                    </v-btn> &nbsp;
                    <v-btn
                        color		="primary"
                        class		="white--text"
                        :disabled 	= "access.add === 1 || access.edit === 1 ? false:true"
                        rounded
                        @click 		= "Confirm('write','')" 
                        small
                    >
                        <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
                    </v-btn>
                </v-col>
              <!-- User ID -->
               <v-col cols="12" sm="4" md="4" class="mt-n2">
				   <app-cb
					cb_type           	= "leader"
					cb_url            	= "apiListUsers"
					cb_url_parameter  	= "users_actived=Y&users_employeef=Y"
					cb_title          	= "Token User ID"
					cb_id             	= "tokens_userid"
					cb_desc           	= "tokens_user_name"
					cb_rules          	= "Please fill User"
					cb_desc_readonly  	= "readonly"
					cb_items_id       	= "users_id"
					cb_items_desc     	= "users_name"
					:cb_value_id	  	= "form.tokens_userid"
					:cb_value_desc		= "form.tokens_user_name"
					>
					</app-cb>
               </v-col>
               <!-- Tokens Key -->
               <!-- <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.tokens_key"
                  required 
                  :rules 	  	="form.tokens_key_rules"
				  :maxlength 	="maxinput.key"
                  label       	= "Token Key"
                  placeholder 	= "Token Key"
                  id 			= "tokens_key"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col> -->
               <!-- Token Secret -->
               <!-- <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.tokens_secret"
                  required 
                  :rules 	  	="form.tokens_secret_rules"
				  :maxlength 	="maxinput.secret"
                  label       	= "Token Secret"
                  placeholder 	= "Token Secret"
                  id 			= "tokens_secret"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col> -->
               <!-- Token Ref Number -->
               <!-- <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.tokens_refno"
                  required 
                  :rules 	  	="form.tokens_refno_rules"
                  label       	= "Token Reference Number"
                  placeholder 	= "Token Reference Number"
                  id 			= "tokens_refno"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col> -->
               	<!-- Token Expired Date -->
                <v-col cols="12" sm="4" md="4">
                    <v-dialog
                    ref="dialog_expdate"
                    v-model="modal.expdate"
                    :return-value.sync="form.tokens_expired_date"
                    persistent
                    width="290px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model				="form.tokens_expired_date"
                            label				="Expired Date"
                            prepend-inner-icon	="event"
                            background-color 	= "date"
                            v-on				="on"
                            readonly
                        ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.tokens_expired_date" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.expdate = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog_expdate.save(form.tokens_expired_date)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <!-- Token Expired Day -->
               <v-col cols="12" sm="4" md="4">
                  <v-text-field 
                  v-model 	  	= "form.tokens_expired_day"
                  required 
                  :rules 	  	="form.tokens_expired_day_rules"
				  type			="number"
                  label       	= "Token Expired Day"
                  placeholder 	= "Token Expired Day"
                  id 			= "tokens_expired_day"
                  @keyup.enter 	= "Confirm('write','')"
                  >
                  </v-text-field>
               </v-col>
               <v-col cols="12" sm="4" md="4" class="mt-n5">
				   <label>Active Status</label>
					<v-switch
						v-model="form.tokens_actived"
						color  = "primary"
						:label 	="form.tokens_actived !== true ? '' : 'Active'" 
					></v-switch>
                </v-col>
			</v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
			   		<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.tokens_cdate}}
Created User 	 : {{form.tokens_cuser}}
Last Update Date : {{form.tokens_ldate}}
Last Update User : {{form.tokens_luser}}

</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<v-card-title>
				<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
					<template v-slot:activator="{ on }">
						<button class="AlertButton ErrorButton" v-on="on">
							<div class="blobs-container">
								<v-icon class="blob red mt-1 mr-2">error_outline</v-icon>
								Open Error Message
							</div>
						</button>
					</template>
					<v-card>
						<v-toolbar dark color="primary">
						<v-btn icon dark @click="remarks.dialog = false">
							<v-icon>{{$vars.V('icon_close')}}</v-icon>
						</v-btn>
						<v-toolbar-title>Data Failed</v-toolbar-title>
						</v-toolbar>
						<v-list three-line subheader>
							<v-list-item>
								<v-list-item-content>
								<v-list-item-title>Daftar Data yang gagal diproses</v-list-item-title>
								<v-list-item-subtitle><div class="ml-4" v-html="remarks.feedback"></div></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-dialog>
			</v-card-title>
			<template>
			  <v-card-title>
	                <v-btn 
	                	color = "error"
	                	title = "Delete"
	                	@click = "Confirm('multidelete','')"
	                	v-if  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false">
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-btn 
	                	color  = "primary"
	                	title  = "Search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true">
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    v-model="selected"
			    :headers="headers"
			    :items="items"
			    :single-select="singleselect"
			    :search="search"
			    item-key="tokens_userid"
			    show-select
			    class="elevation-1"
			    :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			  >
			    <template v-slot:top>
			      <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
			    </template>
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        @click="Edit(item.tokens_userid)"
			        :disabled = "access.edit === 0 ? true:false"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click="Confirm('delete',item.tokens_userid)"
			        :disabled = "access.delete === 0 ? true:false"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
            <template  slot="items" slot-scope="row">
                <tr>
                    <td class="text-xs-left">{{row.item.tokens_userid}}</td>
                    <td class="text-xs-left">{{row.item.tokens_key}}</td>
                    <td class="text-xs-left">{{row.item.tokens_expired_date}}</td>
                    <td class="text-xs-left">{{row.item.tokens_remaining_date}}</td>
                    <td class="text-xs-left">{{row.item.tokens_actived}}</td>
                    <td class="text-xs-left">Edit</td>
                </tr>
            </template>
		  	</v-tab-item>
  	</v-tabs>
	<v-snackbar
	  v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      absolute
      top
      style 	= "z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
	</v-snackbar>
	<v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
		<v-card>
			<v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
			<v-card-text>
				
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn id="dialog" color="primary"
					:disabled   = "confirm.text === 'Ok' ? false:true"
					@click      = "Loading">{{confirm.text}}</v-btn>
				<v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
  </div>
</template>
<script>
export default {
  	data: () => ({
		  url_type: 'add',
		  cont: false,
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
		},
		maxinput: {
			userid:20,
			key:100,
			secret:100,
		},
  		snackbar_dialog: false,
	    snackbar_color: 'success',
	    snackbar_text: '',
	    snackbar_timeout: 3000,
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	ok_text: 'Ok',
	    	ok_disabled: false,
	    },
        singleselect: false,
        selected: [],
        modal: {
			expdate:false,
		},
        search: '',
        headers: [
          {
            text: 'User ID',
            align: 'left',
            sortable: false,
            value: 'tokens_userid',
          },
          { text: 'Key', value: 'tokens_key' },
          { text: 'Expired Date', value: 'tokens_expired_date' },
          { text: 'Remaining Day', value: 'tokens_remaining_date' },
          { text: 'Actived', value: 'tokens_actived' },
          { text: 'Actions', value: 'action', sortable: false }
		],
		remarks: {
			feedback:'',
			dialog: false,
		},
		items: [],
		feeds: [],
		accessmenu: [],
        loading: {
          list : false
        },
        btn_search: true,
        form: {
        	tokens_userid: '',
        	tokens_userid_rules: [v => !!v || 'Please fill User ID'],
        	tokens_userid_readonly: false,
        	tokens_key: '',
			tokens_key_rules: [v => !!v || 'Please fill Token Key'],
			tokens_secret: '',
			tokens_secret_rules: [v => !!v || 'Please fill Token Secret'],
			tokens_expired_day: '',
        	tokens_expired_day_rules: [v => !!v || 'Please fill Expired Day'],
        	tokens_actived: true,
        },
        loader: null,
        loading_save: false
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
  			setTimeout(function(){
                document.getElementById('tokens_userid').focus()
            }, 500)
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
			let formdata = {
				menu_id: 'tokens',
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiListMenuUser'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let priv = this.$functions.DecodeJWT(response.data)
				if (priv.length > 0) {
                	this.access.read 	= priv[0].usersmenu_read
                	this.access.add  	= priv[0].usersmenu_add
                	this.access.edit    = priv[0].usersmenu_edit
                	this.access.delete  = priv[0].usersmenu_delete
                	if (priv[0].usersmenu_read === 0) {
                		this.NoPriviledge()
                	}
                } else {
                	this.NoPriviledge()
                }
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.list = false
			})
		},
		//This function use for give feedback when user has no access with some feature
        NoPriviledge () {
        	this.SnackBar(true, 'error', 'You dont have access `tokens` modul, please contact Administrator', 30000)
        },
		//Function for get all data from table tokens with API in mod_users.go
		List () {
			let formdata = {
					tokens_userid: '',
					tokens_key: '',
					order_by: 'tokens_userid',
					order_type: 'ASC',
					limit: this.limit,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiListToken'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				this.items = this.$functions.DecodeJWT(response.data)
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.list = false
			})
		},  
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_tokens.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		  = 'delete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ id + '`?'
	  			this.tokens_userid 	  = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		},
		//Function to request insert data to table tokens 'The API will validating your data, include the user who process the data' (go to APIWritetokens with url_type = "add") 
	  	Add () {
	  		this.url_type 		 		        = 'add'
	  		this.form.tokens_userid 	 	    = ''
			this.form.tokens_key 	 	        = ''
			this.form.tokens_secret 			= ''
			this.form.tokens_expired_day 		= ''
			this.form.tokens_expired_date 		= ''  
	  		this.form.tokens_actived 	        = true
	  		this.form.tokens_userid_readonly    = false
	  		setTimeout(function(){
                document.getElementById('tokens_userid').focus()
            }, 500)
		},
		//Function to request update data to table tokens 'The API will validating your data, include the user who process the data' (go to APIWritetokens with url_type = "edit") 
		Edit (id) {
			let formdata = {
				tokens_userid: id,
				order_by: 'tokens_userid',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiListToken'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
            .then(response => {
				this.feeds = this.$functions.DecodeJWT(response.data)
				if (this.feeds[0].feedback === 'Y') {
            		document.getElementById('tabform').click()
            		this.url_type 			    = 'edit'
            		this.form.tokens_userid   	    = this.feeds[0].tokens_userid
					this.form.tokens_user_name 		= this.feeds[0].tokens_user_name
                    this.form.tokens_key 	        = this.feeds[0].tokens_key
                    this.form.tokens_secret         = this.feeds[0].tokens_secret
                    this.form.tokens_refno          = this.feeds[0].tokens_refno
                    this.form.tokens_expired_date   = this.feeds[0].tokens_expired_date
                    this.form.tokens_actived        = this.feeds[0].tokens_actived
                    this.form.tokens_expired_day    = this.feeds[0].tokens_expired_day
					this.form.tokens_actived        = this.$functions.TrueOrFalse(this.feeds[0].tokens_actived)
					this.form.tokens_cdate		    = this.feeds[0].tokens_cdate
					this.form.tokens_cuser		    = this.feeds[0].tokens_cuser
					this.form.tokens_ldate		    = this.feeds[0].tokens_ldate
					this.form.tokens_luser		    = this.feeds[0].tokens_luser
            		this.form.tokens_userid_readonly = true
					setTimeout(function(){
                		document.getElementById('tokens_desc').focus()
            		}, 500)
	  			} else {
					this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
					this.remarks.dialog = true
					this.remarks.feedback = this.feeds[0].feedback
				}
			})
			.catch(e => {
				this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
	  	Delete (id) {
			let formdata = {
				tokens_userid: id,
				tokens_actived: this.form.tokens_actived,
			}
	  		let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiDeleteToken'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				this.feeds = this.$functions.DecodeJWT(response.data)
				if (this.feeds[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Delete Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
					this.confirm.ok_disabled = false
					this.url_type 			 = ''
					this.List()
	  			} else {
					this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
					this.remarks.dialog = true
					this.remarks.feedback = this.feeds[0].feedback
				}
			})
			.catch(e => {
				this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	MultiProcess () {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				data_multi: multiData,
			}
			let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiWriteToken'),encode,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				this.items = this.$functions.DecodeJWT(response.data)
				if (this.items[0].feedback === 'Y') {
					let feed
					if (this.items[0].feedback_failed == 0){
						feed = `Success : ${this.items[0].feedback_success}, Failed : ${this.items[0].feedback_failed}`
					} else {
						this.remarks.feedback = this.$functions.FeedbackReplacer(this.items[0].feedback_failed_remarks)
						feed = `Success : ${this.items[0].feedback_success}, Failed : ${this.items[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 5000)
					this.confirm.dialog  	 = false
					this.confirm.ok_text 	 = 'Ok'
					this.confirm.ok_disabled = false
					this.url_type 			 = ''
					this.List()
				} else {
					this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
					this.remarks.dialog = true
					this.remarks.feedback = this.feeds[0].feedback
				}
			})
			.catch(e => {
				this.Error("Data gagal diproses, Silahkan Periksa Error Message ")
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  	},
	  	Loading () {
	  		this.confirm.ok_text 	 = 'Loading...'
	  		this.confirm.ok_disabled = true
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.tokens_userid)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess()
	  		}
	  	},
	  	WriteOLD () {
			let formData = new FormData()
			let userid = document.getElementById('tokens_userid').value  
	  		formData.append('url_type', this.url_type)
	  		formData.append('tokens_userid', userid)
            formData.append('tokens_key', this.form.tokens_key)
            formData.append('tokens_secret', this.form.toekns_secret)
            // formData.append('tokens_refno', this.form.tokens_refno)
            formData.append('tokens_expired_date', this.form.tokens_expired_date)
            formData.append('tokens_actived', this.form.tokens_actived)
            formData.append('tokens_expired_day', this.form.tokens_expired_day)
            this.$axios.post(this.$functions.SafeURL('apiWriteToken', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Save Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = 'edit'
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
		},
		Write () {
			let userid = document.getElementById('tokens_userid').value  
	  		let formdata = {
				url_type: this.url_type,
				tokens_userid: userid,
				tokens_refno: this.form.tokens_refno,
				tokens_expired_date: this.form.tokens_expired_date,
				tokens_actived: this.form.tokens_actived,
				tokens_expired_day: this.form.tokens_expired_day,
				token_actived: this.form.token_actived,
			}
	  		let param 	   = this.$functions.ParamJWT(formdata)
			let encode     = this.$functions.EncodeJWT(param)
			this.$axios.post(this.$functions.UrlJWT('apiWriteToken'),encode,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = this.$functions.DecodeJWT(response.data)
				if (feedback[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Save Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.text 	 	 = 'Ok'
		  			this.url_type 			 = 'edit'
	  			} else {
	  				this.SnackBar(true, 'error', 'Check Error Message', 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', 'Check Error Message', 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar = {
	      	dialog: dialog,
	      	color: color,
	      	text: text,
	      	timeout: timeout
	      }
	      if (color === 'error') {
	      	this.confirm.dialog 	 = false
	      	this.confirm.text 	 	 = 'Ok'
	      }
	    }
	}
}
</script>