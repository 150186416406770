var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-drawer_menu',{attrs:{"drawer_menu_title":"Absence"}}),(this.access.read === 1)?_c('v-tabs',{attrs:{"background-color":"white","color":"fourth"}},[_c('v-tab',{attrs:{"href":"#tab-list"},on:{"click":_vm.List}},[_vm._v(" "+_vm._s(_vm.$vars.V('txt_list'))+" ")]),_c('v-tab-item',{attrs:{"value":"tab-list"},scopedSlots:_vm._u([{key:"items",fn:function(row){return [_c('tr',[_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(row.item.absence_usersid))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(row.item.absence_date))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(row.item.absence_actived))])])]}}],null,false,2837263843)},[[_c('v-card-title',[(_vm.selected.length > 0)?_c('v-btn',{attrs:{"color":"error","title":"Delete","disabled":_vm.access.delete === 0 ? true:false},on:{"click":function($event){return _vm.Confirm('multidelete','')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vars.V('icon_delete')))])],1):_vm._e(),_c('v-spacer'),(_vm.btn_search === false)?_c('v-btn',{attrs:{"color":"primary","title":"Search"},on:{"click":function($event){_vm.btn_search = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vars.V('icon_search')))])],1):_vm._e(),(_vm.btn_search)?_c('v-text-field',{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"click:append":function($event){_vm.btn_search = false}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"single-select":_vm.singleselect,"search":_vm.search,"item-key":"absence_userid","footer-props":{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]],2)],1):_vm._e(),(_vm.remarks.feedback !== '')?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","persistent":"","max-width":"290"},model:{value:(_vm.remarks.dialog),callback:function ($$v) {_vm.$set(_vm.remarks, "dialog", $$v)},expression:"remarks.dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"fourth"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.remarks.dialog = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.$vars.V('icon_close')))])],1),_c('v-toolbar-title',[_vm._v("Error Messages")])],1),_c('v-card-text',{staticClass:"mt-2 ml-n2"},[_c('div',{staticClass:"subheading font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.remarks.feedback)}})])],1)],1):_vm._e(),_c('v-snackbar',{staticStyle:{"z-index":"9999"},attrs:{"timeout":_vm.snackbar.timeout,"color":"fourth","rounded":"pill","absolute":"","top":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
			    var attrs = ref.attrs;
return [(_vm.snackbar.color === 'error')?_c('v-btn',{attrs:{"dark":"","text":"","small":""},nativeOn:{"click":function($event){_vm.remarks.dialog = true}}},[_vm._v("Open")]):_vm._e(),_c('v-btn',{attrs:{"dark":"","text":"","small":""},nativeOn:{"click":function($event){_vm.snackbar.dialog = false}}},[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.dialog),callback:function ($$v) {_vm.$set(_vm.snackbar, "dialog", $$v)},expression:"snackbar.dialog"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-dialog',{attrs:{"max-width":"490","hide-overlay":"","persistent":""},model:{value:(_vm.confirm.dialog),callback:function ($$v) {_vm.$set(_vm.confirm, "dialog", $$v)},expression:"confirm.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(_vm._s(_vm.confirm.title)+"?")]),_c('v-card-text'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"id":"dialog","color":"primary","disabled":_vm.confirm.text === 'Ok' ? false:true},on:{"click":_vm.Loading}},[_vm._v(_vm._s(_vm.confirm.text))]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.confirm.dialog=false}}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }