// src/plugins/vuetify/theme.js

import colors from 'vuetify/lib/util/colors'

export default {
  primary: {
    base: '#114E87',
    darken1: colors.purple.darken2,
  },
  secondary: '#607D8B',
  background: {
    base: '#f2f2f2',
  },
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: '#3EC2D1',
  fourth: '#DF171D',
  readonly: '#ECEFF1',
  date: '#76FF03',
  time: '#FF9800',
  numeric: '#3EC2D1',
  combo: '#FFEB3B',
  more:'#00ff00',
}