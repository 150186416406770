<template>
    <div>
        <v-dialog
            v-model="dialogrisk"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
        <v-card>
            <v-toolbar dark color="primary" dense>
            <v-btn icon dark @click.stop="SearchClose()">
                <v-icon>{{$vars.V('icon_back')}}</v-icon>
            </v-btn>
            <v-toolbar-title>{{ dialog_title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="AddData"
            >
                Add 
                <v-icon>{{$vars.V('icon_add')}}</v-icon>
            </v-btn> &nbsp;
            <v-btn
                text
                @click="ConfirmData"
            >
                Save
                <v-icon>{{$vars.V('icon_save')}}</v-icon>
            </v-btn>
            </v-toolbar>
            <v-container grid-list-md class="pa-12">
            <v-form
                  enctype="multipart/form-data"
                  ref="form_policyrisk"
                  lazy-validation
                >
                <v-layout row wrap>
                    <!-- Riskcov -->
                    <v-col cols="12" sm="6" md="6">
                      <app-cb
                        cb_type           ="riskcov"
                        cb_url            ="apiListRiskCov"
                        cb_url_parameter  ="riskcov_actived=Y"
                        cb_title          ="Risk Coverage"
                        cb_id             ="policyrisk_riskcovid"
                        cb_desc           ="policyrisk_riskcovdesc"
                        cb_rules          ="Please fill Riskcov"
                        cb_desc_readonly  ="readonly"
                        cb_items_id       ="riskcov_id"
                        cb_items_desc     ="riskcov_desc"
                        :cb_value_id	  	="register.policyrisk_riskcovid"
                        :cb_value_desc		="register.policyrisk_riskcovdesc"
                        @clicked          ="GetRiskCov"
                      >
                      </app-cb>
                    </v-col>
        
                    <!-- Description -->
                    <v-col cols="12" sm="6" md="6" class="mt-n3">
                      <v-text-field
                        v-model       ="register.policyrisk_desc"
                        required
                        :rules        ="register.policyrisk_desc_rules"
                        :maxlength    ="maxinput.desc"
                        label         ="Description *"
                        placeholder   ="Description"
                        id            ="policyrisk_desc"
                        @keyup.enter  ="ConfirmData('write', '')"
                      >
                      </v-text-field>
                    </v-col>
        
                    <!-- Category -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <v-select
                        v-model       ="register.policyrisk_category"
                        required
                        :rules 	  		="register.policyrisk_category_rules"
                        :items        ="policyrisk_category"
                        item-text     ="code_desc"
                        item-value    ="code_id"
                        label         ="Category"
                        placeholder   ="Category"
                        id 				    ="policyrisk_category"
                        @keyup.enter  ="ConfirmData('write','')"
                      >
                      </v-select>
                    </v-col>
                    <!-- Rate -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <vuetify-money
                        v-model         ="register.policyrisk_indemnity"
                        label           ="Indemnity"
                        placeholder     ="Indemnity"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                      </vuetify-money>
                    </v-col>
                    <!-- Rate -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <vuetify-money
                        v-model         ="register.policyrisk_rate"
                        label           ="Rate"
                        placeholder     ="Rate"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                      </vuetify-money>
                    </v-col>
                     <!-- Rate Unit -->
                     <v-col cols="12" sm="1" md="1" class="mt-n3">
                      <v-select
                        v-model       ="register.policyrisk_rateunit"
                        required
                        :rules 	  		="register.policyrisk_rateunit_rules"
                        :items        ="policyrisk_rateunit"
                        item-text     ="code_desc"
                        item-value    ="code_id"
                        label         ="Unit"
                        placeholder   ="Unit"
                        id 				    ="policyrisk_rateunit"
                        @keyup.enter  ="ConfirmData('write','')"
                      >
                      </v-select>
                    </v-col>
        
                    <!-- Start Date -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <v-dialog
                        ref="dialog_sdate"
                        v-model="modal.sdate"
                        :return-value.sync="register.policyrisk_sdate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="register.policyrisk_sdate"
                            label="Start Date"
                            prepend-inner-icon="event"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="register.policyrisk_sdate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal.sdate = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog_sdate.save(register.policyrisk_sdate)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
        
                    <!-- End Date -->
                    <v-col cols="12" sm="2" md="2" class="mt-n3">
                      <v-dialog
                        ref="dialog_edate"
                        v-model="modal.edate"
                        :return-value.sync="register.policyrisk_edate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="register.policyrisk_edate"
                            label="End Date"
                            prepend-inner-icon="event"
                            background-color="date"
                            v-on="on"
                            v-mask="'####-##-##'"
                            placeholder="YYYY-MM-DD"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="register.policyrisk_edate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal.edate = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog_edate.save(register.policyrisk_edate)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        @click:append="ListData"
                        ></v-text-field>
                      <v-data-table
                          :headers="header['risk']"
                          :items="items['risk']"
                          :loading="loading.data"
                          :search="search"
                          loading-text="Loading... Please wait"
                          dense
                          item-key="dataid"
                          class="elevation-1 row-pointer"
                      >
                          <template slot="headerCell" slot-scope="props">
                          <v-tooltip bottom>
                              <span slot="activator">
                              {{ props.header.text }}
                              </span>
                              <span>
                              {{ props.header.text }}
                              </span>
                          </v-tooltip>
                          </template>
                          <template v-slot:no-data> No Data Available </template>
                          <template v-slot:item.policyrisk_rate="{ item }">
                            {{item.policyrisk_rate}} {{item.policyrisk_rateunit}}
                        </template>
                          <template v-slot:item.policyrisk_sdate="{ item }">
                              {{$functions.FormatDateFullName(item.policyrisk_sdate)}} to {{$functions.FormatDateFullName(item.policyrisk_edate)}}
                          </template>
                          <template v-slot:item.action="{ item }">
                            <v-icon small class="mr-2" @click="EditData(item.policyrisk_policyrow, item.policyrisk_riskcovid)">
                              {{$vars.V('icon_edit')}}
                            </v-icon>
                            <v-icon small @click="Delete(item.policyrisk_policyrow, item.policyrisk_riskcovid)">
                              {{$vars.V('icon_delete')}}
                            </v-icon>
                        </template>
                      </v-data-table>
                      </v-col>
            </v-layout>
            </v-form>
            </v-container>
        </v-card>
        </v-dialog>
         <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
            <v-card>
              <v-card-title class="primary white--text"
                >{{ confirm.title }}?</v-card-title
              >
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="dialog"
                  color="primary"
                  :disabled="confirm.text === 'Ok' ? false : true"
                  @click="Loading"
                  >{{ confirm.text }}
                </v-btn>
                <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-snackbar
        :value	  ="snackbar.dialog"
        :timeout  ="snackbar.timeout"
        :color		="snackbar.color"
        rounded	  ="pill"
        top
        style 	  ="z-index: 9999;"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
        </template>
      </v-snackbar>
    </div>
</template>

<script>
  export default {
    props: {
    dialog: Boolean,
    dialog_title: String,
    dialog_id: String,
    dialog_param: String,
    dialog_sdate: String,
    dialog_edate: String,
  },
  data: () => ({
    url_type: 'add',
    limit: '1000',
    search: '',
    header: {
      risk: [
        { text: 'Actions', value: 'action', sortable: false },
        {
          text: "ID",
          value: "policyrisk_riskcovid",
          align: "left",
        },
        { text: "Description", value: "policyrisk_desc" },
        { text: "Rate", value: "policyrisk_rate",align: "right" },
        { text: "Indemnity", value: "policyrisk_indemnity",align: "right" },
        { text: "Category", value: "policyrisk_category" },
        { text: "Period", value: "policyrisk_sdate" },
      ],
    },
    items: [],
    loading: {
      data: false
    },
    policyrisk_rateunit : ['C','M','F'],
    policyrisk_category : ['M','A'],
    policyint_category:[],
    register: {
     policyrisk_policyrow: '',
     policyrisk_riskcovid: '',
     policyrisk_riskcovid_old: '',
     policyrisk_riskcovdesc: '',
     policyrisk_desc: '',
     policyrisk_desc_rules: [(v) => !!v || "Please fill Description"],
     policyrisk_rateunit: 'C',
     policyrisk_rateunit_rules: [(v) => !!v || "Please fill Rate Unit"],
     policyrisk_category: 'M',
     policyrisk_category_rules: [(v) => !!v || "Please fill Category"],
    },
    maxinput: {
      desc: 255
    },
    snackbar: {
      dialog: false,
      color: 'primary',
      text: '',
      timeout: 3000
    },
    modal: {
      sdate: false,
      edate: false,
    },
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 4
    },
    // Format Properties
    properties: {
      reverse: true
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
  }),
  computed: {
    dialogrisk: {
        get() {
            return this.dialog
        },
        set(value) {
            if (!value) {
                this.$emit('close')
            }
        }
    }
  },
  watch: {
    dialog: function (newdata) {
      if (newdata !== "") {
        this.ListData()
      }
    },
  },
  methods: {
    SearchClose() {
        this.dialogrisk = false
    },
    AddData() {
      this.url_type = 'add'
      // this.$set(this.policyrisk_riskcovid, 0, '')
      this.register.policyrisk_riskcovid    = ''
      this.register.policyrisk_riskcovdesc  = ''
      this.register.policyrisk_desc         = ''
      this.register.policyrisk_rate         = 0
    },
    EditData(id,cov) {
      // this.$set(this.policy_ccy, 0, '')
      this.loading.data = true
      let formdata = {
        policyrisk_policyrow: id,
        policyrisk_riskcovid: cov,
        order_by: "policyrisk_riskcovid",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.url_type = 'edit'
              this.register.policyrisk_policyrow = feedback[0].policyrisk_policyrow
              this.register.policyrisk_riskcovid = feedback[0].policyrisk_riskcovid
              this.register.policyrisk_riskcovid_old = feedback[0].policyrisk_riskcovid
              this.register.policyrisk_riskcovdesc = feedback[0].policyrisk_riskcovdesc
              this.register.policyrisk_desc = feedback[0].policyrisk_desc
              this.register.policyrisk_category = feedback[0].policyrisk_category
              this.register.policyrisk_rate = feedback[0].policyrisk_rate
              this.register.policyrisk_rateunit = feedback[0].policyrisk_rateunit
              this.register.policyrisk_sdate = feedback[0].policyrisk_sdate
              this.register.policyrisk_edate = feedback[0].policyrisk_edate
              this.register.policyrisk_indemnity = feedback[0].policyrisk_indemnity
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    ListData() {
      this.items['risk'] = []
      this.register.policyrisk_sdate = this.dialog_sdate
      this.register.policyrisk_edate = this.dialog_edate
      this.loading.data = true
      let formdata = {
        policyrisk_policyrow: this.dialog_id,
        order_by: "policyrisk_riskcovid",
        order_type: "ASC",
        limit: this.limit,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiListPolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items['risk'] = feedback
          } else {
              this.loading.data = false
              this.items['risk'] = feedback
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          // this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    Delete(id, cov) {
      this.url_type = 'delete'
      this.register.policyrisk_policyrow = id
      this.register.policyrisk_riskcovid = cov
      this.ConfirmData()
    },
    ConfirmData () {
      if (this.url_type ==='add' || this.url_type === 'edit') {
        if (this.$refs.form_policyrisk.validate()) {
          this.confirm.dialog = true
          this.confirm.title  = 'Confirm'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (this.url_type === 'delete') {
        this.confirm.dialog = true
        this.confirm.title  = 'Delete'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    },
    Loading () {
      this.confirm.text ='Loading...'
      if (this.url_type ==='add' || this.url_type ==='edit') {
        this.SaveData()
      } else if (this.url_type ==='delete') {
        this.DeleteData()
      }
    },
    SaveData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        policyrisk_policyrow: this.dialog_id,
        policyrisk_riskcovid_old: this.register.policyrisk_riskcovid_old,
        policyrisk_riskcovid: this.register.policyrisk_riskcovid,
        policyrisk_desc: this.register.policyrisk_desc,
        policyrisk_rate: String(this.register.policyrisk_rate),
        policyrisk_rateunit: this.register.policyrisk_rateunit,
        policyrisk_indemnity: String(this.register.policyrisk_indemnity),
        policyrisk_category: this.register.policyrisk_category,
        policyrisk_sdate: this.register.policyrisk_sdate,
        policyrisk_edate: this.register.policyrisk_edate,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Save Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    DeleteData() {
      this.loading.data = true
      let formdata = {
        url_type: this.url_type,
        policyrisk_policyrow: this.register.policyrisk_policyrow,
        policyrisk_riskcovid: this.register.policyrisk_riskcovid,
    }
    let param = this.$functions.ParamPOST(formdata)
    this.$axios
      .post(this.$functions.UrlPOST("apiWritePolicyRisk"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
              this.loading.data = false
              this.items[this.dialog_title] = feedback
              this.SnackBar(true, 'primary', 'Delete Successfully', 0)
              this.ListData()
          } else {
              this.loading.data = false
              this.SnackBar(true, "error", feedback[0].feedback, 0)
          }
        } else {
          this.loading.data = false
          this.SnackBar(true, "error", 'No Data Available', 0)
        }
      })
    },
    GetRiskCov (value){
        let split = value.split('|')
        this.register.policyrisk_riskcovid = split[0]
        this.register.policyrisk_riskcovdesc = split[1]
        this.register.policyrisk_desc = split[1]
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      this.confirm.dialog = false
      this.confirm.text = "Ok"
    },
  },
};
</script>