<template>
<div>
<app-drawer_menu drawer_menu_title="Cause Of Loss"></app-drawer_menu>
	<v-progress-linear
		indeterminate
		color="fourth"
		rounded
		v-if="loading.page"
	></v-progress-linear>
	<v-tabs
		background-color="white"
		color="fourth"
		v-if = "access.read === 1"
	>
		<v-tab
			id       = "tabform"
			href   	 ="#tab-form"
			@click 	 = "FirstLoad"
		>
			Form
		</v-tab>
		<v-tab 
			href="#tab-list"
			@click = "List"
		>
			List
		</v-tab>
		<v-tab-item
			value  ="tab-form"
		>
		<v-form enctype="multipart/form-data" ref="form_causeloss" lazy-validation>
		<v-row class="mx-2 ma-1">
			<v-col cols="12" sm="12" md="12">
				<v-btn
					color		="tertiary"
					class		="white--text"
					:disabled = "access.add === 0 ? true:false" 
					rounded
					@click 	= "Add" 
					small
				>
					<v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				</v-btn> &nbsp;
				<v-btn
					color		="primary"
					class		="white--text"
					:disabled = "access.add === 0 || access.edit === 0 ? true:false"
					rounded
					@click 	= "Confirm('write','')" 
					small
				>
					<v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				</v-btn>
			</v-col>
			<!-- ID -->
			<v-col cols="12" sm="2" md="2">
				<v-text-field
				v-model 	  	= "form.causeloss_id"
				required
				:rules 	  	="form.causeloss_id_rules"
				:readonly     = "form.causeloss_id_readonly"
				:background-color = "form.causeloss_id_readonly ? 'readonly' : ''"
				:maxlength 	="maxinput.id"
				:counter		="maxinput.id"
				label       	= "ID *"
				placeholder 	= "ID"
				id 			= "causeloss_id"
				@keyup.enter 	= "Confirm('write','')"
				>
				</v-text-field>
			</v-col>
			<!-- ID -->
			<v-col cols="12" sm="4" md="4">
				<v-text-field
				v-model 	  	= "form.causeloss_desc"
				required
				:rules        ="form.causeloss_desc_rules"
				:maxlength 	="maxinput.desc"
				label       	= "Description *"
				placeholder 	= "Description"
				id 			= "causeloss_desc"
				@keyup.enter 	= "Confirm('write','')"
				>
				</v-text-field>
			</v-col>
			<v-col cols="12" sm="1" md="1" class="mt-n5">
				<label class="caption">Own fault</label>
				<v-checkbox v-model="form.causeloss_ownfault" label=""></v-checkbox>
			</v-col>
			<!-- Active -->
			<v-col cols="12" sm="1" md="1" class="mt-n5">
			<label class="caption">Active Status</label>
			<v-switch
				v-model	="form.causeloss_actived"
				color  	= "primary"
				id 		= "form.causeloss_actived"
				:label 	="form.causeloss_actived !== true ? '' : 'Active'" 
			></v-switch>
			</v-col>
		</v-row>
		<v-row class="mx-2">
			<v-col cols="12" sm="5" md="5">
			<div v-show="url_type !== 'add' ? true:false ">
				<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
				<v-banner v-model="cont" single-line transition="slide-y-transition">
					<pre>
Created Date 	 : {{form.causeloss_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.causeloss_cuser)">{{form.causeloss_cuser}}</span>
Last Update Date : {{form.causeloss_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.causeloss_luser)">{{form.causeloss_luser}}</span>
</pre>
				</v-banner>
			</div>
			</v-col>
		</v-row>
		<v-row class="mx-2"  v-show="url_type !== 'add' ? true:false ">
			<v-tabs
				background-color="white"
				color="fourth"
				v-if ="access.read === 1"
			>
				<v-tab
					id      ="tabnatureofclaim"
					href   	="#tab-natureofclaim"	
					@click	="ListNoc(form.causeloss_id)"
				>
					Nature of Claim
				</v-tab>
				<v-tab-item
						value  ="tab-natureofclaim"
					>
				<template>
					<v-card-title 
						class="ml-n4"
					>
						<v-btn 
							:color 	="selected_causelossnoc.length === items_causelossnoc.length ? 'fourth':''"
							@click	="SelectAllPageNOC"
							class	="mr-2"
							:title="$vars.V('txt_select_all_page')"
							small
							>{{$vars.V('txt_select_all_page')}}
						</v-btn>
						<v-btn 
							color     = "error"
							title 	  = "delete selected"
							@click 	  = "ConfirmNOC('multidelete','')"
							class	  = "mr-2"
							v-if  	  = "selected_causelossnoc.length > 0"
							:disabled = "access.delete === 0 ? true:false"
							small>
							<v-icon>{{$vars.V('icon_delete')}}</v-icon>
						</v-btn>    
						<v-btn 
							@click	="AddNewNOC"
							class	="mr-2"
							title	="Add New"
							small
							>Add
						</v-btn>
						<v-btn 
							color  = "primary"
							title  = "search"
							v-if   = "btn_search_causelossnoc === false"
							@click = "btn_search_causelossnoc = true"
							small>
							<v-icon>{{$vars.V('icon_search')}}</v-icon>
						</v-btn>
						<v-spacer></v-spacer>
						<v-text-field
						v-model="search_causelossnoc"
						append-icon="search"
						@click:append="btn_search_causelossnoc = false"
						label="Search"
						single-line
						hide-details
						v-if ="btn_search_causelossnoc"
						></v-text-field>
					</v-card-title>
					<v-data-table
					:value="selected_causelossnoc"
					:headers="headers_causelossnoc"
					:items="items_causelossnoc"
					:search="search_causelossnoc"
					@input="CheckNOC($event)"
					item-key="causelossnoc_natureclaimid"
					show-select
					class="elevation-1"
					:footer-props="{
						showFirstLastPage: true,
						firstIcon: 'first_page',
						lastIcon: 'last_page',
						prevIcon: 'keyboard_arrow_left',
						nextIcon: 'keyboard_arrow_right'
					}"
					dense
					:loading 	= "loading_noc.list"
					loading-text="Loading... Please wait"
					>
					<template v-slot:item.action="{ item }">
						<v-icon
						small
						@click	  ="ConfirmNOC('delete',item.causelossnoc_natureclaimid)"
						:disabled = "access.delete === 0 ? true:false"
						title 	  = "delete"
						>
						delete
						</v-icon>
					</template>
					</v-data-table>
				</template>
				</v-tab-item>
				</v-tabs>
		</v-row>
		</v-form>
		</v-tab-item>
		<v-tab-item
			value="tab-list"
		>
		<template>
			<v-card-title 
					class="ml-n4"
				>
					<v-btn 
						:color 	="selected.length === items.length ? 'secondary':''"
						@click	="SelectAllPage"
						class	="mr-2"
						:title="$vars.V('txt_select_all_page')"
						small
						>{{$vars.V('txt_select_all_page')}}
					</v-btn>
					<v-btn 
						color     = "error"
						title 	  = "delete selected"
						@click 	  = "Confirm('multidelete','')"
						class	  = "mr-2"
						v-if  	  = "selected.length > 0"
						:disabled = "access.delete === 0 ? true:false"
						small>
						<v-icon>{{$vars.V('icon_delete')}}</v-icon>
					</v-btn>
					<v-btn 
						color  = "primary"
						title  = "search"
						v-if   = "btn_search === false"
						@click = "btn_search = true"
						small>
						<v-icon>{{$vars.V('icon_search')}}</v-icon>
					</v-btn>
					<v-spacer></v-spacer>
					<v-text-field
					v-model="search"
					append-icon="search"
					@click:append="btn_search = false"
					label="Search"
					single-line
					hide-details
					v-if ="btn_search"
					></v-text-field>
			</v-card-title>
			<v-data-table
				:value="selected"
				:headers="headers"
				:items="items"
				:search="search"
				@input="Check($event)"
				item-key="causeloss_id"
				show-select
				class="elevation-1"
				:footer-props="{
				showFirstLastPage: true,
				firstIcon: 'first_page',
				lastIcon: 'last_page',
				prevIcon: 'keyboard_arrow_left',
				nextIcon: 'keyboard_arrow_right'
				}"
				dense
				:loading 	= "loading.list"
				loading-text="Loading... Please wait"
			>
				<template v-slot:item.action="{ item }">
				<v-icon
					small
					class	  ="mr-2"
					@click	  ="Edit(item.causeloss_id)"
					:disabled = "access.edit === 0 ? true:false"
					title 	  = "edit"
				>
					mouse
				</v-icon>| 
				<v-icon
					small
					@click	  ="Confirm('delete',item.causeloss_id)"
					:disabled = "access.delete === 0 ? true:false"
					title 	  = "delete"
				>
					delete
				</v-icon>
				</template>
			</v-data-table>
		</template>
	</v-tab-item>
</v-tabs>
<v-dialog
	v-model="causelossnoc_dialog"
	max-width="400"
	transition="dialog-transition"
>
	<v-card>
		<v-toolbar dark color="primary" dense class="mb-2">
			<v-btn icon dark @click="causelossnoc_dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Nature of Loss</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn icon dark @click="AddNewNOC" title="Add New"><v-icon color="white">add</v-icon></v-btn>
			<v-btn icon dark @click="ConfirmNOC(url_type_noc, '')" title="Save"><v-icon color="white">save</v-icon></v-btn>
		</v-toolbar>
	<v-card-text>
		<v-row class="mx-auto mt-5">
				<app-cb
				cb_type             = "natureclaim"
				cb_url              = "apiListNatureClaim"
				cb_url_parameter    = "natureclaim_actived=Y"
				cb_title            = "Nature of Loss"
				cb_id               = "causelossnoc_nocid"
				cb_desc             = "causelossnoc_nocdesc"
				cb_rules            = "Please fill Nature of Loss"
				cb_desc_readonly    = "readonly"
				cb_items_id         = "natureclaim_id"
				cb_items_desc       = "natureclaim_desc"
				:cb_value_id        = "form.causelossnoc_nocid"
				:cb_value_desc      = "form.causelossnoc_nocdesc"
				>
				</app-cb>
		</v-row>
	</v-card-text>
	<v-card-actions>
		
	</v-card-actions>
	</v-card>
</v-dialog>
<app-snackbar :dialog="snackbar.dialog" :color="snackbar.color" :text="snackbar.text" :timeout="snackbar.timeout" @close="snackbar.dialog = false"></app-snackbar>
<v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
		<v-card :color="Variable('confirm',4)">
		<v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
		<v-card-text>
			
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn id="dialog" :color="Variable('confirm',1)"
				:disabled   = "confirm.text === 'Ok' ? false:true"
				@click      = "Loading">{{confirm.text}}</v-btn>
			<v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
<!-- Dialog Users -->
<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
<v-dialog v-model="confirm_noc.dialog" max-width="490" hide-overlay persistent>
	<v-card>
		<v-card-title class="primary white--text">{{confirm_noc.title}}?</v-card-title>
		<v-card-text>
			
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn id="dialog" color="primary"
					:disabled   = "confirm_noc.text === 'Ok' ? false:true"
					@click      = "LoadingNOC">{{confirm_noc.text}}</v-btn>
			<v-btn color="error" @click="confirm_noc.dialog=false">Close</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog>
</div>
</template>
<script>
export default {
data: () => ({
	url_type: 'add',
	url_type_noc: 'add',
	cont: false,
	access: {
		read: 0,
		add: 0,
		edit: 0,
		delete: 0,
		},
	maxinput: {
		id:	10,
		desc:150,
	},
	snackbar: {
		dialog: false,
		color: 'success',
		text: '',
		timeout: 3000
	},
	confirm: {
		dialog: false,
		title: '',
		text: 'Ok',
	},
	confirm_noc: {
		dialog: false,
		title: '',
		text: 'Ok',
	},
	causelossnoc_dialog: false,
	selected: [],
	selected_causelossnoc: [],
	search: '',
	search_causelossnoc: '',
	headers: [
		{
		text: 'ID',
		align: 'left',
		value: 'causeloss_id',
		},
		{ text: 'Description', value: 'causeloss_desc' },
		{ text: 'Actived', value: 'causeloss_actived' },
		{ text: 'Actions', value: 'action', sortable: false }
	],
	headers_causelossnoc: [
		{ text: 'Nature of Claim', value: 'causelossnoc_natureclaimid' },
		{ text: 'Desc', value: 'causelossnoc_natureclaimdesc' },
		{ text: 'Actions', value: 'action', sortable: false }
	],
	remarks: {
		feedback:'',
		dialog: false,
	},
	items: [],
	items_causelossnoc: [],
	accessmenu: [],
	loading: {
		page: true,
		list : false
	},
	loading_noc: {
		page: true,
		list : false
	},
	btn_search: true,
	btn_search_causelossnoc: false,
	form: {
		causeloss_id: '',
		causeloss_id_rules: [v => !!v || 'Please fill ID'],
		causeloss_id_readonly: false,
		causeloss_desc: '',
		causeloss_desc_rules: [v => !!v || 'Please fill Description'],
		causeloss_actived: true,
		causeloss_ownfault: false,
		display_users: '',
	},
	dialog: {
		users: false
	}
	}),
created () {
	this.FirstLoad()
},
methods: {
	FirstLoad () {
		//this function use for validating the screen with user privilleges
		this.Access()
	},
	//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
	Access () {
		let modul = 'causeloss'
		let formdata = {
			menu_id: modul,
			users_id: this.$functions.UsersID(),
			order_by: 'menu_id',
			order_type: 'ASC',
			limit: this.limit,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let priv = response.data
			if (priv.length > 0) {
				if (priv[0].feedback !== 'Y') {
					this.SnackBar(true, 'error', priv[0].feedback, 0)
				} else {
					this.access.read  = priv[0].usersmenu_read
					this.access.add   = priv[0].usersmenu_add
					this.access.edit    = priv[0].usersmenu_edit
					this.access.delete  = priv[0].usersmenu_delete
					if (priv[0].usersmenu_read === 0) {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
				}
			} else {
				this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
			}
			this.loading.page = false
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
			this.loading.page = false
		})
	},
	SelectAllPage () {
		this.selected = this.selected === this.items ? [] : this.items
	},
	SelectAllPageNOC () {
		this.selected_causelossnoc = this.selected_causelossnoc === this.items_causelossnoc ? [] : this.items_causelossnoc
	},
	Check (value) {
		this.selected = value
	},
	CheckNOC (value) {
		this.selected_causelossnoc = value
	},
	//Function for get all data from table channel with API in mod_users.go
	List () {
		this.loading.list = true
		let formdata = {
			causeloss_id: '',
			causeloss_desc: '',
			order_by: 'causeloss_id',
			order_type: 'ASC',
			limit: this.limit,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiListCauseLoss'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback !== 'Y') {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
				} else {
					this.items = feedback
				}
			} else {
				this.items = feedback
				this.SnackBar(true, 'error', this.$functions.NoData(), 0)
			}
			this.loading.list = false
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 3000)
			this.loading.list = false
		})
	},
	ListNOC (id) {
		this.loading_noc.list = true
		let formdata = {
			causelossnoc_causelossid: id,
			order_by: 'causelossnoc_causelossid',
			order_type: 'ASC',
			limit: this.limit,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiListCauseLossNoc'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback !== 'Y') {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
				} else {
					this.items_causelossnoc = feedback
				}
			} else {
				this.items_causelossnoc = feedback
				// this.SnackBar(true, 'error', this.$functions.NoData(), 0)
			}
			this.loading_noc.list = false
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 3000)
			this.loading_noc.list = false
		})
	},
	//Pop Up message for validating (Ok or Cancel) your proccess
	Confirm (flag, id) {
		if (flag === 'write') {
			if (this.$refs.form_causeloss.validate()) {
				this.confirm.dialog   = true
				this.confirm.title    = 'Save'
				setTimeout(function(){
				document.getElementById('dialog').focus()
				}, 500)
			}
		} else if (flag === 'delete') {
			this.url_type 		  = 'delete'
			this.confirm.dialog   = true
			this.confirm.title    = 'Delete `'+ id + '`'
			this.causeloss_id 	  = id
			setTimeout(function(){
			document.getElementById('dialog').focus()
			}, 500)
		} else if (flag === 'multidelete') {
			this.url_type 		  = 'multidelete'
			this.confirm.dialog   = true
			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
			setTimeout(function(){
			document.getElementById('dialog').focus()
			}, 500)
		}
	},
	ConfirmNOC (flag, id) {
		if (flag === 'write') {
			this.confirm_noc.dialog   	= true
			this.confirm_noc.title    	= 'Save'
			this.url_type_noc 			= 'add'
			setTimeout(function(){
			document.getElementById('dialog').focus()
			}, 500)
		} else if (flag === 'delete') {
			this.url_type_noc	  = 'delete'
			this.confirm_noc.dialog   = true
			this.confirm_noc.title    = 'Delete Nature Claim`'+ id + '` from Cause of Loss `' + this.form.causeloss_id +'`'
			this.causelossnoc_natureclaimid 	  = id
			setTimeout(function(){
			document.getElementById('dialog').focus()
			}, 500)
		} else if (flag === 'multidelete') {
			this.url_type_noc 		  = 'multidelete'
			this.confirm_noc.dialog   = true
			this.confirm_noc.title    = 'Delete `'+ this.selected_causelossnoc.length + '` data'
			setTimeout(function(){
			document.getElementById('dialog').focus()
			}, 500)
		}
	},	  
	//Function to request insert data to table channel 'The API will validating your data, include the user who process the data' (go to APIWriteClass with url_type = "add") 
	Add () {
		this.url_type 		 		    = 'add'
		this.form.causeloss_id 	 	    = ''
		this.form.causeloss_desc 	 	= ''
		this.form.causeloss_actived 	= true
		this.form.causeloss_ownfault 	= false
		this.form.causeloss_id_readonly = false
		setTimeout(function(){
			document.getElementById('causeloss_id').focus()
		}, 500)
	},
	//Function to request update data to table channel 'The API will validating your data, include the user who process the data' (go to APIWriteClass with url_type = "edit") 
	Edit (id) {
		this.loading.list = true
		let formdata = {
			causeloss_id: id,
			causeloss_desc: '',
			order_by: 'causeloss_id',
			order_type: 'ASC',
			limit: this.limit,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiListCauseLoss'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback === 'Y') {
					document.getElementById('tabform').click()
					this.url_type 			        = 'edit'
					this.form.causeloss_id   	    = feedback[0].causeloss_id
					this.form.causeloss_desc 	    = feedback[0].causeloss_desc
					this.form.causeloss_actived     = this.$functions.TrueOrFalse(feedback[0].causeloss_actived)
					this.form.causeloss_ownfault    = this.$functions.TrueOrFalse(feedback[0].causeloss_ownfault)
					this.form.causeloss_cdate		= feedback[0].causeloss_cdate
					this.form.causeloss_cuser		= feedback[0].causeloss_cuser
					this.form.causeloss_ldate		= feedback[0].causeloss_ldate
					this.form.causeloss_luser		= feedback[0].causeloss_luser
					this.form.causeloss_id_readonly  = true
					setTimeout(function(){
						document.getElementById('causeloss_desc').focus()
					}, 500)
					this.ListNOC(feedback[0].causeloss_id)
				} else {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
				}
			} else {
				this.SnackBar(true, 'error', feedback, 0)
			}
			this.loading.list = false
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
			this.loading.list = false
		})
	},
	Delete (id) {
		let formdata = {
			url_type: 'delete',
			causeloss_id: id
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiWriteCauseLoss'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback === 'Y') {
					this.SnackBar(true, 'success', 'Delete Success', 3000)
					this.confirm.dialog  	 = false
					this.confirm.text 	 	 = 'Ok'
					this.selected 			 = []
					this.List()
				} else {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
				}
			}
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
		})
	},
	DeleteNOC (id) {
		let formdata = {
			url_type: 'delete',
			causelossnoc_causelossid: this.form.causeloss_id,
			causelossnoc_natureclaimid: id
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiWriteCauseLossNoc'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback === 'Y') {
					this.SnackBar(true, 'success', 'Delete Success', 3000)
					this.confirm_noc.dialog  	= false
					this.confirm_noc.text 		= 'Ok'
					this.selected_causelossnoc 	= []
					this.ListNOC(this.form.causeloss_id)
				} else {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
				}
			}
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
		})
	},
	MultiProcess () {
		let multiData = JSON.stringify(this.selected)
		let formdata = {
			url_type: this.url_type,
			multi_data: multiData,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiWriteCauseLoss'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback[0].feedback === 'Y') {
				let feed
				if (feedback[0].feedback_failed == 0){
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
				} else {
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					this.SnackBar(true, 'error', feedback[0].feedback_failed_remarks, 0)
				}
				this.SnackBar(true, 'success', feed, 0)
				this.confirm.dialog  	 = false
				this.confirm.text 	 	 = 'Ok'
				this.selected 			 = []
				this.List()
			} else {
				this.SnackBar(true, 'error', feedback[0].feedback, 0)
			}
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
		})
	},
	MultiProcessNOC () {
		let multiData = JSON.stringify(this.selected_causelossnoc)
		let formdata = {
			url_type: this.url_type_noc,
			multi_data: multiData,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiWriteCauseLossNoc'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback[0].feedback === 'Y') {
				let feed
				if (feedback[0].feedback_failed == 0){
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
				} else {
					feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					this.SnackBar(true, 'error', feedback[0].feedback_failed_remarks, 0)
				}
				this.SnackBar(true, 'success', feed, 0)
				this.confirm_noc.dialog  	 = false
				this.confirm_noc.text 	 	 = 'Ok'
				this.selected_causelossnoc 	 = []
				this.ListNOC(this.form.causeloss_id)
			} else {
				this.SnackBar(true, 'error', feedback[0].feedback, 0)
			}
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
		})
	},
	Loading () {
		this.confirm.text = 'Loading...'
		if (this.url_type === 'add' || this.url_type === 'edit') {
			this.Write()
		} else if (this.url_type === 'delete') {
			this.Delete(this.causeloss_id)
		} else if (this.url_type === 'multidelete') {
			this.MultiProcess('delete')
		}
	},
	LoadingNOC () {
		this.confirm_noc.text = 'Loading...'
		if (this.url_type_noc === 'add' || this.url_type_noc === 'edit') {
			this.WriteNOC()
		} else if (this.url_type_noc === 'delete') {
			this.DeleteNOC(this.causeloss_id)
		} else if (this.url_type_noc === 'multidelete') {
			this.MultiProcessNOC('delete')
		}
	},
	Write () {
		let formdata = {
			url_type: this.url_type,
			causeloss_id: this.form.causeloss_id,
			causeloss_desc: this.form.causeloss_desc,
			causeloss_ownfault: this.$functions.ActivedFlag(this.form.causeloss_ownfault),
			causeloss_actived: this.$functions.ActivedFlag(this.form.causeloss_actived),
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiWriteCauseLoss'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback === 'Y') {
					this.SnackBar(true, 'success', 'Save Success', 3000)
					this.confirm.dialog  	 = false
					this.confirm.text 	 	 = 'Ok'
					if (this.url_type === 'add') {
						this.form.causeloss_cuser  = feedback[0].feedback_users_id
						this.form.causeloss_cdate  = feedback[0].feedback_users_date
					}
					this.form.causeloss_luser  = feedback[0].feedback_users_id
					this.form.causeloss_ldate  = feedback[0].feedback_users_date
					this.url_type 		   = 'edit'
				} else {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
				}
			} else {
				this.SnackBar(true, 'error', feedback, 0)
			}
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
		})
	},
	WriteNOC () {
		let natureclaimid = document.getElementById('causelossnoc_nocid').value
		let formdata = {
			url_type: this.url_type_noc,
			causelossnoc_causelossid: this.form.causeloss_id,
			causelossnoc_natureclaimid: natureclaimid,
		}
		let param 	   = this.$functions.ParamPOST(formdata)
		this.$axios.post(this.$functions.UrlPOST('apiWriteCauseLossNoc'),param,{
			headers: {
				'Content-Type': 'text/plain; charset=ISO-8859-1'
			}
		})
		.then(response => {
			let feedback = response.data
			if (feedback.length > 0) {
				if (feedback[0].feedback === 'Y') {
					this.SnackBar(true, 'success', 'Save Success', 3000)
					this.confirm_noc.dialog  	 = false
					this.confirm_noc.text 	 	 = 'Ok'
					if (this.url_type === 'add') {
						this.form.causeloss_cuser  = feedback[0].feedback_users_id
						this.form.causeloss_cdate  = feedback[0].feedback_users_date
					}
					this.ListNOC()
					this.form.causeloss_luser  = feedback[0].feedback_users_id
					this.form.causeloss_ldate  = feedback[0].feedback_users_date
					this.url_type_noc 		   = 'write'
				} else {
					this.SnackBar(true, 'error', feedback[0].feedback, 0)
					this.confirm_noc.dialog  	 = false
					this.confirm_noc.text 	 	 = 'Ok'
					
				}
			} else {
				this.SnackBar(true, 'error', feedback, 0)
			}
		})
		.catch(e => {
			this.SnackBar(true, 'error', e, 0)
		})
	},
	AddNewNOC() { 
		this.causelossnoc_dialog = true
		this.url_type_noc = 'write'
	},
	SnackBar (dialog, color, text, timeout) {
		this.snackbar = {
		dialog: dialog,
		color: color,
		text: text,
		timeout: timeout
		}
		this.confirm.dialog 	 = false
		this.confirm.text 	 	 = 'Ok'
	},
	ShowUsers (id) {
		this.dialog.users = true
		this.form.display_users = id
	},
	Variable (flag, position) {
		return this.$functions.Variable(flag,position)
	}
}
}
</script>