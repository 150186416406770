<template>
  <div>
    <app-drawer_menu drawer_menu_title="Reinsurance Treaty"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>
    <v-tabs background-color="white" color="fourth" v-if="access.read === 1">
      <v-tab id="tabform" href="#tab-form" @click="FirstLoad">
        Form
      </v-tab>
      <v-tab href="#tab-list" @click="List">
        List
      </v-tab>
      <v-tab-item value="tab-form">
        <!--Start Form Reinstreaty -->
        <v-form
          enctype="multipart/form-data"
          ref="form_reinstreaty"
          lazy-validation
        >
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="tertiary"
                class="white--text"
                :disabled="access.add === 0 ? true : false"
                rounded
                @click="Add"
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                :disabled="access.add === 0 || access.edit === 0 ? true : false"
                rounded
                @click="Confirm('write', '')"
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
              &nbsp;
              <v-btn
                color="primary"
                class="white--text"
                rounded
                small
                v-if="url_type !== 'add'"
              >
                <v-icon dark>bookmarks</v-icon>Copy Previous Year
              </v-btn>
            </v-col>
            <!-- Form Reinstreaty ID -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="form.reinstreaty_id"
                required
                :maxlength="maxinput.id"
                :counter="maxinput.id"
                label="ID *"
                placeholder="ID"
                id="reinstreaty_id"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Form Preference -->
            <v-col cols="12" sm="2" md="2">
              <v-text-field
                v-model="form.reinstreaty_refid"
                required
                label="Preference Treaty"
                placeholder="Preference Treaty"
                id="reinstreaty_refid"
                @keyup.enter="Confirm('write', '')"
              >
              </v-text-field>
            </v-col>
            <!-- Form Reins Type -->
            <v-col cols="12" sm="4" md="4" class="mt-4">
              <app-cb
                cb_type="reinstype"
                cb_url="apiListReinsType"
                cb_url_parameter="reinstype_actived=Y"
                cb_title="Reins Type"
                cb_id="reinstreaty_type"
                cb_desc="reinstreaty_typedesc"
                cb_rules="Please fill Reins Type"
                cb_items_id="reinstype_id"
                cb_items_desc="reinstype_desc"
                :cb_value_id="form.reinstreaty_type"
                :cb_value_desc="form.reinstreaty_typedesc"
                @clicked="GetReinsTreaty"
              >
            </app-cb>
            </v-col>
            <!-- Form Reins Type -->
            <v-col cols="12" sm="4" md="4" class="mt-4">
              <app-cb
                cb_type="parameter"
                cb_url="apiListParameter"
                cb_url_parameter="parameter_actived=Y"
                cb_title="Parameter"
                cb_id="reinstreaty_param"
                cb_desc="reinstreaty_param"
                cb_rules="Please fill Parameter"
                cb_items_id="parameter_id"
                cb_items_desc="parameter_desc"
                :cb_value_id="form.reinstreaty_param"
                :cb_value_desc="form.reinstreaty_paramdesc"
                @clicked="GetParameter"
              >
            </app-cb>
            </v-col>
            <!-- Form Reins Period Start Date-->
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="dialog_sdate"
                v-model="modal.sdate"
                transition="scale-transition"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.reinstreaty_sdate"
                    label="Start"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.reinstreaty_sdate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.sdate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_sdate.save(form.reinstreaty_sdate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <!-- Form Reins Period End Date-->
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="dialog_edate"
                v-model="modal.edate"
                transition="scale-transition"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="form.reinstreaty_edate"
                    label="End"
                    prepend-inner-icon="event"
                    background-color="date"
                    v-on="on"
                    v-mask="'####-##-##'"
                    placeholder="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.reinstreaty_edate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.edate = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog_edate.save(form.reinstreaty_edate)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
             <!-- Form Reins Category-->
            <v-col cols="12" sm="2" md="2" class="mt-4">
              <v-select
                v-model="form.reinstreaty_category"
                :items="form.reinstreaty_category_data"
                :item-text="form.reinstreaty_category_data.text"
                :item-value="form.reinstreaty_category_data.value"
                label="Category "
                placeholder="Category"
                @keyup.enter="Confirm('write', '')"
                class="caption"
                dense
              >
              </v-select>
            </v-col>
             <!-- Form Reins Cession-->
            <v-col cols="12" sm="2" md="2" class="mt-4">
              <v-select
                v-model="form.reinstreaty_cessionf"
                :items="form.reinstreaty_cessionf_data"
                :item-text="form.reinstreaty_cessionf_data.text"
                :item-value="form.reinstreaty_cessionf_data.value"
                label="Cession "
                placeholder="Cession"
                @keyup.enter="Confirm('write', '')"
                class="caption"
                dense
              >
              </v-select>
            </v-col>
             <!-- Form Reins Statement-->
            <v-col cols="12" sm="2" md="2" class="mt-4">
              <v-select
                v-model="form.reinstreaty_statementf"
                :items="form.reinstreaty_statementf_data"
                :item-text="form.reinstreaty_statementf_data.text"
                :item-value="form.reinstreaty_statementf_data.value"
                label="Reins Statment"
                placeholder="Statment"
                @keyup.enter="Confirm('write', '')"
                class="caption"
                dense
              >
              </v-select>
            </v-col>
             <!-- Form Reins Currency-->
             <v-col cols="12" sm="1" md="1" class="mt-3">
              <app-cb
                cb_type="currency"
                cb_url="apiListCurrency"
                cb_url_parameter="currency_actived=Y"
                cb_title="Currency"
                cb_id="reinstreaty_ccy"
                cb_desc="reinstreaty_ccydesc"
                cb_rules="Please fill Currency"
                cb_items_id="currency_id"
                cb_items_desc="currency_desc"
                :cb_value_id="form.reinstreaty_ccy"
                :cb_value_desc="form.reinstreaty_ccydesc"
                cb_single="Y"
              >
              </app-cb>
            </v-col>
            <!-- Form Reins Share -->
            <!-- <v-col cols="12" sm="4" md="1">
              <v-text-field
                v-model="form.reinstreaty_share"
                required
                label="Share"
                placeholder="0"
                background-color="numeric"
                @keyup.enter="Confirm('write', '')"
                reverse
              >
              </v-text-field>
            </v-col> -->
            <!-- Form Reins Share Of 1 -->
            <!-- <v-col cols="12" sm="4" md="1">
              <v-text-field
                v-model="form.reinstreaty_ofshare1"
                required
                label="Share Of"
                background-color="numeric"
                @keyup.enter="Confirm('write', '')"
                reverse
              >
              </v-text-field>
            </v-col> -->
            <!-- Form Reins Share Of 2 -->
            <!-- <v-col cols="12" sm="4" md="1">
              <v-text-field
                v-model="form.reinstreaty_ofshare2"
                required
                label="Share Of"
                background-color="numeric"
                @keyup.enter="Confirm('write', '')"
                reverse
              >
              </v-text-field>
            </v-col> -->
            <!-- Form Reins Year-->
            <v-col cols="12" sm="4" md="1">
              <v-text-field
                v-model="form.reinstreaty_year"
                required
                label="Share Year"
                background-color="numeric"
                @keyup.enter="Confirm('write', '')"
                v-mask="'####'"
                reverse
              >
              </v-text-field>
            </v-col>
            </v-row>
          <v-tabs
            background-color="white"
            color="secondary"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tab id="tabgeneral" href="#tab-general">
              General
            </v-tab>
            <v-tab
              v-if="url_type !== 'add'"
              id="tabsubclass"
              href="#tab-subclass"
              @click="ListSubClass(form.reinstreaty_id)"
            >
              Sub Class
            </v-tab>
            <v-tab
              v-if="url_type !== 'add'"
              id="tabparticipant"
              href="#tab-participant"
              @click="ListParticipant(form.reinstreaty_id)"
            >
              Participant
            </v-tab>
            <!-- Form general for reinstreaty -->
            <v-tab-item value="tab-general">
              <v-row class="mx-2 ma-1">
                <!-- Form Reins Cashloss-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_cashlosslimit"
                    required
                    label="Cash Loss Limit"
                    placeholder="Cash Loss Limit"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins PLA Limit-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_plalimit"
                    required
                    label="PLA Limit"
                    placeholder="PLA Limit"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins Claim Coop -->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_claimcoop"
                    required
                    label="Claim Coop"
                    placeholder="Claim Coop"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins Premium Income-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_estcontributioninc"
                    required
                    label="Premium Income"
                    placeholder="Premium Income"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins PML Basis-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_pmlbasis"
                    required
                    label="PML Basis"
                    placeholder="PML Basis"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins Claim Reserve Portfolio-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_claimresportfolio"
                    required
                    label="Claim Portfolio"
                    placeholder="Claim Portfolio"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins Premium Reserve Portfolio-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_contresportfolio"
                    required
                    label="Premium Portfolio"
                    placeholder="Premium Portfolio"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins Min Dep-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_mindeposit"
                    required
                    label="Minimum Deposit"
                    placeholder="Minimum Deposit"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins Burning Cost-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_burningcost"
                    required
                    label="Burning Cost"
                    placeholder="Burning Cost"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->

                <!-- Form Reins Ujroh Fee-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_ujrohfeereins"
                    required
                    label="Ujrohfeereins"
                    placeholder="Ujrohfeereins"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->

                <!-- Form Reins Ujrohfeecedant-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_ujrohfeecedant"
                    required
                    label="Ujrohfeecedant"
                    placeholder="Ujrohfeecedant"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->

                <!-- Form Reins waadsurplus-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_waadsurplus"
                    required
                    label="Waadsurplus"
                    placeholder="Waadsurplus"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!-- Form Reins eventlimit-->
                <!-- <v-col cols="12" sm="4" md="1" class="mt-4">
                  <v-text-field
                    v-model="form.reinstreaty_eventlimit"
                    required
                    label="Eventlimit"
                    background-color="numeric"
                    @keyup.enter="Confirm('write', '')"
                    reverse
                  >
                  </v-text-field>
                </v-col> -->
                <!--Form Reins Consortium -->
                <v-col cols="12" sm="4" md="2" class="mt-n2">
                  <label class="caption">Automated Obligatory</label>
                  <v-switch
                    v-model="form.reinstreaty_autofacobligf"
                    color="primary"
                    id="form.reinstreaty_autofacobligf"
                    :label="form.reinstreaty_autofacobligf !== true ? '' : 'Yes'"
                  ></v-switch>
                </v-col>
                 <!--Form Reins Consortium -->
                 <v-col cols="12" sm="4" md="1" class="mt-n2">
                  <label class="caption">Consortium</label>
                  <v-switch
                    v-model="form.reinstreaty_consortiumf"
                    color="primary"
                    id="form.reinstreaty_consortiumf"
                    :label="form.reinstreaty_consortiumf !== true ? '' : 'Yes'"
                  ></v-switch>
                </v-col>
                <!--Form Reins Actived -->
                <v-col cols="12" sm="4" md="1" class="mt-n2">
                  <label class="caption">Active Status</label>
                  <v-switch
                    v-model="form.reinstreaty_actived"
                    color="primary"
                    id="form.reinstreaty_actived"
                    :label="form.reinstreaty_actived !== true ? '' : 'Active'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- Start Data Table Reinstreaty Subclass-->
            <v-tab-item value="tab-subclass">
              <!-- Data Table Reinstreaty Sub Class -->
              <template>
                <!-- Main Button Reinstreaty Sub Class -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      subclass.selected.length === subclass.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllSubClass"
                    class="mr-2"
                    title="select all"
                    small
                    >select all
                  </v-btn>
                  <v-btn @click="OpenSubClass" class="mr-2" title="add" small
                    >Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    
                    class="mr-2"
                    v-if="subclass.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="subclass.searchbutton === false"
                    @click="SearchSubClass"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="subclass.search"
                    append-icon="search"
                    @click:append="subclass.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="subclass.searchbutton"
                    id="searchsubclass"
                    clearable
                    @keyup.enter="ListSubClass(form.reinstreaty_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Reinstreaty Sub Class  -->
                <v-spacer></v-spacer>
                <v-data-table
                  :value="subclass.selected"
                  :headers="subclass.headers"
                  :items="SubclassData"
                  :search="subclass.search"
                  @input="CheckSubClass($event)"
                  item-key="index"
                  show-select
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.subclass"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.reinstreatysubclass_guaranteevaluemin="{ item }">
                      {{$functions.NewFormatNumber(item.reinstreatysubclass_guaranteevaluemin,0)}} - {{$functions.NewFormatNumber(item.reinstreatysubclass_guaranteevaluemax,0)}}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditReinstreatySubClass(
                          item.reinstreatysubclass_id,
                          item.reinstreatysubclass_subclassid,
                          item.reinstreatysubclass_guaranteevaluemin,
                          item.reinstreatysubclass_guaranteevaluemax
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletereinstreatysubclass',
                          item.reinstreatysubclass_id,
                          item.reinstreatysubclass_subclassid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Reinstreaty SubClass -->
            </v-tab-item>
        <!-- Start Data Table Reinstreaty Participant-->
            <v-tab-item value="tab-participant">
              <!-- Data Table Reinstreaty Participant -->
              <template>
                <!-- Main Button Reinstreaty Participant -->
                <v-card-title class="ml-n4">
                  <v-btn
                    :color="
                      participant.selected.length === participant.items.length
                        ? 'fourth'
                        : ''
                    "
                    @click="SelectAllParticipant"
                    class="mr-2"
                    title="select all"
                    small
                    >select all
                  </v-btn>
                  <v-btn @click="OpenParticipant" class="mr-2" title="add" small
                    >Add
                  </v-btn>
                  <v-btn
                    color="error"
                    title="delete selected"
                    
                    class="mr-2"
                    v-if="participant.selected.length > 0"
                    :disabled="access.delete === 0 ? true : false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn
                    color="primary"
                    title="search"
                    v-if="participant.searchbutton === false"
                    @click="SearchParticipant"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="participant.search"
                    append-icon="search"
                    @click:append="participant.searchbutton = false"
                    label="Type and Enter"
                    single-line
                    hide-details
                    v-if="participant.searchbutton"
                    id="searchparticipant"
                    clearable
                    @keyup.enter="ListParticipant(form.reinstreaty_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Reinstreaty Sub Class  -->
                <v-spacer></v-spacer>
                <v-data-table
                  :value="participant.selected"
                  :headers="participant.headers"
                  :items="participant.items"
                  :search="participant.search"
                  @input="CheckParticipant($event)"
                  item-key="reinstreatyparticipant_id"
                  show-select
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right',
                  }"
                  dense
                  :loading="loading.participant"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="
                        EditReinstreatyParticipant(
                          item.reinstreatyparticipant_id,
                          item.reinstreatyparticipant_profileid
                        )
                      "
                      :disabled="access.edit === 0 ? true : false"
                      title="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="
                        Confirm(
                          'deletereinstreatyparticipant',
                          item.reinstreatyparticipant_id,
                          item.reinstreatyparticipant_profileid
                        )
                      "
                      :disabled="access.delete === 0 ? true : false"
                      title="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Reinstreaty Participant -->
            </v-tab-item>
          </v-tabs>
          <!-- Info -->
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true : false">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                <v-banner
                  v-model="cont"
                  single-line
                  transition="slide-y-transition"
                >
                  <pre>Created Date 	 : {{form.reinstreaty_cdate}}<br>Created User 	 : {{form.reinstreaty_cuser}}<br>Last Update Date : {{form.reinstreaty_ldate}}<br>Last Update User : {{form.reinstreaty_luser}}</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
          <!-- end: Info -->
        </v-form>
        <!-- end: Form Reinstreaty  -->
      </v-tab-item>
      <v-tab-item value="tab-list">
      <!-- Start Data Table Reinstreaty-->
        <template>
          <v-card-title class="ml-n4">
            <v-btn
              :color="selected.length === items.length ? 'secondary' : ''"
              @click="SelectAllPage"
              class="mr-2"
              title="select all page"
              small
              >{{$vars.V('txt_select_all_page')}}
            </v-btn>
            <v-btn
              color="error"
              title="delete selected"
              @click="Confirm('multidelete', '')"
              class="mr-2"
              v-if="selected.length > 0"
              :disabled="access.delete === 0 ? true : false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              title="search"
              v-if="btn_search === false"
              @click="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              @click:append="btn_search = false"
              label="Search"
              single-line
              hide-details
              v-if="btn_search"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :value="selected"
            :headers="headers"
            :items="items"
            :search="search"
            @input="Check($event)"
            item-key="reinstreaty_id"
            show-select
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right',
            }"
            dense
            :loading="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.reinstreaty_type="{ item }">
                {{ item.reinstreaty_type }} - {{ item.reinstreaty_typedesc }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="Edit(item.reinstreaty_id)"
                :disabled="access.edit === 0 ? true : false"
                title="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click="Confirm('delete', item.reinstreaty_id)"
                :disabled="access.delete === 0 ? true : false"
                title="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="primary"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}</v-btn
          >
          <v-btn color="error" @click="Close('confirm')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Write Reinstreaty SubClass -->
    <v-dialog
      v-model="dialog.subclass"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="550"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('subclass')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Reinstreaty Subclass</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="AddReinstreatySubClass"
            title="Add New Detail"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, '')"
            title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.subclassdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_reinstreatysubclass"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="12" md="12">
                <app-cb
                  cb_type="subclass"
                  cb_url="apiListSubClass"
                  cb_url_parameter="subclass_actived=Y"
                  cb_title="SubClass"
                  cb_id="reinstreatysubclass_subclassid"
                  cb_rules=""
                  cb_items_id="subclass_id"
                  cb_items_desc="subclass_desc"
                  :cb_value_id="form.reinstreatysubclass_subclassid"
                  :cb_value_desc="form.reinstreatysubclass_subclassiddesc"
                  @clicked="GetSubClass"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <vuetify-money
                v-model         ="form.reinstreatysubclass_cessionlimit"
                label           ="Cession Limit"
                placeholder     ="Cession Limit"
                backgroundColor ="numeric"
                :options        ="format_amount"
                :properties     ="properties"
              >
              </vuetify-money>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <vuetify-money
                v-model         ="form.reinstreatysubclass_cessioninexcess"
                label           ="Cession in Excess"
                placeholder     ="Cession in Excess"
                backgroundColor ="numeric"
                :options        ="format_amount"
                :properties     ="properties"
              >
              </vuetify-money>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="mt-n3">
                <vuetify-money
                v-model         ="form.reinstreatysubclass_guaranteevaluemin"
                label           ="Guaranteee Value Min"
                placeholder     ="Guaranteee Value Min"
                backgroundColor ="numeric"
                :options        ="format_amount"
                :properties     ="properties"
              >
              </vuetify-money>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="mt-n3">
                <vuetify-money
                v-model         ="form.reinstreatysubclass_guaranteevaluemax"
                label           ="Guaranteee Value Max"
                placeholder     ="Guaranteee Value Max"
                backgroundColor ="numeric"
                :options        ="format_amount"
                :properties     ="properties"
              >
              </vuetify-money>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <vuetify-money
                v-model         ="form.reinstreatysubclass_share"
                label           ="Share"
                placeholder     ="Share"
                backgroundColor ="numeric"
                :options        ="format_rate"
                :properties     ="properties"
              >
              </vuetify-money>
              </v-col>

              <!-- <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  v-model="form.reinstreatysubclass_eventlimit"
                  required
                  label="Event Limit"
                  placeholder="0"
                  background-color="numeric"
                  reverse
                >
                </v-text-field>
              </v-col> -->
            </v-row>
            <v-row class="mx-2 mt-2">
              <!-- <v-col cols="12" sm="4" md="4" class="mt-n3">
                <label class="caption">Colimit</label>
                <v-switch
                  v-model="form.reinstreatysubclass_colimitf"
                  color="primary"
                  id="form.reinstreatysubclass_colimitf"
                  :label="
                    form.reinstreatysubclass_colimitf !== true ? '' : 'Yes'
                  "
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <label class="caption">Table Limit</label>
                <v-switch
                  v-model="form.reinstreatysubclass_tablelimitf"
                  color="primary"
                  id="form.reinstreatysubclass_tablelimitf"
                  :label="
                    form.reinstreatysubclass_tablelimitf !== true ? '' : 'Yes'
                  "
                ></v-switch>
              </v-col> -->

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <label class="caption">Active</label>
                <v-switch
                  v-model="form.reinstreatysubclass_actived"
                  color="primary"
                  id="form.reinstreatysubclass_actived"
                  :label="
                    form.reinstreatysubclass_actived !== true ? '' : 'Active'
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Write Reinstreaty subclass -->

    <!--  Dialog Edit Reinstreaty subclass -->
    <v-dialog
      v-model="dialog.editreinstreatysubclass"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="550"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('subclass')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Reinstreaty Subclass</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="Confirm(url_type, '')"
            title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.subclassdialog"
          ></v-progress-linear>
          <v-form
            enctype="multipart/form-data"
            ref="form_edit_reinstreatysubclass"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="6" md="6">
                <app-cb
                  cb_type="subclass"
                  cb_url="apiListSubClass"
                  cb_url_parameter="subclass_actived=Y"
                  cb_title="SubClass"
                  cb_id="reinstreatysubclass_subclassid"
                  cb_rules=""
                  cb_items_id="subclass_id"
                  cb_items_desc="subclass_desc"
                  :cb_value_id="form.reinstreatysubclass_subclassid"
                  :cb_value_desc="form.reinstreatysubclass_subclassiddesc"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field 

                  v-model="form.reinstreatysubclass_cessionlimit"
                  required
                  label="Cession Limit"
                  placeholder="0"
                  background-color="numeric"
                  reverse
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.reinstreatysubclass_cessioninexcess"
                  required
                  label="Cession in Excess"
                  placeholder="0"
                  background-color="numeric"
                  reverse
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" class="mt-n3">
                <v-text-field
                  v-model="form.reinstreatysubclass_eventlimit"
                  required
                  label="Event Limit"
                  placeholder="0"
                  background-color="numeric"
                  reverse
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <label class="caption">Colimit</label>
                <v-switch
                  v-model="form.reinstreatysubclass_colimitf"
                  color="primary"
                  id="form.reinstreatysubclass_colimitf"
                  :label="
                    form.reinstreatysubclass_colimitf !== true ? '' : 'Yes'
                  "
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <label class="caption">Table Limit</label>
                <v-switch
                  v-model="form.reinstreatysubclass_tablelimitf"
                  color="primary"
                  id="form.reinstreatysubclass_tablelimitf"
                  :label="
                    form.reinstreatysubclass_tablelimitf !== true ? '' : 'Yes'
                  "
                ></v-switch>
              </v-col>

              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <label class="caption">Active</label>
                <v-switch
                  v-model="form.reinstreatysubclass_actived"
                  color="primary"
                  id="form.reinstreatysubclass_actived"
                  :label="
                    form.reinstreatysubclass_actived !== true ? '' : 'Active'
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- End Dialog Edit Reinstreaty Subclass-->

    <!-- Dialog write Reinstreaty participant -->
    <v-dialog
      v-model="dialog.participant"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="680"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="Close('participant')">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Reinstreaty Participant</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="AddReinstreatyParticipant"
            title="Add New Participant"
            ><v-icon color="white">add</v-icon></v-btn
          >
          <v-btn
            icon
            dark
            @click="Confirm(url_type, '')"
            title="Save Detail"
            ><v-icon color="white">save</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            :indeterminate="true"
            v-if="loading.participant"
          ></v-progress-linear>
          <!-- form Reinstreaty participant -->
          <v-form
            enctype="multipart/form-data"
            ref="form_reinstreatyparticipant"
            lazy-validation
          >
            <v-row class="mx-2 mt-2">
              <v-col cols="12" sm="12" md="12">
                <app-cb
                  cb_type="profile"
                  cb_url="apiListProfile"
                  cb_url_parameter="profile_actived=Y"
                  cb_title="Profile"
                  cb_id="reinstreatyparticipant_profileid"
                  cb_rules=""
                  cb_items_id="profile_id"
                  cb_items_desc="profile_name"
                  :cb_value_id="form.reinstreatyparticipant_profileid"
                  :cb_value_desc="form.reinstreatyparticipant_profileiddesc"
                  @clicked="GetParticipant"
                >
                </app-cb>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-text-field
                  v-model="form.reinstreatyparticipant_refno"
                  required
                  label="Refno"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <vuetify-money
                  v-model         ="form.reinstreatyparticipant_share"
                  label           ="Share"
                  placeholder     ="Share"
                  backgroundColor ="numeric"
                  :options        ="format_rate"
                  :properties     ="properties"
                >
                </vuetify-money>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <vuetify-money
                  v-model         ="form.reinstreatyparticipant_ricomm"
                  label           ="RI Commission"
                  placeholder     ="RI Commission"
                  backgroundColor ="numeric"
                  :options        ="format_rate"
                  :properties     ="properties"
                >
                </vuetify-money>
              </v-col>
              <v-col cols="12" sm="4" md="4" class="mt-n3">
                <v-switch
                  v-model="form.reinstreatyparticipant_actived"
                  color="primary"
                  id="form.reinstreatyparticipant_actived"
                  :label="
                    form.reinstreatyparticipant_actived !== true ? '' : 'Active'
                  "
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
          <!-- end: form Reinstreaty participant -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end: Dialog Wrie Reinstreaty participant -->

  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: "add",
    cont: false,
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: {
      reinstreatyid: 6,
      description: 225,
      percent: 5,
    },
    snackbar: {
      dialog: false,
      color: "primary",
      text: "",
      timeout: 3000,
    },
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    format_money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
    },
     // Format Month
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },

    // Format Rate
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2
    },

    // Format Properties
    properties: {
      reverse: true
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "ID",
        align: "left",
        value: "reinstreaty_id",
      },
      { text: "Ref ID", value: "reinstreaty_refid" },
      { text: "Type", value: "reinstreaty_type" },
      { text: "Year", value: "reinstreaty_year" },
      { text: "Actions", value: "action", sortable: false },
    ],
    remarks: {
      feedback: "",
      dialog: false,
    },
    items: [],
    accessmenu: [],
    loading: {
      page: true,
      list: false,
      detaildialog: false,
      treatylimit: false,
      treatylimitdialog: false,
      subclassdialog: false,
      subclass: false,
      participantdialog: false,
    },
    btn_search: true,
    modal: {
      sdate: false,
      edate: false,
    },
    form: {
      // reinstreaty
      reinstreaty_id: "",
      reinstreaty_id_rules: [(v) => !!v || "Please fill ID"],
      reinstreaty_refid: "",
      reinstreaty_type: "",
      reinstreaty_typedesc: "",
      reinstreaty_type_data: [],
      reinstreaty_year: "",
      reinstreaty_sdate: "",
      reinstreaty_edate: "",
      reinstreaty_share: 0,
      reinstreaty_ofshare1: 0,
      reinstreaty_ofshare2: 0,
      reinstreaty_category: "",
      reinstreaty_category_data: [{ text: "RISK", value: "R" }],
      reinstreaty_cessionf: "",
      reinstreaty_cessionf_data: [
        { text: "QUARTERLY", value: "Q" },
        { text: "DAILY", value: "D" },
        { text: "MONTHLY", value: "M" },
        { text: "HALF", value: "H" },
      ],
      reinstreaty_statementf: "",
      reinstreaty_statementf_data: [
        { text: "QUARTERLY", value: "Q" },
        { text: "DAILY", value: "D" },
        { text: "MONTHLY", value: "M" },
        { text: "HALF", value: "H" },
      ],
      reinstreaty_cashlosslimit: 0,
      reinstreaty_plalimit: 0,
      reinstreaty_claimcoop: 0,
      reinstreaty_estcontributioninc: 0,
      reinstreaty_pmlbasis: 0,
      reinstreaty_claimresportfolio: 0,
      reinstreaty_contresportfolio: 0,
      reinstreaty_mindeposit: 0,
      reinstreaty_ujrohfeereins: 0,
      reinstreaty_ujrohfeecedant: 0,
      reinstreaty_waadsurplus: 0,
      reinstreaty_eventlimit: 0,
      reinstreaty_ccy: "",
      reinstreaty_burningcost: 0,
      reinstreaty_consortiumf: false,
      reinstreaty_autofacobligf: false,
      reinstreaty_actived: true,
      reinstreaty_cdate: "",
      reinstreaty_cuser: "",
      reinstreaty_ldate: "",
      reinstreaty_luser: "",
      // sub class
      reinstreatysubclass_id: "",
      reinstreatysubclass_subclassid: "",
      reinstreatysubclass_subclassiddesc: "",
      reinstreatysubclass_subclassid_old: "",
      reinstreatysubclass_cessionlimit: 0,
      reinstreatysubclass_cessioninexcess: 0,
      reinstreatysubclass_eventlimit: 0,
      reinstreatysubclass_guaranteevaluemin: 0,
      reinstreatysubclass_guaranteevaluemax: 0,
      reinstreatysubclass_guaranteevaluemin_old: 0,
      reinstreatysubclass_guaranteevaluemax_old: 0,
      reinstreatysubclass_share: 0,
      reinstreatysubclass_colimitf: true,
      reinstreatysubclass_tablelimitf: true,
      reinstreatysubclass_actived: true,
      reinstreatysubclass_cdate: "",
      reinstreatysubclass_cuser: "",
      reinstreatysubclass_ldate: "",
      reinstreatysubclass_luser: "",
      // participant
      reinstreatyparticipant_id: "",
      reinstreatyparticipant_profileid: "",
      reinstreatyparticipant_profileidold: "",
      reinstreatyparticipant_refno: "",
      reinstreatyparticipant_share: 0,
      reinstreatyparticipant_ricomm: 0,
      reinstreatyparticipant_actived: "",
      reinstreatyparticipant_cdate: true,
      reinstreatyparticipant_cuser: "",
      reinstreatyparticipant_ldate: "",
      reinstreatyparticipant_luser: "",
    },

    attributes: false,
    flag: {
      subclass: false,
      participant: false,
      reinstreatysubclass: false,
      showcocessionlimit: false,
    },
    dialog: {
      attributes: false,
      subclass: false,
      participant: false,
      cocessionlimit: false,
    },
    // Data Table Subclass
    subclass: {
      headers: [
        {
          text: "ID",
          align: "left",
          value: "reinstreatysubclass_id",
        },
        { text: "Subclass ID", value: "reinstreatysubclass_subclassid" },
        { text: "Subclass Desc", value: "reinstreatysubclass_subclassiddesc" },
        { text: "Guarantee Value", value: "reinstreatysubclass_guaranteevaluemin" },
        { text: "Share", value: "reinstreatysubclass_share" },
        { text: "Action", value: "action" },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Subclass

    // Data Table Participant
    participant: {
      headers: [
        {
          text: "ID",
          align: "left",
          value: "reinstreatyparticipant_id",
        },
        { text: "Profile ID", value: "reinstreatyparticipant_profileid" },
        { text: "Profile Desc", value: "reinstreatyparticipant_profileiddesc" },
        { text: "Ref", value: "reinstreatyparticipant_refno" },
        { text: "Share", value: "reinstreatyparticipant_share" },
        { text: "RI Commission", value: "reinstreatyparticipant_ricomm" },
        { text: "Action", value: "action" },
      ],
      items: [],
      selected: [],
      search: "",
      searchbutton: false,
    },
    // end: Data Table Participant
  }),
  computed: {
  SubclassData() {
    return this.subclass.items.map((item, index) => {
      return {
        ...item,
        index: index + 1  // Menambahkan index (dimulai dari 1)
      };
    });
  }
  },
  created() {
    this.FirstLoad();
  },
  methods: {
    FirstLoad() {
      //this function use for validating the screen with user privilleges
      this.form.reinstreaty_ccy  = 'IDR'
      this.form.reinstreaty_year = this.$functions.TodayYear()
      this.Access();
    },
    //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
    Access() {
      let modul = "reinstreaty";
      let formdata = {
        menu_id: modul,
        users_id: this.$functions.UsersID(),
        order_by: "menu_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let priv = response.data;
          if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read;
            this.access.add = priv[0].usersmenu_add;
            this.access.edit = priv[0].usersmenu_edit;
            this.access.delete = priv[0].usersmenu_delete;
            if (priv[0].usersmenu_read === 0) {
              this.SnackBar(
                true,
                "error",
                this.$functions.NoPriviledge(modul),
                0
              );
            }
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.NoPriviledge(modul),
              0
            );
          }
          this.loading.page = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
          this.loading.page = false;
        });
    },
    SelectAllPage() {
      this.selected = this.selected === this.items ? [] : this.items;
    },
    Check(value) {
      this.selected = value;
    },
    //Function for get all data from table reinstreaty with API in mod_reinstreaty.go
    List() {
      this.loading.list = true;
      let formdata = {
        reinstreaty_id: "",
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListReinsTreaty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.items = feedback;
            }
          } else {
            this.items = feedback;
            this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.list = false;
        });
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm(flag, id, key1) {
      if (flag === "write") {
        if (this.$refs.form_reinstreaty.validate()) {
          if (this.form.reinstreaty_id !== '' && this.url_type === 'add') {
              this.url_type = 'add'
          } else {
            this.url_type = 'edit'
          }
          this.confirm.dialog = true;
          this.confirm.title = "Save";
          setTimeout(function() {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "delete") {
        this.url_type = "delete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + id + "`";
        this.reinstreaty_id = id;
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multidelete") {
        this.url_type = "multidelete";
        this.confirm.dialog = true;
        this.confirm.title = "Delete `" + this.selected.length + "` data";
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "addreinstreatysubclass") {
        if (this.$refs.form_reinstreatysubclass.validate()) {
          this.url_type = "addreinstreatysubclass";
          this.confirm.dialog = true;
          this.confirm.title = "Save Reinstreaty Subclass";
          setTimeout(function() {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "editreinstreatysubclass") {
        if (this.$refs.form_reinstreatysubclass.validate()) {
          this.url_type = "editreinstreatysubclass";
          this.confirm.dialog = true;
          this.confirm.title = "Save Reinstreaty Subclass";
          setTimeout(function() {
            document.getElementById("dialog").focus();
          }, 500);
        }
      } else if (flag === "deletereinstreatysubclass") {
        this.url_type = "deletereinstreatysubclass";
        this.confirm.dialog = true;
        this.confirm.title = "Delete Reinstreaty Subclass `" + id + "`";
        this.form.reinstreatysubclass_id = id;
        this.form.reinstreatysubclass_subclassid = key1;
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "multideletereinstreatysubclass") {
        this.url_type = "multideletereinstreatysubclass";
        this.confirm.dialog = true;
        this.confirm.title =
          "Delete `" + this.subclass.selected.length + "` data";
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      } else if (flag === "addreinstreatyparticipant") {
        if (this.$refs.form_reinstreatyparticipant.validate()) {
          this.url_type = "addreinstreatyparticipant";
          this.confirm.dialog = true;
          this.confirm.title = "Save Reinstreaty Participant";
          setTimeout(function() {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }else if (flag === "editreinstreatyparticipant") {
        if (this.$refs.form_reinstreatyparticipant.validate()) {
          this.url_type = "editreinstreatyparticipant";
          this.confirm.dialog = true;
          this.confirm.title = "Save Reinstreaty Participant";
          setTimeout(function() {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }else if (flag === "deletereinstreatyparticipant") {
        this.url_type = "deletereinstreatyparticipant";
        this.confirm.dialog = true;
        this.confirm.title = "Delete Reinstreaty Participant `" + id + "`";
        this.form.reinstreatyparticipant_id = id;
        this.form.reinstreatyparticipant_profileid = key1;
        setTimeout(function() {
          document.getElementById("dialog").focus();
        }, 500);
      }
    },

    //Function to request insert data to table Table Reinstreaty 'The API will validating your data, include the user who process the data' (go to APIWriteReinstreaty with url_type ="add")
    Add() {
      this.url_type = "add";
      this.form.reinstreaty_id = "";
      this.form.reinstreaty_refid = "";
      this.form.reinstreaty_type = "";
      this.form.reinstreaty_year = this.$functions.TodayYear();
      this.form.reinstreaty_sdate = "";
      this.form.reinstreaty_edate = "";
      this.form.reinstreaty_share = 0
      this.form.reinstreaty_ofshare1 = 0;
      this.form.reinstreaty_ofshare2 = 0;
      this.form.reinstreaty_category = "";
      this.form.reinstreaty_cessionf = "";
      this.form.reinstreaty_statementf = "";
      this.form.reinstreaty_cashlosslimit = "";
      this.form.reinstreaty_plalimit = "";
      this.form.reinstreaty_claimcoop = "";
      this.form.reinstreaty_estcontributioninc = "";
      this.form.reinstreaty_pmlbasis = "";
      this.form.reinstreaty_claimresportfolio = "";
      this.form.reinstreaty_contresportfolio = "";
      this.form.reinstreaty_mindeposit = "";
      this.form.reinstreaty_ujrohfeereins = "";
      this.form.reinstreaty_ujrohfeecedant = "";
      this.form.reinstreaty_waadsurplus = "";
      this.form.reinstreaty_eventlimit = "";
      this.form.reinstreaty_ccy = "";
      this.form.reinstreaty_burningcost = "";
      this.form.reinstreaty_consortiumf = true;
      this.form.reinstreaty_autofacobligf = true;
      this.form.reinstreaty_actived = true;
      setTimeout(function() {
        document.getElementById("reinstreaty_id").focus();
      }, 500);
    },
    //Function to request update data to table Table Limit 'The API will validating your data, include the user who process the data' (go to apiWriteReinstreaty url_type ="edit")
    Edit(id) {
      this.loading.list = true;

      // this.ListSubClass(id);

      let formdata = {
        reinstreaty_id: id,
        order_by: "reinstreaty_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListReinsTreaty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;

          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              document.getElementById("tabform").click();

              this.url_type = "edit";
              this.form.reinstreaty_id = feedback[0].reinstreaty_id
              this.form.reinstreaty_refid = feedback[0].reinstreaty_refid
              this.form.reinstreaty_type = feedback[0].reinstreaty_type
              this.form.reinstreaty_typedesc = feedback[0].reinstreaty_typedesc
              this.form.reinstreaty_year = feedback[0].reinstreaty_year
              this.form.reinstreaty_sdate = feedback[0].reinstreaty_sdate
              this.form.reinstreaty_edate = feedback[0].reinstreaty_edate
              this.form.reinstreaty_share = feedback[0].reinstreaty_share
              this.form.reinstreaty_ofshare1 = feedback[0].reinstreaty_ofshare1
              this.form.reinstreaty_ofshare2 = feedback[0].reinstreaty_ofshare2
              this.form.reinstreaty_category = feedback[0].reinstreaty_category
              this.form.reinstreaty_cessionf = feedback[0].reinstreaty_cessionf
              this.form.reinstreaty_statementf = feedback[0].reinstreaty_statementf;
              this.form.reinstreaty_cashlosslimit = feedback[0].reinstreaty_cashlosslimit;
              this.form.reinstreaty_plalimit = feedback[0].reinstreaty_plalimit
              this.form.reinstreaty_claimcoop = feedback[0].reinstreaty_claimcoop
              this.form.reinstreaty_estcontributioninc = feedback[0].reinstreaty_estcontributioninc
              this.form.reinstreaty_pmlbasis = feedback[0].reinstreaty_pmlbasis
              this.form.reinstreaty_claimresportfolio = feedback[0].reinstreaty_claimresportfolio
              this.form.reinstreaty_contresportfolio = feedback[0].reinstreaty_contresportfolio
              this.form.reinstreaty_mindeposit = feedback[0].reinstreaty_mindeposit
              this.form.reinstreaty_ujrohfeereins = feedback[0].reinstreaty_ujrohfeereins
              this.form.reinstreaty_ujrohfeecedant = feedback[0].reinstreaty_ujrohfeecedant;
              this.form.reinstreaty_waadsurplus = feedback[0].reinstreaty_waadsurplus;
              this.form.reinstreaty_eventlimit = feedback[0].reinstreaty_eventlimit;
              this.form.reinstreaty_actived = this.$functions.TrueOrFalse(
                feedback[0].reinstreaty_actived
              );
              this.form.reinstreaty_consortiumf = this.$functions.TrueOrFalse(
                feedback[0].reinstreaty_consortiumf
              );
              this.form.reinstreaty_autofacobligf = this.$functions.TrueOrFalse(
                feedback[0].reinstreaty_autofacobligf
              );
              this.form.reinstreaty_ccy = feedback[0].reinstreaty_ccy;
              this.form.reinstreaty_burningcost = feedback[0].reinstreaty_burningcost;
              this.form.reinstreaty_cuser = feedback[0].reinstreaty_cuser;
              this.form.reinstreaty_ldate = feedback[0].reinstreaty_ldate;
              this.form.reinstreaty_luser = feedback[0].reinstreaty_luser;

              // Set param Row
              this.form.treatylimit_tablelimitrow = id;
              this.form.reinstreatysubclass_id = id;

              setTimeout(function() {
                document.getElementById("reinstreaty_id").focus();
              }, 500);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.list = false;
        });
    },
    // function delete for reinstreaty with API apiWriteReinstreaty methode delete
    Delete(id) {
      let formdata = {
        url_type: "delete",
        reinstreaty_id: id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreaty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];
              this.List();
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    MultiProcess() {
      let multiData = JSON.stringify(this.selected);
      let formdata = {
        url_type: this.url_type,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreaty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "primary", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.selected = [];
            if (this.url_type === "multidelete") {
              this.List();
            } else if (this.url_type === "multideletedetail") {
              this.ListDetail();
              this.url_type = "edit";
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "multidelete") {
        this.MultiProcess();
      } else if (this.url_type === "addreinstreatysubclass") {
        this.WriteReinstreatySubclass();
      } else if (this.url_type === "editreinstreatysubclass") {
        this.WriteReinstreatySubclass();
      } else if (this.url_type === "deletereinstreatysubclass") {
        this.DeleteReinstreatySubclass(
          this.form.reinstreatysubclass_id,
          this.form.reinstreatysubclass_subclassid
        );
      } else if (this.url_type === "multideletereinstreatysubclass") {
        this.MultiProcessSubClass("multideletereinstreatysubclass");
      }else if (this.url_type === "addreinstreatyparticipant") {
        this.WriteReinstreatyParticipant();
      }else if (this.url_type === "editreinstreatyparticipant") {
        this.WriteReinstreatyParticipant();
      }else if (this.url_type === "deletereinstreatyparticipant") {
        this.DeleteReinstreatyParticipant(
          this.form.reinstreatyparticipant_id,
          this.form.reinstreatyparticipant_profileid
        );
      }
    },
    //write for reinstreaty
    Write() {
      let reinsccy = document.getElementById("reinstreaty_ccy").value;
      let reinstype = document.getElementById("reinstreaty_type").value;
      let formdata = {
        url_type: this.url_type,
        reinstreaty_id: this.form.reinstreaty_id,
        reinstreaty_refid: this.form.reinstreaty_refid,
        reinstreaty_type: reinstype,
        reinstreaty_year: this.form.reinstreaty_year,
        reinstreaty_sdate: this.form.reinstreaty_sdate,
        reinstreaty_edate: this.form.reinstreaty_edate,
        reinstreaty_share: String(this.form.reinstreaty_share),
        reinstreaty_ofshare1: String(this.form.reinstreaty_ofshare1),
        reinstreaty_ofshare2: String(this.form.reinstreaty_ofshare2),
        reinstreaty_category: this.form.reinstreaty_category,
        reinstreaty_cessionf: this.form.reinstreaty_cessionf,
        reinstreaty_statementf: this.form.reinstreaty_statementf,
        reinstreaty_cashlosslimit:String(this.form.reinstreaty_cashlosslimit),
        reinstreaty_plalimit: String(this.form.reinstreaty_plalimit),
        reinstreaty_claimcoop: String(this.form.reinstreaty_claimcoop),
        reinstreaty_estcontributioninc: String(this.form.reinstreaty_estcontributioninc),
        reinstreaty_pmlbasis: String(this.form.reinstreaty_pmlbasis),
        reinstreaty_claimresportfolio: String(this.form.reinstreaty_claimresportfolio),
        reinstreaty_contresportfolio: String(this.form.reinstreaty_contresportfolio),
        reinstreaty_mindeposit: String(this.form.reinstreaty_mindeposit),
        reinstreaty_ujrohfeereins: String(this.form.reinstreaty_ujrohfeereins),
        reinstreaty_ujrohfeecedant: String(this.form.reinstreaty_ujrohfeecedant),
        reinstreaty_waadsurplus: String(this.form.reinstreaty_waadsurplus),
        reinstreaty_eventlimit: String(this.form.reinstreaty_eventlimit),
        reinstreaty_param: this.form.reinstreaty_param,
        reinstreaty_ccy: reinsccy,
        reinstreaty_burningcost: String(this.form.reinstreaty_burningcost),
        reinstreaty_consortiumf: this.$functions.ActivedFlag(
          this.form.reinstreaty_consortiumf
        ),
        reinstreaty_autofacobligf: this.$functions.ActivedFlag(
          this.form.reinstreaty_autofacobligf
        ),
        reinstreaty_actived: this.$functions.ActivedFlag(
          this.form.reinstreaty_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreaty"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "add") {
                this.form.reinstreaty_cuser = feedback[0].feedback_users_id;
                this.form.reinstreaty_cdate = feedback[0].feedback_users_date;
              }
              this.form.reinstreaty_ldate = feedback[0].feedback_users_id;
              this.form.reinstreaty_luser = feedback[0].feedback_users_date;
              this.url_type = "edit";
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      };
      if (color === "error") {
        this.confirm.dialog = false;
        this.confirm.text = "Ok";
      }
    },
    Close(flag) {
      if (flag === "confirm") {
        this.confirm.dialog = false;
        if (this.url_type !== "add") {
          this.url_type = "edit";
        }
      } else if (flag === "subclass") {
        this.dialog.subclass = false;
      } else if (flag === "participant") {
        this.dialog.participant = false;
      }
    },
    Search() {
      this.detail.searchbutton = true;
      setTimeout(function() {
        document.getElementById("searchdetail").focus();
      }, 500);
    },
    // open dialog subclass
    OpenSubClass() {
      this.dialog.subclass = true;
      this.AddReinstreatySubClass();
    },
    // add reinstreaty subclass
    AddReinstreatySubClass() {
      this.url_type = "addreinstreatysubclass";
      this.form.reinstreatysubclass_subclassid = "";
      this.form.reinstreatysubclass_subclassiddesc = "";
      this.form.reinstreatysubclass_cessionlimit = 0;
      this.form.reinstreatysubclass_cessioninexcess = 0;
      this.form.reinstreatysubclass_guaranteevaluemin = 0;
      this.form.reinstreatysubclass_guaranteevaluemax = 0;
      this.form.reinstreatysubclass_share = 0;
      this.form.reinstreatysubclass_eventlimit = 0;
      this.form.reinstreatysubclass_colimitf = true;
      this.form.reinstreatysubclass_tablelimitf = true;
      this.form.reinstreatysubclass_actived = true;
      this.dialog.subclass = true;
    },
    //select all item datatable subclass
    SelectAllSubClass() {
      this.subclass.selected =
        this.subclass.selected === this.subclass.items
          ? []
          : this.subclass.items;
    },
    // check value selected datatable subclass
    CheckSubClass(value) {
      this.subclass.selected = value;
    },
    // search reinstreaty subclass
    SearchSubClass() {
      this.subclass.searchbutton = true;
      setTimeout(function() {
        document.getElementById("searchsubclass").focus();
      }, 1000);
    },
    // list data for reinstreaty sub class
    ListSubClass(id) {
      this.loading.subclass = true;
      let formdata = {
        reinstreatysubclass_id: id,
        order_by: "reinstreatysubclass_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListReinsTreatySubClass"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.subclass.items = feedback;
            }
          } else {
            this.subclass.items = feedback;
            // this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.subclass = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.subclass = false;
        });
    },
    MultiProcessSubClass() {
      let reins_id = document.getElementById("reinstreaty_id").value;
      let multiData = JSON.stringify(this.subclass.selected);
      let formdata = {
        url_type: "multideletereinstreatysubclass",
        reinstreatysubclass_id: this.form.reinstreatysubclass_id,
        multi_data: multiData,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreatySubClass"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback[0].feedback === "Y") {
            let feed;
            if (feedback[0].feedback_failed == 0) {
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
            } else {
              this.remarks.feedback = feedback[0].feedback_failed_remarks;
              feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`;
              this.remarks.dialog = true;
            }
            this.SnackBar(true, "primary", feed, 0);
            this.confirm.dialog = false;
            this.confirm.text = "Ok";
            this.subclass.selected = [];
            if (this.url_type === "multideletereinstreatysubclass") {
              this.ListSubClass(reins_id);
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback[0].feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // function for write reinstreaty subclass
    WriteReinstreatySubclass() {
      let sub_class = document.getElementById("reinstreatysubclass_subclassid").value;
      let reins_id = document.getElementById("reinstreaty_id").value;
      let formdata = {
        url_type: this.url_type,
        reinstreatysubclass_id: reins_id,
        reinstreatysubclass_subclassid: sub_class,
        reinstreatysubclass_subclassid_old: this.form.reinstreatysubclass_subclassid_old,
        reinstreatysubclass_cessionlimit: String(this.form.reinstreatysubclass_cessionlimit),
        reinstreatysubclass_cessioninexcess: String(this.form.reinstreatysubclass_cessioninexcess),
        reinstreatysubclass_eventlimit: String(this.form.reinstreatysubclass_eventlimit),
        reinstreatysubclass_guaranteevaluemin: String(this.form.reinstreatysubclass_guaranteevaluemin),
        reinstreatysubclass_guaranteevaluemax: String(this.form.reinstreatysubclass_guaranteevaluemax),
        reinstreatysubclass_guaranteevaluemin_old: String(this.form.reinstreatysubclass_guaranteevaluemin_old),
        reinstreatysubclass_guaranteevaluemax_old: String(this.form.reinstreatysubclass_guaranteevaluemax_old),
        reinstreatysubclass_share: String(this.form.reinstreatysubclass_share),
        reinstreatysubclass_colimitf: this.$functions.ActivedFlag(
          this.form.reinstreatysubclass_colimitf
        ),
        reinstreatysubclass_tablelimitf: this.$functions.ActivedFlag(
          this.form.reinstreatysubclass_tablelimitf
        ),
        reinstreatysubclass_actived: this.$functions.ActivedFlag(
          this.form.reinstreatysubclass_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreatySubClass"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addreinstreatysubclass") {
                this.form.reinstreatysubclass_cuser =
                  feedback[0].feedback_users_id;
                this.form.reinstreatysubclass_cdate =
                  feedback[0].feedback_users_date;
              }
              this.form.reinstreatysubclass_ldate =
                feedback[0].feedback_users_id;
              this.form.reinstreatysubclass_luser =
                feedback[0].feedback_users_date;
              this.url_type = "editreinstreatysubclass";
              this.ListSubClass(reins_id)
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    EditReinstreatySubClass(id, subclassid, min, max) {
      this.dialog.subclass = true;
      this.loading.subclassdialog = true;

      let formdata = {
        reinstreatysubclass_id: id,
        reinstreatysubclass_subclassid: subclassid,
        reinstreatysubclass_guaranteevaluemin: min,
        reinstreatysubclass_guaranteevaluemax: max,
        order_by: "reinstreatysubclass_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListReinsTreatySubClass"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editreinstreatysubclass";
              this.form.reinstreatysubclass_id = feedback[0].reinstreatysubclass_id
              this.form.reinstreatysubclass_subclassid = feedback[0].reinstreatysubclass_subclassid
              this.form.reinstreatysubclass_cessionlimit = feedback[0].reinstreatysubclass_cessionlimit
              this.form.reinstreatysubclass_cessioninexcess = feedback[0].reinstreatysubclass_cessioninexcess
              this.form.reinstreatysubclass_eventlimit = feedback[0].reinstreatysubclass_eventlimit
              this.form.reinstreatysubclass_guaranteevaluemin = feedback[0].reinstreatysubclass_guaranteevaluemin
              this.form.reinstreatysubclass_guaranteevaluemax = feedback[0].reinstreatysubclass_guaranteevaluemax
              this.form.reinstreatysubclass_guaranteevaluemin_old = feedback[0].reinstreatysubclass_guaranteevaluemin
              this.form.reinstreatysubclass_guaranteevaluemax_old = feedback[0].reinstreatysubclass_guaranteevaluemax
              this.form.reinstreatysubclass_share = feedback[0].reinstreatysubclass_share
              this.form.reinstreatysubclass_subclassid_old = feedback[0].reinstreatysubclass_subclassid
              this.form.reinstreatysubclass_colimitf = this.$functions.YesOrNo(
                feedback[0].reinstreatysubclass_colimitf
              );
              this.form.reinstreatysubclass_tablelimitf = this.$functions.YesOrNo(
                feedback[0].reinstreatysubclass_tablelimitf
              );
              this.form.reinstreatysubclass_actived = this.$functions.YesOrNo(
                feedback[0].reinstreatysubclass_actived
              );

              this.form.reinstreatysubclass_cdate =
                feedback[0].reinstreatysubclass_cdate;
              this.form.reinstreatysubclass_cuser =
                feedback[0].reinstreatysubclass_cuser;
              this.form.reinstreatysubclass_ldate =
                feedback[0].reinstreatysubclass_ldate;
              this.form.reinstreatysubclass_luser =
                feedback[0].reinstreatysubclass_luser;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.subclassdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.subclassdialog = false;
        });
    },
    // function for delete data reinstreaty subclass
    DeleteReinstreatySubclass(id, subclass) {
      let reins_id = document.getElementById("reinstreaty_id").value;
      let formdata = {
        url_type: "deletereinstreatysubclass",
        reinstreatysubclass_id: id,
        reinstreatysubclass_subclassid: subclass,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreatySubClass"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];

              this.ListSubClass(reins_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    //open dialog participant
    OpenParticipant() {
      this.dialog.participant = true;
      this.AddReinstreatyParticipant();
    },
    AddReinstreatyParticipant() {
      this.url_type = "addreinstreatyparticipant";
      this.form.reinstreatyparticipant_id = "";
      this.form.reinstreatyparticipant_profileid = "";
      this.form.reinstreatyparticipant_refno = "";
      this.form.reinstreatyparticipant_share = 0;
      this.form.reinstreatyparticipant_ricomm = 0;
      this.form.reinstreatyparticipant_actived = true;
      this.dialog.participant = true;
    },
    // select all participant from datatable participant
    SelectAllParticipant() {
      this.participant.selected =
        this.participant.selected === this.participant.items
          ? []
          : this.participant.items;
    },
    // check value selected from datatable participant
    CheckParticipant(value) {
      this.participant.selected = value;
    },
    // search reinstreaty participant
    SearchParticipant() {
      this.participant.searchbutton = true;
      setTimeout(function() {
        document.getElementById("searchparticipant").focus();
      }, 1000);
    },
    //list for reinstreaty participant
    ListParticipant(id) {
      this.loading.participant = true;
      let formdata = {
        reinstreatyparticipant_id: id,
        order_by: "reinstreatyparticipant_id",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListReinsTreatyParticipant"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback !== "Y") {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            } else {
              this.participant.items = feedback;
            }
          } else {
            this.participant.items = feedback;
            // this.SnackBar(true, "error", this.$functions.NoData(), 0);
          }
          this.loading.participant = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 3000);
          this.loading.participant = false;
        });
    },
    // function for write reinstreaty participant
    WriteReinstreatyParticipant() {
      let formdata = {
        url_type: this.url_type,
        reinstreatyparticipant_id: this.form.reinstreaty_id,
        reinstreatyparticipant_profileidold: this.form.reinstreatyparticipant_profileidold,
        reinstreatyparticipant_profileid: this.form.reinstreatyparticipant_profileid,
        reinstreatyparticipant_refno: this.form.reinstreatyparticipant_refno,
        reinstreatyparticipant_share: String(this.form.reinstreatyparticipant_share),
        reinstreatyparticipant_ricomm: String(this.form.reinstreatyparticipant_ricomm),
        reinstreatyparticipant_actived: this.$functions.ActivedFlag(
          this.form.reinstreatyparticipant_actived
        ),
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreatyParticipant"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Save Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              if (this.url_type === "addreinstreatyparticipant") {
                this.form.reinstreatyparticipant_cuser = feedback[0].feedback_users_id;
                this.form.reinstreatyparticipant_cdate = feedback[0].feedback_users_date;
              }
              this.form.reinstreatysubclass_ldate = feedback[0].feedback_users_id;
              this.form.reinstreatysubclass_luser = feedback[0].feedback_users_date;
              this.url_type = "editreinstreatyparticipant";
              this.ListParticipant(this.form.reinstreaty_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    //edit participant
    EditReinstreatyParticipant(id, profileid) {
      this.dialog.participant = true;
      this.loading.participantdialog = true;

      let formdata = {
        reinstreatyparticipant_id: id,
        reinstreatyparticipant_profileid: profileid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListReinsTreatyParticipant"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.url_type = "editreinstreatyparticipant";
              this.form.reinstreatyparticipant_id = feedback[0].reinstreatyparticipant_id;
              this.form.reinstreatyparticipant_profileid = feedback[0].reinstreatyparticipant_profileid;
              this.form.reinstreatyparticipant_profileiddesc = feedback[0].reinstreatyparticipant_profileiddesc;
              this.form.reinstreatyparticipant_refno = feedback[0].reinstreatyparticipant_refno;
              this.form.reinstreatyparticipant_share = feedback[0].reinstreatyparticipant_share;
              this.form.reinstreatyparticipant_ricomm = feedback[0].reinstreatyparticipant_ricomm;
              this.form.reinstreatyparticipant_profileidold = feedback[0].reinstreatyparticipant_profileid;
              this.form.reinstreatyparticipant_actived = this.$functions.YesOrNo(
                feedback[0].reinstreatyparticipant_actived
              );

              this.form.reinstreatyparticipant_cdate = feedback[0].reinstreatyparticipant_cdate;
              this.form.reinstreatyparticipant_cuser = feedback[0].reinstreatyparticipant_cuser;
              this.form.reinstreatyparticipant_ldate = feedback[0].reinstreatyparticipant_ldate;
              this.form.reinstreatyparticipant_luser = feedback[0].reinstreatyparticipant_luser;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
          this.loading.participantdialog = false;
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.participantdialog = false;
        });
    },
     // function for delete data reinstreaty participant
    DeleteReinstreatyParticipant(id, profid) {
      let reins_id = document.getElementById("reinstreaty_id").value;
      let formdata = {
        url_type: "deletereinstreatyparticipant",
        reinstreatyparticipant_id: id,
        reinstreatyparticipant_profileid: profid,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteReinsTreatyParticipant"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Delete Success", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.selected = [];

              this.ListParticipant(reins_id);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    GetReinsTreaty(value) {
      let splitdata = value.split("|")
      this.form.reinstreaty_type = splitdata[0]
      this.form.reinstreaty_typedesc = splitdata[1]
    },
    GetParameter(value) {
      let splitdata = value.split("|")
      this.form.reinstreaty_param = splitdata[0]
      this.form.reinstreaty_paramdesc = splitdata[1]
    },
    GetSubClass(value) {
      let splitdata = value.split("|")
      this.form.reinstreatysubclass_subclassid = splitdata[0]
      this.form.reinstreatysubclass_subclassiddesc = splitdata[1]
    },
    GetParticipant(value) {
      let splitdata = value.split("|")
      this.form.reinstreatyparticipant_profileid = splitdata[0]
      this.form.reinstreatyparticipant_profileiddesc = splitdata[1]
    }
  },
};
</script>
