<template>
	<div>
		<app-drawer_menu drawer_menu_title="My Account"></app-drawer_menu>
		<v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
		<!-- Show information Profile from Login -->
		<v-card>
			<v-card-actions>
				<v-list-item class="grow">
					<v-list-item-avatar color="grey darken-3">
					<v-img
						class="elevation-6"
						alt=""
						:src="form.avatar"
					></v-img>
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{ form.name }}</v-list-item-title>
					</v-list-item-content>
					<v-row
					align="center"
					justify="end"
					>
					<v-btn
						depressed
						small
						class="primary--text"
						@click="dialog.myaccount = true"
						>VIEW</v-btn>
					</v-row>
				</v-list-item>
			</v-card-actions>
			<v-card-text>
				<v-container 
				fluid
				class ="mt-n5">
					<v-row dense>
						<v-col
						v-for="data in subheader"
						:key="data.title"
						:cols="data.flex">
							<div>{{ data.title }}<br>{{ data.subtitle }}</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
		<!-- Tabular Information Based on Login -->
		<v-row
			no-gutters
			class ="mt-3"
			>
			<v-col
				cols="12"
				md	="8"
				sm	="8"
			>
				<v-card>
					<!-- Tabular -->
					<v-tabs v-model ="tab" background-color="white">
						<v-tabs-slider></v-tabs-slider>
						<!-- Show Activity from -->
						<v-tab href="#tab-1">
							<v-icon>mdi-bell-outline</v-icon> Notifications
						</v-tab>
						<!-- Show Pending Items -->
						<v-tab href="#tab-2">
							<v-icon>mdi-clock-time-four-outline</v-icon> Pending
						</v-tab>
						<!-- Show Activity Logs -->
						<v-tab href="#tab-3" @click="DetailLogs">
							<v-icon>mdi-history</v-icon> Activity Logs
						</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab">
						<v-tab-item value="tab-1">
							<v-card flat>
								<v-card-text class="mt-n5">
									<v-text-field
										v-model="notifsearch"
										append-icon="search"
										label="Search"
										single-line
										hide-details
										clearable
										></v-text-field>
								</v-card-text>
								<v-data-table
									:headers="notifheaders"
									:items="notifitems"
									:search="notifsearch"
									item-key="notification_id"
									class="elevation-1"
									:footer-props="{
										showFirstLastPage: true,
										firstIcon: 'first_page',
										lastIcon: 'last_page',
										prevIcon: 'keyboard_arrow_left',
										nextIcon: 'keyboard_arrow_right'
									}"
									dense
									:loading="loading.listnotif"
									loading-text="Loading... Please wait"
								>
								<template v-slot:item.notification_from="{ item }">
									{{item.notification_from}} @{{item.notification_cdate}}
								</template>
								</v-data-table>
							</v-card>
						</v-tab-item>
						<v-tab-item value="tab-2">
							<v-card flat>
								<v-card-text>Data 2</v-card-text>
							</v-card>
						</v-tab-item>
						<!-- Data Table Logs -->
						<v-tab-item value="tab-3">
							<v-card flat>
								<v-card-text class="mt-n5">
									<v-text-field
										v-model="logssearch"
										append-icon="search"
										label="Search"
										single-line
										hide-details
										clearable
										></v-text-field>
								</v-card-text>
								<v-data-table
									:headers="logsheaders"
									:items="logsitems"
									:search="logssearch"
									item-key="logs_id"
									class="elevation-1"
									:footer-props="{
										showFirstLastPage: true,
										firstIcon: 'first_page',
										lastIcon: 'last_page',
										prevIcon: 'keyboard_arrow_left',
										nextIcon: 'keyboard_arrow_right'
									}"
									dense
									:loading="loading.listlogs"
									loading-text="Loading... Please wait"
								>
								<template v-slot:item.logs_id="{ item }">
									{{item.logs_id}} @{{item.logs_cdate}}
								</template>
								</v-data-table>
							</v-card>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-col>
			<!-- Todo List -->
			<v-col
				cols ="12"
				md	 ="4"
				sm   ="4"
			>
				<v-card
					tile
					class ="ml-3"
				>
					<v-list>
						<v-list-item link>
						<v-list-item-content>
							<v-list-item-title class="title">
							Todo
							</v-list-item-title>
						</v-list-item-content>

						<v-list-item-action>
							<v-icon>mdi-menu-down</v-icon>
						</v-list-item-action>
						</v-list-item>
					</v-list>
					<v-divider></v-divider>
					<v-list
						nav
						dense
					>
						<v-list-item-group
						v-model="selectedItem"
						color="primary"
						>
						<v-list-item
							v-for="(item, i) in todos"
							:key="i"
						>
							<v-list-item-icon>
							<v-icon v-text="item.icon"></v-icon>
							</v-list-item-icon>

							<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-card>
			</v-col>
		</v-row>
		<v-dialog
		v-model="dialog.myaccount"
		scrollable
		persistent
		transition="dialog-bottom-transition"
		max-width="690" 
		>
		<v-card>
			<v-progress-linear
			indeterminate
			color="fourth"
			rounded
			v-if="loading.myaccount"
			></v-progress-linear>
			<v-toolbar dark color="secondary" dense>
				<v-btn icon dark @click="dialog.myaccount = false">
					<v-icon>{{$vars.V('icon_close')}}</v-icon>
				</v-btn>
				<v-toolbar-title>My Account</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon dark>
					<v-icon>mdi-refresh</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			<template  v-if="detailusers.length > 0">
			<!-- Form Edit Account -->
			<v-form enctype="multipart/form-data" ref="form_editaccount" lazy-validation>
				<v-row>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="ID"
						:value       ="detailusers[0].users_id"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Full Name"
						:value       ="detailusers[0].users_name"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Title"
						:value       ="detailusers[0].users_title"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Branch"
						:value       ="detailusers[0].users_branchdesc"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Email"
						:value       ="detailusers[0].users_email"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Mobile Phone"
						:value       ="detailusers[0].users_mobile_1"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Department"
						:value       ="detailusers[0].users_departmentdesc"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Leader"
						:value       ="detailusers[0].users_leader"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
					<v-col cols="12" sm="12" md="6">
						<v-text-field
						label		 ="Employee Status"
						:value       ="detailusers[0].users_employeestatus"
						:readonly	 = "true"
						dense
						>
						</v-text-field>
					</v-col>
				</v-row>
			</v-form>
			</template>
			</v-card-text>
		</v-card>
		</v-dialog>
	</div>
</template>
<script>
  export default {
    data: () => ({
		subheader: [
			{ title: 'TITLE', subtitle: '', flex: 4 },
			{ title: 'JOIN DATE', subtitle: '', flex: 4 },
			{ title: 'TYPE', subtitle: '', flex: 4 },
		],
		tab: null,
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		selectedItem: 0,
		todos: [
			{ text: 'My Files', icon: 'mdi-folder' },
			{ text: 'Shared with me', icon: 'mdi-account-multiple' },
			{ text: 'Starred', icon: 'mdi-star' },
			{ text: 'Recent', icon: 'mdi-history' },
			{ text: 'Offline', icon: 'mdi-check-circle' },
			{ text: 'Uploads', icon: 'mdi-upload' },
			{ text: 'Backups', icon: 'mdi-cloud-upload' },
		],
	  	snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
		confirm: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		loading: {
          page: true,
          list: false,
		  listlogs: false,
		  myaccount: false
		},
		form: {
			id: '',
			avatar: '',
			name: ''
		},
		dialog: {
			myaccount: false
		},
		logsitems: [],
		logsheaders: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'logs_id',
          },
          { text: 'Action', value: 'logs_action' },
          { text: 'Table', value: 'logs_table' },
          { text: 'Change', value: 'logs_change' },
        //   { text: 'Actions', value: 'action', sortable: false }
		],
		logssearch: '',
		notifitems: [],
		notifheaders: [
          {
            text: 'From',
            align: 'left',
            sortable: false,
            value: 'notification_from',
          },
          { text: 'Message', value: 'notification_desc' },
          { text: 'Modul', value: 'notification_desc' },
        //   { text: 'Actions', value: 'action', sortable: false }
		],
		notifsearch: '',
		detailusers: []
    }),
	created () {
		this.FirstLoad()
	},
	methods: {
		FirstLoad () {
			// Load Users Data
			this.DetailUsers()
			this.DetailNotification()
		},
		// Show Detail User
		DetailUsers () {
			this.loading.page = true
			this.form.id = this.$functions.UsersID()
			let formdata = {
				users_id: this.form.id,
				users_name: '',
				order_by: 'users_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsers'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.detailusers = feedback
						this.form.avatar = feedback[0].users_avatar_url
						this.form.name = feedback[0].users_name
						this.subheader[0].subtitle = feedback[0].users_title
						this.subheader[1].subtitle = this.$functions.FormatDateFullName(feedback[0].users_entrydate)
						this.subheader[2].subtitle = feedback[0].users_typedesc
					}
				} else {
					this.items = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.page = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.page = false
			})
		},
		// Get Detail Logs based on login
		DetailLogs () {
			this.loading.listlogs = true
			let formdata = {
				logs_cuser: this.form.id,
				order_by: 'logs_id',
				order_type: 'DESC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListLogs'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.logsitems = feedback
					}
				} else {
					this.logsitems = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.listlogs = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.listlogs = false
			})
		},
		// Get Detail Notification based on login
		DetailNotification () {
			this.loading.listnotif = true
			let formdata = {
				notification_to: this.form.id,
				order_by: 'notification_id',
				order_type: 'DESC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListNotification'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== 'Y') {
						this.SnackBar(true, 'error', feedback[0].feedback, 0)
					} else {
						this.notifitems = feedback
					}
				} else {
					this.notifitems = feedback
					this.SnackBar(true, 'error', this.$functions.NoData(), 0)
				}
				this.loading.listnotif = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
				this.loading.listnotif = false
			})
		},
		// Snackbar Dialog
		SnackBar (dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout
			}
			if (color === 'error') {
				this.confirm.dialog 	 = false
				this.confirm.text 	 	 = 'Ok'
			}
	    }
	}
  }
</script>