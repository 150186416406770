<template>
	<div>
	<app-drawer_menu drawer_menu_title="Ticket Type"></app-drawer_menu>
		<v-progress-linear
			indeterminate
			color="fourth"
			rounded
			v-if="loading.page"
		></v-progress-linear>
		<v-tabs
			background-color="white"
			color="fourth"
			v-if = "access.read === 1"
		>
			<v-tab
				id       = "tabform"
				href   	 ="#tab-form"
				@click 	 = "FirstLoad"
			>
				Form
			</v-tab>
			<v-tab 
				href="#tab-list"
				@click = "List"
			>
				List
			</v-tab>
			<v-tab-item
				value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_tickettype" lazy-validation>
			<v-row class="mx-2 ma-1">
				<v-col cols="12" sm="12" md="12">
					<v-btn
						color		    ="tertiary"
						class			="white--text"
						:disabled     = "access.add === 0 ? true:false" 
						depressed
						@click 	    = "Add" 
						small
					>
						<v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
					</v-btn> &nbsp;
					<v-btn
						color		="primary"
						class		="white--text"
						:disabled = "access.add === 0 || access.edit === 0 ? true:false"
						depressed
						@click 	= "Confirm('write','')" 
						small
					>
						<v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
					</v-btn>
				</v-col>
				<!-- ID -->
				<v-col cols="12" sm="4" md="4">
					<v-text-field
					v-model 	  	= "form.tickettype_id"
					required
					:rules 	  	="form.tickettype_id_rules"
					:readonly     = "form.tickettype_id_readonly"
					:background-color = "form.tickettype_id_readonly ? 'readonly' : ''"
					:maxlength 	="maxinput.id"
					:counter		="maxinput.id"
					label       	= "ID *"
					placeholder 	= "ID"
					id 			= "tickettype_id"
					@keyup.enter 	= "Confirm('write','')"
					>
					</v-text-field>
				</v-col>
				<!-- ID -->
				<v-col cols="12" sm="4" md="4">
					<v-text-field
					v-model 	  	= "form.tickettype_desc"
					required
					:rules        ="form.tickettype_desc_rules"
					:maxlength 	="maxinput.desc"
					label       	= "Description *"
					placeholder 	= "Description"
					id 			= "tickettype_desc"
					@keyup.enter 	= "Confirm('write','')"
					>
					</v-text-field>
				</v-col>
				<!-- Active -->
				<v-col cols="12" sm="2" md="2" class="mt-n5">
					<label class="caption">Active Status</label>
					<v-switch
						v-model	="form.tickettype_actived"
						color  	= "primary"
						id 		= "form.tickettype_actived"
						:label 	="form.tickettype_actived !== true ? '' : 'Active'" 
					></v-switch>
				</v-col>
			</v-row>
			<v-row class="mx-2">
				<v-col cols="12" sm="5" md="5">
				<div v-show="url_type !== 'add' ? true:false ">
					<v-checkbox v-model="cont" label="Show Info"></v-checkbox>
					<v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.tickettype_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.tickettype_cuser)">{{form.tickettype_cuser}}</span>
Last Update Date : {{form.tickettype_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.tickettype_luser)">{{form.tickettype_luser}}</span>
</pre>
					</v-banner>
				</div>
				</v-col>
            </v-row>
			</v-form>
			</v-tab-item>
			<v-tab-item
				value="tab-list"
			>
			<template>
				<v-card-title 
					class="ml-n4"
				>
					<v-btn 
						:color 	="selected.length === items.length ? 'secondary':''"
						@click	="SelectAllPage"
						class	="mr-2"
						:title="$vars.V('txt_select_all_page')"
						small
						>{{$vars.V('txt_select_all_page')}}
					</v-btn>
					<v-btn 
						color     = "error"
						title 	  = "delete selected"
						@click 	  = "Confirm('multidelete','')"
						class	  = "mr-2"
						v-if  	  = "selected.length > 0"
						:disabled = "access.delete === 0 ? true:false"
						small>
						<v-icon>{{$vars.V('icon_delete')}}</v-icon>
					</v-btn>
					<v-btn 
						color  = "primary"
						title  = "search"
						v-if   = "btn_search === false"
						@click = "btn_search = true"
						small>
						<v-icon>{{$vars.V('icon_search')}}</v-icon>
					</v-btn>
					<v-spacer></v-spacer>
					<v-text-field
					v-model="search"
					append-icon="search"
					@click:append="btn_search = false"
					label="Search"
					single-line
					hide-details
					v-if ="btn_search"
					></v-text-field>
				</v-card-title>
				<v-data-table
				:value="selected"
				:headers="headers"
				:items="items"
				:search="search"
				@input="Check($event)"
				item-key="tickettype_id"
				show-select
				class="elevation-1"
				:footer-props="{
					showFirstLastPage: true,
					firstIcon: 'first_page',
					lastIcon: 'last_page',
					prevIcon: 'keyboard_arrow_left',
					nextIcon: 'keyboard_arrow_right'
				}"
				dense
				:loading 	= "loading.list"
				loading-text="Loading... Please wait"
				>
				<!-- <template v-slot:item.tickettype_actived="{ item }">
					<v-btn outlined color="primary" small v-if="item.tickettype_actived === 'Y'">
						Active
					</v-btn>
					<v-btn outlined color="orange" small v-else>
						Non Active
					</v-btn>
				</template> -->
				<template v-slot:item.action="{ item }">
					<v-icon
					small
					class	  ="mr-2"
					@click	  ="Edit(item.tickettype_id)"
					:disabled = "access.edit === 0 ? true:false"
					title 	  = "edit"
					>
					mouse
              </v-icon>|
					<v-icon
					small
					@click	  ="Confirm('delete',item.tickettype_id)"
					:disabled = "access.delete === 0 ? true:false"
					title 	  = "delete"
					>
					delete
					</v-icon>
				</template>
				</v-data-table>
			</template>
		</v-tab-item>
	</v-tabs>
	<v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
				<div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
		v-model	= "snackbar.dialog"
		:timeout	= "snackbar.timeout"
		color		= "fourth"
		rounded	= "pill"
		top
		style 	= "z-index: 9999"
		>
		{{ snackbar.text }}
		<template v-slot:action="{ attrs }">
		<v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
		<v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
		</template>
	</v-snackbar>
	<v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
		<v-card :color="Variable('confirm',4)">
			<v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
			<v-card-text>
				
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn id="dialog" :color="Variable('confirm',1)"
					:disabled   = "confirm.text === 'Ok' ? false:true"
					@click      = "Loading">{{confirm.text}}</v-btn>
				<v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
	</div>
</template>
<script>
export default {
	data: () => ({
		url_type: "add",
		cont: false,
		access: {
			read: 0,
			add: 0,
			edit: 0,
			delete: 0,
		},
		maxinput: {
			id: 10,
			desc: 150,
		},
		snackbar: {
			dialog: false,
			color: "primary",
			text: "",
			timeout: 3000,
		},
		confirm: {
			dialog: false,
			title: "",
			text: "Ok",
		},
		selected: [],
		search: "",
		headers: [
			{
				text: "ID",
				align: "left",
				value: "tickettype_id",
			},
			{ text: "Description", value: "tickettype_desc" },
			{ text: "Actived", value: "tickettype_actived" },
			{ text: "Actions", value: "action", sortable: false },
		],
		remarks: {
			feedback: "",
			dialog: false,
		},
		items: [],
		accessmenu: [],
		loading: {
			page: true,
			list: false,
		},
		btn_search: true,
		form: {
			tickettype_id: "",
			tickettype_id_rules: [(v) => !!v || "Please fill ID"],
			tickettype_id_readonly: false,
			tickettype_desc: "",
			tickettype_desc_rules: [(v) => !!v || "Please fill Description"],
			tickettype_actived: true,display_users: '',
        },
		dialog: {
			users: false
		}
	}),
	created() {
		this.FirstLoad()
	},
	methods: {
		FirstLoad() {
			//this function use for validating the screen with user privilleges
			this.Access()
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "tickettype"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let priv = response.data
				if (priv.length > 0) {
					this.access.read = priv[0].usersmenu_read
					this.access.add = priv[0].usersmenu_add
					this.access.edit = priv[0].usersmenu_edit
					this.access.delete = priv[0].usersmenu_delete
					if (priv[0].usersmenu_read === 0) {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					}
				} else {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
				}
				this.loading.page = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.page = false
			})
		},
		SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},
		Check(value) {
			this.selected = value
		},
		//Function for get all data from table TicketType with API in mod_users.go
		List() {
			this.loading.list = true
			let formdata = {
				tickettype_id: "",
				tickettype_desc: "",
				order_by: "tickettype_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListTicketType"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback !== "Y") {
							this.SnackBar(
								true,
								"error",
								feedback[0].feedback,
								0
							)
						} else {
							this.items = feedback
						}
					} else {
						this.items = feedback
						this.SnackBar(true, 'error', this.$functions.NoData(), 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(true, "error", e, 3000)
					this.loading.list = false
				})
		},
		//Pop Up message for validating (Ok or Cancel) your proccess
		Confirm(flag, id) {
			if (flag === "write") {
				if (this.$refs.form_tickettype.validate()) {
					this.confirm.dialog = true
					this.confirm.title = "Save"
					setTimeout(function() {
						document.getElementById("dialog").focus()
					}, 500)
				}
			} else if (flag === "delete") {
				this.url_type = "delete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + id + "`"
				this.tickettype_id = id
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			} else if (flag === "multidelete") {
				this.url_type = "multidelete"
				this.confirm.dialog = true
				this.confirm.title = "Delete `" + this.selected.length + "` data"
				setTimeout(function() {
					document.getElementById("dialog").focus()
				}, 500)
			}
		},

		//Function to request insert data to table TicketType 'The API will validating your data, include the user who process the data' (go to APIWriteTicketType with url_type = "add")
		Add() {
			this.url_type = "add"
			this.form.tickettype_id = ""
			this.form.tickettype_desc = ""
			this.form.tickettype_actived = true
			this.form.tickettype_id_readonly = false
			setTimeout(function() {
				document.getElementById("tickettype_id").focus()
			}, 500)
		},
		//Function to request update data to table TicketType 'The API will validating your data, include the user who process the data' (go to APIWriteTicketType with url_type = "edit")
		Edit(id) {
			this.loading.list = true
			let formdata = {
				tickettype_id: id,
				tickettype_desc: "",
				order_by: "tickettype_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiListTicketType"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback === "Y") {
							document.getElementById("tabform").click()
							this.url_type 					= "edit"
							this.form.tickettype_id 			= feedback[0].tickettype_id
							this.form.tickettype_desc 			= feedback[0].tickettype_desc
							this.form.tickettype_actived 		= this.$functions.TrueOrFalse(feedback[0].tickettype_actived)
							this.form.tickettype_cdate 		= feedback[0].tickettype_cdate
							this.form.tickettype_cuser 		= feedback[0].tickettype_cuser
							this.form.tickettype_ldate 		= feedback[0].tickettype_ldate
							this.form.tickettype_luser 		= feedback[0].tickettype_luser
							this.form.tickettype_id_readonly 	= true
							setTimeout(function() {
								document.getElementById("tickettype_desc").focus()
							}, 500)
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog = true
							this.remarks.feedback = feedback[0].feedback
						}
					} else {
						this.SnackBar(true, "error", feedback, 0)
					}
					this.loading.list = false
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = e
					this.loading.list = false
				})
		},
		Delete(id) {
			let formdata = {
				url_type: "delete",
				tickettype_id: id,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
				.post(this.$functions.UrlPOST("apiWriteTicketType"), param, {
					headers: {
						"Content-Type": "text/plain charset=ISO-8859-1",
					},
				})
				.then((response) => {
					let feedback = response.data
					if (feedback.length > 0) {
						if (feedback[0].feedback === "Y") {
							this.SnackBar(
								true,
								"primary",
								"Delete Success",
								3000
							)
							this.confirm.dialog = false
							this.confirm.text = "Ok"
							this.selected = []
							this.List()
						} else {
							this.SnackBar(
								true,
								"error",
								this.$functions.ErrorCode(),
								0
							)
							this.remarks.dialog 	= true
							this.remarks.feedback 	= feedback[0].feedback
						}
					}
				})
				.catch((e) => {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
		},
		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			console.log(param)
			this.$axios.post(this.$functions.UrlPOST("apiWriteTicketType"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback[0].feedback === "Y") {
					let feed
					if (feedback[0].feedback_failed == 0) {
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, "primary", feed, 0)
					this.confirm.dialog = false
					this.confirm.text 	= "Ok"
					this.selected 		= []
					this.List()
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog = true
					this.remarks.feedback = feedback[0].feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
		Loading() {
			this.confirm.text = "Loading..."
			if (this.url_type === "add" || this.url_type === "edit") {
				this.Write()
			} else if (this.url_type === "delete") {
				this.Delete(this.tickettype_id)
			} else if (this.url_type === "multidelete") {
				this.MultiProcess("delete")
			}
		},
		Write() {
			let formdata = {
				url_type: this.url_type,
				tickettype_id: this.form.tickettype_id,
				tickettype_desc: this.form.tickettype_desc,
				tickettype_actived: this.$functions.ActivedFlag(this.form.tickettype_actived),
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
			.post(this.$functions.UrlPOST("apiWriteTicketType"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.tickettype_cuser = feedback[0].feedback_users_id
							this.form.tickettype_cdate = feedback[0].feedback_users_date
						}
						this.form.tickettype_luser = feedback[0].feedback_users_id
						this.form.tickettype_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(
					true,
					"error",
					this.$functions.ErrorCode(),
					0
				)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
		SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
		},
		ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	},
};
</script>