<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Dummy"></app-drawer_menu>
	  	<v-tabs
	      background-color="white"
	      color="fourth"
	      v-if = "this.access.read === 1"
	    >
	        <v-tab
	        	id       = "tabform"
	        	href   	 ="#tab-form"
	        	@click 	 = "FirstLoad"
	        >
	        	{{$vars.V('txt_form')}}
	    	</v-tab>
	        <v-tab 
	        	href="#tab-list"
	        	@click = "List"
	        >
	    		{{$vars.V('txt_list')}}
	    	</v-tab>
			<v-tab-item
			    value  ="tab-form"
			>
			<v-form enctype="multipart/form-data" ref="form_dummy" lazy-validation>
			<v-row class="mx-2">
				<v-col cols="12" sm="12" md="12">
					<v-btn
				      color		="orange"
				      class		="white--text"
				      :disabled = "access.add === 0 ? true:false" 
				      depressed
				      @click 	= "Add" 
				    >
				      <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
				    </v-btn> &nbsp;
				    <v-btn
				      color		="primary"
				      class		="white--text"
				      :disabled = "access.add === 1 || access.edit === 1 ? false:true"
				      depressed
				      @click 	= "Confirm('write','')" 
				    >
				      <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
				    </v-btn>
				</v-col>
              	<!-- ID -->
               	<v-col cols="12" sm="4" md="4">
					<v-text-field 
					v-model 	  	= "form.dummy_id"
					required 		
					:rules 	  		="form.dummy_id_rules"
					:readonly    	= "form.dummy_id_readonly"
					:counter		="maxinput.id"
					:maxlength		="maxinput.id"
					label       	= "ID *"
					placeholder 	= "ID"
					id 				= "dummy_id"
					@keyup.enter 	= "Confirm('write','')"
					>
					</v-text-field>
				</v-col>
				<!-- fill -->
               	<v-col cols="12" sm="4" md="4">
					<v-text-field 
					v-model 	  	= "content"
					required 		
					:rules 	  		="form.dummy_fill_rules"
					v-if			="this.url_type == 'edit' ? true:false"
					:counter		="maxinput.fill"
					:maxlength		="maxinput.fill"
					label       	= "fill *"
					placeholder 	= "fill"
					id 				= "dummy_fill"
					@keyup.enter 	= "Confirm('write','')"
					>
					</v-text-field>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-if="this.url_type == 'edit' ? true:false">
					<v-combobox
					v-model="select"
					:items="DocumentType"
					label="Document Type"
					></v-combobox>
				</v-col>
				<v-col cols="12" sm="12" md="12" v-if="this.url_type == 'add' ? true:false">
					<quill-editor ref="myTextEditor"
						v-model="content"
						:config="editorOption">
					</quill-editor>
				</v-col>
			</v-row>
			<template>
				<v-file-input show-size counter multiple label="File input"></v-file-input>
			</template>
			<br>
			<button class="buttonAddMore" @click="addVisa()">Add another Field</button>
			<br>
			<div class="previous" v-for="(applicant, counter) in applicants" v-bind:key="counter">
				<span @click="deleteVise(counter)" class="spanAddMore">x</span>
				<label for="duration" class="labelAddMore">{{counter+1}}. Field Name:</label>
				<input type="text" class="inputAddMore" v-model="applicant.previous" required>
				<label for="duration" class="labelAddMore">Field Type:</label>
				<input type="text" class="inputAddMore" v-model="applicant.expiration" required> 
			</div>
			<v-row class="mx-2">
				<v-col cols="12" sm="4" md="4">
				<v-btn
				      color		="primary"
				      class		="white--text"
				      v-show 	="this.url_type !== 'add' ? true:false"
				      rounded 
					  @click	="print(form.dummy_id, select)"
				    >
				      <v-icon dark>print</v-icon>Print
				</v-btn>
				</v-col>
				<v-col cols="12" sm="4" md="4">
				<v-btn
				      color		="primary"
				      class		="white--text"
				      v-show 	="this.url_type !== 'add' ? true:false"
				      rounded 
					  @click	="Word(form.dummy_id, select)"
				    >
				      <v-icon dark>print</v-icon>Word
				</v-btn>
				</v-col>
			</v-row>
			<v-row class="mx-2" v-if="pdf">
			<v-col cols="12" sm="12" md="12">
				<iframe :src="pdf" frameborder="1" width="100%" height="300px">
				</iframe>
			</v-col>
			</v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
			>
			<template>
			  <v-card-title>
	                <v-btn 
	                	color = "error"
	                	title = "Delete"
	                	@click = "Confirm('multidelete','')"
	                	v-if  = "selected.length > 0"
	                	:disabled = "access.delete === 0 ? true:false">
	                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
	                </v-btn>
	                <v-spacer></v-spacer>
	                <v-btn 
	                	color  = "primary"
	                	title  = "Search"
	                	v-if   = "btn_search === false"
	                	@click = "btn_search = true">
	                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
	                </v-btn>
	                <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			  </v-card-title>
			  <v-data-table
			    v-model="selected"
			    :headers="headers"
			    :items="items"
			    :single-select="singleselect"
			    :search="search"
			    item-key="dummy_id"
			    show-select
			    class="elevation-1"
			    :footer-props="{
			      showFirstLastPage: true,
			      firstIcon: 'first_page',
			      lastIcon: 'last_page',
			      prevIcon: 'keyboard_arrow_left',
			      nextIcon: 'keyboard_arrow_right'
			    }"
			  >
			    <template v-slot:top>
			      <v-switch v-model="singleselect" label="Single select" class="pa-3"></v-switch>
			    </template>
			    <template v-slot:item.action="{ item }">
			      <v-icon
			        small
			        class="mr-2"
			        @click="Edit(item.dummy_id)"
			        :disabled = "access.edit === 0 ? true:false"
			      >
			        mouse
              </v-icon>|
			      <v-icon
			        small
			        @click="Confirm('delete',item.dummy_id)"
			        :disabled = "access.delete === 0 ? true:false"
			      >
			        delete
			      </v-icon>
			    </template>
			  </v-data-table>
			</template>
            <template  slot="items" slot-scope="row">
                <tr>
                    <td class="text-xs-left">{{row.item.dummy_id}}</td>
                    <td class="text-xs-left">{{row.item.dummy_fill}}</td>
                    <td class="text-xs-left">Edit</td>
                </tr>
            </template>
		  	</v-tab-item>
  	</v-tabs>
	<v-snackbar 
      :timeout="snackbar_timeout"
      :color="snackbar_color"
      v-model="snackbar_dialog"
      >
      {{ snackbar_text }}
      <v-btn dark text @click.native="snackbar_dialog = false">Close</v-btn>
  </v-snackbar>
  <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
            	<v-spacer></v-spacer>
            	<v-btn id="dialog" color="primary"
            		   :disabled   = "confirm.ok_disabled"
      				   @click      = "Loading">{{confirm.ok_text}}</v-btn>
                <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  	data: () => ({
		  url_type: 'add',
		  cont: false,
		content: '<p>example content</p>',
      	editorOption: { /* quill options */ },
  		access: {
  			read: 0,
  			add: 0,
  			edit: 0,
  			delete: 0,
  		},
  		snackbar_dialog: false,
	    snackbar_color: 'success',
	    snackbar_text: '',
	    snackbar_timeout: 3000,
	    confirm: {
	    	dialog: false,
	    	title: '',
	    	ok_text: 'Ok',
	    	ok_disabled: false,
		},
		select:'',
		applicants:[
    		{
     		previous: '',
			expiration:''
			}
		],
		DocumentType: [
			'COMMISION NOTE',
			'DOCUMENT RECEIPT',
			'FACULTATIVE INSURANCE SLIP',
			'KUITANSI POLIS',
			'OVERRIDING DISCOUNT',
			'POLICY SCHEDULE',
			'PREMIUM NOTE',
			'PRODUCTION CONTROL SHEET',
			'RENEWAL NOTICE',
        ],
        singleselect: false,
        selected: [],
        search: '',
        headers: [
          {
            text: 'ID',
            align: 'left',
            sortable: false,
            value: 'dummy_id',
          },
          { text: 'Description', value: 'dummy_fill' },
          { text: 'Actions', value: 'action', sortable: false }
        ],
        items: [],
        loading: {
          list : false
        },
		btn_search: true,
		pdf : false,
		maxinput:{
			id:5,
			paypalcode:10,
		},
        form: {
        	dummy_id: '',
			dummy_id_rules: [v => !!v || 'Please fill dummy ID'],
			dummy_id_readonly: false,
        	dummy_fill: '',
            dummy_fill_rules: [v => !!v || 'Please fill dummy Name'],
        },
        loader: null,
        loading_save: false
      	}),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
			//this function use for validating the screen with user privilleges
  			this.Access()
  			setTimeout(function(){
                document.getElementById('dummy_id').focus()
            }, 500)
		},
		addVisa(){
			this.applicants.push({
				previous:'',
				expiration: ''
			})
		},
		deleteVisa(counter){
			this.applicants.splice(counter,1);
		},
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
            let formData = new FormData()
            formData.append('menu_id', 'dummy')
            formData.append('users_id', this.$functions.UsersID())
            this.$axios.post(this.$functions.SafeURL('apiListMenuUser', ''),formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            })
            .then(response => {
                if (response.data.length > 0) {
                	this.access.read 	= response.data[0].usersmenu_read
                	this.access.add  	= response.data[0].usersmenu_add
                	this.access.edit    = response.data[0].usersmenu_edit
                	this.access.delete  = response.data[0].usersmenu_delete
                	if (response.data[0].usersmenu_read === 0) {
                		this.NoPriviledge()
                	}
                } else {
                	this.NoPriviledge()
                }

            })
            .catch(e => {
                this.SnackBar(true, 'error', e, 3000)
            })
		},
		//This function use for give feedback when user has no access with some feature
        NoPriviledge () {
        	this.SnackBar(true, 'error', 'You dont have access `Dummy` modul, please contact Administrator', 30000)
        },
		//Function for get all data from table dummy with API in mod_users.go
	  	List () {
	  		this.loading.list = true
            let formData = new FormData()
            this.$axios.post(this.$functions.SafeURL('apiListDummy', ``), formData, {
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                this.items = response.data
                this.loading.list = false
            })
            .catch(e => {
                this.SnackBar(true, 'error', e, 3000)
                this.loading.list = false
            })
		  },
		//Pop Up message for validating (Ok or Cancel) your proccess
	  	Confirm (flag, id) {
	  		if (flag === 'write') {
		  		if (this.$refs.form_dummy.validate()) {
		  			this.confirm.dialog   = true
		  			this.confirm.title    = 'Save'
		  			setTimeout(function(){
	                document.getElementById('dialog').focus()
	            	}, 500)
		  		}
	  		} else if (flag === 'delete') {
	  			this.url_type 		    = 'delete'
	  			this.confirm.dialog     = true
	  			this.confirm.title      = 'Delete `'+ id + '`?'
	  			this.dummy_id 	        = id
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		} else if (flag === 'multidelete') {
	  			this.url_type 		  = 'multidelete'
	  			this.confirm.dialog   = true
	  			this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
	  			setTimeout(function(){
                document.getElementById('dialog').focus()
            	}, 500)
	  		}
		  },
		  
		//Function to request insert data to table dummy 'The API will validating your data, include the user who process the data' (go to APIWriteDummy with url_type = "add") 
	  	Add () {
	  		this.url_type 		 			= 'add'
	  		this.form.dummy_id 	 	    	= ''
            this.form.dummy_fill 	 		= ''
            this.form.dummy_atmcode 		= ''
            this.form.dummy_paypalcode 		= ''
	  		this.form.dummy_actived 		= true
	  		this.form.dummy_id_readonly  	= false
	  		setTimeout(function(){
                document.getElementById('dummy_id').focus()
            }, 500)
		  },
		  
		//Function to request update data to table dummy 'The API will validating your data, include the user who process the data' (go to APIWriteDummy with url_type = "edit") 
	  	Edit (id) {
	  		let formData = new FormData()
	  		formData.append('dummy_id', id)
            this.$axios.post(this.$functions.SafeURL('apiListDummy', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		document.getElementById('tabform').click()
            		this.url_type 			    	= 'edit'
            		this.form.dummy_id   	    	= response.data[0].dummy_id
                    this.content 					= response.data[0].dummy_fill
					this.form.dummy_id_readonly  	= true
					this.pdf 						= true
					// this.print (this.form.dummy_id)
					setTimeout(function(){
                		document.getElementById('dummy_fill').focus()
            		}, 500)
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	Delete (id) {
	  		let formData = new FormData()
            formData.append('dummy_id', id)
            this.$axios.post(this.$functions.SafeURL('apiDeleteDummy', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Delete Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = ''
		  			this.List()
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	MultiProcess (flag) {
	  		let formData = new FormData()
	  		formData.append('choose', flag)
            let multiData = JSON.stringify(this.selected)
            let objData   = JSON.parse(multiData)
            for (var i = 0; i < this.selected.length; i++) {
                formData.append('data_multi', objData[i]['dummy_id'])
            }
            this.$axios.post(this.$functions.SafeURL('apiMultiDataDummy', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = ''
		  			this.List()
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
	  	},
	  	Loading () {
	  		this.confirm.ok_text 	 = 'Loading...'
	  		this.confirm.ok_disabled = true
	  		if (this.url_type === 'add' || this.url_type === 'edit') {
	  			this.Write()
	  		} else if (this.url_type === 'delete') {
	  			this.Delete(this.dummy_id)
	  		} else if (this.url_type === 'multidelete') {
	  			this.MultiProcess('delete')
	  		}
	  	},
	  	Write () {
	  		let formData = new FormData()
			formData.append('url_type', this.url_type)
	  		formData.append('dummy_id', this.form.dummy_id)
			formData.append('dummy_fill', JSON.stringify(this.content))
			formData.append('dummy_addmore', this.applicants)
            this.$axios.post(this.$functions.SafeURL('apiWriteDummy', ``), formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
            	if (response.data[0].feedback === 'Y') {
            		this.SnackBar(true, 'success', 'Save Success', 3000)
	            	this.confirm.dialog  	 = false
	                this.confirm.ok_text 	 = 'Ok'
		  			this.confirm.ok_disabled = false
		  			this.url_type 			 = 'edit'
	  			} else {
	  				this.SnackBar(true, 'error', response.data[0].feedback, 3000)
	  			}
            })
            .catch(e => {
            	this.confirm.dialog  	 = false
                this.confirm.ok_text 	 = 'Ok'
	  			this.confirm.ok_disabled = false
            	this.SnackBar(true, 'error', e, 3000)
            })
		},
		print (id, type) {
			let url

			if (this.select == '' || this.select == null || this.select == undefined) 
			{
				this.SnackBar(true, 'error', 'SL', 3000)
			} else {
				url = this.$functions.DocumentURL(type, `id=${id}`, 'pdf')
				this.pdf = url
				window.open(url, '_blank')
			}
	  	},
		Word (id, type) {
			let url

			if (this.select == '' || this.select == null || this.select == undefined) 
			{
				this.SnackBar(true, 'error', 'SL', 3000)
			} else {
				url = this.$functions.DocumentURL(type, `id=${id}`, 'word')
				this.pdf = url
				window.open(url, '_blank')
			}
	  	},
	  	SnackBar (dialog, color, text, timeout) {
	      this.snackbar_dialog = dialog
	      this.snackbar_color  = color
	      this.snackbar_text   = text
	      this.snackbar_timeout = timeout
	    }
	}
}
</script>