
<template>
  <div>
    <app-drawer_menu drawer_menu_title="Audit"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color="fourth"
      rounded
      v-if="loading.page"
    ></v-progress-linear>

    <v-stepper v-model="pp_la" vertical>
      <v-stepper-step :complete="pp_la > 1" step="1"> Start </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12" outlined>
          <v-form
            enctype="multipart/form-data"
            ref="form_claim"
            lazy-validation
          >
            <v-card-title>Claim</v-card-title>
            <v-card-subtitle>Select Certificate</v-card-subtitle>
            <v-row class="mx-1">
              <v-col cols="12" sm="4" md="4">
                <app-cb
                  cb_type           = "policy"
                  cb_url            = "apiListPolicyShort"
                  cb_url_parameter  = "policy_status=W"
                  cb_title          = "Certificate No"
                  cb_id             = "claim_policyrow"
                  cb_desc           = "claim_policyno"
                  cb_rules          = "Please fill Certificate No"
                  cb_desc_readonly  = "readonly"
                  cb_items_id       = "policy_row"
                  cb_items_desc     = "policy_no"
                  :cb_value_id		  = "form.claim_policyrow"
                  :cb_value_desc		= "form.claim_policyno"
                  @clicked="GetPolicyDetail"
                >
                </app-cb>
              </v-col>

              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-text-field
                  v-model="form.claim_refno"
                  label="References No"
                  placeholder="<<Automation No.>>"
                  readonly="readonly"
                  background-color="readonly"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="2" md="2" class="mt-n3">
                <v-text-field
                  label="Insured"
                  v-model="form.claim_insuredname"
                  placeholder="<<Automation No.>>"
                  readonly="readonly"
                  background-color="readonly"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref="dialog_dateofloss"
                  v-model="modal.dateofloss"
                  :return-value.sync="form.claim_dateofloss"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.claim_dateofloss"
                      label="Date Of Loss"
                      prepend-inner-icon="event"
                      background-color="date"
                      v-on="on"
                      v-mask="'####-##-##'"
                      placeholder="YYYY-MM-DD"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="form.claim_dateofloss" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal.dateofloss = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog_dateofloss.save(form.claim_dateofloss)
                      "
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="2" md="2" class="mt-n3">
                <v-dialog
                  ref="dialog_claim_timeofloss"
                  v-model="modal.claim_timeofloss_header"
                  :return-value.sync="form.claim_timeofloss"
                  persistent
                  width="250px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.claim_timeofloss"
                      prepend-inner-icon="access_time"
                      background-color="time"
                      readonly
                      label="Time of loss"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="modal.claim_timeofloss_header"
                    v-model="form.claim_timeofloss"
                    format="24hr"
                    use-seconds
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal.claim_timeofloss_header = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dialog_claim_timeofloss.save(
                          form.claim_timeofloss
                        )
                      "
                      >OK</v-btn
                    >
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-btn color="primary" @click="Confirm('writestep1', '')"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="pp_la > 2" step="2">
        Main Form
        <small></small>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-row>
          <v-col cols="12" sm="12" md="8">
            <v-card outlined>
              <v-card-title>General Claim Information</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="3" sm="3" md="3" class="mb-n3">
                  <v-dialog
                    ref                 ="dialog_claim_dateofloss"
                    v-model             ="modal.claim_dateofloss"
                    :return-value.sync  ="form.claim_dateofloss"
                    width               ="290px"
                    persistent
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model             ="form.claim_dateofloss"
                        label               ="Date of Loss"
                        prepend-inner-icon  ="event"
                        background-color    ="date"
                        v-on                ="on"
                        v-mask              ="'####-##-##'"
                        placeholder         ="YYYY-MM-DD"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.claim_dateofloss" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.claim_dateofloss = false">Cancel</v-btn>
                      <v-btn
                        text
                        color   ="primary"
                        @click  ="$refs.dialog_claim_dateofloss.save(form.claim_dateofloss)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                 <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-dialog
                      ref                 ="dialog_claim_timeofloss"
                      v-model             ="modal.claim_timeofloss"
                      :return-value.sync  ="form.claim_timeofloss"
                      persistent
                      width               ="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model             ="form.claim_timeofloss"
                          prepend-inner-icon  ="access_time"
                          background-color    ="time"
                          readonly
                          v-on                ="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if    ="modal.claim_timeofloss"
                        v-model ="form.claim_timeofloss"
                        format  ="24hr"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.claim_timeofloss = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog_claim_timeofloss.save(form.claim_timeofloss)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>

                  <v-col cols="3" sm="3" md="3" class="mb-n3">
                  <v-dialog
                    ref                 ="dialog_claim_reportdate"
                    v-model             ="modal.claim_reportdate"
                    :return-value.sync  ="form.claim_reportdate"
                    width               ="290px"
                    persistent
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model             ="form.claim_reportdate"
                        label               ="Report Date"
                        prepend-inner-icon  ="event"
                        background-color    ="date"
                        v-on                ="on"
                        v-mask              ="'####-##-##'"
                        placeholder         ="YYYY-MM-DD"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.claim_reportdate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal.claim_reportdate = false">Cancel</v-btn>
                      <v-btn
                        text
                        color   ="primary"
                        @click  ="$refs.dialog_claim_reportdate.save(form.claim_reportdate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                 <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-dialog
                      ref                 ="dialog_claim_reporttime"
                      v-model             ="modal.claim_reporttime"
                      :return-value.sync  ="form.claim_reporttime"
                      persistent
                      width               ="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model             ="form.claim_reporttime"
                          prepend-inner-icon  ="access_time"
                          background-color    ="time"
                          readonly
                          v-on                ="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if    ="modal.claim_reporttime"
                        v-model ="form.claim_reporttime"
                        format  ="24hr"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal.claim_reporttime = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.dialog_claim_reporttime.save(form.claim_reporttime)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <!-- Claim ROw -->
                  <v-col cols="12" sm="12" md="12" class="mb-n5">
                    <v-text-field
                      v-model="form.claim_no"
                      label="Claim No."
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Claim Certificate ROw -->
                  <v-col cols="2" sm="2" md="2" class="mb-n5">
                    <v-text-field
                      v-model="form.claim_policyrow"
                      label="Certificate Row."
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Claim ID -->
                  <v-col cols="4" sm="4" md="4" class="mb-n5">
                    <v-text-field
                      v-model="form.claim_policyno"
                      label="Certificate No."
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Certificate Number -->
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-text-field
                      v-model="form.claim_certificateno"
                      label="Cert No"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!--References No-->
                  <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <v-text-field
                      label="Ref No."
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      v-model="form.claim_refno"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- claim_campaign_id -->
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <v-text-field
                      label="Campaign"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      v-model="form.claim_campaign_id"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" sm="3" md="3" class="mt-n3">
                    <v-text-field
                      v-model="form.claim_revisionno"
                      readonly="readonly"
                      background-color="numeric"
                      reverse
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="2" sm="3" md="3" class="mt-n3">
                    <v-text-field
                      v-model="form.claim_correctionno"
                      readonly="readonly"
                      background-color="numeric"
                      reverse
                    >
                    </v-text-field>
                  </v-col>

                  <!-- claim_policytype_id -->
                  <v-col cols="12" sm="6" md="6" class="mt-n6">
                    <v-text-field
                      label="Subclass"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      :value="`${form.claim_subclass_id}-${form.claim_subclass_desc}`"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- claim_branch_id -->
                  <v-col cols="12" sm="6" md="6" class="mt-n6">
                    <v-text-field
                      label="Branch"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="code"
                      :value="`${form.claim_branch_id}-${form.claim_branch_desc}`"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- claim_handling_branch_id -->
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                      cb_type="branch"
                      cb_url="apiListBranch"
                      cb_url_parameter="branch_actived=Y"
                      cb_title="Handling Branch"
                      cb_id="claim_handling_branch_id"
                      cb_desc="claim_handling_branch_id_desc"
                      cb_rules="Please fill Branch"
                      cb_desc_readonly="readonly"
                      cb_items_id="branch_id"
                      cb_items_desc="branch_desc"
                      :cb_value_id="form.claim_handling_branch_id"
                      :cb_value_desc="form.claim_handling_branch_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- claim_insuredname -->
                  <v-col cols="12" sm="6" md="6" class="mt-n6">
                    <v-text-field
                      label="Insured Name"
                      placeholder="<<Automation No.>>"
                      prepend-inner-icon="text_fields"
                      v-model="form.claim_insuredname"
                      readonly="readonly"
                      background-color="readonly"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- causeloss -->
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                      cb_type="causeloss"
                      cb_url="apiListCauseLoss"
                      cb_url_parameter="causeloss_actived=Y"
                      cb_title="Cause Of Loss"
                      cb_id="claim_col_id"
                      cb_desc="claim_col_id_desc"
                      cb_rules="Please fill Cause Of Loss"
                      cb_desc_readonly="readonly"
                      cb_items_id="causeloss_id"
                      cb_items_desc="causeloss_desc"
                      :cb_value_id="form.claim_col_id"
                      :cb_value_desc="form.claim_col_id_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Internal Surveyor -->
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                      cb_type="users"
                      cb_url="apiListUsersShort"
                      cb_url_parameter="users_actived=Y"
                      cb_title="Internal Surveyor"
                      cb_id="claim_surveyor"
                      cb_desc="claim_surveyor_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="users_id"
                      cb_items_desc="users_name"
                      :cb_value_id="form.claim_surveyor"
                      :cb_value_desc="form.claim_surveyor_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Internal Asessor -->
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                      cb_type="users"
                      cb_url="apiListUsersShort"
                      cb_url_parameter="users_actived=Y"
                      cb_title="Internal Asessor"
                      cb_id="claim_assesor"
                      cb_desc="claim_assesor_desc"
                      cb_rules="Please fill ID"
                      cb_desc_readonly="readonly"
                      cb_items_id="users_id"
                      cb_items_desc="users_name"
                      :cb_value_id="form.claim_assesor"
                      :cb_value_desc="form.claim_assesor_desc"
                    >
                    </app-cb>
                  </v-col>
                  <!-- Report Name -->
                  <v-col cols="12" sm="6" md="6" class="mt-n6">
                    <v-textarea
                      v-model="form.claim_reportname"
                      label="Report Name"
                      hint="Report Name"
                      rows="1"
                      prepend-inner-icon="person"
                    ></v-textarea>
                  </v-col>
                  <!-- Email -->
                  <v-col cols="12" sm="6" md="6" class="mb-n5">
                    <v-textarea
                      v-model="form.claim_reportemail"
                      label="Email"
                      hint="Email"
                      rows="1"
                      prepend-inner-icon="mail"
                    ></v-textarea>
                  </v-col>

                  <!-- Mobile -->
                  <v-col cols="12" sm="4" md="4" class="mb-n5">
                    <v-textarea
                      v-model="form.claim_reportmobileno"
                      label="Mobile"
                      hint="Business"
                      prepend-inner-icon="phone_iphone"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="2" md="2" class="mt-2">
                    <label>Whatsapp :</label>
                    <v-checkbox class="mt-n1" v-model="form.claim_reportmobilewaf"> </v-checkbox>
                  </v-col>
                  <!-- Remarks -->
                  <v-col cols="12" sm="12" md="12" class="mb-n5">
                    <v-textarea
                      v-model="form.claim_remarks"
                      label="Remarks"
                      hint="Remarks"
                      prepend-inner-icon="text_fields"
                      rows="1"
                    ></v-textarea>
                  </v-col>
                  <!-- <v-col cols="6" sm="4" md="4" class="mb-n5">
                    <v-dialog
                      ref="dialog_surveydatetime"
                      v-model="modal.surveydatetime"
                      :return-value.sync="form.claim_surveydatetime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.claim_surveydatetime"
                          label="Survey Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          v-on="on"
                          v-mask="'####-##-##'"
                          placeholder="YYYY-MM-DD"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.claim_surveydatetime"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.surveydatetime = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_surveydatetime.save(
                              form.claim_surveydatetime
                            )
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6" sm="4" md="4" class="mb-n5">
                    <v-dialog
                      ref="dialog_claim_reservedate"
                      v-model="modal.claim_reservedate"
                      :return-value.sync="form.claim_claim_reservedate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.claim_claim_reservedate"
                          label="Reserve Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          v-on="on"
                          v-mask="'####-##-##'"
                          placeholder="YYYY-MM-DD"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.claim_claim_reservedate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.claim_reservedate = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_claim_reservedate.save(
                              form.claim_claim_reservedate
                            )
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="6" sm="4" md="4" class="mb-n5">
                    <v-dialog
                      ref="dialog_claim_approvedate"
                      v-model="modal.claim_approvedate"
                      :return-value.sync="form.claim_claim_approvedate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.claim_claim_approvedate"
                          label="Approve Date"
                          prepend-inner-icon="event"
                          background-color="date"
                          v-on="on"
                          v-mask="'####-##-##'"
                          placeholder="YYYY-MM-DD"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form.claim_claim_approvedate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="modal.claim_approvedate = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.dialog_claim_approvedate.save(
                              form.claim_claim_approvedate
                            )
                          "
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="mb-n5">
            <v-card class="mb-4" outlined>
              <v-card-title></v-card-title>
              <!-- <v-card-subtitle>Select Campaign Template</v-card-subtitle> -->
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="6" md="6" class="mt-4">
                    <v-select
                      v-model="form.claim_status"
                      required
                      :items="rowss.claimstatus"
                      :item-text="rowss.claimstatus.text"
                      :item-value="rowss.claimstatus.value"
                      label="Claim Status"
                      placeholder="Claim Status"
                      prepend-inner-icon="list"
                      dense
                      disabled
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mt-4">
                    <v-select
                      v-model="form.claim_statustype"
                      required
                      :items="rowss.claimstatustype"
                      :item-text="rowss.claimstatustype.text"
                      :item-value="rowss.claimstatustype.value"
                      label="Claim Status Type"
                      placeholder="Claim Status Type"
                      prepend-inner-icon="list"
                      dense
                      disabled
                    >
                    </v-select>
                  </v-col>
                  <!-- <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      label="CNO"
                      background-color="numeric"
                      hint="Area"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6" md="6" class="mb-n5">
                    <v-text-field
                      v-model="form.pp_surroundingarea"
                      ref="pp_surroundingarea"
                      id="pp_surroundingarea"
                      label="ANO"
                      hint="Area"
                      background-color="numeric"
                      :value="$store.getters.long_insured_name"
                    ></v-text-field>
                  </v-col> -->
                  <!-- ExGratia -->
                  <v-col cols="4" sm="4" md="4" class="mb-n5">
                    <label>ExGratia :</label>
                    <v-checkbox
                      v-model="genclaim.dialog.exgratia"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                  <!-- <v-col cols="3" sm="3" md="3" class="mb-n5">
                    <label>Merimen :</label>
                    <v-checkbox class="mt-n1"> </v-checkbox>
                  </v-col> -->
                  <v-col cols="4" sm="4" md="4" class="mb-n5">
                    <label>Back Dated :</label>
                    <v-checkbox
                      v-model="form.claim_backdatedf"
                      false-value="N"
                      true-value="Y"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col cols="4" sm="4" md="4" class="mb-n5">
                    <label>Settled :</label>
                    <v-checkbox
                      v-model="form.claim_settledf"
                      false-value="N"
                      true-value="Y"
                      class="mt-n1"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-alert border="top" colored-border type="info" elevation="2">
              Please save your data first to unlock the next form
            </v-alert>
            <!-- <v-btn color="primary" @click="pp_la = 3" outlined block>Save</v-btn> -->
            <v-btn
              color="primary"
              @click="Confirm('writestep2', '')"
              outlined
              block
            >
              <v-icon dark>{{ $vars.V("icon_save") }}</v-icon
              >{{ $vars.V("txt_save") }}</v-btn
            >
            <v-card class="mb-4 mt-4" outlined>
              <v-card-title>General</v-card-title>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark purple darken-1"
                  class="mr-2"
                  @click="genclaim.dialog.risk = true"
                >
                  <v-icon left>lock</v-icon>
                  LOSS ITEM
                </v-btn>
                <!-- <v-btn
                  tile
                  color="theme--dark purple darken-2"
                  class="mr-2"
                  @click="genclaim.dialog.deductible = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  DEDUCTIBLE
                </v-btn> -->
              </v-card-text>
              <v-card-title>Expense</v-card-title>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark green darken-2"
                  class="mr-2"
                  @click="modal.claimexpense = true"
                >
                  <v-icon left>lock</v-icon>
                  EXPENSE
                </v-btn>
                <!-- <v-btn
                  tile
                  color="theme--dark green darken-2"
                  class="mr-2"
                  @click="genclaim.dialog.clause = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  SALVAGE
                </v-btn> -->
                <v-btn
                  tile
                  color="theme--dark green darken-2"
                  class="mr-2"
                  @click="modal.claimsubro = true"
                >
                  <v-icon left>lock</v-icon>
                  SUBROGATION
                </v-btn>
                <v-btn
                  tile
                  color="theme--dark green darken-3"
                  class="mr-2 mt-2"
                  @click="genclaim.dialog.feeduty = true"
                >
                  <v-icon left>assignment_ind</v-icon>
                  FEE & DUTY
                </v-btn>
              </v-card-text>
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark blue darken-2"
                  class="mr-2"
                  @click="genclaim.dialog.payors = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  CLAIM SURVEY
                </v-btn>
                <!-- <v-btn
                  tile
                  color="theme--dark blue darken-2"
                  class="mr-2"
                  @click="genclaim.dialog.payors = true"
                >
                  <v-icon left>mdi-heart</v-icon>
                  CLAIM COMPLAINT
                </v-btn> -->
              </v-card-text>
             
              <v-card-text>
                <v-btn
                  tile
                  color="theme--dark red darken-2"
                  class="mr-2"
                  @click="genclaim.dialog.history = true"
                >
                  <v-icon left>mdi-history</v-icon>
                  HISTORY
                </v-btn>
              </v-card-text>
              <v-overlay :absolute="true" :value="gpioverlay">
                <v-btn color="danger" @click="gpioverlay = false">
                  <v-icon left>lock</v-icon> Locked
                </v-btn>
              </v-overlay>
            </v-card>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper>

    <!-- DIALOG -->

    <!-- Start Unkown -->
    <v-breadcrumbs :items="pp_breadcrumb">
      <template v-slot:divider>
        <v-icon>trip_origin</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item v-on:click="BreadcrumbURL(item.text)">{{
          item.text.toUpperCase()
        }}</v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Certificate Processing</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Save</v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-list three-line subheader>
          <v-subheader>This will be showing time now</v-subheader>
          <v-card class="policycard" v-if="widgets == false">
            <v-app-bar dark color="primary">
              <v-toolbar-title
                >Certificate Processing Document Printing</v-toolbar-title
              >
              <v-spacer></v-spacer>
            </v-app-bar>
            <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> -->
            <div id="policycard">
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft"
                  >Campaign No :
                </v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"
                  ><input type="text" v-model="form.pp_campaign_no" readonly
                /></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft">
                  Quotation No :
                </v-col>
                <v-col cols="12" sm="7" md="7" class="tdright"
                  ><input type="text" v-model="form.pp_quotation_no" readonly
                /></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="5" md="5" class="tdleft"
                  ><label for="documenttype">Document Type : </label></v-col
                >
                <v-col cols="12" sm="7" md="7" class="tdright"
                  ><select
                    id="documenttype"
                    class="textpolicycard"
                    v-model="selected.documenttype"
                  >
                    <option>COMMISION NOTE</option>
                    <option>DOCUMENT RECEIPT</option>
                    <option>FACULTATIVE INSURANCE SLIP</option>
                    <option>KUITANSI POLIS</option>
                    <option>OVERRIDING DISCOUNT</option>
                    <option>Certificate SCHEDULE</option>
                    <option>PREMIUM NOTE</option>
                    <option>PRODUCTION CONTROL SHEET</option>
                    <option>RENEWAL NOTICE</option>
                  </select>
                </v-col>
              </v-row>
              <!-- <v-btn
                        color		="primary"
                        class		="white--text mt-2"
                        rounded 
                        @click	="Print(form.pp_campaign_no, selected, 'pdf')"
                      >
                        <v-icon dark>print</v-icon>Print
                  </v-btn> -->
              <button
                class="buttoncard"
                v-on:click="Print(form.pp_campaign_no, selected.documenttype)"
              >
                Print
              </button>
              <button class="buttoncard" v-on:click="widgets = true">
                View Log
              </button>
            </div>
            <!-- <v-card-actions>
                <v-row class="mx-2">
                  <v-col cols="12" sm="4" md="4">
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                  </v-col>
                </v-row>
              </v-card-actions> -->
          </v-card>
          <v-card class="policycard1" v-else>
            <v-system-bar color="white">
              <v-spacer></v-spacer>
              <v-card-subtitle v-on:click="widgets = false"
                >Close<v-icon>mdi-close</v-icon></v-card-subtitle
              >
            </v-system-bar>
            <v-app-bar dark color="primary">
              <v-toolbar-title>Certificate Schedule</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
            <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>by Kurt Wagner</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> -->
            <div id="policycard1">
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  ><label for="languange">Languange : </label></v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <select
                    id="languange"
                    class="textpolicycard"
                    v-model="selected.languange"
                  >
                    <option>English</option>
                    <option>Indonesia</option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  >Print Date :
                </v-col>
                <v-col cols="12" sm="4" md="2" class="mb-n5 tdright">
                  <v-dialog
                    ref="dialog_leader_policy_date"
                    v-model="modal.leader_policy_date"
                    :return-value.sync="form.pp_leader_policy_date"
                    width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="form.pp_leader_policy_date"
                        prepend-inner-icon="event"
                        background-color="date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.pp_leader_policy_date"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal.leader_policy_date = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.dialog_leader_policy_date.save(
                            form.pp_leader_policy_date
                          )
                        "
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft">Options : </v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <input
                    type="radio"
                    id="one"
                    class="ml-2 mr-1"
                    value="Without Originality"
                    v-model="selected.picked"
                  /><label for="one">Without Originality</label>
                  <input
                    type="radio"
                    id="two"
                    class="ml-2 mr-1"
                    value="Original"
                    v-model="selected.picked"
                  /><label for="two">Original</label>
                  <input
                    type="radio"
                    id="three"
                    class="ml-2 mr-1"
                    value="Duplicate"
                    v-model="selected.picked"
                  /><label for="three">Duplicate</label>
                  <input
                    type="radio"
                    id="Four"
                    class="ml-2 mr-1"
                    value="Triplecate"
                    v-model="selected.picked"
                  /><label for="four">Triplecate</label>
                  <input
                    type="radio"
                    id="five"
                    class="ml-2 mr-1"
                    value="Copy"
                    v-model="selected.picked"
                  /><label for="five">Copy</label>
                </v-col>
                <!-- <label class="container-checkbox">
                      <input type="checkbox" >
                      <span class="checkmark"></span>
                    </label> -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-Certificate mb-n2">
                    <input
                      type="checkbox"
                      id="Premium"
                      value="Show Premium Content"
                      v-model="checked.premium"
                    />
                    <label for="Premium">Show Premium Content</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-Certificate mb-n2">
                    <input
                      type="checkbox"
                      id="Discount"
                      value="Show Discount"
                      v-model="checked.discount"
                    />
                    <label for="Discount">Show Discount</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft">
                  Select item to Print :</v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-Certificate mb-n2">
                    <input
                      type="checkbox"
                      id="Attachment"
                      value="Attachment"
                      v-model="checked.attachment"
                    />
                    <label for="Attachment">Attachment</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-Certificate mb-n2">
                    <input
                      type="checkbox"
                      id="Selected Classess"
                      value="Selected Classes"
                      v-model="checked.selected"
                    />
                    <label for="Selected Classess">Selected Classess</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-Certificate mb-n2">
                    <input
                      type="checkbox"
                      id="Deferred Premium Clause"
                      value="Deferred Premium Clause"
                      v-model="checked.deferred"
                    />
                    <label for="Deferred Premium Clause"
                      >Deferred Premium Clause</label
                    >
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-Certificate mb-n2">
                    <input
                      type="checkbox"
                      id="PA Card"
                      value="PA Card"
                      v-model="checked.pa"
                    />
                    <label for="PA Card">PA Card</label>
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdleft"></v-col>
                <v-col cols="12" sm="6" md="6" class="tdright">
                  <label class="containter-checkbox-policy">
                    <input
                      type="checkbox"
                      id="With Header And Footer"
                      value="With Header And Footer"
                      v-model="checked.headerfooter"
                    />
                    <label for="With Header And Footer"
                      >With Header And Footer</label
                    >
                    <span class="checkmark-policy"></span>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  ><label for="layout">Layout : </label></v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright"
                  ><select
                    id="layout"
                    class="textpolicycard"
                    v-model="selected.layout"
                  >
                    <option disabled value="">Default</option>
                    <option>Point</option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft"
                  ><label for="layoutAttachment"
                    >Layout Attachment :
                  </label></v-col
                >
                <v-col cols="12" sm="6" md="6" class="tdright"
                  ><select
                    id="layoutAttachment"
                    class="textpolicycard"
                    v-model="selected.layoutAttachment"
                  >
                    <option disabled value="">Default</option>
                    <option>Point</option>
                  </select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="tdleft mt-n2">
                  <button
                    class="buttoncard"
                    v-on:click="Export(form.pp_campaign_no)"
                  >
                    Export
                  </button>
                  <button
                    class="buttoncard"
                    v-on:click="
                      Print(form.pp_campaign_no, selected.documenttype, 'pdf')
                    "
                  >
                    Print
                  </button>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="tdright mt-n2">
                  <button
                    class="buttoncard"
                    v-on:click="
                      Preview(form.pp_campaign_no, selected.documenttype)
                    "
                  >
                    Preview Only
                  </button>
                  <button
                    class="buttoncard"
                    v-on:click="
                      Print(form.pp_campaign_no, selected.documenttype, 'pdf')
                    "
                  >
                    Preview WithPrint
                  </button>
                </v-col>
              </v-row>
              <!-- <v-btn
                        color		="primary"
                        class		="white--text mt-2"
                        rounded 
                        @click	="Print(form.pp_campaign_no, selected.documenttype, 'pdf')"
                      >
                        <v-icon dark>print</v-icon>Print
                  </v-btn>
                  <v-btn
                        color		="primary"
                        class		="white--text mt-2"
                        rounded 
                        @click	="Print(form.pp_campaign_no, selected.documenttype, 'word')"
                      >
                        <v-icon dark>print</v-icon>Word
                  </v-btn> -->
            </div>
          </v-card>
        </v-list>
        <v-row class="mx-2" v-show="selected.iframe">
          <!-- <v-slider
            v-model="slider"
            prepend-icon="mdi-pan-horizontal"
            min="0"
            max="1000"
            thumb-label
          ></v-slider> -->
          <v-col cols="12" sm="12" md="12">
            <iframe
              :src="selected.iframeURL"
              frameborder="1"
              width="100%"
              height="1000px"
              id="PDFPreview"
            >
            </iframe>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="remarks.dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      max-width="290"
      v-if="remarks.feedback !== ''"
    >
      <v-card>
        <v-toolbar dark color="fourth" dense>
          <v-btn icon dark @click="remarks.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div
            class="subheading font-weight-bold"
            v-html="remarks.feedback"
          ></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.dialog"
      :timeout="snackbar.timeout"
      color="fourth"
      rounded="pill"
      top
      style="z-index: 9999"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          @click.native="remarks.dialog = true"
          small
          v-if="snackbar.color === 'error'"
          >Open</v-btn
        >
        <v-btn dark text @click.native="snackbar.dialog = false" small
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
      <v-card>
        <v-card-title class="primary white--text"
          >{{ confirm.title }}?</v-card-title
        >
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="dialog"
            color="primary"
            :disabled="confirm.text === 'Ok' ? false : true"
            @click="Loading"
            >{{ confirm.text }}
          </v-btn>
          <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Start dialog history -->
    <v-dialog
      v-model="genclaim.dialog.history"
      transition="dialog-bottom-transition"
      :maximize="true"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genclaim.dialog.history = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>History</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-timeline align="start">
            <v-timeline-item
              v-for="(histories, i) in history"
              :key="i"
              size="small"
            >
              <template v-slot:icon>
                <!-- Menambahkan template untuk ikon -->
                <v-icon color="white">mdi-account</v-icon>
                <!-- Ganti 'mdi-account' dengan ikon pengguna yang sesuai -->
              </template>
              <div>
                <h2
                  :class="`mt-n1 headline font-weight-light mb-4 text-${histories.color}`"
                >
                  Lorem ipsum
                </h2>
                <div>
                  <p>Current Date and Time: {{ dumyDateHistory() }}</p>
                </div>

                <div>
                  Lorem ipsum dolor sit amet, no nam oblique veritus. Commune
                  scaevola imperdiet nec ut, sed euismod convenire principes at.
                  Est et nobis iisque percipit, an vim zril disputando
                  voluptatibus, vix an salutandi sententiae.
                </div>
              </div>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog history -->

    <!-- Start dialog exgratia -->
    <v-dialog
      v-model="genclaim.dialog.exgratia"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="genclaim.dialog.exgratia = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>ExGratia</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <!-- User-->
            <v-col cols="6" sm="6" md="6" class="mt-3">
              <app-cb
                cb_type="users"
                cb_url="apiListUsers"
                cb_url_parameter="users_actived=Y"
                cb_title="User"
                cb_rules="Please fill User"
                cb_desc_readonly="readonly"
                cb_items_id="users_id"
                cb_items_desc="users_name"
              >
              </app-cb>
            </v-col>
            <!-- Approve Date -->
            <v-col cols="6" sm="6" md="6" class="mb-n5">
              <v-text-field
                label="Approve Date"
                placeholder="YYYY-MM-DD"
                background-color="date"
                prepend-inner-icon="event"
              >
              </v-text-field>
            </v-col>
            <!-- Approve-->
            <v-col cols="6" sm="6" md="6" class="mb-n5">
              <v-text-field
                label="Approve"
                prepend-inner-icon="person"
                readonly
                placeholder="Approve"
              ></v-text-field>
            </v-col>
            <!-- Approve-->
            <v-col cols="6" sm="6" md="6" class="mb-n5">
              <v-text-field
                label="Attachment"
                readonly
                prepend-inner-icon="attachment"
                placeholder="Attachment"
              ></v-text-field>
            </v-col>
            <!-- Remarks-->
            <v-col cols="12" sm="12" md="12" class="mb-n5">
              <v-textarea
                label="Remarks"
                hint="Remarks"
                prepend-inner-icon="edit"
                rows="1"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End dialog exgratia -->
    <app-claim-subro :dialog="modal.claimsubro" @close="modal.claimsubro = false" :dialog_id="form.claim_row" dialog_title="Form Claim Subrogation"></app-claim-subro>
    <app-claim-expense :dialog="modal.claimexpense" @close="modal.claimexpense = false" :dialog_id="form.claim_row" dialog_title="Form Claim Expense"></app-claim-expense>
  </div>
</template>
  <script>
export default {
  data: () => ({
    // ========================================================================
    // Start General Claim Information
    // ========================================================================
    history: [
      {
        color: "cyan",
        dates: "1960",
      },
      {
        color: "green",
        dates: "1970",
      },
      {
        color: "pink",
        dates: "1980",
      },
      {
        color: "amber",
        dates: "1990",
      },
      {
        color: "orange",
        dates: "2000",
      },
    ],
    gpioverlay: true,
    pp_la: 1,
    genclaim: {
      form: {},
      dialog: {
        subrogation: false,
        exgratia: false,
      },
    },
    format_money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
    },
    pp_stepper: 2,
    pp_panel: [0],
    timeofloss: false,
    timeofloss_: false,
    rowss: {
      claimstatus: [
        { text: "", value: "" },
        { text: "New Claim", value: "N" },
        { text: "Correction", value: "E" },
        { text: "Cancellation", value: "C" },
      ],
      claimstatustype: [
        { text: "", value: "" },
        { text: "Request", value: "R" },
        { text: "Processed", value: "P" },
        { text: "Approved", value: "A" },
        { text: "Closed", value: "C" },
        { text: "Temporary Approved", value: "T" },
        { text: "Temporary Processed", value: "S" },
        { text: "Temporary Closed", value: "U" },
      ],
    },
    form: {
      claim_row: "",
      claim_no: "",
      claim_policyrow: "",
      claim_policyno: "",
      claim_certificateno: "",
      claim_refno: "",
      claim_campaign_id: "",
      claim_campaign_id_desc: "",
      claim_revisionno: "",
      claim_correctionno: "",
      claim_subclass_id: "",
      claim_subclass_desc: "",
      claim_branch_id: "",
      claim_handling_branch_id: "",
      claim_dateofloss: new Date().toISOString().substr(0, 10),
      claim_timeofloss: "12:00",
      claim_reportdate: new Date().toISOString().substr(0, 10),
      claim_reporttime: "",
      claim_policytype_id: "",
      claim_policytype_id_desc: "",
      claim_branch_id_desc: "",
      claim_col_id: "",
      claim_surveyor: "",
      claim_assesor: "",
      claim_event: "",
      claim_reportname: "",
      claim_reportemail: "",
      claim_reportmobileno: "",
      claim_reportmobilewaf: "",
      claim_surveydatetime: "",
      claim_remarks: "",
      claim_location: "",
      claim_losslocation: "",
      claim_reservedate: new Date().toISOString().substr(0, 10),
      claim_reserveby: "",
      claim_approvedate: new Date().toISOString().substr(0, 10),
      claim_approveby: "",
      claim_bookingdate: "",
      claim_correctiondate: new Date().toISOString().substr(0, 10),
      claim_correctionby: "",
      claim_revisiondate: new Date().toISOString().substr(0, 10),
      claim_revisionby: "",
      claim_settledf: "",
      claim_exgratiaf: "",
      claim_exgratiaremarks: "",
      claim_exgratiadate: "",
      claim_exgratiauser: "",
      claim_backdatedf: "",
      claim_cdate: "",
      claim_cuser: "",
      claim_ldate: "",
      claim_luser: "",
      claim_insuredname: "",
      claim_statustype: "",
      claim_status: "",
      // end form claim

      pp_tab_payor_payment_method_data: [
        {
          id: "TRANSFER",
          text: "Transfer",
        },
        {
          id: "CASH",
          text: "Cash",
        },
        {
          id: "CREDIT",
          text: "Credit Card",
        },
        {
          id: "VIRTUAL",
          text: "Virtual",
        },
      ],

      pp_tab_payor_period_term_data: [
        {
          id: 0,
          text: "Daily",
        },
        {
          id: 1,
          text: "MOnthly",
        },
        {
          id: 3,
          text: "Quarterly",
        },
        {
          id: 6,
          text: "Half Yearly",
        },
        {
          id: 12,
          text: "Annually",
        },
      ],
    },

    selected: {
      documenttype: "COMMISION NOTE",
      languange: "English",
      picked: "Without Originality",
      layout: "",
      checkedOption: [],
      layoutAttachment: "",
      iframe: false,
      iframeURL: "",
    },
    checked: {
      premium: false,
      discount: false,
      attachment: false,
      selected: false,
      deferred: false,
      pa: false,
      headerfooter: false,
    },
    slider: 0,
    loading: {
      page: false,
      riskcoverage: false,
      deductible: false,
    },
    modal: {
      claim_reportdate: false,
      claim_dateofloss: false,
      inception: false,
      expiry: false,
      dateofloss: false,
      surveydatetime: false,
      claim_reservedate: false,
      claim_approvedate: false,
      reportdate: false,
      timeofloss: false,
      inception_time: false,
      claim_timeofloss: false,
      claim_timeofloss_header: false,
      claim_timeofloss_main: false,
      expiry_time: false,
      reference_date: false,
      leader_policy_date: false,
      claim_reporttime: false,
      due_date: false,
      first_due_date: false,
      claimsubro: false,
      claimexpense: false,
    },
    dialog: false,
    widgets: false,
    pp_breadcrumb: [
      {
        text: "Treaty Arragement",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Business Result",
        disabled: false,
        href: "breadcrumbs_link_1",
      },
      {
        text: "Upload",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
      {
        text: "Print",
        disabled: false,
        href: "#/policyproduction/print",
      },
    ],
    confirm: {
      dialog: false,
      title: "",
      text: "Ok",
    },
    remarks: {
      feedback: "",
      dialog: false,
    },
    snackbar: {
      dialog: false,
      color: "primary",
      text: "",
      timeout: 3000,
    },
  }),
  created() {
    // this.OpenDetail()
    // this.Benefit()
    // this.Deductible()
    // this.PolicySource()
    // this.FeeDuty()
  },
  watch: {
      '$route.params.id': {
        handler: function(value) {
          this.DetailData(value)
        },
        deep: true,
        immediate: true
      }
  },
  methods: {
    onTimeInput(newValue) {
      console.log(newValue);
    },
    dumyDateHistory() {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`;
      return formattedDate;
    },
    // Temp
    AddMapDetail() {},
    SelectAllMapValue() {},
    GetPolicyDetail(value) {
      if (value !== undefined) {
        this.loading.page = true;
        let splits = this.$functions.Split(value, "|");
        let policy_row = splits[0];
        // Call Back API Deductible
        let formdata = {
          policy_row: policy_row,
          order_by: "policy_row",
          order_type: "ASC",
          limit: this.limit,
        };
        let param = this.$functions.ParamPOST(formdata);
        this.$axios
          .post(this.$functions.UrlPOST("apiListPolicy"), param, {
            headers: {
              "Content-Type": "text/plain; charset=ISO-8859-1",
            },
          })
          .then((response) => {
            let feedback = response.data;
            if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                this.form.claim_policyrow = feedback[0].policy_row;
                this.form.claim_policyno = feedback[0].policy_no;
                this.form.claim_refno = feedback[0].policy_refno;
                this.form.claim_insuredname = feedback[0].policy_longinsured;
                this.loading.page = false
                // this.dialog.policyded = true
              } else {
                this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
                this.remarks.dialog = true;
                this.remarks.feedback = feedback[0].feedback;
                this.loading.page = false
              }
            } else {
              this.SnackBar(true, "error", feedback, 0);
              this.loading.page = false
            }
          })
          .catch((e) => {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = e;
            this.loading.page = false
          });
      }
    },
    SearchMapValue() {},
    // End: Temp
    Confirm(flag) {
      if (flag === "writestep1") {
        if (this.$refs.form_claim.validate()) {
          this.url_type = "writestep1";
          this.confirm.dialog = true;
          this.confirm.title = "Confirm Claim Register";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }
      if (flag === "writestep2") {
        if (this.$refs.form_claim.validate()) {
          this.url_type = "writestep2";
          this.confirm.dialog = true;
          this.confirm.title = "Confirm Claim";
          setTimeout(function () {
            document.getElementById("dialog").focus();
          }, 500);
        }
      }
    },
    Close(flag) {
      if (flag === "writestep1") {
        this.url_type = "add";
        this.confirm.dialog = false;
      }
    },
    Loading() {
      this.confirm.text = "Loading...";
      if (this.url_type === "add" || this.url_type === "edit") {
        this.Write();
      } else if (this.url_type === "writestep1") {
        this.WriteStep1();
      } else if (this.url_type === "writestep2") {
        this.WriteStep2();
      }
    },

    GetGeneralTableDetail(gentab_id, row) {
      let formdata = {
        gendet_gentab_id: gentab_id,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListGenDetailShort"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              let gendet_value = [];
              for (let i = 0; i < feedback.length; i++) {
                gendet_value.push(feedback[i]["gendet_id"]);
              }

              this.$set(this.policy_objectinfo_id, row, gentab_id);
              this.$set(this.policy_objectinfo_value, row, gendet_value);
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },

    GetPolicy() {
      let claim_policyno = document.getElementById("claim_policyno").value
      let formdata = {
        policy_no: claim_policyno,
        order_by: "policy_no",
        order_type: "ASC",
        limit: this.limit,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListPolicy"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log("GetPolicy", feedback);
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.claim_policyno = feedback[0].policy_no;
              this.form.claim_certificateno = feedback[0].policy_certificateno;
              this.form.claim_policyrow = feedback[0].policy_row;
              this.form.claim_campaign_id = feedback[0].policy_campaign;
              this.form.claim_campaign_id_desc =
                feedback[0].policy_campaign_desc;
              this.form.claim_policytype_id = feedback[0].policy_subclass;
              this.form.claim_policytype_id_desc =
                feedback[0].policy_subclass_desc;
              this.form.claim_branch_id = feedback[0].policy_branch;
              this.form.claim_branch_id_desc = feedback[0].policy_branch_desc;
              this.form.claim_insuredname = feedback[0].policy_insured;

              this.form.claim_refno = feedback[0].policy_refno;
              if (
                this.form.claim_policyno != "" &&
                this.form.claim_certificateno != ""
              ) {
                this.WriteStep1();
              }
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    // Step 1
    Step1() {
      this.GetPolicy();
    },
    WriteStep1() {
      this.loading.page = true;
      let formdata = {
        url_type: this.url_type,
        claim_policyrow: this.form.claim_policyrow,
        claim_dateofloss: this.form.claim_dateofloss,
        claim_timeofloss: this.form.claim_timeofloss,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClaim"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Claim Available", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.form.claim_row = feedback[0].claim_row;
              this.pp_la = 2;
              this.loading.page = false;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.loading.page = false;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
            this.loading.page = false;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.page = false;
        });
    },

    // Step 2
    IEDMOnChange(flag) {
      let day = parseInt(this.form.policy_day);
      let month = parseInt(this.form.policy_month);
      let formdata = {
        url_type: flag,
        calculate_inception: this.form.policy_inception,
        calculate_expiry: this.form.policy_expiry,
        calculate_day: day,
        calculate_month: month,
      };
      console.log("Param IEDM", formdata);
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteCalculate"), param, {
          headers: {
            "Content-Type": "text/plain charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          console.log("Response", feedback);
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.form.policy_inception = feedback[0].calculate_inception;
              this.form.policy_expiry = feedback[0].calculate_expiry;
              this.form.policy_day = feedback[0].calculate_day;
              this.form.policy_month = feedback[0].calculate_month;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
            }
          } else {
            this.SnackBar(true, "error", feedback, 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
        });
    },
    Step2() {},
    WriteStep2() {
      let claim_handling_branch_id = document.getElementById("claim_handling_branch_id").value
      let claim_col_id = document.getElementById("claim_col_id").value
      let claim_surveyor = document.getElementById("claim_surveyor").value
      let claim_assesor = document.getElementById("claim_assesor").value
      this.loading.page = true;
      let formdata = {
        url_type: "writestep2",
        claim_row: this.form.claim_row,
        claim_handling_branch_id: claim_handling_branch_id,
        claim_dateofloss: this.form.claim_dateofloss,
        claim_timeofloss: this.form.claim_timeofloss,
        claim_reportdate: this.form.claim_reportdate,
        claim_reporttime: this.form.claim_reporttime,
        claim_col_id: claim_col_id,
        claim_surveyor: claim_surveyor,
        claim_assesor: claim_assesor,
        claim_reportname: this.form.claim_reportname,
        claim_reportemail: this.form.claim_reportemail,
        claim_reportmobileno: this.form.claim_reportmobileno,
        claim_reportmobilewaf: this.$functions.ActivedFlag(this.form.claim_reportmobilewaf),
        claim_remarks: this.form.claim_remarks,
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiWriteClaim"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              this.SnackBar(true, "primary", "Claim Save Successfully", 3000);
              this.confirm.dialog = false;
              this.confirm.text = "Ok";
              this.loading.page = false;
            } else {
              this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
              this.remarks.dialog = true;
              this.remarks.feedback = feedback[0].feedback;
              this.loading.page = false;
            }
          } else {
            this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
            this.remarks.dialog = true;
            this.remarks.feedback = feedback;
            this.loading.page = false;
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
          this.remarks.dialog = true;
          this.remarks.feedback = e;
          this.loading.page = false;
        });
    },
    WriteStep3() {},
    WriteStep4() {},
    WriteStep5() {},

    SnackBar(dialog, color, text, timeout) {
      if (text !== "No Data Available") {
        this.snackbar = {
          dialog: dialog,
          color: color,
          text: text,
          timeout: timeout,
        };
        if (color === "error") {
          this.confirm.dialog = false;
          this.confirm.text = "Ok";
        }
      }
    },
    OpenRisk() {
      this.risk.dialog.form = true;
      this.AddRisk();
    },
    AddRisk() {
      this.url_type = this.url_type;
      this.risk.form.input.profile_id = "";
      this.risk.form.input.address = "";
      setTimeout(function () {
        document.getElementById("risk_from").focus();
      }, 500);
    },
    BreadcrumbURL(url) {
      if (url === "Print") {
        this.dialog = true;
      }
    },
    DetailData (id) {
      if (id !== undefined) {
        this.loading.page = true;
        let formdata = {
            claim_row: id,
            order_by: "claim_row",
            order_type: "ASC",
            limit: this.limit,
          };
          let param = this.$functions.ParamPOST(formdata);
          this.$axios
            .post(this.$functions.UrlPOST("apiListClaim"), param, {
              headers: {
                "Content-Type": "text/plain; charset=ISO-8859-1",
              },
            })
            .then((response) => {
              let feedback = response.data;
              if (feedback.length > 0) {
                if (feedback[0].feedback === "Y") {
                this.pp_la  = 2
                this.form.claim_dateofloss = this.$functions.DateNull(feedback[0].claim_dateofloss)
                this.form.claim_timeofloss = feedback[0].claim_timeofloss
                this.form.claim_reportdate = this.$functions.DateNull(feedback[0].claim_reportdate)
                this.form.claim_reporttime = feedback[0].claim_reporttime
                this.form.claim_row        = feedback[0].claim_row
                this.form.claim_no         = feedback[0].claim_no
                this.form.claim_policyrow  = feedback[0].claim_policyrow
                this.form.claim_policyno   = feedback[0].claim_policyno
                this.form.claim_campaign_id = feedback[0].claim_campaign_id
                this.form.claim_subclass_id = feedback[0].claim_subclass_id
                this.form.claim_subclass_desc = feedback[0].claim_subclass_desc
                this.form.claim_branch_id = feedback[0].claim_branch_id
                this.form.claim_branch_desc = feedback[0].claim_branch_desc
                this.form.claim_handling_branch_id = feedback[0].claim_handling_branch_id
                this.form.claim_handling_branch_desc = feedback[0].claim_handling_branch_desc
                this.form.claim_insuredname = feedback[0].claim_insuredname
                this.form.claim_col_id = feedback[0].claim_col_id
                this.form.claim_col_desc = feedback[0].claim_col_desc
                this.form.claim_surveyor = feedback[0].claim_surveyor
                this.form.claim_surveyor_desc = feedback[0].claim_surveyor_desc
                this.form.claim_assesor = feedback[0].claim_assesor
                this.form.claim_assesor_desc = feedback[0].claim_assesor_desc
                this.form.claim_reportname = feedback[0].claim_reportname
                this.form.claim_reportemail = feedback[0].claim_reportemail
                this.form.claim_reportmobileno = feedback[0].claim_reportmobileno
                this.form.claim_reportmobilewaf = this.$functions.TrueOrFalse(feedback[0].claim_reportmobilewaf)
                this.form.claim_remarks = feedback[0].claim_remarks
                this.form.claim_revisionno = feedback[0].claim_revisionno
                this.form.claim_correctionno = feedback[0].claim_correctionno
                this.form.claim_status = feedback[0].claim_status
                this.form.claim_statustype = feedback[0].claim_statustype
                this.loading.page = false
                } else {
                  this.SnackBar(true, "error", this.$functions.ErrorCode(), 0);
                  this.loading.page = false
                }
              } else {
                this.SnackBar(true, "error", feedback, 0);
                this.loading.page = false
              }
            })
            .catch((e) => {
              this.SnackBar(true, "error", e, 0);
              this.loading.page = false
            });
      }
    }
  },
};
</script>
  