<template>
  <div>
      <v-dialog
          v-model="dialogrisk"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
      >
      <v-card>
          <v-toolbar dark color="primary" dense>
          <v-btn icon dark @click.stop="SearchClose()">
              <v-icon>{{$vars.V('icon_back')}}</v-icon>
          </v-btn>
          <v-toolbar-title>{{ dialog_title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="AddData"
          >
              Add 
              <v-icon>{{$vars.V('icon_add')}}</v-icon>
          </v-btn> &nbsp;
          <v-btn
              text
              @click="ConfirmData"
          >
              Save
              <v-icon>{{$vars.V('icon_save')}}</v-icon>
          </v-btn>
          </v-toolbar>
          <v-container grid-list-md class="pa-12">
          <v-form
                enctype="multipart/form-data"
                ref="form_policyrisk"
                lazy-validation
              >
              <v-layout row wrap>
                  <!-- Profile ID -->
                  <v-col cols="12" sm="9" md="9" class="mt-n0">
                    <app-cb
                      cb_type               ="profile"
                      cb_url                ="apiListProfile"
                      cb_url_parameter      ="profile_actived=Y"
                      cb_title              ="Biz. Source"
                      cb_id                 ="policysource_profileid"
                      cb_desc               ="policysource_profile_desc"
                      cb_rules              ="Please fill Biz Source"
                      cb_desc_readonly      ="readonly"
                      cb_items_id           ="profile_id"
                      cb_items_desc         ="profile_name"
                      cb_items_additional_1 ="profile_tax_pct"
                      cb_items_additional_2 ="profile_vat_pct"
                      :cb_value_id          ="register.policysource_profileid"
                      :cb_value_desc        ="register.policysource_profiledesc"
                      @clicked              ="GetBizSource"
                    >
                    </app-cb>
                  </v-col>
      
                  <!-- Type -->
                  <v-col cols="12" sm="3" md="3" class="mt-n3">
                    <v-combobox
                      v-model       ="register.policysource_type"
                      required
                      :rules        ="register.policysource_type_rules"
                      :items        ="policysource_type"
                      label         ="Type"
                      placeholder   ="Type"
                      id            ="policysource_type"
                      @keyup.enter  ="Confirm('write', '')"
                    >
                    </v-combobox>
                  </v-col>
      
                  <!-- Main Flag -->
                  <v-col cols="12" sm="3" md="3" class="mt-n5">
                    <small>Main Flag</small><br />
                    <v-btn-toggle
                      dense
                      v-model ="register.policysource_mainflag"
                      rounded
                    >
                      <v-btn small> No </v-btn>
                      <v-btn small> Yes </v-btn>
                    </v-btn-toggle>
                  </v-col>
      
                  <!-- Net After Member Flag -->
                  <v-col cols="12" sm="3" md="3" class="mt-n5">
                    <small>Net After Member Flag</small><br />
                    <v-btn-toggle
                      dense
                      v-model="register.policysource_netaftermemberflag"
                      rounded
                    >
                      <v-btn small> No </v-btn>
                      <v-btn small> Yes </v-btn>
                    </v-btn-toggle>
                  </v-col>
      
                  <!-- Gross Commision Flag -->
                  <v-col cols="12" sm="3" md="3" class="mt-n5">
                    <small>Gross Commision Flag</small><br />
                    <v-btn-toggle
                      dense
                      v-model="register.policysource_grosscommflag"
                      rounded
                    >
                      <v-btn small> No </v-btn>
                      <v-btn small> Yes </v-btn>
                    </v-btn-toggle>
                  </v-col>
      
                  <!-- Member Fee Flag -->
                  <v-col cols="12" sm="3" md="3" class="mt-n5">
                    <small>Member Fee Flag</small><br />
                    <v-btn-toggle
                      dense
                      v-model="register.policysource_memberfeeflag"
                      rounded
                    >
                      <v-btn small> No </v-btn>
                      <v-btn small> Yes </v-btn>
                    </v-btn-toggle>
                  </v-col>
      
                  <!-- Fee Pct -->
                  <v-col cols="12" sm="2" md="2">
                    <vuetify-money
                        v-model         ="register.policysource_feepct"
                        label           ="Fee PCT"
                        placeholder     ="Fee PCT"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                    </vuetify-money>
                  </v-col>
      
                  <!-- Tax -->
                  <v-col cols="12" sm="2" md="2">
                    <vuetify-money
                        v-model         ="register.policysource_tax"
                        label           ="Tax"
                        placeholder     ="Tax"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                    </vuetify-money>
                  </v-col>
      
                  <!-- Vat -->
                  <v-col cols="12" sm="2" md="2">
                    <vuetify-money
                        v-model         ="register.policysource_vat"
                        label           ="Vat"
                        placeholder     ="Vat"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                    </vuetify-money>
                  </v-col>
      
                  <!-- Discount -->
                  <v-col cols="12" sm="2" md="2">
                    <vuetify-money
                        v-model         ="register.policysource_discount"
                        label           ="Discount"
                        placeholder     ="Discount"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                    </vuetify-money>
                  </v-col>
      
                  <!-- Fee Amt -->
                  <v-col cols="12" sm="2" md="2">
                    <vuetify-money
                        v-model         ="register.policysource_feeamt"
                        label           ="Fee Amount"
                        placeholder     ="Fee Amount"
                        backgroundColor ="numeric"
                        :options        ="format_amount"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                    </vuetify-money>
                  </v-col>
      
                  <!-- Add Fee Pct -->
                  <v-col cols="12" sm="2" md="2">
                    <vuetify-money
                        v-model         ="register.policysource_addfeepct"
                        label           ="Additional Fee"
                        placeholder     ="Additional Fee"
                        backgroundColor ="numeric"
                        :options        ="format_rate"
                        :properties     ="properties"
                        valueWhenIsEmpty ="0"
                      >
                    </vuetify-money>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Search"
                      single-line
                      hide-details
                      @click:append="ListData"
                      ></v-text-field>
                    <v-data-table
                        :headers="header['source']"
                        :items="items['source']"
                        :loading="loading.data"
                        :search="search"
                        loading-text="Loading... Please wait"
                        dense
                        item-key="dataid"
                        class="elevation-1 row-pointer"
                    >
                        <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                            {{ props.header.text }}
                            </span>
                            <span>
                            {{ props.header.text }}
                            </span>
                        </v-tooltip>
                        </template>
                        <template v-slot:no-data> No Data Available </template>
                        <template v-slot:item.action="{ item }">
                          <v-icon small class="mr-2" @click="EditData(item.policysource_policyrow, item.policysource_profileid)">
                            {{$vars.V('icon_edit')}}
                          </v-icon>
                          <v-icon small @click="Delete(item.policysource_policyrow, item.policysource_profileid)">
                            {{$vars.V('icon_delete')}}
                          </v-icon>
                      </template>
                    </v-data-table>
                    </v-col>
          </v-layout>
          </v-form>
          </v-container>
      </v-card>
      </v-dialog>
       <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
          <v-card>
            <v-card-title class="primary white--text"
              >{{ confirm.title }}?</v-card-title
            >
            <v-card-text></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                id="dialog"
                color="primary"
                :disabled="confirm.text === 'Ok' ? false : true"
                @click="Loading"
                >{{ confirm.text }}
              </v-btn>
              <v-btn color="error" @click="confirm.dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-snackbar
      :value	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      :color		="snackbar.color"
      rounded	  ="pill"
      top
      style 	  ="z-index: 9999;"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.dialog =false" small>Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
  dialog: Boolean,
  dialog_title: String,
  dialog_id: String,
  dialog_param: String,
},
data: () => ({
  url_type: 'add',
  limit: '1000',
  search: '',
  header: {
    source: [
      { text: 'Actions', value: 'action', sortable: false },
      {
        text: "ID",
        value: "policysource_profileid",
        align: "left",
      },
      { text: "Description", value: "policysource_profiledesc" },
      { text: "Type", value: "policysource_type" },
      { text: "Fee PCT", value: "policysource_feepct",align: "right" },
      { text: "Tax", value: "policysource_tax",align: "right" },
      { text: "Vat", value: "policysource_vat",align: "right" },
      { text: "Additional Fee", value: "policysource_addfeepct",align: "right" },
      { text: "Main Flag", value: "policysource_mainflag" },
    ],
  },
  items: [],
  loading: {
    data: false
  },
  policysource_type : ['A','B','D'],
  policyint_category:[],
  register: {
   policysource_policyrow: '',
   policysource_profileid: '',
   policysource_profileid_old: '',
   policysource_profiledesc: '',
   policysource_type: 'A',
   policysource_mainflag: 0,
   policysource_netaftermemberflag: 0,
   policysource_grosscommflag: 0,
   policysource_memberfeeflag: 0,
   policysource_feepct: 0,
   policysource_tax: 0,
   policysource_vat: 0,
   policysource_discount: 0,
   policysource_feeamt: 0,
   policysource_addfeepct: 0,
  },
  maxinput: {
    desc: 255
  },
  snackbar: {
    dialog: false,
    color: 'primary',
    text: '',
    timeout: 3000
  },
  modal: {
    sdate: false,
    edate: false,
  },
  format_amount: {
    locale: "id-ID",
    prefix: "",
    suffix: "",
    length: 15,
    precision: 0
  },
  format_rate: {
    locale: "id-ID",
    prefix: "",
    suffix: "",
    length: 15,
    precision: 4
  },
  // Format Properties
  properties: {
    reverse: true
  },
  confirm: {
    dialog: false,
    title: "",
    text: "Ok",
  },
}),
computed: {
  dialogrisk: {
      get() {
          return this.dialog
      },
      set(value) {
          if (!value) {
              this.$emit('close')
          }
      }
  }
},
watch: {
  dialog: function (newdata) {
    if (newdata !== "") {
      this.ListData()
    }
  },
},
methods: {
  SearchClose() {
      this.dialogrisk = false
  },
  AddData() {
    this.url_type = 'add'
    // this.$set(this.policysource_profileid, 0, '')
    this.register.policysource_profileid    = ''
    this.register.policysource_profiledesc  = ''
    this.register.policysource_type         = 'A'
    this.register.policysource_mainflag     = 0
    this.register.policysource_netaftermemberflag = 0
    this.register.policysource_grosscommflag = 0
    this.register.policysource_memberfeeflag = 0
    this.register.policysource_feepct = 0
    this.register.policysource_tax = 0
    this.register.policysource_vat = 0
    this.register.policysource_discount = 0
    this.register.policysource_feeamt = 0
    this.register.policysource_addfeepct = 0
  },
  EditData(id,cov) {
    // this.$set(this.policy_ccy, 0, '')
    this.loading.data = true
    let formdata = {
      policysource_policyrow: id,
      policysource_profileid: cov,
      order_by: "policysource_profileid",
      order_type: "ASC",
      limit: this.limit,
  }
  let param = this.$functions.ParamPOST(formdata)
  this.$axios
    .post(this.$functions.UrlPOST("apiListPolicySource"), param, {
      headers: {
        "Content-Type": "text/plain charset=ISO-8859-1",
      },
    })
    .then((response) => {
      let feedback = response.data
      if (feedback.length > 0) {
        if (feedback[0].feedback === "Y") {
            this.loading.data = false
            this.url_type = 'edit'
            this.register.policysource_policyrow = feedback[0].policysource_policyrow
            this.register.policysource_profileid = feedback[0].policysource_profileid
            this.register.policysource_profileid_old = feedback[0].policysource_profileid
            this.register.policysource_profiledesc = feedback[0].policysource_profiledesc
            this.register.policysource_type = feedback[0].policysource_type
            this.register.policysource_mainflag = this.$functions.YesOrNo(feedback[0].policysource_mainflag)
            this.register.policysource_netaftermemberflag = this.$functions.YesOrNo(feedback[0].policysource_netaftermemberflag)
            this.register.policysource_grosscommflag = this.$functions.YesOrNo(feedback[0].policysource_grosscommflag)
            this.register.policysource_memberfeeflag = this.$functions.YesOrNo(feedback[0].policysource_memberfeeflag)
            this.register.policysource_feepct = feedback[0].policysource_feepct
            this.register.policysource_tax = feedback[0].policysource_tax
            this.register.policysource_vat = feedback[0].policysource_vat
            this.register.policysource_discount = feedback[0].policysource_discount
            this.register.policysource_feeamt = feedback[0].policysource_feeamt
            this.register.policysource_addfeepct = feedback[0].policysource_addfeepct
        } else {
            this.loading.data = false
            this.SnackBar(true, "error", feedback[0].feedback, 0)
        }
      } else {
        this.loading.data = false
        this.SnackBar(true, "error", 'No Data Available', 0)
      }
    })
  },
  ListData() {
    this.items['source'] = []
    this.register.policysource_sdate = this.dialog_sdate
    this.register.policysource_edate = this.dialog_edate
    this.loading.data = true
    let formdata = {
      policysource_policyrow: this.dialog_id,
      order_by: "policysource_profileid",
      order_type: "ASC",
      limit: this.limit,
  }
  let param = this.$functions.ParamPOST(formdata)
  this.$axios
    .post(this.$functions.UrlPOST("apiListPolicySource"), param, {
      headers: {
        "Content-Type": "text/plain charset=ISO-8859-1",
      },
    })
    .then((response) => {
      let feedback = response.data
      if (feedback.length > 0) {
        if (feedback[0].feedback === "Y") {
            this.loading.data = false
            this.items['source'] = feedback
        } else {
            this.loading.data = false
            this.items['source'] = feedback
            this.SnackBar(true, "error", feedback[0].feedback, 0)
        }
      } else {
        this.loading.data = false
        // this.SnackBar(true, "error", 'No Data Available', 0)
      }
    })
  },
  Delete(id, cov) {
    this.url_type = 'delete'
    this.register.policysource_policyrow = id
    this.register.policysource_profileid = cov
    this.ConfirmData()
  },
  ConfirmData () {
    if (this.url_type ==='add' || this.url_type === 'edit') {
      if (this.$refs.form_policyrisk.validate()) {
        this.confirm.dialog = true
        this.confirm.title  = 'Confirm'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    } else if (this.url_type === 'delete') {
      this.confirm.dialog = true
      this.confirm.title  = 'Delete'
      setTimeout(function(){
        document.getElementById('dialog').focus()
      }, 500)
    }
  },
  Loading () {
    this.confirm.text ='Loading...'
    if (this.url_type ==='add' || this.url_type ==='edit') {
      this.SaveData()
    } else if (this.url_type ==='delete') {
      this.DeleteData()
    }
  },
  SaveData() {
    this.loading.data = true
    let formdata = {
      url_type: this.url_type,
      policysource_policyrow: this.dialog_id,
      policysource_profileid_old: this.register.policysource_profileid_old,
      policysource_profileid: this.register.policysource_profileid,
      policysource_type: this.register.policysource_type,
      policysource_mainflag: this.$functions.SwitchFlag(this.register.policysource_mainflag),
      policysource_netaftermemberflag: this.$functions.SwitchFlag(this.register.policysource_netaftermemberflag),
      policysource_grosscommflag: this.$functions.SwitchFlag(this.register.policysource_grosscommflag),
      policysource_memberfeeflag: this.$functions.SwitchFlag(this.register.policysource_memberfeeflag),
      policysource_feepct: String(this.register.policysource_feepct),
      policysource_tax: String(this.register.policysource_tax),
      policysource_vat: String(this.register.policysource_vat),
      policysource_discount: String(this.register.policysource_discount),
      policysource_feeamt: String(this.register.policysource_feeamt),
      policysource_addfeepct: String(this.register.policysource_addfeepct),
  }
  let param = this.$functions.ParamPOST(formdata)
  this.$axios
    .post(this.$functions.UrlPOST("apiWritePolicySource"), param, {
      headers: {
        "Content-Type": "text/plain charset=ISO-8859-1",
      },
    })
    .then((response) => {
      let feedback = response.data
      if (feedback.length > 0) {
        if (feedback[0].feedback === "Y") {
            this.loading.data = false
            this.items[this.dialog_title] = feedback
            this.SnackBar(true, 'primary', 'Save Successfully', 0)
            this.ListData()
        } else {
            this.loading.data = false
            this.SnackBar(true, "error", feedback[0].feedback, 0)
        }
      } else {
        this.loading.data = false
        this.SnackBar(true, "error", 'No Data Available', 0)
      }
    })
  },
  DeleteData() {
    this.loading.data = true
    let formdata = {
      url_type: this.url_type,
      policysource_policyrow: this.register.policysource_policyrow,
      policysource_profileid: this.register.policysource_profileid,
  }
  let param = this.$functions.ParamPOST(formdata)
  this.$axios
    .post(this.$functions.UrlPOST("apiWritePolicySource"), param, {
      headers: {
        "Content-Type": "text/plain charset=ISO-8859-1",
      },
    })
    .then((response) => {
      let feedback = response.data
      if (feedback.length > 0) {
        if (feedback[0].feedback === "Y") {
            this.loading.data = false
            this.items[this.dialog_title] = feedback
            this.SnackBar(true, 'primary', 'Delete Successfully', 0)
            this.ListData()
        } else {
            this.loading.data = false
            this.SnackBar(true, "error", feedback[0].feedback, 0)
        }
      } else {
        this.loading.data = false
        this.SnackBar(true, "error", 'No Data Available', 0)
      }
    })
  },
  GetBizSource (value){
      let split = value.split('|')
      this.register.policysource_profileid = split[0]
      this.register.policysource_profiledesc = split[1]
  },
  SnackBar(dialog, color, text, timeout) {
    this.snackbar = {
      dialog: dialog,
      color: color,
      text: text,
      timeout: timeout,
    }
    this.confirm.dialog = false
    this.confirm.text = "Ok"
  },
},
};
</script>