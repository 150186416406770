<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Riskloss"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color ="fourth"
      rounded
      v-if  ="loading.page"
    ></v-progress-linear>
    <v-tabs
      background-color="white"
      color="fourth"
      v-if = "this.access.read === 1"
    >
      <v-tab
        id       = "tabform"
        href   	 ="#tab-form"
        @click 	 = "FirstLoad"
      >
        Form
      </v-tab>
      <v-tab 
        href="#tab-list"
        @click = "List"
      >
        List
      </v-tab>
			<v-tab-item
        value  ="tab-form"
			>
        <v-form enctype="multipart/form-data" ref="form_riskloss" lazy-validation>
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color	    = "tertiary"
                class     = "white--text"
                :disabled = "access.add === 0 ? true:false" 
                rounded
                @click 	  = "Add" 
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn> &nbsp;
              <v-btn
                  color		  = "primary"
                  class		  = "white--text"
                  :disabled = "access.add === 1 || access.edit === 1 ? false:true"
                  rounded
                  @click 		= "Confirm('write','')" 
                  small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field 
                v-model 	  	= "form.riskloss_id"
                required 
                :rules 	  		= "form.riskloss_id_rules"
                :readonly    	= "form.riskloss_id_readonly"
                :counter		  = "maxinput.id"
                :maxlength    = "maxinput.id"
                label       	= "ID *"
                placeholder   = "ID"
                id 				    = "riskloss_id"
                @keyup.enter  = "Confirm('write','')"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field 
                v-model 	  	= "form.riskloss_desc"
                required 
                :rules 	  		= "form.riskloss_desc_rules"
                label       	= "Description"
                placeholder 	= "Description"
                id 				    = "riskloss_desc"
                @keyup.enter 	= "Confirm('write','')"
              >
              </v-text-field>
            </v-col>

            <!-- Currency -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <app-cb
                cb_type           = "currency"
                cb_url            = "apiListCurrency"
                cb_url_parameter  = "currency_actived=Y"
                cb_title          = "Currency"
                cb_id             = "riskloss_currency"
                cb_desc           = "riskloss_currency_desc"
                cb_rules          = "Please fill Currency"
                cb_desc_readonly  = "readonly"
                cb_items_id       = "currency_id"
                cb_items_desc     = "currency_desc"
                :cb_value_id		  = "form.riskloss_currency"
                :cb_value_desc		= "form.riskloss_currency_desc"
              >
              </app-cb>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-combobox
                v-model 	  	    = "form.riskloss_category"
                required 
                :rules 	  		    ="form.riskloss_category_rules"
                :items        	    ="riskloss_category"
                label       	    = "Category"
                placeholder 	    = "Category"
                hint                = "(R: Risk, L: Loss, C: Company,)"
                persistent-hint
                id 				    = "riskloss_category"
                @keyup.enter 	    = "Confirm('write','')"
              >
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
                <v-switch
                  v-model			="form.riskloss_actived"
                  color  			= "primary"
                  id 				= "form.riskloss_actived"
                  :label 			="form.riskloss_actived !== true ? '' : 'Active'" 
                  @keyup.enter 	= "Confirm('write','')"
                >
              </v-switch>
            </v-col>
          </v-row>

          <!-- Info Riskloss -->
          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
            <div v-show="url_type !== 'add' ? true:false ">
              <v-checkbox v-model="cont.riskloss" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.riskloss" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.riskloss_cdate}}<br>Created User 	 : {{form.riskloss_cuser}}<br>Last Update Date : {{form.riskloss_ldate}}<br>Last Update User : {{form.riskloss_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Riskloss -->
        </v-form>

        <template v-if="url_type !== 'add'">
          <!-- Tab Sub Module -->
          <v-tabs
            background-color="white"
            color="fourth"
            v-if = "access.read === 1"
          >
            <v-tab
              id      = "tabrange"
              href   	= "#tab-range"	
              @click	= "ListRange(form.riskloss_id)"
            >
              Range
					  </v-tab>
            <v-tab 
              id      = "tabsubclass"
              href	  = "#tab-subclass"
              @click 	= "ListSubClass(form.riskloss_id)"
            >
              Sub Class
            </v-tab>
            <v-tab-item
              value  = "tab-range"
            >
              <!-- Data Table Range -->
              <template>
                <!-- Main Button Range -->
                <v-card-title
                  class="ml-n4"
                >
                  <v-btn 
                    :color 	= "range.selected.length === range.items.length ? 'fourth':''"
                    @click	= "SelectAllRange"
                    class	  = "mr-2"
                    title	  = "select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn 
                    @click  = "OpenRange"
                    class	  = "mr-2"
                    title	  = "add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn 
                    color     = "error"
                    title 	  = "delete selected"
                    @click 	  = "Confirm('multideleterange','')"
                    class	    = "mr-2"
                    v-if  	  = "range.selected.length > 0"
                    :disabled = "access.delete === 0 ? true:false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn 
                    color  = "primary"
                    title  = "search"
                    v-if   = "range.searchbutton === false"
                    @click = "SearchRange"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model				= "range.search"
                    append-icon		= "search"
                    @click:append = "range.searchbutton = false"
                    label				  = "Type and Enter"
                    single-line
                    hide-details
                    v-if 				  = "range.searchbutton"
                    id   				  = "searchrange"
                    clearable
                    @keyup.enter 	= "ListRange(form.riskloss_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Range  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value        ="range.selected"
                  :headers      ="range.headers"
                  :items        ="ItemsRange"
                  :search       ="range.search"
                  @input        ="CheckRange($event)"
                  item-key      ="risklossrange_row"
                  show-select
                  class         ="elevation-1"
                  :footer-props ="{
                    showFirstLastPage : true,
                    firstIcon         : 'first_page',
                    lastIcon          : 'last_page',
                    prevIcon          : 'keyboard_arrow_left',
                    nextIcon          : 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	    ="loading.listrange"
                  loading-text  ="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	    = "mr-2"
                      @click	  = "EditRange(item.risklossrange_id, item.risklossrange_min, item.risklossrange_max)"
                      :disabled = "access.edit === 0 ? true:false"
                      title 	  = "edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click	  = "Confirm('deleterange',item.risklossrange_id, item.risklossrange_min, item.risklossrange_max)"
                      :disabled = "access.delete === 0 ? true:false"
                      title 	  = "delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Range -->
            </v-tab-item>            
            <v-tab-item
              value  ="tab-subclass"
            >
              <!-- Data Table Sub Class -->
              <template>
                <!-- Main Button Sub Class -->
                <v-card-title
                  class="ml-n4"
                >
                  <v-btn 
                    :color 	= "subclass.selected.length === subclass.items.length ? 'fourth':''"									
                    @click	= "SelectAllSubClass"
                    class	  = "mr-2"
                    title	  = "select all"
                    small
                  >
                    select all
                  </v-btn>								
                  <v-btn 
                    @click	= "OpenSubClass"
                    class	  = "mr-2"
                    title	  = "add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn 
                    color     = "error"
                    title 	  = "delete selected"
                    @click 	  = "Confirm('multideletesubclass','')"
                    class	    = "mr-2"
                    v-if  	  = "subclass.selected.length > 0"
                    :disabled = "access.delete === 0 ? true:false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn 
                    color  = "primary"
                    title  = "search"
                    v-if   = "subclass.searchbutton === false"
                    @click = "SearchSubClass"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model			  = "subclass.search"
                    append-icon		= "search"
                    @click:append	= "subclass.searchbutton = false"
                    label			    = "Type and Enter"
                    single-line
                    hide-details
                    v-if 			    = "subclass.searchbutton"
                    id   			    = "searchsubclass"
                    clearable
                    @keyup.enter 	= "ListSubClass(form.riskloss_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Subclass  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value        ="subclass.selected"
                  :headers      ="subclass.headers"
                  :items        ="subclass.items"
                  :search       ="subclass.search"
                  @input        ="CheckSubClass($event)"
                  item-key      ="risklosssubclass_risklossid"
                  show-select
                  class         ="elevation-1"
                  :footer-props ="{
                    showFirstLastPage : true,
                    firstIcon         : 'first_page',
                    lastIcon          : 'last_page',
                    prevIcon          : 'keyboard_arrow_left',
                    nextIcon          : 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	    ="loading.listsubclass"
                  loading-text  ="Loading... Please wait"
                >        
                  <template v-slot:item.risklosssubclass_id="{ item }">
                    {{ item.risklosssubclass_id }} - {{ item.risklosssubclass_desc }}
                  </template>          
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	    ="mr-2"
                      @click	  ="EditSubClass(item.risklosssubclass_risklossid, item.risklosssubclass_id)"
                      :disabled ="access.edit === 0 ? true:false"
                      title 	  ="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click	  ="Confirm('deletesubclass',item.risklosssubclass_risklossid, item.risklosssubclass_id)"
                      :disabled ="access.delete === 0 ? true:false"
                      title 	  ="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Subclass -->
            </v-tab-item>
            <!--  -->
          </v-tabs>
				  <!-- end: Tab Sub Module --> 
        </template>
      </v-tab-item>

      <v-tab-item
        value="tab-list"
      >
        <template>
          <v-card-title 
            class="ml-n4"
          >
            <v-btn 
              :color 	="selected.length === items.length ? 'secondary':''"
              @click  ="SelectAllPage"
              class	  ="mr-2"
              title	  ="select all page"
              small
            >
              select all page
            </v-btn>

            <v-btn 
              color     ="error"
              title 	  ="delete selected"
              @click 	  ="Confirm('multidelete','')"
              class	    ="mr-2"
              v-if  	  ="selected.length > 0"
              :disabled ="access.delete === 0 ? true:false"
              small
            >
              <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>

            <v-btn 
              color  ="primary"
              title  ="search"
              v-if   ="btn_search === false"
              @click ="btn_search = true"
              small
            >
              <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-text-field
              v-model       ="search"
              append-icon   ="search"
              @click:append ="btn_search = false"
              label         ="Search"
              single-line
              hide-details
              v-if          ="btn_search"
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :value        ="selected"
            :headers      ="headers"
            :items        ="items"
            :search       ="search"
            @input        ="Check($event)"
            item-key      ="riskloss_id"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
            dense
            :loading 	    ="loading.list"
            loading-text  ="Loading... Please wait"
          >
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class	    ="mr-2"
                @click	  ="Edit(item.riskloss_id)"
                :disabled ="access.edit === 0 ? true:false"
                title 	  ="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click	  ="Confirm('delete',item.riskloss_id)"
                :disabled ="access.delete === 0 ? true:false"
                title 	  ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
  	</v-tabs>

    <!-- Dialog Range -->
    <v-dialog
      v-model="dialog.range"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="550"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('range')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Range</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddRange" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.rangedialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_range" lazy-validation>          
          <v-row class="mx-2 mt-2">
            <v-col cols="12" sm="6" md="6" class="mt-n3">							
              <v-text-field 
                v-model.lazy 		  = "form.risklossrange_min"
                ref 				      = "risklossrange_min"
                label 				    = "Minimum Range"
                placeholder 		  = "Rp 0"
                background-color  = "numeric"
                v-money 			    = "format_money"
                id 		      	    = "risklossrange_min"
                reverse
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-n3">							
              <v-text-field 
                v-model.lazy 		  = "form.risklossrange_max"
                ref 				      = "risklossrange_max"
                label 				    = "Max Range"
                placeholder 		  = "Rp 0"
                background-color  = "numeric"
                v-money 			    = "format_money"
                id 		      	    = "risklossrange_max"
                reverse							
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Info Range -->
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
            <div v-show="url_type !== 'addrange' ? true:false ">
              <v-checkbox v-model="cont.range" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.range" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.risklossrange_cdate}}<br>Created User 	 : {{form.risklossrange_cuser}}<br>Last Update Date : {{form.risklossrange_ldate}}<br>Last Update User : {{form.risklossrange_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Range -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>        	
      </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end: Dialog Range -->

    <!-- Dialog Sub Class -->
    <v-dialog
      v-model="dialog.subclass"
      scrollable
      transition="dialog-bottom-transition"
      persistent
      max-width="550"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('subclass')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Sub Class</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddSubClass" title="Add New Sub Class"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Sub Class"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.subclassdialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_subclass" lazy-validation>          
          <v-row class="mx-2 mt-2">
            <v-col cols="12" sm="12" md="12">
              <app-cb
                cb_type           = "subclass"
                cb_url            = "apiListSubClass"
                cb_url_parameter  = "subclass_actived=Y"
                cb_title          = "Sub Class"
                cb_id             = "risklosssubclass_id"
                cb_desc           = "risklosssubclass_desc"
                cb_rules          = "Please fill Sub Class"
                cb_desc_readonly  = "readonly"
                cb_items_id       = "subclass_id"
                cb_items_desc     = "subclass_desc"
                :cb_value_id		  = "form.risklosssubclass_id"
                :cb_value_desc		= "form.risklosssubclass_desc"
              >
              </app-cb>
            </v-col>
          </v-row>

          <!-- Info Sub Class -->
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
            <div v-show="url_type !== 'addsubclass' ? true:false ">
              <v-checkbox v-model="cont.subclass" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.subclass" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.risklosssubclass_cdate}}<br>Created User 	 : {{form.risklosssubclass_cuser}}<br>Last Update Date : {{form.risklosssubclass_ldate}}<br>Last Update User : {{form.risklosssubclass_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Sub Class -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>        	
      </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end: Dialog Sub Class -->

    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
      <v-card>
        <v-toolbar dark color="fourth">
        <v-btn icon dark @click="remarks.dialog = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model	= "snackbar.dialog"
      :timeout	= "snackbar.timeout"
      color		= "fourth"
      rounded	= "pill"
      absolute
      top
      style 	= "z-index: 9999;"
    >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
        </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
        <v-card>
            <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" color="primary"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
		url_type: 'add',
		url_range_type : '',
		url_subclass_type : '',
    cont: {
		  riskloss  : false,
		  range     : false,
		  subclass  : false,
    },
		contrange: false,
    access: {
      read    : 0,
      add     : 0,
      edit    : 0,
      delete  : 0,
    },
		maxinput: {
			id    :	10,
			desc  :150,
		},
		snackbar: {
			dialog  : false,
			color   : 'success',
			text    : '',
			timeout : 3000
		},
    confirm: {
      dialog  : false,
      title   : '',
      text    : 'Ok',
		},
		dialog: {
			range     : false,
			subclass  : false,
		},
    flag: {
      range         : false,
			showrange     : false,
      subclass      : false,
			showsubclass  : false,
    },
		format_money: {
			decimal   : ',',
			thousands : '.',
			precision : 0,
		},

    // Data Table Riskloss
    headers           : [
      {
        text      : 'ID',
        align     : 'left',
        sortable  : false,
        value     : 'riskloss_id',
      },
      { text: 'Description', value: 'riskloss_desc' },
      { text: 'Actived', value: 'riskloss_actived' },
      { text: 'Actions', value: 'action', sortable: false }
		],
    items         : [],
    selected      : [],
    search        : '',
    searchbutton  : false,
    // end: Data Table Riskloss

    // Data Table Range		
		range: {
      headers: [				
        { text: 'Minimum', value: 'risklossrange_min' },
        { text: 'Maximum', value: 'risklossrange_max' },
        { text: 'Actions', value: 'action', sortable: false }
			],
			items         : [],
			selected      : [],
			search        : "",
			searchbutton  : false,
    },
		// end: Data Table Range

    // Data Table Sub Class		
		subclass: {
      headers: [				
        { text: 'Subclass', value: 'risklosssubclass_id' },
        { text: 'Actions', value: 'action', sortable: false }
			],
			items         : [],
			selected      : [],
			search        : "",
			searchbutton  : false,
    },
		// end: Data Table Sub Class

		remarks: {
			feedback  : '',
			dialog    : false,
    },
    riskloss_category: [
      'R',
      'L',
      'C'
    ],
		accessmenu: [],
    loading: {
      page            : true,
      list            : false,
      listrange       : false,
      listsubclass    : false,
      range           : false,
			rangedialog     : false,
      subclass        : false,
			subclassdialog  : false
    },
    btn_search: true,
    form: {
      riskloss_id             : '',
      riskloss_desc           : '',
      riskloss_currency       : '',
      riskloss_currency_desc  : '',
      riskloss_category       : '',
			riskloss_actived        : true,

      riskloss_id_rules       : [v => !!v || 'Please fill ID'],
      riskloss_desc_rules     : [v => !!v || 'Please fill Description'],
      riskloss_currency_rules : [v => !!v || 'Please fill Currency'],
      riskloss_category_rules : [v => !!v || 'Please fill Category'],

      riskloss_id_readonly  : false,
			riskloss_button       : 0,

      // Range
      risklossrange_id      : '',
      risklossrange_min     : '',
      risklossrange_max     : '',
      risklossrange_cdate   : '',
      risklossrange_cuser   : '',
      risklossrange_ldate   : '',
      risklossrange_luser   : '',
      risklossrange_min_par : '',
      risklossrange_max_par : '',

      risklossrange_min_rules : [v => !!v || 'Please fill Min'],
      risklossrange_max_rules : [v => !!v || 'Please fill Max'],

      // Sub Class
      risklosssubclass_risklossid : '',
      risklosssubclass_id         : '',
      risklosssubclass_desc       : '',
      risklosssubclass_id_par     : '',
      risklosssubclass_cdate      : '',
      risklosssubclass_cuser      : '',
      risklosssubclass_ldate      : '',
      risklosssubclass_luser      : '',

      risklosssubclass_id_rules : [v => !!v || 'Please fill Sub Class'],
    },
    loader        : null,
    loading_save  : false
  }),

  created () {
    this.FirstLoad()
  },

  computed: {
    ItemsRange() {
      for (let i = 0 ;i <= this.range.items.length - 1 ;i++) {
        const rangeindex = this.range.items[i]
        rangeindex.risklossrange_row = this.range.items[i].risklossrange_id+'|'+this.range.items[i].risklossrange_min+'|'+this.range.items[i].risklossrange_max
      }
      return this.range.items
    },
  },

  methods: {
    FirstLoad () {
      //this function use for validating the screen with user privilleges
      this.Access()
    },
		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "riskloss"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListMenuUser"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
          let priv = response.data
          if (priv.length > 0) {
              this.access.read        = priv[0].usersmenu_read
              this.access.add         = priv[0].usersmenu_add
              this.access.edit        = priv[0].usersmenu_edit
              this.access.delete      = priv[0].usersmenu_delete
              if (priv[0].usersmenu_read === 0) {
                this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
              }
          } else {
              this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
          }
          this.loading.page = false
      })
      .catch((e) => {
          this.SnackBar(true, "error", e, 0)
          this.loading.page = false
      })
    },

		//This function use for give feedback when user has no access with some feature
    NoPriviledge () {
      this.SnackBar(true, 'error', 'You dont have access `Risk & Loss` modul, please contact Administrator', 0)
    },

    SelectAllPage () {
      this.selected = this.selected === this.items ? [] : this.items
    },    

    Check (value) {
      this.selected = value
    },

		//Function for get all data from table riskloss with API in mod_riskloss.go
		List() {
			this.loading.list = true
			let formdata = {
				riskloss_id: '',
				riskloss_desc: '',
				order_by: 'riskloss_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRiskloss"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
              this.SnackBar(
                true,
                "error",
                feedback[0].feedback,
                0
              )
          } else {
            this.items = feedback
          }
        } else {
          this.SnackBar(true, "error", feedback, 0)
        }
        this.loading.list = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.list = false
      })
		},

		//Pop Up message for validating (Ok or Cancel) your proccess
    Confirm (flag, id, par1, par2) {      
      if (flag === 'write') {
        if (this.$refs.form_riskloss.validate()) {
          this.confirm.dialog   = true
          this.confirm.title    = 'Save'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type 			= 'delete'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ id
        this.riskloss_id 		= id
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 			= 'multidelete'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'addrange') {
				this.url_type 			= 'addrange'
				this.confirm.dialog = true
				this.confirm.title  = 'Save'
				setTimeout(function(){
				  document.getElementById('dialog').focus()
				}, 500)
      } else if (flag === 'editrange') {
				this.url_type 			= 'editrange'
				this.confirm.dialog = true
				this.confirm.title  = 'Save'
				setTimeout(function(){
				  document.getElementById('dialog').focus()
				}, 500)
      } else if (flag === 'deleterange') {
        this.url_type 			    = 'deleterange'
        this.confirm.dialog     = true
        this.confirm.title    	= 'Delete minimum range `'+ par1 + '` with maximum `' + par2
        this.risklossrange_id   = id
        this.risklossrange_min  = par1
        this.risklossrange_max  = par2
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multideleterange') {
        this.url_type 			= 'multideleterange'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ this.selected_range.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'addsubclass' ){
				this.url_type 	    = 'addsubclass'
				this.confirm.dialog = true
				this.confirm.title  = 'Save'
				setTimeout(function(){
				  document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'editsubclass' ){
				this.url_type       = 'editsubclass'
				this.confirm.dialog = true
				this.confirm.title  = 'Save'
				setTimeout(function(){
				  document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'deletesubclass') {
        this.url_type 			      = 'deletesubclass'
        this.confirm.dialog 	    = true
        this.confirm.title 		    = 'Delete `'+ par1
        this.risklosssubclass_risklossid  = id
        this.risklosssubclass_id  = par1
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multideletesubclass') {
        this.url_type 			= 'multideletesubclass'
        this.confirm.dialog = true
        this.confirm.title 	= 'Delete `'+ this.selected_subclass.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
		},

    Loading () {
      this.confirm.text = 'Loading...'
      if (this.url_type === 'add' || this.url_type === 'edit') {
        this.Write()
      } else if (this.url_type === 'delete') {
        this.Delete(this.riskloss_id)
      } else if (this.url_type === 'multidelete') {
        this.MultiProcess('delete')
			} else if (this.url_type === 'addrange' || this.url_type === 'editrange') {
        this.WriteRange()
      } else if (this.url_type === 'deleterange') {
        this.DeleteRange(this.risklossrange_id, this.risklossrange_min, this.risklossrange_max)        
      } else if (this.url_type === 'multideleterange') {
        this.MultiProcessRange()
      } else if (this.url_type === 'addsubclass' || this.url_type === 'editsubclass') {
        this.WriteSubClass()
      } else if (this.url_type === 'deletesubclass') {
        this.DeleteSubClass(this.risklosssubclass_risklossid, this.risklosssubclass_id)
      } else if (this.url_type === 'multideletesubclass') {
        this.MultiProcessSubClass()
			}
    },

    Close (flag) {
      if (flag === 'attributes') {
        this.url_type = 'edit'
        this.dialog.attributes = false
      } else if (flag === 'confirm') {
        this.confirm.dialog = false
        if (this.url_type !== 'add') {
          this.url_type = 'edit'
        }
      } else if (flag === 'range') {
				this.url_type     = 'edit'
        this.dialog.range = false
				this.ListRange(this.form.riskloss_id)
			} else if (flag === 'subclass') {
				this.url_type     = 'edit'
        this.dialog.subclass = false
				this.ListSubClass(this.form.riskloss_id)
			}
    },

		// Function Riskloss
		Write() {
      let currency
      currency = document.getElementById('riskloss_currency').value
      let formdata = {
        url_type			: this.url_type,
        riskloss_id			: this.form.riskloss_id,
        riskloss_desc		: this.form.riskloss_desc,
        riskloss_currency	: currency,
        riskloss_category	: this.form.riskloss_category,				
        riskloss_actived	: this.$functions.ActivedFlag(this.form.riskloss_actived),
      }
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
			.post(this.$functions.UrlPOST("apiWriteRiskloss"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "add") {
							this.form.expense_cuser = feedback[0].feedback_users_id
							this.form.expense_cdate = feedback[0].feedback_users_date
						}
						this.form.expense_luser = feedback[0].feedback_users_id
						this.form.expense_ldate = feedback[0].feedback_users_date
						this.url_type = "edit"
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback = feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback = feedback
				}
			})
			.catch((e) => {
				this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback = e
			})
		},
		// end: Function Riskloss

		//Function to request insert data to table riskloss 'The API will validating your data, include the user who process the data' (go to APIWriteriskloss with url_type = "add") 
    Add () {
      this.url_type 		 		        = 'add'
      this.form.riskloss_id 	 	    = ''
      this.form.riskloss_desc 	 	  = ''
      this.form.riskloss_category   = ''

      document.getElementById('riskloss_currency').value = ''

      this.form.riskloss_currency_desc  = ''  
      this.form.riskloss_actived 	      = true
      this.form.riskloss_id_readonly    = false
      setTimeout(function(){
        document.getElementById('riskloss_id').focus()
      }, 500)
		},

		//Function to request update data to table riskloss 'The API will validating your data, include the user who process the data' (go to APIWriteriskloss with url_type = "edit") 
		Edit(id) {
			this.loading.list = true

      this.ListRange(id)

			let formdata = {
				riskloss_id: id,
				riskloss_desc: '',
				order_by: 'riskloss_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListRiskloss"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === "Y") {
            document.getElementById("tabform").click()
            this.url_type 			    	        = 'edit'
						this.form.riskloss_id   	        = feedback[0].riskloss_id
						this.form.riskloss_desc 	        = feedback[0].riskloss_desc
						this.form.riskloss_currency       = feedback[0].riskloss_currency
						this.form.riskloss_currency_desc  = feedback[0].riskloss_currency_desc
						this.form.riskloss_category       = feedback[0].riskloss_category
						this.form.riskloss_actived        = this.$functions.TrueOrFalse(feedback[0].riskloss_actived)
						this.form.riskloss_cdate		      = feedback[0].riskloss_cdate
						this.form.riskloss_cuser		      = feedback[0].riskloss_cuser
						this.form.riskloss_ldate		      = feedback[0].riskloss_ldate
						this.form.riskloss_luser		      = feedback[0].riskloss_luser
						this.form.riskloss_id_readonly    = true						
            setTimeout(function() {
              document.getElementById("riskloss_desc").focus()
            }, 500)
          } else {
            this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, "error", feedback, 0)
        }
          this.loading.list = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.list = false
      })
		},

		Delete(id) {
			let formdata = {
				riskloss_id: id,
				riskloss_actived: this.form.riskloss_actived,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRiskloss"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback.length > 0) {
                    if (feedback[0].feedback === "Y") {
                        this.SnackBar(
                            true,
                            "primary",
                            "Delete Success",
                            3000
                        )
                        this.confirm.dialog = false
                        this.confirm.text = "Ok"
                        this.selected = []
                        this.List()
                    } else {
                        this.SnackBar(
                            true,
                            "error",
                            this.$functions.ErrorCode(),
                            0
                        )
                        this.remarks.dialog 	= true
                        this.remarks.feedback 	= feedback[0].feedback
                    }
                }
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog 	= true
                this.remarks.feedback 	= e
            })
		},

		MultiProcess() {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				data_multi: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRiskloss"), param, {
                headers: {
                    "Content-Type": "text/plain charset=ISO-8859-1",
                },
            })
            .then((response) => {
                let feedback = response.data
                if (feedback[0].feedback === "Y") {
                    let feed
                    if (feedback[0].feedback_failed == 0) {
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                    } else {
                        this.remarks.feedback = feedback[0].feedback_failed_remarks
                        feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                        this.remarks.dialog = true
                    }
                    this.SnackBar(true, "primary", feed, 0)
                    this.confirm.dialog = false
                    this.confirm.text 	= "Ok"
                    this.selected 		= []
                    this.List()
                } else {
                    this.SnackBar(
                        true,
                        "error",
                        this.$functions.ErrorCode(),
                        0
                    )
                    this.remarks.dialog = true
                    this.remarks.feedback = feedback[0].feedback
                }
            })
            .catch((e) => {
                this.SnackBar(
                    true,
                    "error",
                    this.$functions.ErrorCode(),
                    0
                )
                this.remarks.dialog = true
                this.remarks.feedback = e
            })
		},

    // Function Range
    SelectAllRange() {
			this.range.selected = this.range.selected === this.range.items ? [] : this.range.items
      console.log(this.range.selected)
		},

    ListRange (id) {
      this.loading.listrange  = true
			let formdata            = {
				risklossrange_id  : id,
				order_by          : 'risklossrange_id',
				order_type        : 'ASC',
				limit             : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRisklossRange"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        console.log('Response', feedback)
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(
              true,
              "error",
              feedback[0].feedback,
              0
            )
          } else {
            this.range.items = feedback
          }
        } else {
          this.range.items = feedback
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.listrange = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.listrange = false
      })
		},

		CheckRange(value) {
			this.range.selected = value
      console.log("Value", value)
		},

    SearchRange () {
      this.range.searchbutton = true
      setTimeout(function() {
				document.getElementById("searchrange").focus()
			}, 500)
    },

    OpenRange () {
			this.dialog.range = true
			this.AddRange()
		},

    AddRange () {
      this.url_type               = 'addrange'
      this.form.risklossrange_id	= ''
      this.form.risklossrange_min = 0
      this.form.risklossrange_max = 0

      this.flag.range		= true
			this.dialog.range = true

      this.$refs.risklossrange_min.$el.getElementsByTagName('input')[0].value = 0
      this.$refs.risklossrange_max.$el.getElementsByTagName('input')[0].value = 0
    },

    WriteRange() {
      let rangemin			= document.getElementById('risklossrange_min').value
      let rangemax			= document.getElementById('risklossrange_max').value
      
      let formdata = {
				url_type          : this.url_type,
				risklossrange_id  : this.form.riskloss_id,
				risklossrange_min : rangemin,
				risklossrange_max : rangemax,
        risklossrange_min_par : this.form.risklossrange_min_par,
        risklossrange_max_par : this.form.risklossrange_max_par
			}     
       
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
			.post(this.$functions.UrlPOST("apiWriteRisklossRange"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "addrange") {
							this.form.expense_cuser = feedback[0].feedback_users_id
							this.form.expense_cdate = feedback[0].feedback_users_date
						}
						this.form.expense_luser = feedback[0].feedback_users_id
						this.form.expense_ldate = feedback[0].feedback_users_date
						this.url_type 		= "editrange"
						this.dialog.range = false
						this.ListRange(this.form.riskloss_id)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},
    
    EditRange (id, min, max) {
      this.flag.range			      = true
			this.dialog.range			    = true
			this.loading.rangedialog 	= true
			let formdata = {
				risklossrange_id  : id,
				risklossrange_min : min,
				risklossrange_max : max,
				order_by          : 'risklossrange_id',
				order_type        : 'ASC',
				limit             : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRisklossRange"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data        
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {              
              this.url_type 			    		  = 'editrange'
              this.form.risklossrange_id   	= feedback[0].risklossrange_id

              this.$refs.risklossrange_min.$el.getElementsByTagName('input')[0].value = feedback[0].risklossrange_min
              this.$refs.risklossrange_max.$el.getElementsByTagName('input')[0].value = feedback[0].risklossrange_max

              this.form.risklossrange_min_par = feedback[0].risklossrange_min
              this.form.risklossrange_max_par = feedback[0].risklossrange_max

              this.form.risklossrange_cdate = feedback[0].risklossrange_cdate
              this.form.risklossrange_cuser = feedback[0].risklossrange_cuser
              this.form.risklossrange_ldate	= feedback[0].risklossrange_ldate
              this.form.risklossrange_luser	= feedback[0].risklossrange_luser
              this.form.risklossrange_id_readonly = true
              this.dialog.range = true
              console.log("Form Edit", this.form)
              setTimeout(function(){
                document.getElementById('risklossrange_min').focus()
              }, 500)
            } else {
              this.SnackBar(
                true,
                "error",
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
        this.loading.rangedialog = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.rangedialog = false
      })
		},

    DeleteRange(id, min, max) {
			let formdata = {
				url_type: 'deleterange',
				risklossrange_id: id,
				risklossrange_min: min,
				risklossrange_max: max,
			}
			let param = this.$functions.ParamPOST(formdata)      
			this.$axios.post(this.$functions.UrlPOST("apiWriteRisklossRange"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
          let feedback = response.data          
          if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                  this.SnackBar(
                      true,
                      "primary",
                      "Delete Success",
                      3000
                  )
                  this.confirm.dialog = false
                  this.confirm.text = "Ok"
                  this.range.selected = []
                  this.ListRange(this.form.riskloss_id)
              } else {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.ErrorCode(),
                  0
                )
                this.remarks.dialog 	= true
                this.remarks.feedback 	= feedback[0].feedback
              }
          }
      })
      .catch((e) => {
          this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
          )
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
      })
		},

    MultiProcessRange() {
			let multiData = JSON.stringify(this.range.selected)
      console.log('Multi Data', multiData)
			let formdata = {
				url_type: 'multideleterange',
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRisklossRange"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback[0].feedback === "Y") {
            let feed
            if (feedback[0].feedback_failed == 0) {
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
                this.remarks.feedback = feedback[0].feedback_failed_remarks
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                this.remarks.dialog = true
            }
            this.SnackBar(true, "primary", feed, 0)
            this.confirm.dialog = false
            this.confirm.text 	= "Ok"
            this.range.selected = []
            this.ListRange(this.form.riskloss_id)
          } else {
              this.SnackBar(
                  true,
                  "error",
                  this.$functions.ErrorCode(),
                  0
              )
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
		},
    // end: Function Range

    // Function Sub Class
    SelectAllSubClass() {
      this.subclass.selected = this.subclass.selected === this.subclass.items ? [] : this.subclass.items
      console.log(this.subclass.selected)
    },

    ListSubClass (id) {
      this.loading.listsubclass  = true
			let formdata            = {
				risklosssubclass_risklossid   : id,
				order_by                      : 'risklosssubclass_risklossid',
				order_type                    : 'ASC',
				limit                         : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRisklossSubClass"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
        console.log('Response', feedback)
        if (feedback.length > 0) {
          if (feedback[0].feedback !== "Y") {
            this.SnackBar(
              true,
              "error",
              feedback[0].feedback,
              0
            )
          } else {
            this.subclass.items = feedback
          }
        } else {
          this.subclass.items = feedback
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.listsubclass = false
      })
      .catch((e) => {
        this.SnackBar(true, "error", e, 3000)
        this.loading.listsubclass = false
      })
		},

    CheckSubClass(value) {
			this.subclass.selected = value
		},

    SearchSubClass () {
      this.subclass.searchbutton = true
      setTimeout(function() {
				document.getElementById("searchsubclass").focus()
			}, 500)
    },

    OpenSubClass () {
			this.dialog.subclass = true
			this.AddSubClass()
		},

    AddSubClass () {
      this.url_type                         = 'addsubclass'
      this.form.risklosssubclass_risklossid	= ''
      this.form.risklosssubclass_id         = ''      
      this.form.risklosssubclass_desc       = ''

      this.flag.subclass		= true
			this.dialog.subclass  = true
    },

    WriteSubClass() {       
      let subclass	= document.getElementById('risklosssubclass_id').value     
      let formdata = {
				url_type                    : this.url_type,
				risklosssubclass_risklossid : this.form.riskloss_id,
				risklosssubclass_id         : subclass,
				risklosssubclass_id_par     : this.form.risklosssubclass_id_par
			}     
       
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
			.post(this.$functions.UrlPOST("apiWriteRisklossSubClass"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === "Y") {
						this.SnackBar(
							true,
							"primary",
							"Save Success",
							3000
						)
						this.confirm.dialog = false
						this.confirm.text = "Ok"
						if (this.url_type === "addsubclass") {
							this.form.risklosssubclass_cdate = feedback[0].feedback_users_date
							this.form.risklosssubclass_cuser = feedback[0].feedback_users_id
						}
						this.form.risklosssubclass_ldate = feedback[0].feedback_users_date
						this.form.risklosssubclass_luser = feedback[0].feedback_users_id
						this.url_type 		   = "editsubclass"
						this.dialog.subclass = false
						this.ListSubClass(this.form.riskloss_id)
					} else {
						this.SnackBar(
							true,
							"error",
							this.$functions.ErrorCode(),
							0
						)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(
						true,
						"error",
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback
				}
			})
			.catch((e) => {
				this.SnackBar(true, "error", this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
		},

    EditSubClass (id, subclassid) {
      this.flag.subclass			      = true
			this.dialog.subclass			    = true
			this.loading.subclassdialog 	= true
			let formdata = {
				risklosssubclass_risklossid : id,
				risklosssubclass_id         : subclassid,
				order_by          : 'risklosssubclass_risklossid',
				order_type        : 'ASC',
				limit             : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST("apiListRisklossSubClass"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data        
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {              
              this.url_type = 'editsubclass'

              this.form.risklosssubclass_risklossid = feedback[0].risklosssubclass_risklossid
              this.form.risklosssubclass_id         = feedback[0].risklosssubclass_id
              this.form.risklosssubclass_desc       = feedback[0].risklosssubclass_desc
              this.form.risklosssubclass_id_par     = feedback[0].risklosssubclass_id

              this.form.risklosssubclass_cdate = feedback[0].risklosssubclass_cdate
              this.form.risklosssubclass_cuser = feedback[0].risklosssubclass_cuser
              this.form.risklosssubclass_ldate = feedback[0].risklosssubclass_ldate
              this.form.risklosssubclass_luser = feedback[0].risklosssubclass_luser
              this.dialog.subclass = true              
            } else {
              this.SnackBar(
                true,
                "error",
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, "error", feedback, 0)
          }
        this.loading.subclassdialog = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.subclassdialog = false
      })
		},

    DeleteSubClass(id, subclass) {
      console.log('Delete ID', id)
      console.log('Sub Class ID', subclass)
			let formdata = {
				url_type                    : 'deletesubclass',
				risklosssubclass_risklossid : id,
				risklosssubclass_id         : subclass
			}
			let param = this.$functions.ParamPOST(formdata)      
			this.$axios.post(this.$functions.UrlPOST("apiWriteRisklossSubClass"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
          let feedback = response.data          
          if (feedback.length > 0) {
              if (feedback[0].feedback === "Y") {
                  this.SnackBar(
                      true,
                      "primary",
                      "Delete Success",
                      3000
                  )
                  this.confirm.dialog = false
                  this.confirm.text   = "Ok"
                  this.subclass.selected = []
                  this.ListSubClass(this.form.riskloss_id)
              } else {
                this.SnackBar(
                  true,
                  "error",
                  this.$functions.ErrorCode(),
                  0
                )
                this.remarks.dialog 	= true
                this.remarks.feedback 	= feedback[0].feedback
              }
          }
      })
      .catch((e) => {
          this.SnackBar(
              true,
              "error",
              this.$functions.ErrorCode(),
              0
          )
          this.remarks.dialog 	= true
          this.remarks.feedback 	= e
      })
		},

    MultiProcessSubClass() {
			let multiData = JSON.stringify(this.subclass.selected)
      console.log('Multi Data', multiData)
			let formdata = {
				url_type: 'multideletesubclass',
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiWriteRisklossSubClass"), param, {
        headers: {
          "Content-Type": "text/plain charset=ISO-8859-1",
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback[0].feedback === "Y") {
            let feed
            if (feedback[0].feedback_failed == 0) {
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
                this.remarks.feedback = feedback[0].feedback_failed_remarks
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                this.remarks.dialog = true
            }
            this.SnackBar(true, "primary", feed, 0)
            this.confirm.dialog = false
            this.confirm.text 	= "Ok"
            this.subclass.selected = []
            this.ListSubClass(this.form.riskloss_id)
          } else {
              this.SnackBar(
                  true,
                  "error",
                  this.$functions.ErrorCode(),
                  0
              )
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          "error",
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
		},
    // end: Function Sub Class

    SnackBar (dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout
      }
      if (color === 'error') {
        this.confirm.dialog 	 = false
        this.confirm.text 	 	 = 'Ok'
      }
    }
	}
}
</script>