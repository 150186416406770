<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Risk Coverage"></app-drawer_menu>
    <v-progress-linear
      indeterminate
      color ="fourth"
      rounded
      v-if  ="loading.page"
    >
    </v-progress-linear>
    <v-tabs
      background-color  ="white"
      color             ="fourth"
      v-if              ="this.access.read === 1"
    >
      <v-tab
        id      ="tabform"
        href   	="#tab-form"
        @click  ="FirstLoad"
      >
        Form
      </v-tab>

      <v-tab 
        href    ="#tab-list"
        @click  ="List"
      >
        List
      </v-tab>

      <v-tab-item
        value  ="tab-form"
      >

        <!-- Form Riskcov -->
        <v-form enctype="multipart/form-data" ref="form_riskcov" lazy-validation>
          <v-row class="mx-2 ma-1">
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color		  ="tertiary"
                class			="white--text"
                :disabled ="access.add === 0 ? true:false" 
                depressed
                @click 	  ="Add" 
                small
              >
                <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
              </v-btn> &nbsp;
              <v-btn
                color		  ="primary"
                class		  ="white--text"
                :disabled ="access.add === 1 || access.edit === 1 ? false:true"
                rounded
                @click 		="Confirm('write','')" 
                small
              >
                <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
              </v-btn>
            </v-col>
            <!-- Riskcov Class -->
            <v-col cols="12" sm="12" md="3">
              <app-cb
                cb_type           ="class"
                cb_url            ="apiListClass"
                cb_url_parameter  ="class_active=Y"
                cb_title          ="Class"
                cb_id             ="riskcov_classid"
                cb_desc           ="riskcov_classdesc"
                cb_rules          ="Please fill Class"
                cb_desc_readonly  ="readonly"
                cb_items_id       ="class_id"
                cb_items_desc     ="class_desc"
                :cb_value_id	  	="form.riskcov_classid"
                :cb_value_desc		="form.riskcov_classdesc"
              >
              </app-cb>
            </v-col>

            <!-- ID -->
            <v-col cols="12" sm="4" md="2" class="mt-n3">
              <v-text-field 
                v-model 		  ="form.riskcov_id"
                required 
                :rules 	  		="form.riskcov_id_rules"
                :readonly   	="form.riskcov_id_readonly"
                :maxlength    ="maxinput.id"
                :counter		  ="maxinput.id"
                label       	="ID *"
                placeholder 	="ID"
                id 				    ="riskcov_id"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-text-field>
            </v-col>

            <!-- Description -->
            <v-col cols="12" sm="8" md="3" class="mt-n3">
              <v-text-field 
                v-model 	  	="form.riskcov_desc"
                required 
                :rules 	  		="form.riskcov_desc_rules"
                :maxlength		="maxinput.desc"
                label       	="Description"
                placeholder 	="Description"
                id 				    ="riskcov_desc"
                @keyup.enter 	="Confirm('write','')"
              >
              </v-text-field>
            </v-col>

            <!-- Category -->
            <v-col cols="12" sm="8" md="2" class="mt-n3">
              <v-select
                v-model       ="form.riskcov_category"
                required
                :rules 	  		="form.riskcov_category_rules"
                :items        ="riskcov_category"
                item-text     ="code_desc"
                item-value    ="code_id"
                label         ="Category"
                placeholder   ="Category"
                id 				    ="riskcov_category"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-select>
            </v-col>

            <!-- Active Status -->
            <v-col cols="12" sm="4" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model	="form.riskcov_actived"
                color  	="primary"
                id 		  ="form.riskcov_actived"
                :label 	="form.riskcov_actived !== true ? '' : 'Active'" 
              ></v-switch>
            </v-col>

            <!-- Rate -->
            <v-col cols="12" sm="8" md="1" class="mt-n3">
              <v-text-field 
                v-model.lazy 		  ="form.riskcov_rate"
                ref 				      ="riskcov_rate"
                label 				    ="Rate"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_riskcov.rate"
                id 		      	    ="riskcov_rate"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Unit -->
            <v-col cols="12" sm="4" md="2" class="mt-n3">
              <v-select
                v-model       ="form.riskcov_unit"
                required
                :rules 	  		="form.riskcov_unit_rules"
                :items        ="riskcov_unit"
                item-text     ="code_desc"
                item-value    ="code_id"
                label         ="Unit"
                placeholder   ="Unit"
                id 				    ="riskcov_unit"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-select>
            </v-col>
            
            <!-- Effective Date -->
            <v-col cols="12" sm="4" md="2" class="mt-n3">
              <v-dialog
                ref                 ="dialog_effectivedate"
                v-model             ="modal.effectivedate"
                :return-value.sync  ="form.riskcov_effectivedate"
                persistent
                width               ="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model             ="form.riskcov_effectivedate"
                    label               ="Effective Date"
                    prepend-inner-icon  ="event"
                    background-color    ="date"
                    v-on                ="on"
                    v-mask				      ="'####-##-##'"
                    placeholder			    ="YYYY-MM-DD"
                  ></v-text-field>
                </template>
                <v-date-picker 
                  v-model ="form.riskcov_effectivedate" 
                  scrollable                      
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal.effectivedate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.dialog_effectivedate.save(form.riskcov_effectivedate)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <!-- Remarks -->
             <v-col cols="12" sm="8" md="6" class="mt-n3">
              <v-text-field 
                v-model 	  	="form.riskcov_remarks"
                required 
                :rules 	  		="form.riskcov_remarks_rules"
                :maxlength		="maxinput.remarks"
                label       	="Remarks"
                placeholder 	="Remarks"
                id 				    ="riskcov_remarks"
                @keyup.enter 	="Confirm('write','')"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-2">
            <v-col cols="12" sm="5" md="5">
              <div v-show="url_type !== 'add' ? true:false ">
                <v-checkbox v-model="cont.riskcov" label="Show Info"></v-checkbox>
                <v-banner v-model="cont.riskcov" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.riskcov_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.riskcov_cuser)">{{form.riskcov_cuser}}</span>
Last Update Date : {{form.riskcov_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.riskcov_luser)">{{form.riskcov_luser}}</span>
</pre>
                </v-banner>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <template v-if="url_type !== 'add'">
          <v-tabs
            background-color  ="white"
            color             ="secondary"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tab
              id      ="tabinterest"
              href    ="#tab-interest"
              @click	="ListRiskCovInt(form.riskcov_id)"
            >
              Interest
            </v-tab>
            <!-- <v-tab
              id      ="tabdeductible"
              href    ="#tab-deductible"
              @click	="ListRiskcovDed(form.riskcov_id)"
            >
              Deductible
            </v-tab> -->
            <v-tab
              id      ="tabloss"
              href    ="#tab-loss"
              @click  ="ListRiskCovLoss(form.riskcov_id)"
            >
              Cause Of Loss
            </v-tab>

            <v-tab-item
              value ="tab-interest"
            >
              <!-- Data Table Riskcov Interest -->
              <template>
                <!-- Main Button Riskcov Interest -->
                <v-card-title
                  class="ml-n4"
                >
                  <v-btn 
                    :color 	="riskcovint.selected.length === riskcovint.items.length ? 'fourth':''"
                    @click	="SelectAllRiskcovInt"
                    class	  ="mr-2"
                    title	  ="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn 
                    @click  ="OpenRiskcovInt"
                    class	  ="mr-2"
                    title	  ="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn 
                    color     ="error"
                    title 	  ="delete selected"
                    @click 	  ="Confirm('multideleteriskcovint','')"
                    class	    ="mr-2"
                    v-if  	  ="riskcovint.selected.length > 0"
                    :disabled ="access.delete === 0 ? true:false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn 
                    color  ="primary"
                    title  ="search"
                    v-if   ="riskcovint.searchbutton === false"
                    @click ="SearchRiskcovInt"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model				="riskcovint.search"
                    append-icon		="search"
                    @click:append ="riskcovint.searchbutton = false"
                    label				  ="Type and Enter"
                    single-line
                    hide-details
                    v-if 				  ="riskcovint.searchbutton"
                    id   				  ="searchriskcovint"
                    clearable
                    @keyup.enter 	="ListRiskCovInt(form.riskcov_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Riskcov Interest  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value        ="riskcovint.selected"
                  :headers      ="riskcovint.headers"
                  :items        ="ItemsRiskcovInt"
                  :search       ="riskcovint.search"
                  @input        ="CheckRiskcovInt($event)"
                  item-key      ="riskcovint_row"
                  show-select
                  class         ="elevation-1"
                  :footer-props ="{
                    showFirstLastPage : true,
                    firstIcon         : 'first_page',
                    lastIcon          : 'last_page',
                    prevIcon          : 'keyboard_arrow_left',
                    nextIcon          : 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	    ="loading.listriskcovint"
                  loading-text  ="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	    ="mr-2"
                      @click	  ="EditRiskcovInt(item.riskcovint_riskcovid, item.riskcovint_id)"
                      :disabled ="access.edit === 0 ? true:false"
                      title 	  ="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click	  ="Confirm('deleteriskcovint',item.riskcovint_riskcovid, item.riskcovint_id)"
                      :disabled ="access.delete === 0 ? true:false"
                      title 	  ="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Riskcov Interest -->
            </v-tab-item>

            <v-tab-item
              value ="tab-deductible"
            >
              <!-- Data Table Riskcov Deductible -->
              <template>
                <!-- Main Button Riskcov Deductible -->
                <v-card-title
                  class="ml-n4"
                >
                  <v-btn 
                    :color 	="riskcovded.selected.length === riskcovded.items.length ? 'fourth':''"
                    @click	="SelectAllRiskcovDed"
                    class	  ="mr-2"
                    title	  ="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn 
                    @click  ="OpenRiskcovDed"
                    class	  ="mr-2"
                    title	  ="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn 
                    color     ="error"
                    title 	  ="delete selected"
                    @click 	  ="Confirm('multideleteriskcovded','')"
                    class	    ="mr-2"
                    v-if  	  ="riskcovded.selected.length > 0"
                    :disabled ="access.delete === 0 ? true:false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn 
                    color  ="primary"
                    title  ="search"
                    v-if   ="riskcovded.searchbutton === false"
                    @click ="SearchRiskcovDed"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model				="riskcovded.search"
                    append-icon		="search"
                    @click:append ="riskcovded.searchbutton = false"
                    label				  ="Type and Enter"
                    single-line
                    hide-details
                    v-if 				  ="riskcovded.searchbutton"
                    id   				  ="searchriskcovded"
                    clearable
                    @keyup.enter 	="ListRiskcovDed(form.riskcov_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Riskcov Deductible  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value        ="riskcovded.selected"
                  :headers      ="riskcovded.headers"
                  :items        ="ItemsRiskcovDed"
                  :search       ="riskcovded.search"
                  @input        ="CheckRiskcovDed($event)"
                  item-key      ="riskcovded_row"
                  show-select
                  class         ="elevation-1"
                  :footer-props ="{
                    showFirstLastPage : true,
                    firstIcon         : 'first_page',
                    lastIcon          : 'last_page',
                    prevIcon          : 'keyboard_arrow_left',
                    nextIcon          : 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	    ="loading.listriskcovded"
                  loading-text  ="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	    ="mr-2"
                      @click	  ="EditRiskcovDed(item.riskcovded_riskcovid, item.riskcovded_id)"
                      :disabled ="access.edit === 0 ? true:false"
                      title 	  ="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click	  ="Confirm('deleteriskcovded',item.riskcovded_riskcovid, item.riskcovded_id)"
                      :disabled ="access.delete === 0 ? true:false"
                      title 	  ="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Riskcov Deductible -->
            </v-tab-item>

            <v-tab-item
              value ="tab-loss"
            >
              <!-- Data Table Riskcov Loss -->
              <template>
                <!-- Main Button Riskcov Loss -->
                <v-card-title
                  class="ml-n4"
                >
                  <v-btn 
                    :color 	="riskcovloss.selected.length === riskcovloss.items.length ? 'fourth':''"
                    @click	="SelectAllRiskcovLoss"
                    class	  ="mr-2"
                    title	  ="select all"
                    small
                  >
                    select all
                  </v-btn>
                  <v-btn 
                    @click  ="OpenRiskcovLoss"
                    class	  ="mr-2"
                    title	  ="add"
                    small
                  >
                    Add
                  </v-btn>
                  <v-btn 
                    color     ="error"
                    title 	  ="delete selected"
                    @click 	  ="Confirm('multideleteriskcovloss','')"
                    class	    ="mr-2"
                    v-if  	  ="riskcovloss.selected.length > 0"
                    :disabled ="access.delete === 0 ? true:false"
                    small
                  >
                    <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                  </v-btn>
                  <v-btn 
                    color  ="primary"
                    title  ="search"
                    v-if   ="riskcovloss.searchbutton === false"
                    @click ="SearchRiskcovLoss"
                    small
                  >
                    <v-icon>{{$vars.V('icon_search')}}</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>

                  <v-text-field
                    v-model				="riskcovloss.search"
                    append-icon		="search"
                    @click:append ="riskcovloss.searchbutton = false"
                    label				  ="Type and Enter"
                    single-line
                    hide-details
                    v-if 				  ="riskcovloss.searchbutton"
                    id   				  ="searchriskcovloss"
                    clearable
                    @keyup.enter 	="ListRiskCovLoss(form.riskcov_id)"
                  ></v-text-field>
                </v-card-title>
                <!-- end: Main Button Riskcov Loss  -->
                <v-spacer></v-spacer>

                <v-data-table
                  :value        ="riskcovloss.selected"
                  :headers      ="riskcovloss.headers"
                  :items        ="ItemsRiskcovLoss"
                  :search       ="riskcovloss.search"
                  @input        ="CheckRiskcovLoss($event)"
                  item-key      ="riskcovloss_row"
                  show-select
                  class         ="elevation-1"
                  :footer-props ="{
                    showFirstLastPage : true,
                    firstIcon         : 'first_page',
                    lastIcon          : 'last_page',
                    prevIcon          : 'keyboard_arrow_left',
                    nextIcon          : 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	    ="loading.listriskcovloss"
                  loading-text  ="Loading... Please wait"
                >
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	    ="mr-2"
                      @click	  ="EditRiskcovLoss(item.riskcovloss_riskcovid, item.riskcovloss_id)"
                      :disabled ="access.edit === 0 ? true:false"
                      title 	  ="edit"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click	  ="Confirm('deleteriskcovloss',item.riskcovloss_riskcovid, item.riskcovloss_id)"
                      :disabled ="access.delete === 0 ? true:false"
                      title 	  ="delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
              <!-- end: Data Table Riskcov Loss -->
            </v-tab-item>
          </v-tabs>
        </template> 
      </v-tab-item>

      <v-tab-item
        value="tab-list"
      >
        <template>
          <v-card-title 
            class="ml-n4"
          >
            <v-btn 
              :color 	="selected.length === items.length ? 'secondary':''"
              @click	="SelectAllPage"
              class	  ="mr-2"
              title	  ="select all page"
              small
            >{{$vars.V('txt_select_all_page')}}
            </v-btn>
            <v-btn 
              color     ="error"
              title 	  ="delete selected"
              @click 	  ="Confirm('multidelete','')"
              class	    ="mr-2"
              v-if  	  ="selected.length > 0"
              :disabled ="access.delete === 0 ? true:false"
              small>
                <v-icon>{{$vars.V('icon_delete')}}</v-icon>
            </v-btn>
            <v-btn 
              color  ="primary"
              title  ="search"
              v-if   ="btn_search === false"
              @click ="btn_search = true"
              small>
                <v-icon>{{$vars.V('icon_search')}}</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
              <v-text-field
                v-model       ="search"
                append-icon   ="search"
                @click:append ="btn_search = false"
                label         ="Search"
                single-line
                hide-details
                v-if          ="btn_search"
              ></v-text-field>
          </v-card-title>

          <v-data-table
            :value        ="selected"
            :headers      ="headers"
            :items        ="items"
            :search       ="search"
            @input        ="Check($event)"
            item-key      ="riskcov_id"
            show-select
            class         ="elevation-1"
            :footer-props ="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'keyboard_arrow_left',
              nextIcon: 'keyboard_arrow_right'
            }"
            dense
            :loading 	="loading.list"
            loading-text="Loading... Please wait"
          >
            <template v-slot:item.riskcov_classid="{ item }">
              {{item.riskcov_classid}} - {{item.riskcov_classdesc}}
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                small
                class	    ="mr-2"
                @click	  ="Edit(item.riskcov_id)"
                :disabled ="access.edit === 0 ? true:false"
                title 	  ="edit"
              >
                mouse
              </v-icon>|
              <v-icon
                small
                @click	  ="Confirm('delete',item.riskcov_id)"
                :disabled ="access.delete === 0 ? true:false"
                title 	  ="delete"
              >
                delete
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-tab-item>
    </v-tabs>

    <!-- Dialog Riskcov Deductible -->
    <v-dialog
      v-model     ="dialog.riskcovded"
      scrollable
      transition  ="dialog-bottom-transition"
      persistent
      max-width   ="720"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('riskcovded')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Deductible</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddRiskcovDed" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.riskcovdeddialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_riskcovded" lazy-validation>          
          <v-row class="mx-2 mt-2">
            <!-- Deductable ID -->
            <v-col cols="12" sm="12" md="9">
              <app-cb
                cb_type           ="deductible"
                cb_url            ="apiListDeductible"
                cb_url_parameter  ="deductible_actived=Y"
                cb_title          ="Deductible"
                cb_id             ="riskcovded_id"
                cb_rules          ="Please fill Deductible"
                cb_items_id       ="deductible_id"
                cb_items_desc     ="deductible_remarkseng"
                :cb_value_id		  ="form.riskcovded_id"
                :cb_value_desc	  ="form.riskcovded_desc"
              >
              </app-cb>
            </v-col>  

            <!-- Description -->
            <!-- <v-col cols="12" sm="6" md="6" class="mt-n3">
              <v-text-field
                v-model 	  	="form.riskcovded_desc"
                required
                :rules        ="form.riskcovded_desc_rules"
                :maxlength    ="maxinput.desc"
                label       	="Description *"
                placeholder 	="Description"
                id 			      ="riskcovded_desc"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-text-field>
            </v-col>    -->

            <!-- Actived -->
            <v-col cols="4" sm="12" md="3" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model	="form.riskcovded_actived"
                color  	="primary"
                id 		  ="form.riskcovded_actived"
                :label 	="form.riskcovded_actived !== true ? '' : 'Active'" 
              ></v-switch>
            </v-col>
          </v-row>

          <!-- Info Riskcov Deductible -->
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
            <div v-show="url_type !== 'addriskcovded' ? true:false ">
              <v-checkbox v-model="cont.riskcovded" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.riskcovded" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.riskcovded_cdate}}<br>Created User 	 : {{form.riskcovded_cuser}}<br>Last Update Date : {{form.riskcovded_ldate}}<br>Last Update User : {{form.riskcovded_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Riskcov Deductible -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end: Dialog Riskcov Deductible -->

    <!-- Dialog Riskcov Interest -->
    <v-dialog
      v-model     ="dialog.riskcovint"
      scrollable
      transition  ="dialog-bottom-transition"
      persistent
      max-width   ="780"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('riskcovint')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Interest</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddRiskcovInt" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.riskcovintdialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_riskcovint" lazy-validation>          
          <v-row class="mx-2 mt-2">
            <!-- Interest ID -->
            <v-col cols="12" sm="6" md="6">
              <app-cb
                  cb_type           ="interest"
                  cb_url            ="apiListInterest"
                  cb_url_parameter  ="interest_actived=Y"
                  cb_title          ="Interest"
                  cb_id             ="riskcovint_id"
                  cb_desc           ="riskcovint_intdesc"
                  cb_rules          ="Please fill Interest"
                  cb_desc_readonly  ="readonly"
                  cb_items_id       ="interest_id"
                  cb_items_desc     ="interest_desc"
                  :cb_value_id		  ="form.riskcovint_id"
                  :cb_value_desc		="form.riskcovint_intdesc"
                >
                </app-cb>
            </v-col>

            <!-- Category -->
            <v-col cols="12" sm="4" md="4" class="mt-n3">
              <v-select
                v-model       ="form.riskcovint_category"
                required
                :rules 	  		="form.riskcovint_category_rules"
                :items        ="riskcovint_category"
                item-text     ="code_desc"
                item-value    ="code_id"
                label         ="Category"
                placeholder   ="Category"
                id 				    ="riskcovint_category"
                @keyup.enter  ="Confirm('write','')"
              >
              </v-select>
            </v-col>

            <!-- Actived -->
            <v-col cols="4" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model	="form.riskcovint_actived"
                color  	="primary"
                id 		  ="form.riskcovint_actived"
                :label 	="form.riskcovint_actived !== true ? '' : 'Active'" 
              ></v-switch>
            </v-col>

            <!-- Indemnity -->
            <v-col cols="12" sm="2" md="2" :class="$vuetify.breakpoint.xs?'':'mt-n5'">
              <v-text-field 
                v-model.lazy 		  ="form.riskcovint_indemnity"
                ref 				      ="riskcovint_indemnity"
                label 				    ="Indemnity"
                placeholder 		  ="0"
                background-color  ="numeric"
                v-money 			    ="format_riskcovint.indemnity"
                id 		      	    ="riskcovint_indemnity"
                reverse
              >
              </v-text-field>
            </v-col>

            <!-- Tlo Logic -->
            <v-col cols="12" sm="3" md="3" :class="$vuetify.breakpoint.xs?'':'mt-n5'">
              <label class="caption">TLO (Total Loss Only)</label>
              <v-checkbox v-model="form.riskcovint_tlof" label="" :class="$vuetify.breakpoint.xs?'':'mt-n1'"></v-checkbox>
            </v-col>
          </v-row>

          <!-- Info Riskcov Interest -->
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
            <div v-show="url_type !== 'addriskcovint' ? true:false ">
              <v-checkbox v-model="cont.riskcovint" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.riskcovint" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.riskcovint_cdate}}<br>Created User 	 : {{form.riskcovint_cuser}}<br>Last Update Date : {{form.riskcovint_ldate}}<br>Last Update User : {{form.riskcovint_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Riskcov Interest -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end: Dialog Riskcov Interest -->

    <!-- Dialog Riskcov Loss -->
    <v-dialog
      v-model     ="dialog.riskcovloss"
      scrollable
      transition  ="dialog-bottom-transition"
      persistent
      max-width   ="720"
    >
      <v-card>
      <v-toolbar dark color="primary" dense class="mb-2">
        <v-btn icon dark @click="Close('riskcovloss')">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Cause of Loss</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="AddRiskcovLoss" title="Add New Detail"><v-icon color="white">add</v-icon></v-btn>
        <v-btn icon dark @click="Confirm(url_type,'')" title="Save Detail"><v-icon color="white">save</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-progress-linear :indeterminate="true" v-if="loading.riskcovlossdialog"></v-progress-linear>
        <v-form enctype="multipart/form-data" ref="form_riskcovloss" lazy-validation>          
          <v-row class="mx-2 mt-2">
            <!-- Loss ID -->
            <v-col cols="12" sm="9" md="9">
              <app-cb
                  cb_type           ="causeloss"
                  cb_url            ="apiListCauseLoss"
                  cb_url_parameter  ="causeloss_actived=Y"
                  cb_title          ="Loss"
                  cb_id             ="riskcovloss_id"
                  cb_desc           ="riskcovloss_lossdesc"
                  cb_rules          ="Please fill Loss"
                  cb_desc_readonly  ="readonly"
                  cb_items_id       ="causeloss_id"
                  cb_items_desc     ="causeloss_desc"
                  :cb_value_id		  ="form.riskcovloss_id"
                  :cb_value_desc		="form.riskcovloss_lossdesc"
                >
                </app-cb>
            </v-col>

            <!-- Actived -->
            <v-col cols="4" sm="3" md="3" class="mt-n6">
              <label class="caption">Active Status</label>
              <v-switch
                v-model	="form.riskcovloss_actived"
                color  	="primary"
                id 		  ="form.riskcovloss_actived"
                :label 	="form.riskcovloss_actived !== true ? '' : 'Active'" 
              ></v-switch>
            </v-col>
          </v-row>

          <!-- Info Riskcov Loss -->
          <v-row class="mx-2">
            <v-col cols="12" sm="12" md="12">
            <div v-show="url_type !== 'addriskcovloss' ? true:false ">
              <v-checkbox v-model="cont.riskcovloss" label="Show Info"></v-checkbox>
              <v-banner v-model="cont.riskcovloss" single-line transition="slide-y-transition">
                <pre>Created Date 	 : {{form.riskcovloss_cdate}}<br>Created User 	 : {{form.riskcovloss_cuser}}<br>Last Update Date : {{form.riskcovloss_ldate}}<br>Last Update User : {{form.riskcovloss_luser}}</pre>
              </v-banner>
            </div>
            </v-col>
          </v-row>
          <!-- end: Info Riskcov Loss -->
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- end: Dialog Riskcov Loss -->

    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
      <v-card>
        <v-toolbar dark color="fourth">
        <v-btn icon dark @click="remarks.dialog = false">
          <v-icon>{{$vars.V('icon_close')}}</v-icon>
        </v-btn>
        <v-toolbar-title>Error Messages</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-2 ml-n2">
          <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"      
      top
      style 	  ="z-index: 9999;"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>
    
    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
	<!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>

<script>
export default {
  data: () => ({
    url_type: 'add',
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    maxinput: { 
			id:10,
			desc:150,
			remarks:255,
		},
    snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000,
		},
    confirm: {
      dialog: false,
      title: '',
      text: 'Ok',
    },
    modal: {
			effectivedate : false
		},   
		format_riskcov: {
      rate: {
        decimal: '.',
        thousands: ',',
        precision: 4,
      }
    },
    format_riskcovint: {
      indemnity: {
        decimal: '.',
        thousands: ',',
        precision: 2,
      }
    },
    selected: [],
		search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'riskcov_id',
      },
      { text: 'Description', value: 'riskcov_desc' },
      { text: 'Remarks', value: 'riskcov_remarks' },
      { text: 'Class', value: 'riskcov_classid' },
      { text: 'Actived', value: 'riskcov_actived' },      
      { text: 'Actions', value: 'action', sortable: false },
    ],
    remarks: {
			feedback:'',
			dialog: false,
		},
    items: [],
		accessmenu: [],
    cont: {
		  riskcov     : false,
		  riskcovded  : false,
		  riskcovint  : false,
		  riskcovloss : false
    },
    dialog: {
			riskcovded  : false,
			riskcovint  : false,
			riskcovloss : false,
			users : false,
		},
    flag: {
      riskcovded      : false,
			showriskcovded  : false,
      riskcovint      : false,
			showriskcovint  : false,
      riskcovloss     : false,
			showriskcovloss : false
    },
    loading: {
      page              : true,
      list              : false,
      listriskcovded    : false,
      riskcovdeddialog  : false,
      listriskcovint    : false,
      riskcovintdialog  : false,
      listriskcovloss   : false,
      riskcovlossdialog : false,
		},
    btn_search: true,
    riskcov_category    : [],
    riskcov_unit        : [],
    riskcovint_category : [],
    form: {
      // Riskcov
      riskcov_id            : '',
      riskcov_classid       : '',
      riskcov_classdesc     : '',
      riskcov_desc          : '',
      riskcov_remarks       : '',
      riskcov_category      : '',
      riskcov_rate          : '',
      riskcov_unit          : '',
      riskcov_effectivedate : '',
      riskcov_actived       : true,
      
      riskcov_id_rules            : [v => !!v || 'Please fill ID'],
      riskcov_classid_rules       : [v => !!v || 'Please fill Class ID'],
      riskcov_desc_rules          : [v => !!v || 'Please fill Description'],      
      riskcov_remarks_rules       : [v => !!v || 'Please fill Remarks'],      
      riskcov_category_rules      : [v => !!v || 'Please fill Category'],
      riskcov_rate_rules          : [v => !!v || 'Please fill Rate'],
      riskcov_unit_rules          : [v => !!v || 'Please fill Unit'],
      riskcov_effectivedate_rules : [v => !!v || 'Please fill Effective Date'],

      riskcov_id_readonly: false,

      // Riskcov Deductible
      riskcovded_id         : '',
      riskcovded_desc       : '',
      riskcovded_riskcovid  : '',
      riskcovded_actived    : true,
      riskcovded_id_par     : '',

      riskcovded_id_rules   : [v => !!v || 'Please fill Deductible'],
      riskcovded_desc_rules : [v => !!v || 'Please fill Description'],

      // Riskcov Interest
      riskcovint_id         : '',
      riskcovint_intdesc    : '',
      riskcovint_riskcovid  : '',
      riskcovint_indemnity  : '',
      riskcovint_category   : '',
      riskcovint_tlof       : false,
      riskcovint_actived    : true,
      riskcovint_id_par     : '',

      riskcovint_id_rules         : [v => !!v || 'Please fill Interest'],
      riskcovint_indemnity_rules  : [v => !!v || 'Please fill Indemnity'],
      riskcovint_category_rules   : [v => !!v || 'Please fill Category'],

      // Riskcov Loss
      riskcovloss_id        : '',
      riskcovloss_lossdesc  : '',
      riskcovloss_riskcovid : '',
      riskcovloss_actived   : true,
      riskcovloss_id_par    : '',

      riskcovloss_id_rules  : [v => !!v || 'Please fill Cause Of Loss'],
      display_users: ''
    },			
		feeds: [],

    // Data Table Riskcov Deductible
    riskcovded: {
      headers: [				
        { text: 'ID', value: 'riskcovded_id' },
        { text: 'Desc', value: 'riskcovded_desc' },
        { text: 'Actived', value: 'riskcovded_actived' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      items         : [],
      selected      : [],
      search        : '',
      searchbutton  : false,
    },
    // end: Data Table Riskcov Deductible

    // Data Table Riskcov Interest
    riskcovint: {
      headers: [				
        { text: 'ID', value: 'riskcovint_id' },
        { text: 'Description', value: 'riskcovint_intdesc' },
        { text: 'Category', value: 'riskcovint_category' },
        { text: 'Indemnity', value: 'riskcovint_indemnity' },
        { text: 'Actived', value: 'riskcovint_actived' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      items         : [],
      selected      : [],
      search        : '',
      searchbutton  : false,
    },
    // end: Data Table Riskcov Interest

    // Data Table Riskcov Loss
    riskcovloss: {
      headers: [				
        { text: 'ID', value: 'riskcovloss_id' },
        { text: 'Actived', value: 'riskcovloss_actived' },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      items         : [],
      selected      : [],
      search        : '',
      searchbutton  : false,
    },
    // end: Data Table Riskcov Loss
  }),

  created () {
    this.FirstLoad()
  },

  computed: {
    ItemsRiskcovDed() {
      for (let i = 0 ;i <= this.riskcovded.items.length - 1 ;i++) {
        const riskcovdedindex = this.riskcovded.items[i]
        riskcovdedindex.riskcovded_row = this.riskcovded.items[i].riskcovded_id+'|'+this.riskcovded.items[i].riskcovded_riskcovid+'|'
      }
      return this.riskcovded.items
    },

    ItemsRiskcovInt() {
      for (let i = 0 ;i <= this.riskcovint.items.length - 1 ;i++) {
        const riskcovintindex = this.riskcovint.items[i]
        riskcovintindex.riskcovint_row = this.riskcovint.items[i].riskcovint_id+'|'+this.riskcovint.items[i].riskcovint_riskcovid+'|'
      }
      return this.riskcovint.items
    },

    ItemsRiskcovLoss() {
      for (let i = 0 ;i <= this.riskcovloss.items.length - 1 ;i++) {
        const riskcovlossindex = this.riskcovloss.items[i]
        riskcovlossindex.riskcovloss_row = this.riskcovloss.items[i].riskcovloss_id+'|'+this.riskcovloss.items[i].riskcovloss_riskcovid+'|'
      }
      return this.riskcovloss.items
    }
  },

  methods: {
    FirstLoad () {
			//this function use for validating the screen with user privilleges
      this.Access()
      setTimeout(function(){
        document.getElementById('riskcov_id').focus()
      }, 500)

      // Load API to select box riskcov_unit
      this.GetListCode('RATEUNIT')
      this.GetListCode('RISKCATEGORY')
		},

		//this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access () {
      let modul     = 'riskcov'
			let formdata  = {
				menu_id: 'riskcov',
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param     = this.$functions.ParamPOST(formdata)		

			this.$axios
      .post(this.$functions.UrlPOST('apiListMenuUser'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let priv = response.data
				if (priv.length > 0) {
          this.access.read 	  = priv[0].usersmenu_read
          this.access.add  	  = priv[0].usersmenu_add
          this.access.edit    = priv[0].usersmenu_edit
          this.access.delete  = priv[0].usersmenu_delete
          if (priv[0].usersmenu_read === 0) {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
        }
        this.loading.page = false
			})
			.catch((e) => {
        this.SnackBar(true, 'error', e, 0)
        this.loading.page = false
      })
		},

    //This function use for give feedback when user has no access with some feature
    NoPriviledge () {
      this.SnackBar(true, 'error', 'You dont have access `Rate Tab` modul, please contact Administrator', 0)
    },

    SelectAllPage() {
			this.selected = this.selected === this.items ? [] : this.items
		},

		Check(value) {
			this.selected = value
		},

		//Function for get all data from table riskcov with API in mod_riskcov.go
		List () {
      this.loading.list = true
			let formdata = {
        riskcov_id: '',
        riskcov_desc: '',
        order_by: 'riskcov_id',
        order_type: 'ASC',
        limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListRiskCov'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== 'Y') {
            this.SnackBar(
              true,
              'error',
              feedback[0].feedback,
              0
            )
          } else {
            this.items = feedback
          }
        } else {
          this.items = feedback
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.list = false
      })
			.catch((e) => {
        this.SnackBar(true, 'error', e, 3000)
        this.loading.list = false
      })
		},

		//Pop Up message for validating (Ok or Cancel) your proccess
    Confirm (flag, id, par1) {
      if (flag === 'write') {
        if (this.$refs.form_riskcov.validate()) {
          this.confirm.dialog   = true
          this.confirm.title    = 'Save'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type 		    = 'delete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ id + '`?'
        this.riskcov_id 	    = id
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 		  = 'multidelete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='addriskcovded' || flag ==='editriskcovded') {
        this.url_type 		  =this.url_type
        this.confirm.dialog =true
        this.confirm.title  ='Save'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='deleteriskcovded') {
        this.url_type 		        ='deleteriskcovded'
        this.confirm.dialog       =true
        this.confirm.title        ='Delete `'+ par1 + '`'
        this.riskcovded_id        =this.form.riskcov_id
        this.riskcovded_riskcovid =par1  
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='multideleteriskcovded') {
        this.url_type 		    ='multideleteriskcovded'
        this.confirm.dialog   =true
        this.confirm.title    ='Delete `'+ this.riskcovded.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='addriskcovint' || flag ==='editriskcovint') {
        this.url_type 		  =this.url_type
        this.confirm.dialog =true
        this.confirm.title  ='Save'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='deleteriskcovint') {
        this.url_type 		        ='deleteriskcovint'
        this.confirm.dialog       =true
        this.confirm.title        ='Delete `'+ par1 + '`'
        this.riskcovint_id        =this.form.riskcov_id
        this.riskcovint_riskcovid =par1  
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='multideleteriskcovint') {
        this.url_type 		    ='multideleteriskcovint'
        this.confirm.dialog   =true
        this.confirm.title    ='Delete `'+ this.riskcovint.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='addriskcovloss' || flag ==='editriskcovloss') {
        this.url_type 		  =this.url_type
        this.confirm.dialog =true
        this.confirm.title  ='Save'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='deleteriskcovloss') {
        this.url_type 		        ='deleteriskcovloss'
        this.confirm.dialog       =true
        this.confirm.title        ='Delete `'+ par1 + '`'
        this.riskcovloss_id        =this.form.riskcov_id
        this.riskcovloss_riskcovid =par1  
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag ==='multideleteriskcovloss') {
        this.url_type 		    ='multideleteriskcovloss'
        this.confirm.dialog   =true
        this.confirm.title    ='Delete `'+ this.riskcovloss.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    },

    Loading () {
      this.confirm.text 	 = 'Loading...'
      if (this.url_type === 'add' || this.url_type === 'edit') {
        this.Write()
      } else if (this.url_type === 'delete') {
        this.Delete(this.riskcov_id)
      } else if (this.url_type === 'multidelete') {
        this.MultiProcess()
      } else if (this.url_type ==='addriskcovded' || this.url_type ==='editriskcovded') {
        this.WriteRiskcovDed()
			} else if (this.url_type ==='deleteriskcovded') {
				this.DeleteRiskcovDed(this.riskcovded_id, this.riskcovded_riskcovid)
			} else if (this.url_type ==='multideleteriskcovded') {
				this.MultiProcessRiskcovDed()
			} else if (this.url_type ==='addriskcovint' || this.url_type ==='editriskcovint') {
        this.WriteRiskcovInt()
			} else if (this.url_type ==='deleteriskcovint') {
				this.DeleteRiskcovInt(this.riskcovint_id, this.riskcovint_riskcovid)
			} else if (this.url_type ==='multideleteriskcovint') {
				this.MultiProcessRiskcovInt()
			} else if (this.url_type ==='addriskcovloss' || this.url_type ==='editriskcovloss') {
        this.WriteRiskcovLoss()
			} else if (this.url_type ==='deleteriskcovloss') {
				this.DeleteRiskcovLoss(this.riskcovloss_id, this.riskcovloss_riskcovid)
			} else if (this.url_type ==='multideleteriskcovloss') {
				this.MultiProcessRiskcovLoss()
			}
    },

    Close (flag) {
      if (flag === 'attributes') {
        this.url_type = 'edit'
        this.dialog.attributes = false
      } else if (flag === 'riskcovded') {
        this.url_type           = 'edit'
	  		this.dialog.riskcovded  = false
        this.ListRiskCovDed(this.form.riskcov_id)
      } else if (flag === 'riskcovint') {
        this.url_type           = 'edit'
	  		this.dialog.riskcovint  = false
        this.ListRiskCovInt(this.form.riskcov_id)
      } else if (flag === 'riskcovloss') {
        this.url_type           = 'edit'
	  		this.dialog.riskcovloss  = false
        this.ListRiskCovLoss(this.form.riskcov_id)
      } else if (flag === 'confirm') {
        this.confirm.dialog = false
        if (this.url_type !== 'add') {
          this.url_type = 'edit'
        }
      }
    },

    Write () {
			let riskclass   = document.getElementById('riskcov_classid').value
      let formdata = {
				url_type              : this.url_type,
				riskcov_id            : this.form.riskcov_id,
				riskcov_desc          : this.form.riskcov_desc,				
				riskcov_remarks       : this.form.riskcov_remarks,				
				riskcov_classid       : riskclass,				
				riskcov_category      : this.form.riskcov_category,
				riskcov_rate          : this.form.riskcov_rate,
				riskcov_unit          : this.form.riskcov_unit,
				riskcov_effectivedate : this.form.riskcov_effectivedate,
				riskcov_actived       : this.$functions.ActivedFlag(this.form.riskcov_actived)
			}
      let param 	   = this.$functions.ParamPOST(formdata)			
			this.$axios
      .post(this.$functions.UrlPOST('apiWriteRiskCov'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(
              true,
              'success',
              'Save Success',
              3000
            )
            this.confirm.dialog  	 = false
            this.confirm.text      = 'Ok'
            if (this.url_type === 'add') {
              this.form.riskcov_cuser = feedback[0].feedback_users_id
              this.form.riskcov_cdate = feedback[0].feedback_users_date
            }
            this.form.riskcov_ldate = feedback[0].feedback_users_id
            this.form.riskcov_luser = feedback[0].feedback_users_date
            this.url_type = 'edit'
          } else {
            this.SnackBar(
              true,
              'error',
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(
            true,
            'error',
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog 	= true
          this.remarks.feedback 	= feedback
        }				
			})
			.catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
		},
		  
		//Function to request insert data to table riskcov 'The API will validating your data, include the user who process the data' (go to APIWriteriskcov with url_type = 'add') 
    Add () {
      this.url_type 		 			        = 'add'
      this.form.riskcov_id 	 		      = ''
      this.form.riskcov_desc 	 		    = ''
      this.form.riskcov_remarks		    = ''
      this.form.riskcov_classid		    = ''
      this.form.riskcov_classdesc		  = ''
      this.form.riskcov_category		  = ''
      this.form.riskcov_rate		      = ''
      this.form.riskcov_unit		      = ''
      this.form.riskcov_effectivedate = ''
      this.form.riskcov_actived 		  = true
      this.form.riskcov_id_readonly   = false

      this.$refs.riskcov_rate.$el.getElementsByTagName('input')[0].value  = 0

      // Load API to select box riskcov_unit
      this.GetListCode('RATEUNIT')

      setTimeout(function(){
        document.getElementById('riskcov_id').focus()
      }, 500)
		},

		//Function to request update data to table riskcov 'The API will validating your data, include the user who process the data' (go to APIWriteriskcov with url_type = 'edit') 
		Edit(id) {
			this.loading.list = true
			let formdata = {
				riskcov_id    : id,
				riskcov_desc  : '',
				order_by      : 'riskcov_id',
				order_type    : 'ASC',
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListRiskCov'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            document.getElementById('tabform').click()
            this.url_type                   = 'edit'
            this.form.riskcov_id   	        = feedback[0].riskcov_id
            this.form.riskcov_desc 	        = feedback[0].riskcov_desc
            this.form.riskcov_remarks       = feedback[0].riskcov_remarks
            this.form.riskcov_classid       = feedback[0].riskcov_classid
            this.form.riskcov_classdesc     = feedback[0].riskcov_classdesc
            this.form.riskcov_category      = feedback[0].riskcov_category
            this.form.riskcov_rate          = feedback[0].riskcov_rate
            this.form.riskcov_unit          = feedback[0].riskcov_unit
            this.form.riskcov_effectivedate = feedback[0].riskcov_effectivedate
            this.form.riskcov_actived       = this.$functions.TrueOrFalse(feedback[0].riskcov_actived)
            this.form.riskcov_cdate		      = feedback[0].riskcov_cdate
            this.form.riskcov_cuser		      = feedback[0].riskcov_cuser
            this.form.riskcov_ldate		      = feedback[0].riskcov_ldate
            this.form.riskcov_luser		      = feedback[0].riskcov_luser
						this.form.riskcov_id_readonly   = true
            
            this.$refs.riskcov_rate.$el.getElementsByTagName('input')[0].value  = feedback[0].riskcov_rate

            this.ListRiskCovInt(feedback[0].riskcov_id)
            this.ListRiskCovLoss(feedback[0].riskcov_id)
            this.ListRiskCovDed(feedback[0].riskcov_id)

            setTimeout(function() {
              document.getElementById('riskcov_desc').focus()
            }, 500)
          } else {
            this.SnackBar(
              true,
              'error',
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog = true
						this.remarks.feedback = feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', feedback, 0)
        }
          this.loading.list = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.list = false
      })
		},

		Delete (id) {
			let formdata = {
        url_type    : 'delete',
				riskcov_id  : id
			}
      let param    = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiWriteRiskCov'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(
              true,
              'success',
              'Delete Success',
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = 'Ok'
            this.selected = []
            this.List()
          } else {
            this.SnackBar(
              true,
              'error',
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        }
      })
			.catch((e) => {
					this.SnackBar(
						true,
						'error',
						this.$functions.ErrorCode(),
						0
					)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= e
				})
    },

    MultiProcess() {
      let multiData = JSON.stringify(this.selected)      
			let formdata = {
				url_type: 'multidelete',
				multi_data: multiData,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCov'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback[0].feedback === 'Y') {
            let feed
            if (feedback[0].feedback_failed == 0) {
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            } else {
                this.remarks.feedback = feedback[0].feedback_failed_remarks
                feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                this.remarks.dialog = true
            }
            this.SnackBar(true, 'success', feed, 0)
            this.confirm.dialog = false
            this.confirm.text 	= 'Ok'
            this.selected = []
             this.List()          
          } else {
              this.SnackBar(
                  true,
                  'error',
                  this.$functions.ErrorCode(),
                  0
              )
              this.remarks.dialog = true
              this.remarks.feedback = feedback[0].feedback
          }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },
    
    // Function Riskcov Deductible
    SelectAllRiskcovDed() {
			this.riskcovded.selected = this.riskcovded.selected === this.riskcovded.items ? [] : this.riskcovded.items
		},

    ListRiskCovDed (id) {
      this.loading.listriskcovded = true
			let formdata = {
				riskcovded_riskcovid  : id,
				order_by              : 'riskcovded_id',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListRiskCovDed'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data     
        if (feedback.length > 0) {
          if (feedback[0].feedback !== 'Y') {
            this.SnackBar(
              true,
              'error',
              feedback[0].feedback,
              0
            )
          } else {
            this.riskcovded.items = feedback
          }
        } else {
          this.riskcovded.items = feedback
          // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.listriskcovded = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', e, 3000)
        this.loading.listriskcovded = false
      })
    },

    CheckRiskcovDed(value) {
			this.riskcovded.selected = value
		},

    SearchRiskcovDed () {
      this.riskcovded.searchbutton = true
      setTimeout(function() {
				document.getElementById('searchriskcovded').focus()
			}, 500)
    },

    OpenRiskcovDed () {
			this.dialog.riskcovded = true
			this.AddRiskcovDed()
		},

    AddRiskcovDed () {
      this.url_type                     = 'addriskcovded'
      this.form.riskcovded_id           = ''
      this.form.riskcovded_desc         = ''
      this.form.riskcovded_riskcovid    = ''
      this.form.riskcovded_actived      = true
      this.flag.riskcovded	  = true
			this.dialog.riskcovded  = true
      document.getElementById('riskcovded_id').value = ''
      document.getElementById('riskcovded_desc').value = ''
      setTimeout(function(){
        document.getElementById('riskcovded_id').focus()
      }, 500)
    },

    EditRiskcovDed (id, deductible) {
      this.flag.riskcovded			    = true
			this.dialog.riskcovded			  = true
			this.loading.riskcovdeddialog = true
			let formdata = {
				riskcovded_riskcovid  : id,
				riskcovded_id         : deductible,
				order_by              : 'riskcovded_id',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListRiskCovDed'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {              
              this.url_type = 'editriskcovded'
              this.form.riskcovded_id         = feedback[0].riskcovded_id
              this.form.riskcovded_riskcovid  = feedback[0].riskcovded_riskcovid
              this.form.riskcovded_desc       = feedback[0].riskcovded_desc
              this.form.riskcovded_actived    = this.$functions.TrueOrFalse(feedback[0].riskcovded_actived)
              
              this.form.riskcovded_id_par = feedback[0].riskcovded_id

              this.form.riskcovded_cdate = feedback[0].riskcovded_cdate
              this.form.riskcovded_cuser = feedback[0].riskcovded_cuser
              this.form.riskcovded_ldate = feedback[0].riskcovded_ldate
              this.form.riskcovded_luser = feedback[0].riskcovded_luser
              this.dialog.riskcovded = true              
            } else {
              this.SnackBar(
                true,
                'error',
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, 'error', feedback, 0)
          }
        this.loading.riskcovdeddialog = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.riskcovdeddialog = false
      })
    },

    DeleteRiskcovDed (id, deductible) {
      let formdata ={
				url_type              : 'deleteriskcovded',
				riskcovded_id         : deductible,
				riskcovded_riskcovid  : id
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCovDed'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then((response) => {
        let feedback = response.data          
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(
              true,
              'success',
              'Delete Success',
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = 'Ok'
            this.riskcovded.selected = []
            this.ListRiskcovDed(this.form.riskcov_id)
          } else {
            this.SnackBar(
              true,
              'error',
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
    },

    MultiProcessRiskcovDed () {
      let multiData = JSON.stringify(this.riskcovded.selected)
			let formdata  = {
				url_type        : this.url_type,				
				multi_data      : multiData
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiWriteRiskcovDed'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog 		          = false
          this.confirm.text 			          = 'Ok'
          this.riskcovded.selected  = []
          if (this.url_type === 'multideleteriskcovded') {
            this.ListRiskcovDed(this.form.riskcov_id)
          }
        } else {
          this.SnackBar(
            true,
            'error',
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },

    WriteRiskcovDed () {
      let deductible = document.getElementById('riskcovded_id').value

      let formdata ={
				url_type              : this.url_type,
				riskcovded_id         : deductible,
				riskcovded_riskcovid  : this.form.riskcov_id,
				riskcovded_desc       : this.form.riskcov_desc,
				riskcovded_actived    : this.$functions.ActivedFlag(this.form.riskcovded_actived),
        riskcovded_id_par     : this.form.riskcovded_id_par
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCovDed'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback =response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback ==='Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog =false
            this.confirm.text   ='Ok'
            if (this.url_type ==='addriskcovded') {
              this.form.riskcovded_cuser =feedback[0].feedback_users_id
              this.form.riskcovded_cdate =feedback[0].feedback_users_date
            }
            this.form.riskcovded_luser =feedback[0].feedback_users_id
            this.form.riskcovded_ldate =feedback[0].feedback_users_date
            this.url_type 			        ='editriskcovded'
            this.dialog.riskcovded  = false
						this.ListRiskcovDed(this.form.riskcov_id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   =true
            this.remarks.feedback =feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   =true
          this.remarks.feedback =feedback
        }
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   =true
				this.remarks.feedback =e
			})
    },
    // end: Function Riskcov Deductible

    // Function Riskcov Interest
    SelectAllRiskcovInt() {
			this.riskcovint.selected = this.riskcovint.selected === this.riskcovint.items ? [] : this.riskcovint.items
		},

    ListRiskCovInt (id) {
      this.loading.listriskcovint = true
			let formdata = {
				riskcovint_riskcovid  : id,
				order_by              : 'riskcovint_id',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListRiskCovInt'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data     
        if (feedback.length > 0) {
          if (feedback[0].feedback !== 'Y') {
            this.SnackBar(
              true,
              'error',
              feedback[0].feedback,
              0
            )
          } else {
            this.riskcovint.items = feedback
          }
        } else {
          this.riskcovint.items = feedback
          // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.listriskcovint = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', e, 3000)
        this.loading.listriskcovint = false
      })
    },

    CheckRiskcovInt(value) {
			this.riskcovint.selected = value
		},

    SearchRiskcovInt () {
      this.riskcovint.searchbutton = true
      setTimeout(function() {
				document.getElementById('searchriskcovint').focus()
			}, 500)
    },

    OpenRiskcovInt () {
			this.dialog.riskcovint = true
			this.AddRiskcovInt()
		},

    AddRiskcovInt () {
      this.url_type                   = 'addriskcovint'
      this.form.riskcovint_id         = ''
      this.form.riskcovint_intdesc    = ''
      this.form.riskcovint_riskcovid  = ''
      this.form.riskcovint_indemnity  = 0
      this.form.riskcovint_category   = ''
      this.form.riskcovint_tlof       = false
      this.form.riskcovint_actived    = true

      this.$refs.riskcovint_indemnity.$el.getElementsByTagName('input')[0].value  = 0

      this.flag.riskcovint	  = true
			this.dialog.riskcovint  = true
      document.getElementById('riskcovint_id').value = ''
      document.getElementById('riskcovint_intdesc').value = ''
      setTimeout(function(){
        document.getElementById('riskcovint_id').focus()
      }, 500)
      
    },

    EditRiskcovInt (id, interest) {
      this.flag.riskcovint			    = true
			this.dialog.riskcovint			  = true
			this.loading.riskcovintdialog = true
			let formdata = {
				riskcovint_riskcovid  : id,
				riskcovint_id         : interest,
				order_by              : 'riskcovint_id',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListRiskCovInt'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {              
              this.url_type = 'editriskcovint'
              this.form.riskcovint_id         = feedback[0].riskcovint_id
              this.form.riskcovint_intdesc    = feedback[0].riskcovint_intdesc
              this.form.riskcovint_riskcovid  = feedback[0].riskcovint_riskcovid
              this.form.riskcovint_indemnity  = feedback[0].riskcovint_indemnity
              this.form.riskcovint_category   = feedback[0].riskcovint_category
              this.form.riskcovint_tlof       = this.$functions.TrueOrFalse(feedback[0].riskcovint_tlof)
              this.form.riskcovint_actived    = this.$functions.TrueOrFalse(feedback[0].riskcovint_actived)

              this.$refs.riskcovint_indemnity.$el.getElementsByTagName('input')[0].value  = feedback[0].riskcovint_indemnity
              
              this.form.riskcovint_id_par = feedback[0].riskcovint_id

              this.form.riskcovint_cdate = feedback[0].riskcovint_cdate
              this.form.riskcovint_cuser = feedback[0].riskcovint_cuser
              this.form.riskcovint_ldate = feedback[0].riskcovint_ldate
              this.form.riskcovint_luser = feedback[0].riskcovint_luser
              this.dialog.riskcovint = true              
            } else {
              this.SnackBar(
                true,
                'error',
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, 'error', feedback, 0)
          }
        this.loading.riskcovintdialog = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.riskcovintdialog = false
      })
    },

    DeleteRiskcovInt (id, interest) {
      let formdata ={
				url_type              : 'deleteriskcovint',
				riskcovint_id         : interest,
				riskcovint_riskcovid  : id
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCovInt'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then((response) => {
        let feedback = response.data          
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(
              true,
              'success',
              'Delete Success',
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = 'Ok'
            this.riskcovint.selected = []
            this.ListRiskcovInt(this.form.riskcov_id)
          } else {
            this.SnackBar(
              true,
              'error',
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
    },

    MultiProcessRiskcovInt () {
      let multiData = JSON.stringify(this.riskcovint.selected)
			let formdata  = {
				url_type        : this.url_type,				
				multi_data      : multiData
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiWriteRiskCovInt'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog 		          = false
          this.confirm.text 			          = 'Ok'
          this.riskcovint.selected  = []
          if (this.url_type === 'multideleteriskcovint') {
            this.ListRiskcovInt(this.form.riskcov_id)
          }
        } else {
          this.SnackBar(
            true,
            'error',
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },

    WriteRiskcovInt () {
      let interest = document.getElementById('riskcovint_id').value

      let formdata ={
				url_type              : this.url_type,
				riskcovint_id         : interest,
				riskcovint_riskcovid  : this.form.riskcov_id,
				riskcovint_indemnity  : this.form.riskcovint_indemnity,
				riskcovint_category   : this.form.riskcovint_category,
				riskcovint_tlof       : this.$functions.ActivedFlag(this.form.riskcovint_tlof),
				riskcovint_actived    : this.$functions.ActivedFlag(this.form.riskcovint_actived),
        riskcovint_id_par     : this.form.riskcovint_id_par
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCovInt'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback =response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback ==='Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog =false
            this.confirm.text   ='Ok'
            if (this.url_type ==='addriskcovint') {
              this.form.riskcovint_cuser =feedback[0].feedback_users_id
              this.form.riskcovint_cdate =feedback[0].feedback_users_date
            }
            this.form.riskcovint_luser  =feedback[0].feedback_users_id
            this.form.riskcovint_ldate  =feedback[0].feedback_users_date
            this.url_type 			        ='editriskcovint'
            this.dialog.riskcovint      = false
						this.ListRiskcovInt(this.form.riskcov_id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   =true
            this.remarks.feedback =feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   =true
          this.remarks.feedback =feedback
        }
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   =true
				this.remarks.feedback =e
			})
    },
    // end: Function Riskcov Interest

    // Function Riskcov Loss
    SelectAllRiskcovLoss() {
			this.riskcovloss.selected = this.riskcovloss.selected === this.riskcovloss.items ? [] : this.riskcovloss.items
		},

    ListRiskCovLoss (id) {
      this.loading.listriskcovloss = true
			let formdata = {
				riskcovloss_riskcovid  : id,
				order_by              : 'riskcovloss_id',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListRiskCovLoss'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data     
        if (feedback.length > 0) {
          if (feedback[0].feedback !== 'Y') {
            this.SnackBar(
              true,
              'error',
              feedback[0].feedback,
              0
            )
          } else {
            this.riskcovloss.items = feedback
          }
        } else {
          this.riskcovloss.items = feedback
          // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
        this.loading.listriskcovloss = false
      })
      .catch((e) => {
        this.SnackBar(true, 'error', e, 3000)
        this.loading.listriskcovloss = false
      })
    },

    CheckRiskcovLoss(value) {
			this.riskcovloss.selected = value
		},

    SearchRiskcovLoss () {
      this.riskcovloss.searchbutton = true
      setTimeout(function() {
				document.getElementById('searchriskcovloss').focus()
			}, 500)
    },

    OpenRiskcovLoss () {
			this.dialog.riskcovloss = true
			this.AddRiskcovLoss()
		},

    AddRiskcovLoss () {
      this.url_type                   = 'addriskcovloss'
      this.form.riskcovloss_id        = ''
      this.form.riskcovloss_lossdesc  = ''
      this.form.riskcovloss_riskcovid = ''
      this.form.riskcovloss_actived   = true
      this.flag.riskcovloss	  = true
			this.dialog.riskcovloss = true
      document.getElementById('riskcovloss_id').value = ''
      document.getElementById('riskcovloss_lossdesc').value = ''
      setTimeout(function(){
        document.getElementById('riskcovloss_id').focus()
      }, 500)
    },

    EditRiskcovLoss (id, loss) {
      this.flag.riskcovloss			    = true
			this.dialog.riskcovloss			  = true
			this.loading.riskcovlossdialog = true
			let formdata = {
				riskcovloss_riskcovid  : id,
				riskcovloss_id         : loss,
				order_by              : 'riskcovloss_id',
				order_type            : 'ASC',
				limit                 : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListRiskCovLoss'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {              
              this.url_type = 'editriskcovloss'
              this.form.riskcovloss_id         = feedback[0].riskcovloss_id
              this.form.riskcovloss_lossdesc   = feedback[0].riskcovloss_lossdesc
              this.form.riskcovloss_riskcovid  = feedback[0].riskcovloss_riskcovid
              this.form.riskcovloss_actived    = this.$functions.TrueOrFalse(feedback[0].riskcovloss_actived)
              
              this.form.riskcovloss_id_par = feedback[0].riskcovloss_id

              this.form.riskcovloss_cdate = feedback[0].riskcovloss_cdate
              this.form.riskcovloss_cuser = feedback[0].riskcovloss_cuser
              this.form.riskcovloss_ldate = feedback[0].riskcovloss_ldate
              this.form.riskcovloss_luser = feedback[0].riskcovloss_luser
              this.dialog.riskcovloss = true              
            } else {
              this.SnackBar(
                true,
                'error',
                this.$functions.ErrorCode(),
                0
              )
              this.remarks.dialog   = true
              this.remarks.feedback = feedback[0].feedback
            }
          } else {
            this.SnackBar(true, 'error', feedback, 0)
          }
        this.loading.riskcovlossdialog = false
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.loading.riskcovlossdialog = false
      })
    },

    DeleteRiskcovLoss (id, loss) {
      let formdata ={
				url_type              : 'deleteriskcovloss',
				riskcovloss_id         : loss,
				riskcovloss_riskcovid  : id
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCovLoss'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then((response) => {
        let feedback = response.data          
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(
              true,
              'success',
              'Delete Success',
              3000
            )
            this.confirm.dialog = false
            this.confirm.text = 'Ok'
            this.riskcovloss.selected = []
            this.ListRiskCovLoss(this.form.riskcov_id)
          } else {
            this.SnackBar(
              true,
              'error',
              this.$functions.ErrorCode(),
              0
            )
            this.remarks.dialog 	= true
            this.remarks.feedback 	= feedback[0].feedback
          }
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog 	= true
        this.remarks.feedback 	= e
      })
    },

    MultiProcessRiskcovLoss () {
      let multiData = JSON.stringify(this.riskcovloss.selected)
			let formdata  = {
				url_type        : this.url_type,				
				multi_data      : multiData
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiWriteRiskCovLoss'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback[0].feedback === 'Y') {
          let feed
          if (feedback[0].feedback_failed == 0) {
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
          } else {
            this.remarks.feedback = feedback[0].feedback_failed_remarks
            feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
            this.remarks.dialog = true
          }
          this.SnackBar(true, 'success', feed, 0)
          this.confirm.dialog 		          = false
          this.confirm.text 			          = 'Ok'
          this.riskcovloss.selected  = []
          if (this.url_type === 'multideleteriskcovloss') {
            this.ListRiskCovLoss(this.form.riskcov_id)
          }
        } else {
          this.SnackBar(
            true,
            'error',
            this.$functions.ErrorCode(),
            0
          )
          this.remarks.dialog = true
          this.remarks.feedback = feedback[0].feedback
        }
      })
      .catch((e) => {
        this.SnackBar(
          true,
          'error',
          this.$functions.ErrorCode(),
          0
        )
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },

    WriteRiskcovLoss () {
      let loss = document.getElementById('riskcovloss_id').value

      let formdata ={
				url_type              : this.url_type,
				riskcovloss_id        : loss,
				riskcovloss_riskcovid : this.form.riskcov_id,
				riskcovloss_actived   : this.$functions.ActivedFlag(this.form.riskcovloss_actived),
        riskcovloss_id_par    : this.form.riskcovloss_id_par
			}
      let param 	   =this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteRiskCovLoss'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback =response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback ==='Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog =false
            this.confirm.text   ='Ok'
            if (this.url_type ==='addriskcovloss') {
              this.form.riskcovloss_cuser =feedback[0].feedback_users_id
              this.form.riskcovloss_cdate =feedback[0].feedback_users_date
            }
            this.form.riskcovloss_luser =feedback[0].feedback_users_id
            this.form.riskcovloss_ldate =feedback[0].feedback_users_date
            this.url_type 			        ='editriskcovloss'
            this.dialog.riskcovloss     = false
						this.ListRiskCovLoss(this.form.riskcov_id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog   =true
            this.remarks.feedback =feedback[0].feedback
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog   =true
          this.remarks.feedback =feedback
        }
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog   =true
				this.remarks.feedback =e
			})
    },
    // end: Function Riskcov Loss

    SnackBar (dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout
      }
      if (color === 'error') {
        this.confirm.dialog 	 = false
        this.confirm.text 	 	 = 'Ok'
      }
    },

    GetListCode (value) {
      let formdata = {
				code_group  : value,
				order_by    : 'code_id',
				order_type  : 'ASC',
				limit       : this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios
      .post(this.$functions.UrlPOST('apiListCode'), param, {
        headers: {
          'Content-Type': 'text/plain charset=ISO-8859-1',
        },
      })
      .then((response) => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback !== 'Y') {
            this.SnackBar(
              true,
              'error',
              feedback[0].feedback,
              0
            )
          } else {
            if (value === 'RATEUNIT') {
              this.riskcov_unit = feedback
            } else if (value === 'RISKCATEGORY') {
              this.riskcov_category     = feedback
              this.riskcovint_category  = feedback
            }
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.NoData(), 0)
        }
      })
      .catch((e) => {
        this.SnackBar(true, 'error', e, 3000)
      })
    },
    ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	}
}
</script>