<template>
    <div id="app-profile-organization">
    <v-progress-linear
          indeterminate
          color="fourth"
          rounded
          v-if="loading.page"
        ></v-progress-linear>
    <v-card-title 
        class="ml-n4"
    >
        <v-btn 
        :color 	="selected.length === items.length ? 'fourth':''"
        @click	="SelectAllPage"
        class	="mr-2"
        title	="select all page"
        small
        >{{$vars.V('txt_select_all_page')}}
        </v-btn>
        <v-btn 
        color     = "error"
        title 	  = "delete selected"
        @click 	  = "Confirm('multidelete','')"
        class	  = "mr-2"
        v-if  	  = "selected.length > 0"
        :disabled = "access.delete === 0 ? true:false"
        small>
        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
        </v-btn>    
        <v-btn 
        @click	="Add"
        class	="mr-2"
        title	="Add New"
        small
        >{{$vars.V('txt_add')}}
        </v-btn>
       <v-btn 
          @click="List(id)"
          class="mr-2"
          title="Refresh"
          small
          ><v-icon>{{$vars.V('icon_refresh')}}</v-icon>
        </v-btn>
        <v-btn 
        color  = "primary"
        title  = "search"
        v-if   = "btn_search === false"
        @click = "btn_search = true"
        small>
        <v-icon>{{$vars.V('icon_search')}}</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        @click:append="btn_search = false"
        label="Search"
        single-line
        hide-details
        v-if ="btn_search"
        ></v-text-field>
    </v-card-title>
    <v-data-table
    :value="selected"
    :headers="headers"
    :items="items"
    :search="search"
    @input="Check($event)"
    item-key="profileorganization_id"
    show-select
    class="elevation-1"
    :header-props="{ 'sort-icon': 'mdi-arrow-down-circle' }"
    :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
    }"
    dense
    :loading 	= "loading.detail"
    loading-text="Loading... Please wait"
    >
    <template v-slot:item.profileorganization_capitalshares="{ item }">
        {{$functions.NewFormatNumber(item.profileorganization_capitalshares)}}
    </template>
    <template v-slot:item.profileorganization_capitalamount="{ item }">
        {{$functions.NewFormatNumber(item.profileorganization_capitalamount)}}
    </template>
    <template v-slot:item.action="{ item }">
        <v-icon
        small
        @click	  ="Edit(item.profileorganization_id)"
        :disabled ="access.edit === 0 ? true:false"
        title 	  = "edit"
        >
        {{$vars.V('icon_edit')}}
        </v-icon>
    </template>
    </v-data-table>
    <!-- Start Form -->
    <v-dialog
      v-model="dialog.detail"
      max-width="800"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-toolbar dark color="primary" dense class="mb-2">
          <v-btn icon dark @click="dialog.detail = false">
            <v-icon>{{$vars.V('icon_close')}}</v-icon>
          </v-btn>
          <v-toolbar-title>Organization</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="Add" title="Add New"><v-icon color="white">add</v-icon></v-btn>
          <v-btn icon dark @click="Confirm(url_type, '')" title="Save"><v-icon color="white">save</v-icon></v-btn>
        </v-toolbar>
      <v-card-text>
      <v-form enctype="multipart/form-data" ref="form_organization" lazy-validation>
        <v-row class="mx-auto">
            <v-col cols="12" sm="12" md="12" class="mt-3">
                <app-cb
                cb_type                ="profile"
                cb_url                 ="apiListProfile"
                cb_url_parameter       ="profile_lob=06&profile_actived=Y"
                cb_title               ="Person"
                cb_id                  ="profileorganization_refid"
                cb_desc                ="profileorganization_refdesc"
                cb_rules               ="Person"
                cb_desc_readonly       ="readonly"
                cb_items_id            ="profile_id"
                cb_items_desc          ="profile_fullname"
                cb_items_additional_1  ="profile_address"
                :cb_value_id		   ="form.profileorganization_refid"
                :cb_value_desc	       ="form.profileorganization_refdesc"
                @clicked               ="GetProfile"
                >
                </app-cb>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="mt-n3">
              <app-cb
                cb_type             ="code"
                cb_url              ="apiListCode"
                cb_url_parameter    ="code_actived=Y&code_group=PROFILEORGTYPE"
                cb_title            ="Type"
                cb_id               ="profileorganization_type"
                cb_desc             ="profileorganization_typedesc"
                cb_rules            ="Please fill Type"
                cb_items_id         ="code_id"
                cb_items_desc       ="code_desc"
                :cb_value_id        ="form.profileorganization_type"
                :cb_value_desc      ="form.profileorganization_typedesc"
              >
              </app-cb>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-n6">
              <v-text-field 
              v-model       ="form.profileorganization_name"
              required 
              label       	="Name"
              placeholder 	="Name"
              id 		    ="profileorganization_name"
              :rules 	  	="form.profileorganization_name_rules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="mt-n6">
              <v-text-field 
              v-model       ="form.profileorganization_title"
              required 
              label       	="Title"
              placeholder 	="Title"
              id 		    ="profileorganization_title"
              :rules 	    ="form.profileorganization_title_rules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <vuetify-money
                v-model           ="form.profileorganization_capitalshares"
                v-bind:options    ="formatamount"
                label             ="Capital In Shares"
                placeholder       ="Capital In Shares"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <vuetify-money
                v-model           ="form.profileorganization_capitalpct"
                v-bind:options    ="formatrate"
                label             ="Capital In Percentage"
                placeholder       ="Capital In Percentage"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <vuetify-money
                v-model           ="form.profileorganization_capitalamount"
                v-bind:options    ="formatamount"
                label             ="Capital In Amount"
                placeholder       ="Capital In Amount"
                background-color  ="numeric"
                v-bind:properties ="properties"
              />
            </v-col>
            <!-- Active -->
            <v-col cols="2" sm="2" md="2" class="mt-n5">
              <label class="caption">Active Status</label>
              <v-switch
                v-model="form.profileorganization_actived"
                color="primary"
                id="form.profileorganization_actived"
                :label="form.profileorganization_actived !== true ? '' : 'Active'"
              ></v-switch>
            </v-col>
        </v-row>
      </v-form>
      </v-card-text>
      <v-card-actions>
        
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model	  ="snackbar.dialog"
      :timeout  ="snackbar.timeout"
      color		  ="fourth"
      rounded	  ="pill"
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
      <v-card :color="Variable('confirm',4)">
        <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
        <v-card-text></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            id="dialog"
            :color="Variable('confirm',1)"
            :disabled   ="confirm.text === 'Ok' ? false:true"
            @click      ="Loading"
          >
            {{confirm.text}}
          </v-btn>
          <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Organization -->
    </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    id: String,
  },
  data: () => ({
    url_type: 'add',
    access: {
      read    : 0,
      add     : 0,
      edit    : 0,
      delete  : 0,
    },
    remarks: {
        feedback: "",
        dialog: false,
    },
    snackbar: {
        dialog: false,
        color: "success",
        text: "",
        timeout: 3000,
    },
    confirm: {
        dialog: false,
        title: "",
        text: "Ok",
    },
    loading: {
        page      : true,
        detail    : true,
    },
    dialog: {
        detail: false
    },
    formatamount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    formatrate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 5,
      precision: 2
    },
    properties: {
      reverse: true
    },
    selected: [],
    items: [],
    btn_search: false,
    search: '',
    headers: [
      { text: 'ID', value: 'profileorganization_id' },
      { text: 'Type', value: 'profileorganization_type' },
      { text: 'Name', value: 'profileorganization_name' },
      { text: 'Title', value: 'profileorganization_title' },
      { text: 'Capital Shares', value: 'profileorganization_capitalshares', align:'right' },
      { text: 'Capital PCT', value: 'profileorganization_capitalpct', align:'right' },
      { text: 'Capital Amount', value: 'profileorganization_capitalamount', align:'right' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    form: {
        profileorganization_id: '',
        profileorganization_profileid: '',
        profileorganization_refid: '',
        profileorganization_refdesc: '',
        profileorganization_refaddress: '',
        profileorganization_type: '',
        profileorganization_name: '',
        profileorganization_name_rules: [v => !!v || 'Please fill Name'],
        profileorganization_title: '',
        profileorganization_title_rules: [v => !!v || 'Please fill Title'],
        profileorganization_capitalshares: 0,
        profileorganization_capitalpct: 0,
        profileorganization_capitalamount: 0,
        profileorganization_actived: true,
    },
   }),
   created() {
    this.List(this.id)
   },
   methods: {
    Access() {
        let modul = 'profile'
        let formdata = {
            menu_id     : modul,
            users_id    : this.$functions.UsersID(),
            order_by    : 'menu_id',
            order_type  : 'ASC',
            limit       : this.limit,
        }
        let param = this.$functions.ParamPOST(formdata)
        this.$axios
        .post(this.$functions.UrlPOST('apiListMenuUser'), param, {
            headers: {
            'Content-Type': 'text/plain charset=ISO-8859-1',
            },
        })
        .then((response) => {
            let priv = response.data
            if (priv.length > 0) {
            this.access.read = priv[0].usersmenu_read
            this.access.add = priv[0].usersmenu_add
            this.access.edit = priv[0].usersmenu_edit
            this.access.delete = priv[0].usersmenu_delete
            if (priv[0].usersmenu_read === 0) {
                this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            } else {
            this.SnackBar(true, 'error', this.$functions.NoPriviledge(modul), 0)
            }
            this.loading.page = false
        })
        .catch((e) => {
            this.SnackBar(true, 'error', e, 0)
            this.loading.page = false
        })
    },
    SnackBar(dialog, color, text, timeout) {
        this.snackbar = {
            dialog: dialog,
            color: color,
            text: text,
            timeout: timeout,
        }
        if (color === "error") {
            this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
        }
    },
    Variable (flag, position) {
        return this.$functions.Variable(flag,position)
    },
    SelectAllPage () {
        this.selected = this.selected === this.items ? [] : this.items
    },
    Check (value) {
        this.selected = value
    },
    Loading () {
        this.confirm.text = 'Loading...'
        if (this.url_type === 'add' || this.url_type === 'edit') {
            this.Write()
        } else if (this.url_type === 'delete') {
            this.Delete(this.form.profileorganization_id)
        } else if (this.url_type === 'multidelete') {
            this.MultiProcess('delete')
        }
    },
    Write () {
      let profileorganization_type = document.getElementById('profileorganization_type').value
      let formdata = {
        url_type: this.url_type,
        profileorganization_id: this.form.profileorganization_id,
        profileorganization_profileid: this.form.profileorganization_profileid,
        profileorganization_refid: this.form.profileorganization_refid,
        profileorganization_type: profileorganization_type,
        profileorganization_name: this.form.profileorganization_name,
        profileorganization_title: this.form.profileorganization_title,
        profileorganization_capitalshares: String(this.form.profileorganization_capitalshares),
        profileorganization_capitalpct: String(this.form.profileorganization_capitalpct),
        profileorganization_capitalamount: String(this.form.profileorganization_capitalamount),
        profileorganization_actived: this.$functions.ActivedFlag(this.form.profileorganization_actived),
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileOrganization'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.dialog.detail = false
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            if (this.url_type === 'add') {
              this.form.profileorganization_cuser  = feedback[0].feedback_users_id
              this.form.profileorganization_cdate  = feedback[0].feedback_users_date
            }
            this.List(this.id)
            this.form.profileorganization_luser  = feedback[0].feedback_users_id
            this.form.profileorganization_ldate  = feedback[0].feedback_users_date
            this.url_type 		      = 'edit'
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            
          }
        } else {
          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
          this.remarks.dialog = true
          this.remarks.feedback = feedback
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
      })
    },
    Delete (id) {
      let formdata = {
        url_type: 'delete',
        profileorganization_id: id
      }
      let param 	   = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteProfileOrganization'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
            this.List(this.id)
          } else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog = true
            this.remarks.feedback = feedback[0].feedback
            this.confirm.dialog  	= false
            this.confirm.text 		= 'Ok'
          }
        }
      })
      .catch(e => {
        this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
        this.remarks.dialog = true
        this.remarks.feedback = e
        this.confirm.dialog  	= false
        this.confirm.text 		= 'Ok'
      })
    },
    Add() { 
      this.dialog.detail = true
      this.url_type = 'add'
      this.form.profileorganization_refid = ''
      this.form.profileorganization_refdesc = ''
      this.form.profileorganization_type = ''
      this.form.profileorganization_typedesc = ''
      this.form.profileorganization_name = ''
      this.form.profileorganization_title = ''
      this.form.profileorganization_capitalshares = 0
      this.form.profileorganization_capitalpct = 0
      this.form.profileorganization_capitalamount = 0
      setTimeout(function () {
        document.getElementById("profileorganization_refid").focus();
      }, 500)
    },
    Edit (id) {
      if (id !== '') {
        this.Add()
        this.loading.detail = true
        let formdata = {
          profileorganization_id: id
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileOrganization'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback === 'Y') {
                this.dialog.detail = true
                this.url_type = 'edit'
                this.form.profileorganization_id = feedback[0].profileorganization_id
                this.form.profileorganization_profileid = feedback[0].profileorganization_profileid
                this.form.profileorganization_refid = feedback[0].profileorganization_refid
                this.form.profileorganization_refdesc = feedback[0].profileorganization_refdesc
                this.form.profileorganization_type = feedback[0].profileorganization_type
                this.form.profileorganization_typedesc = feedback[0].profileorganization_typedesc
                this.form.profileorganization_name = feedback[0].profileorganization_name
                this.form.profileorganization_title = feedback[0].profileorganization_title
                this.form.profileorganization_capitalshares = feedback[0].profileorganization_capitalshares
                this.form.profileorganization_capitalpct = feedback[0].profileorganization_capitalpct
                this.form.profileorganization_capitalamount = feedback[0].profileorganization_capitalamount
                this.form.profileorganization_actived = this.$functions.TrueOrFalse(feedback[0].profileorganization_actived)
            } else {
                this.SnackBar(true, 'error', feedback[0].feedback, '3000')
            }
          } else {
            this.SnackBar(true, 'error', this.$functions.NoData(), '3000')
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    Confirm (flag, id) {
      if (flag === 'add' || flag === 'edit') {
        if (this.$refs.form_organization.validate()) {
          this.confirm.dialog   	= true
          this.confirm.title    	= 'Save'
          setTimeout(function(){
          document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type	  = 'delete'
        this.confirm.dialog       = true
        this.confirm.title        = 'Delete `'+ id + '`'
        this.form.profileorganization_id 	        = id
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 		        = 'multidelete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
        document.getElementById('dialog').focus()
        }, 500)
      }
    },
    List (id) {
      this.Access()
      this.items = []
      if (id !== '') {
        this.loading.detail = true
        this.form.profileorganization_profileid = id
        let formdata = {
          profileorganization_profileid: id,
          order_by: 'profileorganization_profileid',
          order_type: 'ASC',
          limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListProfileOrganization'),param,{
          headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
        })
        .then(response => {
          let feedback = response.data
          if (feedback.length > 0) {
            if (feedback[0].feedback !== 'Y') {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
            } else {
              this.items = feedback
            }
          } else {
            this.items = feedback
            // this.SnackBar(true, 'error', this.$functions.NoData(), 0)
          }
          this.loading.detail = false
        })
        .catch(e => {
          this.SnackBar(true, 'error', e, 3000)
          this.loading.detail = false
        })
      }
    },
    GetProfile(value) {
        if (value !== undefined) {
            let splits = this.$functions.Split(value, "|")
            let address = splits[3]
            this.form.profileorganization_refid       = splits[0]
            this.form.profileorganization_refdesc     = splits[1]
            this.form.profileorganization_name        = splits[1]
            this.form.profileorganization_refaddress  = address
        }
    },
    }
}
</script>